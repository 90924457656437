import type { LiteralUnion } from "../types"

export class SuperMap<K extends string, T extends object, TRequireDefault extends boolean = true, TPartial extends boolean = false> {
    public readonly array: Array<T & { key: K }>
    protected map: Record<K | (true extends TRequireDefault ? "default" : never), T & { key: K }>

    public enum: { [Key in K]: Key }
    constructor(map: PartialCond<Record<K | (true extends TRequireDefault ? "default" : never), T & { key?: K }>, TPartial>) {
        this.array = Object.keys(map).map((key) => ({ key: key as K, ...map[key as K] }))

        this.map = {} as any
        for (const item of this.array) {
            this.map[item.key] = item
        }

        this.enum = Object.keys(map)
            .filter((key) => key !== "default")
            .reduce((acc, key) => ({ ...acc, [key]: key }), {}) as any
    }
    /**
     * Créé un dictionnaire pour une colonne spécifique
     */
    getDict<K2 extends keyof T, IncDef extends boolean>(k: K2, includeDefault?: IncDef): IncDef extends true ? Record<K | "default", T[K2]> : Record<K, T[K2]> {
        const dict: any = {}
        for (const item of this.array) {
            if (!includeDefault && item.key === "default") continue
            dict[item.key] = item[k]
        }
        return dict
    }

    get(key: LiteralUnion<true extends TRequireDefault ? K | "default" : K>) {
        if (key) {
            return this.map[key as K]! ?? this.map["default" as keyof typeof this.map]!
        } else {
            return this.map["default" as keyof typeof this.map]!
        }
    }
}

type PartialCond<T extends object, C extends boolean> = C extends true ? Partial<T> : T
