import { DayDate } from "../tools/DayDate"
import { age } from "../tools/format"
import type { LiteralUnion } from "../types"

type gender = LiteralUnion<"F" | "M">

export class WPerson {
    // gender: Nullable<gender>
    // birthDate: string
    // firstName:string
    // lastName:string

    // constructor(object){
    //     Object.assign(this,object);
    // }

    static getMadame(person: { gender: gender }) {
        return { M: "Monsieur", F: "Madame" }[person.gender]
    }

    static getMme(person: { gender: gender }) {
        const mme = { M: "M.", F: "Mme" }[person.gender]
        return mme
    }

    static getFullNameShort(person: { lastName: string; firstName: string }) {
        return `${person.firstName} ${person.lastName}`
    }

    static getFullNameLong(person: { lastName: string; firstName: string; gender: gender }) {
        return `${this.getMme(person)} ${person.lastName?.toUpperCase()} ${person.firstName}`
    }

    static getFormattedBirthDate(person: { birthDate: string }, customFormat = "dd-LL-yyyy") {
        return DayDate.fromISOString(person.birthDate).toFormat(customFormat)
    }
    static getFormattedAge(person: { birthDate: string }) {
        return age(DayDate.fromISOString(person.birthDate))
    }
}
