import { commonComboBoxModel, commonDialogModel, commonTextFieldModel } from "core/controls"
import { Agent, rasmik } from "core/services/rasmik"
import { EzModel } from "core/store/base"
import { notistack } from "core/utils/notistack-global"
import { Action, Child, Listener, Model, Property, Thunk } from "easy-peasy-classes"
import { isNil } from "lodash"

type emailData = {
    sender: string
    senderId: number
    recipient: string
    subject: string
    html: string
}

@Model
class unlayerModel extends EzModel {
    @Property
    template: any = undefined

    @Property
    html: string = null

    @Property
    design: any = null

    @Action
    onCollect({ html, design }: { html: string; design: any }) {
        this.html = html
        this.design = design
    }
}

@Model
export class newEmailModel extends commonDialogModel<{ emailData: emailData; design: Record<string, any> }> {
    @Child
    lstSender$: commonComboBoxModel = commonComboBoxModel.obj

    @Child
    lstRecipient$: commonComboBoxModel = commonComboBoxModel.obj

    @Child
    txtSubject$: commonTextFieldModel = commonTextFieldModel.obj

    @Child
    unlayer: unlayerModel = unlayerModel.obj

    @Action
    setDesign(design: string) {
        const parsedDesign = JSON.parse(design)
        this.unlayer.design = parsedDesign
    }

    @Thunk
    async init({ senderId, recipient, design, subject }: { recipient: string; design: string; subject: string; senderId: number }) {
        //    this.setStateFn(s=>{s.unsignedTemplate = design})
        await this.getStoreState().auth.ensureValidToken()

        const agent = await rasmik
            .readOne(Agent)
            .where(senderId)
            .options({ include: ["signature", "email", "username", "id"] })
            .run()

        const signedDesign = design.replace(/<!--signature-start-->.*<!--signature-end-->/g, `<!--signature-start-->${JSON.stringify(agent.signature).slice(1, -1)}<!--signature-end-->`)

        this.lstRecipient$.setSettings({ lookupKey: "email", labelKey: "email" })
        this.lstSender$.setSettings({ lookupKey: "id", labelKey: "email" })
        this.lstRecipient$._setData([{ email: recipient }])
        this.lstSender$._setData(this.getStoreState().core.db.agents.filter((agent) => agent.isActive))
        this.lstRecipient$.setValue(recipient)
        this.lstSender$.setValue(agent.id)

        this.txtSubject$.setValue(subject)

        this.setDesign(signedDesign)

        this.show()

        return this.promise()
    }

    @Listener<newEmailModel>((actions) => [actions.lstSender$.handleChange])
    async updateSignature() {
        const newId = this.lstSender$.value

        window.unlayer.exportHtml(async (data: { html: any; design: any }) => {
            const { html, design } = data

            if (!isNil(newId)) {
                await this.getStoreState().auth.ensureValidToken()

                const newAgent = await rasmik
                    .readOne(Agent)
                    .where(newId)
                    .options({ include: ["signature", "email", "username", "id"] })
                    .run()

                const newSignedDesign = JSON.stringify(design).replace(/<!--signature-start-->.*<!--signature-end-->/g, `<!--signature-start-->${JSON.stringify(newAgent.signature).slice(1, -1)}<!--signature-end-->`)
                this.setDesign(newSignedDesign)
            } else {
                const newSignedDesign = JSON.stringify(design).replace(/<!--signature-start-->.*<!--signature-end-->/g, `<!--signature-start-->${""}<!--signature-end-->`)
                this.setDesign(newSignedDesign)
            }
        })
    }

    @Thunk
    validate({ html, design }: { html: string; design: Record<string, any> }) {
        const emailData = this.collect({ html })

        if (!emailData.senderId || !emailData.recipient || !emailData.subject) {
            notistack.legacy({ severity: "warning", delay: 3000, msg: "Incomplet - Un email doit avoir un expediteur, un destinatare et un sujet" })
            return
        }

        this.endDialog(["success", { emailData, design }])
    }

    @Thunk
    collect({ html }: { html: string }) {
        //console.log(JSON.stringify(design));

        const htmlFixed = html.replace("min-height: 100vh;", "")

        const emailData = {
            sender: this.lstSender$.inputValue,
            senderId: this.lstSender$.value?.id,
            recipient: this.lstRecipient$.inputValue,
            subject: this.txtSubject$.value,
            html: htmlFixed,
        }
        return emailData
    }

    @Thunk
    cancel() {
        this.endDialog(["cancel"])
    }
}
