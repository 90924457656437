import type { TeachingWithLevelsPopulated } from "core/services/rasmik"
import { rasmik, Teacher } from "core/services/rasmik"
import { formatString } from "core/utils/tools/format"

import { WPerson } from "./person"

//use of DraftData + required is ok because relation keys are omitted if not explicitely defined. (evite problème Candidature | number is not assignable to Candidature)
//Loaded semble mieux car il signale quand on oublie de mettre dans include
//Mais loaded a les relation keys en number
//pushdata a le même pb que draft data (trop)
type THIS = typeof WTeacher
export class WTeacher extends WPerson {
    static statusDict = {
        ACTIVE: "Actif",
        FINISHED: "Terminé",
        PAUSED: "En pause",
        DRAFT: "Brouillon",
    }

    private static getFormattedNumSecuShape = rasmik.define
        .pickData(Teacher)
        .def({ required: ["numSecu"] })
        .shape()
    static getFormattedNumSecu(teacher: THIS["getFormattedNumSecuShape"]) {
        return formatString(teacher.numSecu, "X XX XX XX XXX XXX XX")
    }

    private static getFullBirthShape = rasmik.define
        .pickData(Teacher)
        .def({ required: ["birthCity", "birthDate"] })
        .shape()
    static getFullBirth(teacher: THIS["getFullBirthShape"], customFormat?: string) {
        return "le " + this.getFormattedBirthDate(teacher, customFormat) + " à " + teacher.birthCity
    }

    private static getFormattedStatusShape = rasmik.define
        .pickData(Teacher)
        .def({ required: ["status"] })
        .shape()
    static getFormattedStatus(teacher: THIS["getFormattedStatusShape"]) {
        const status = teacher.status || ""

        const dict = WTeacher.statusDict

        return dict[status] || ""
    }

    private static getStatusColorShape = rasmik.define
        .pickData(Teacher)
        .def({ required: ["status"] })
        .shape()
    static getStatusColor(teacher: THIS["getStatusColorShape"]) {
        const status = teacher.status || ""
        const dict = {
            ACTIVE: "green",
            FINISHED: "brown",
            PAUSED: "orange",
            DRAFT: "gray",
        }

        return dict[status] || ""
    }

    //TODO: trouver où utilisé et mettre TeachingLevels
    private static getFormattedTeachingLevelsShape = rasmik.define
        .pickData(Teacher)
        .def({ required: ["$Teachings"] })
        .shape()
    static getFormattedTeachingLevels(teacher: THIS["getFormattedTeachingLevelsShape"]) {
        const Teachings = (teacher.$Teachings || []) as unknown as TeachingWithLevelsPopulated[]

        const strArray = Teachings.map((teaching) => {
            const teachingName = teaching.name

            const levelsCount = teaching.Levels?.length || 0

            const orderArr = teaching.Levels?.map((level) => level.orderNb) || []
            const minOrder = Math.min(...orderArr)
            const maxOrder = Math.max(...orderArr)

            const minLevel = teaching.Levels?.find((level) => level.orderNb === minOrder)
            const maxLevel = teaching.Levels?.find((level) => level.orderNb === maxOrder)

            //console.log({levelsCount,orderArr,minOrder,maxOrder})

            const output = teachingName + " " + (levelsCount > 1 ? minLevel?.nameShort + " à " + maxLevel?.nameShort : minLevel?.nameShort)
            return output
        })
        const joined = strArray.join(", ")
        return joined
    }

    private static getFormattedAbilitiesShape = rasmik.define
        .pickData(Teacher)
        .def({ children: { TeacherAbilities: { required: ["privateName"] } } })
        .shape()
    static getFormattedAbilities(teacher: THIS["getFormattedAbilitiesShape"]) {
        const abilities = teacher.TeacherAbilities || []
        const strArray = abilities.map((ability) => typeof ability !== "number" && ability.privateName)
        const joined = strArray.join(", ")

        return joined
    }
}
