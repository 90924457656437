/* tslint:disable */
/* eslint-disable */
//@ts-nocheck
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import globalAxios, { type AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

  
/**
 * 
 * @export
 * @interface AICICtrlCreateFromLegalInvoiceBody
 */
export interface AICICtrlCreateFromLegalInvoiceBody {
    /**
     * 
     * @type {string}
     * @memberof AICICtrlCreateFromLegalInvoiceBody
     */
    dateVersementAcompte: string;
    /**
     * 
     * @type {number}
     * @memberof AICICtrlCreateFromLegalInvoiceBody
     */
    mntAcompte: number;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const AICIModeEntries = {
    'CIDiff': 'CIDiff',
    'AICI': 'AICI'
} as const
export type AICIMode = typeof AICIModeEntries[keyof typeof AICIModeEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const AICIPrestationTypeEntries = {
    'SCOLAIRE': 'SCOLAIRE',
    'INFORMATIQUE': 'INFORMATIQUE',
    'FRAIS': 'FRAIS'
} as const
export type AICIPrestationType = typeof AICIPrestationTypeEntries[keyof typeof AICIPrestationTypeEntries]

  
/**
 * 
 * @export
 * @interface AcceptLessonProposition200Response
 */
export interface AcceptLessonProposition200Response {
    /**
     * 
     * @type {string}
     * @memberof AcceptLessonProposition200Response
     */
    matchStatus: AcceptLessonProposition200ResponseMatchStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AcceptLessonProposition200Response
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptLessonProposition200Response
     */
    lessonId: number;
}

/**
    * @export
    * @enum {string}
    */
export const AcceptLessonProposition200ResponseMatchStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'FINISHED': 'FINISHED',
    'ON_HOLD': 'ON_HOLD',
    'ABANDONNED': 'ABANDONNED'
} as const
export type AcceptLessonProposition200ResponseMatchStatusEnum = typeof AcceptLessonProposition200ResponseMatchStatusEnumEntries[keyof typeof AcceptLessonProposition200ResponseMatchStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface AcceptMatchAndLessonProposition200Response
 */
export interface AcceptMatchAndLessonProposition200Response {
    /**
     * 
     * @type {Array<number>}
     * @memberof AcceptMatchAndLessonProposition200Response
     */
    expiredPropositionIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AcceptMatchAndLessonProposition200Response
     */
    acceptedPropositionId: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptMatchAndLessonProposition200Response
     */
    lessonId: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptMatchAndLessonProposition200Response
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptMatchAndLessonProposition200Response
     */
    matchId: number;
}


  
/**
 * 
 * @export
 * @interface ActivateCreditImpotResponse
 */
export interface ActivateCreditImpotResponse {
    /**
     * 
     * @type {string}
     * @memberof ActivateCreditImpotResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCreditImpotResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCreditImpotResponse
     */
    code?: string;
    /**
     * 
     * @type {Array<Erreur>}
     * @memberof ActivateCreditImpotResponse
     */
    errors?: Array<Erreur>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivateCreditImpotResponse
     */
    error?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActivateCreditImpotResponse
     */
    success?: boolean;
}


  
/**
 * 
 * @export
 * @interface AddFirstLessonByAdmin200Response
 */
export interface AddFirstLessonByAdmin200Response {
    /**
     * 
     * @type {number}
     * @memberof AddFirstLessonByAdmin200Response
     */
    lessonId: number;
}


  
/**
 * 
 * @export
 * @interface AffiliationCodeServiceGetCustomerAffiliationCodeOutput
 */
export interface AffiliationCodeServiceGetCustomerAffiliationCodeOutput {
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetCustomerAffiliationCodeOutput
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetCustomerAffiliationCodeOutput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetCustomerAffiliationCodeOutput
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliationCodeServiceGetCustomerAffiliationCodeOutput
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface AffiliationCodeServiceGetTeacherAffiliationCodeOutput
 */
export interface AffiliationCodeServiceGetTeacherAffiliationCodeOutput {
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetTeacherAffiliationCodeOutput
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetTeacherAffiliationCodeOutput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AffiliationCodeServiceGetTeacherAffiliationCodeOutput
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof AffiliationCodeServiceGetTeacherAffiliationCodeOutput
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface AiciActivationServiceTestConfirmationResult
 */
export interface AiciActivationServiceTestConfirmationResult {
    /**
     * 
     * @type {boolean}
     * @memberof AiciActivationServiceTestConfirmationResult
     */
    alreadyConfirmed: boolean;
    /**
     * 
     * @type {string}
     * @memberof AiciActivationServiceTestConfirmationResult
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AiciActivationServiceTestConfirmationResult
     */
    confirmed: boolean;
}


  
/**
 * 
 * @export
 * @interface AiciSyncServiceSyncAiciPaymentRequestOuput
 */
export interface AiciSyncServiceSyncAiciPaymentRequestOuput {
    /**
     * 
     * @type {boolean}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    hasChanged: boolean;
    /**
     * 
     * @type {number}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    mntVirement: number;
    /**
     * 
     * @type {Date}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    dateVirement: Date;
    /**
     * 
     * @type {Date}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    lastSyncAt: Date;
    /**
     * 
     * @type {string}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    statusCode: string;
    /**
     * 
     * @type {number}
     * @memberof AiciSyncServiceSyncAiciPaymentRequestOuput
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface ApiErrors
 */
export interface ApiErrors {
    /**
     * 
     * @type {ApiErrorsComptaLocked}
     * @memberof ApiErrors
     */
    ComptaLocked: ApiErrorsComptaLocked;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    UnknownValidationToken: ApiErrorsUnknownValidationTokenEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    CustomerNotFound: ApiErrorsCustomerNotFoundEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    TeacherNotFound: ApiErrorsTeacherNotFoundEnum;
    /**
     * 
     * @type {ApiErrorsPaymentRequestRejected}
     * @memberof ApiErrors
     */
    PaymentRequestRejected: ApiErrorsPaymentRequestRejected;
    /**
     * 
     * @type {ApiErrorsAwaitingPropositionsInvolved}
     * @memberof ApiErrors
     */
    AwaitingPropositionsInvolved: ApiErrorsAwaitingPropositionsInvolved;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    InvalidPassword: ApiErrorsInvalidPasswordEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    UnknownUser: ApiErrorsUnknownUserEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    UserAlreadyExist: ApiErrorsUserAlreadyExistEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    InsufficientPermissions: ApiErrorsInsufficientPermissionsEnum;
    /**
     * 
     * @type {ApiErrorsExpiredToken}
     * @memberof ApiErrors
     */
    ExpiredToken: ApiErrorsExpiredToken;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    InvalidToken: ApiErrorsInvalidTokenEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiErrors
     */
    UnsuppliedToken: ApiErrorsUnsuppliedTokenEnum;
}

/**
    * @export
    * @enum {string}
    */
export const ApiErrorsUnknownValidationTokenEnumEntries = {
    null: null
} as const
export type ApiErrorsUnknownValidationTokenEnum = typeof ApiErrorsUnknownValidationTokenEnumEntries[keyof typeof ApiErrorsUnknownValidationTokenEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsCustomerNotFoundEnumEntries = {
    null: null
} as const
export type ApiErrorsCustomerNotFoundEnum = typeof ApiErrorsCustomerNotFoundEnumEntries[keyof typeof ApiErrorsCustomerNotFoundEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsTeacherNotFoundEnumEntries = {
    null: null
} as const
export type ApiErrorsTeacherNotFoundEnum = typeof ApiErrorsTeacherNotFoundEnumEntries[keyof typeof ApiErrorsTeacherNotFoundEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsInvalidPasswordEnumEntries = {
    null: null
} as const
export type ApiErrorsInvalidPasswordEnum = typeof ApiErrorsInvalidPasswordEnumEntries[keyof typeof ApiErrorsInvalidPasswordEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsUnknownUserEnumEntries = {
    null: null
} as const
export type ApiErrorsUnknownUserEnum = typeof ApiErrorsUnknownUserEnumEntries[keyof typeof ApiErrorsUnknownUserEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsUserAlreadyExistEnumEntries = {
    null: null
} as const
export type ApiErrorsUserAlreadyExistEnum = typeof ApiErrorsUserAlreadyExistEnumEntries[keyof typeof ApiErrorsUserAlreadyExistEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsInsufficientPermissionsEnumEntries = {
    null: null
} as const
export type ApiErrorsInsufficientPermissionsEnum = typeof ApiErrorsInsufficientPermissionsEnumEntries[keyof typeof ApiErrorsInsufficientPermissionsEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsInvalidTokenEnumEntries = {
    null: null
} as const
export type ApiErrorsInvalidTokenEnum = typeof ApiErrorsInvalidTokenEnumEntries[keyof typeof ApiErrorsInvalidTokenEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ApiErrorsUnsuppliedTokenEnumEntries = {
    null: null
} as const
export type ApiErrorsUnsuppliedTokenEnum = typeof ApiErrorsUnsuppliedTokenEnumEntries[keyof typeof ApiErrorsUnsuppliedTokenEnumEntries]



  
/**
 * 
 * @export
 * @interface ApiErrorsAwaitingPropositionsInvolved
 */
export interface ApiErrorsAwaitingPropositionsInvolved {
    /**
     * 
     * @type {Array<number>}
     * @memberof ApiErrorsAwaitingPropositionsInvolved
     */
    ids: Array<number>;
}


  
/**
 * 
 * @export
 * @interface ApiErrorsComptaLocked
 */
export interface ApiErrorsComptaLocked {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorsComptaLocked
     */
    opDateOn: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorsComptaLocked
     */
    comptaLockedOn: string;
}


  
/**
 * 
 * @export
 * @interface ApiErrorsExpiredToken
 */
export interface ApiErrorsExpiredToken {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorsExpiredToken
     */
    token: string;
}


  
/**
 * 
 * @export
 * @interface ApiErrorsPaymentRequestRejected
 */
export interface ApiErrorsPaymentRequestRejected {
    /**
     * 
     * @type {InputDemandePaiement}
     * @memberof ApiErrorsPaymentRequestRejected
     */
    inputDemandePaiement: InputDemandePaiement;
    /**
     * 
     * @type {Array<Erreur>}
     * @memberof ApiErrorsPaymentRequestRejected
     */
    errors: Array<Erreur>;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload
 */
export interface AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload
     */
    familyDeclarationIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload
     */
    globalDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceClearFamilyDeclarationResultPayload
 */
export interface AsapDeclarationServiceClearFamilyDeclarationResultPayload {
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceClearFamilyDeclarationResultPayload
     */
    familyDeclarationId: number;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceClearFamilyDeclarationResultPayload
     */
    globalDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceFamilyDeclarationResultItem
 */
export interface AsapDeclarationServiceFamilyDeclarationResultItem {
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceFamilyDeclarationResultItem
     */
    realPaymentAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof AsapDeclarationServiceFamilyDeclarationResultItem
     */
    rejected: boolean;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceFamilyDeclarationResultItem
     */
    familyDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload
 */
export interface AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload {
    /**
     * 
     * @type {Array<AsapDeclarationServiceFamilyDeclarationResultItem>}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload
     */
    results: Array<AsapDeclarationServiceFamilyDeclarationResultItem>;
    /**
     * 
     * @type {boolean}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload
     */
    override: boolean;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload
     */
    globalDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceSetFamilyDeclarationResultPayload
 */
export interface AsapDeclarationServiceSetFamilyDeclarationResultPayload {
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultPayload
     */
    globalDeclarationId: number;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultPayload
     */
    realPaymentAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultPayload
     */
    rejected: boolean;
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultPayload
     */
    familyDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AsapDeclarationServiceSetFamilyDeclarationResultPayloadAllOf
 */
export interface AsapDeclarationServiceSetFamilyDeclarationResultPayloadAllOf {
    /**
     * 
     * @type {number}
     * @memberof AsapDeclarationServiceSetFamilyDeclarationResultPayloadAllOf
     */
    globalDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface AttestationFiscaleLine
 */
export interface AttestationFiscaleLine {
    /**
     * 
     * @type {boolean}
     * @memberof AttestationFiscaleLine
     */
    hidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    aiciAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    cesuAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    paidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    invoicedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    usedCredits: number | null;
    /**
     * 
     * @type {number}
     * @memberof AttestationFiscaleLine
     */
    teacherId: number | null;
    /**
     * 
     * @type {string}
     * @memberof AttestationFiscaleLine
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof AttestationFiscaleLine
     */
    month: string;
    /**
     * 
     * @type {AttestationFiscaleType}
     * @memberof AttestationFiscaleLine
     */
    aiciMode: AttestationFiscaleType;
    /**
     * 
     * @type {string}
     * @memberof AttestationFiscaleLine
     */
    type: AttestationFiscaleLineTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const AttestationFiscaleLineTypeEnumEntries = {
    'lessons': 'lessons',
    'fees': 'fees'
} as const
export type AttestationFiscaleLineTypeEnum = typeof AttestationFiscaleLineTypeEnumEntries[keyof typeof AttestationFiscaleLineTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const AttestationFiscaleTypeEntries = {
    'CIDiff': 'CIDiff',
    'AICI': 'AICI',
    'Mixed': 'Mixed',
    'Unknown': 'Unknown'
} as const
export type AttestationFiscaleType = typeof AttestationFiscaleTypeEntries[keyof typeof AttestationFiscaleTypeEntries]

  
/**
 * 
 * @export
 * @interface AttestationPoleEmploisServiceGenerateParams
 */
export interface AttestationPoleEmploisServiceGenerateParams {
    /**
     * 
     * @type {string}
     * @memberof AttestationPoleEmploisServiceGenerateParams
     */
    signOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AttestationPoleEmploisServiceGenerateParams
     */
    endOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AttestationPoleEmploisServiceGenerateParams
     */
    startOn?: string;
    /**
     * 
     * @type {number}
     * @memberof AttestationPoleEmploisServiceGenerateParams
     */
    teacherId: number;
}


  
/**
 * 
 * @export
 * @interface BankChecksServiceCreateAndReconcileBankCheckDepositOutput
 */
export interface BankChecksServiceCreateAndReconcileBankCheckDepositOutput {
    /**
     * 
     * @type {number}
     * @memberof BankChecksServiceCreateAndReconcileBankCheckDepositOutput
     */
    bankCheckDepositId: number;
}


  
/**
 * 
 * @export
 * @interface BankChecksServiceCreateBankCheckDepositNotReconciliedYetOutput
 */
export interface BankChecksServiceCreateBankCheckDepositNotReconciliedYetOutput {
    /**
     * 
     * @type {number}
     * @memberof BankChecksServiceCreateBankCheckDepositNotReconciliedYetOutput
     */
    bankCheckDepositId: number;
}


  
/**
 * 
 * @export
 * @interface BankChecksServiceCreateCustomerBankCheckOutput
 */
export interface BankChecksServiceCreateCustomerBankCheckOutput {
    /**
     * 
     * @type {number}
     * @memberof BankChecksServiceCreateCustomerBankCheckOutput
     */
    debitId: number;
    /**
     * 
     * @type {number}
     * @memberof BankChecksServiceCreateCustomerBankCheckOutput
     */
    checkId: number;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const BufferBufferEncodingEntries = {
    'ascii': 'ascii',
    'utf8': 'utf8',
    'utf-8': 'utf-8',
    'utf16le': 'utf16le',
    'utf-16le': 'utf-16le',
    'ucs2': 'ucs2',
    'ucs-2': 'ucs-2',
    'base64': 'base64',
    'base64url': 'base64url',
    'latin1': 'latin1',
    'binary': 'binary',
    'hex': 'hex'
} as const
export type BufferBufferEncoding = typeof BufferBufferEncodingEntries[keyof typeof BufferBufferEncodingEntries]

  
/**
 * 
 * @export
 * @interface CalendlyScheduledEvent
 */
export interface CalendlyScheduledEvent {
    /**
     * 
     * @type {CalendlyScheduledEventCalendarEvent}
     * @memberof CalendlyScheduledEvent
     */
    calendar_event: CalendlyScheduledEventCalendarEvent;
    /**
     * 
     * @type {Array<CalendlyScheduledEventEventGuestsInner>}
     * @memberof CalendlyScheduledEvent
     */
    event_guests: Array<CalendlyScheduledEventEventGuestsInner>;
    /**
     * 
     * @type {Array<CalendlyScheduledEventEventMembershipsInner>}
     * @memberof CalendlyScheduledEvent
     */
    event_memberships: Array<CalendlyScheduledEventEventMembershipsInner>;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    created_at: string;
    /**
     * 
     * @type {CalendlyScheduledEventInviteesCounter}
     * @memberof CalendlyScheduledEvent
     */
    invitees_counter: CalendlyScheduledEventInviteesCounter;
    /**
     * 
     * @type {CalendlyScheduledEventLocation}
     * @memberof CalendlyScheduledEvent
     */
    location: CalendlyScheduledEventLocation;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    event_type: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    end_time: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    booking_method: CalendlyScheduledEventBookingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    status: CalendlyScheduledEventStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEvent
     */
    uri: string;
}

/**
    * @export
    * @enum {string}
    */
export const CalendlyScheduledEventBookingMethodEnumEntries = {
    'instant': 'instant'
} as const
export type CalendlyScheduledEventBookingMethodEnum = typeof CalendlyScheduledEventBookingMethodEnumEntries[keyof typeof CalendlyScheduledEventBookingMethodEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const CalendlyScheduledEventStatusEnumEntries = {
    'active': 'active',
    'canceled': 'canceled'
} as const
export type CalendlyScheduledEventStatusEnum = typeof CalendlyScheduledEventStatusEnumEntries[keyof typeof CalendlyScheduledEventStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface CalendlyScheduledEventCalendarEvent
 */
export interface CalendlyScheduledEventCalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventCalendarEvent
     */
    external_id: CalendlyScheduledEventCalendarEventExternalIdEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventCalendarEvent
     */
    kind: string;
}

/**
    * @export
    * @enum {string}
    */
export const CalendlyScheduledEventCalendarEventExternalIdEnumEntries = {
    '8suu9k3hj00mni03ss12ba0ce0': '8suu9k3hj00mni03ss12ba0ce0'
} as const
export type CalendlyScheduledEventCalendarEventExternalIdEnum = typeof CalendlyScheduledEventCalendarEventExternalIdEnumEntries[keyof typeof CalendlyScheduledEventCalendarEventExternalIdEnumEntries]



  
/**
 * 
 * @export
 * @interface CalendlyScheduledEventEventGuestsInner
 */
export interface CalendlyScheduledEventEventGuestsInner {
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventEventGuestsInner
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventEventGuestsInner
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventEventGuestsInner
     */
    email: string;
}


  
/**
 * 
 * @export
 * @interface CalendlyScheduledEventEventMembershipsInner
 */
export interface CalendlyScheduledEventEventMembershipsInner {
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventEventMembershipsInner
     */
    user_email: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventEventMembershipsInner
     */
    user: string;
}


  
/**
 * 
 * @export
 * @interface CalendlyScheduledEventInviteesCounter
 */
export interface CalendlyScheduledEventInviteesCounter {
    /**
     * 
     * @type {number}
     * @memberof CalendlyScheduledEventInviteesCounter
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof CalendlyScheduledEventInviteesCounter
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof CalendlyScheduledEventInviteesCounter
     */
    total: number;
}


  
/**
 * 
 * @export
 * @interface CalendlyScheduledEventLocation
 */
export interface CalendlyScheduledEventLocation {
    /**
     * 
     * @type {any}
     * @memberof CalendlyScheduledEventLocation
     */
    data?: any;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventLocation
     */
    join_url?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventLocation
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendlyScheduledEventLocation
     */
    type: string;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceDoodleSessionDTO
 */
export interface CandidatureServiceDoodleSessionDTO {
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    MainCity?: number;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    isVisio: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceDoodleSessionDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceHandleCalendlyScheduledOutput
 */
export interface CandidatureServiceHandleCalendlyScheduledOutput {
    /**
     * 
     * @type {CalendlyScheduledEvent}
     * @memberof CandidatureServiceHandleCalendlyScheduledOutput
     */
    event: CalendlyScheduledEvent;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceHandleCalendlyScheduledPayload
 */
export interface CandidatureServiceHandleCalendlyScheduledPayload {
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceHandleCalendlyScheduledPayload
     */
    teacherId: number;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceHandleCalendlyScheduledPayload
     */
    inviteeUri: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceHandleCalendlyScheduledPayload
     */
    eventUri: string;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceMainCityDTO
 */
export interface CandidatureServiceMainCityDTO {
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceMainCityDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceMainCityDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServicePhoneNumberDTO
 */
export interface CandidatureServicePhoneNumberDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServicePhoneNumberDTO
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServicePhoneNumberDTO
     */
    isMain?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServicePhoneNumberDTO
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServicePhoneNumberDTO
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServicePhoneNumberDTO
     */
    number?: string;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceScolarityComplementDTO
 */
export interface CandidatureServiceScolarityComplementDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceScolarityComplementDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceScolarityComplementDTO
     */
    orderNb?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceScolarityComplementDTO
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceScolarityComplementDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceScolarityMainDTO
 */
export interface CandidatureServiceScolarityMainDTO {
    /**
     * 
     * @type {Array<CandidatureServiceScolarityComplementDTO>}
     * @memberof CandidatureServiceScolarityMainDTO
     */
    Complements: Array<CandidatureServiceScolarityComplementDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceScolarityMainDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceScolarityMainDTO
     */
    orderNb?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceScolarityMainDTO
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceScolarityMainDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceStudyLevelDTO
 */
export interface CandidatureServiceStudyLevelDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceStudyLevelDTO
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceStudyLevelDTO
     */
    orderNb?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceStudyLevelDTO
     */
    novaLevel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceStudyLevelDTO
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceStudyLevelDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceTeacherDTO
 */
export interface CandidatureServiceTeacherDTO {
    /**
     * 
     * @type {Array<CandidatureServiceTeacherDTOTeacherAbilitiesInner>}
     * @memberof CandidatureServiceTeacherDTO
     */
    TeacherAbilities: Array<CandidatureServiceTeacherDTOTeacherAbilitiesInner>;
    /**
     * 
     * @type {Array<CandidatureServiceTeacherDTOYearDisposInner>}
     * @memberof CandidatureServiceTeacherDTO
     */
    YearDispos: Array<CandidatureServiceTeacherDTOYearDisposInner>;
    /**
     * 
     * @type {Array<CandidatureServiceTeacherDTOWeekDisposInner>}
     * @memberof CandidatureServiceTeacherDTO
     */
    WeekDispos: Array<CandidatureServiceTeacherDTOWeekDisposInner>;
    /**
     * 
     * @type {CandidatureServiceMainCityDTO}
     * @memberof CandidatureServiceTeacherDTO
     */
    MainCity?: CandidatureServiceMainCityDTO;
    /**
     * 
     * @type {CandidatureServicePhoneNumberDTO}
     * @memberof CandidatureServiceTeacherDTO
     */
    MainPhoneNumber?: CandidatureServicePhoneNumberDTO;
    /**
     * 
     * @type {CandidatureServiceTeacherDTOCandidature}
     * @memberof CandidatureServiceTeacherDTO
     */
    Candidature: CandidatureServiceTeacherDTOCandidature;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTO
     */
    terminatedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceTeacherDTO
     */
    isTeacher?: boolean;
    /**
     * 
     * @type {DryTeacherStatus}
     * @memberof CandidatureServiceTeacherDTO
     */
    status?: DryTeacherStatus;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTO
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTO
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface CandidatureServiceTeacherDTOCandidature
 */
export interface CandidatureServiceTeacherDTOCandidature {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    isTrash?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    isFastTrack?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    sessionPlannedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    qualifiedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    preselectResponseAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    completedInfoAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    pausedAt?: Date | null;
    /**
     * 
     * @type {DryCandidatureStatus}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    status: DryCandidatureStatus;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTOCandidature
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface CandidatureServiceTeacherDTOTeacherAbilitiesInner
 */
export interface CandidatureServiceTeacherDTOTeacherAbilitiesInner {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceTeacherDTOTeacherAbilitiesInner
     */
    isPrivateOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOTeacherAbilitiesInner
     */
    privateName?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOTeacherAbilitiesInner
     */
    publicName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTOTeacherAbilitiesInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceTeacherDTOWeekDisposInner
 */
export interface CandidatureServiceTeacherDTOWeekDisposInner {
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOWeekDisposInner
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOWeekDisposInner
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTOWeekDisposInner
     */
    dayId?: number;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTOWeekDisposInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidatureServiceTeacherDTOYearDisposInner
 */
export interface CandidatureServiceTeacherDTOYearDisposInner {
    /**
     * 
     * @type {boolean}
     * @memberof CandidatureServiceTeacherDTOYearDisposInner
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOYearDisposInner
     */
    endOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOYearDisposInner
     */
    startOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatureServiceTeacherDTOYearDisposInner
     */
    comments?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CandidatureServiceTeacherDTOYearDisposInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface CandidaturesAcceptAskParams
 */
export interface CandidaturesAcceptAskParams {
    /**
     * 
     * @type {string}
     * @memberof CandidaturesAcceptAskParams
     */
    wixCode: string;
    /**
     * 
     * @type {number}
     * @memberof CandidaturesAcceptAskParams
     */
    candidatureId: number;
    /**
     * 
     * @type {number}
     * @memberof CandidaturesAcceptAskParams
     */
    teacherId: number;
    /**
     * 
     * @type {CandidaturesEmailData}
     * @memberof CandidaturesAcceptAskParams
     */
    emailData: CandidaturesEmailData;
    /**
     * 
     * @type {number}
     * @memberof CandidaturesAcceptAskParams
     */
    currentUserId: number;
}


  
/**
 * 
 * @export
 * @interface CandidaturesEmailData
 */
export interface CandidaturesEmailData {
    /**
     * 
     * @type {string}
     * @memberof CandidaturesEmailData
     */
    html: string;
    /**
     * 
     * @type {string}
     * @memberof CandidaturesEmailData
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof CandidaturesEmailData
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof CandidaturesEmailData
     */
    sender: string;
}


  
/**
 * 
 * @export
 * @interface CandidaturesRefuseAskParams
 */
export interface CandidaturesRefuseAskParams {
    /**
     * 
     * @type {number}
     * @memberof CandidaturesRefuseAskParams
     */
    candidatureId: number;
    /**
     * 
     * @type {number}
     * @memberof CandidaturesRefuseAskParams
     */
    teacherId: number;
    /**
     * 
     * @type {CandidaturesEmailData}
     * @memberof CandidaturesRefuseAskParams
     */
    emailData: CandidaturesEmailData;
    /**
     * 
     * @type {number}
     * @memberof CandidaturesRefuseAskParams
     */
    currentUserId: number;
}


  
/**
 * 
 * @export
 * @interface CesuChecksServiceCreateAndReconcileCesuCheckDepositOutput
 */
export interface CesuChecksServiceCreateAndReconcileCesuCheckDepositOutput {
    /**
     * 
     * @type {number}
     * @memberof CesuChecksServiceCreateAndReconcileCesuCheckDepositOutput
     */
    cesuCheckDepositId: number;
}


  
/**
 * 
 * @export
 * @interface CesuChecksServiceCreateCesuCheckDepositNotReconciliedYetOutput
 */
export interface CesuChecksServiceCreateCesuCheckDepositNotReconciliedYetOutput {
    /**
     * 
     * @type {number}
     * @memberof CesuChecksServiceCreateCesuCheckDepositNotReconciliedYetOutput
     */
    cesuCheckDepositId: number;
}


  
/**
 * 
 * @export
 * @interface CesuChecksServiceCreateCesuCheckLotOutput
 */
export interface CesuChecksServiceCreateCesuCheckLotOutput {
    /**
     * 
     * @type {number}
     * @memberof CesuChecksServiceCreateCesuCheckLotOutput
     */
    debitId: number;
    /**
     * 
     * @type {number}
     * @memberof CesuChecksServiceCreateCesuCheckLotOutput
     */
    lotId: number;
}


  
/**
 * 
 * @export
 * @interface ChangeWeekDispoCustomerParams
 */
export interface ChangeWeekDispoCustomerParams {
    /**
     * 
     * @type {Array<ChangeWeekDispoTeacherParamsDispoInner>}
     * @memberof ChangeWeekDispoCustomerParams
     */
    dispo: Array<ChangeWeekDispoTeacherParamsDispoInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeWeekDispoCustomerParams
     */
    dayIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ChangeWeekDispoCustomerParams
     */
    memberId: number;
}


  
/**
 * 
 * @export
 * @interface ChangeWeekDispoTeacherParams
 */
export interface ChangeWeekDispoTeacherParams {
    /**
     * 
     * @type {Array<ChangeWeekDispoTeacherParamsDispoInner>}
     * @memberof ChangeWeekDispoTeacherParams
     */
    dispo: Array<ChangeWeekDispoTeacherParamsDispoInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeWeekDispoTeacherParams
     */
    dayIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ChangeWeekDispoTeacherParams
     */
    teacherId: number;
}


  
/**
 * 
 * @export
 * @interface ChangeWeekDispoTeacherParamsDispoInner
 */
export interface ChangeWeekDispoTeacherParamsDispoInner {
    /**
     * 
     * @type {string}
     * @memberof ChangeWeekDispoTeacherParamsDispoInner
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeWeekDispoTeacherParamsDispoInner
     */
    startTime: string;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectAiciPaybackBody
 */
export interface ComptaCtrlAffectAiciPaybackBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectAiciPaybackBody
     */
    aiciPaymentRequestId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectAiciPaymentBody
 */
export interface ComptaCtrlAffectAiciPaymentBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectAiciPaymentBody
     */
    aiciPaymentRequestId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectAsapPaymentBody
 */
export interface ComptaCtrlAffectAsapPaymentBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectAsapPaymentBody
     */
    asapUrssafDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectCustomerTransferCashinBody
 */
export interface ComptaCtrlAffectCustomerTransferCashinBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectCustomerTransferCashinBody
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectCustomerTransferPaybackBody
 */
export interface ComptaCtrlAffectCustomerTransferPaybackBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectCustomerTransferPaybackBody
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlAffectTeacherTransferPaybackBody
 */
export interface ComptaCtrlAffectTeacherTransferPaybackBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlAffectTeacherTransferPaybackBody
     */
    teacherId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateAndReconcileBankCheckDepositBody
 */
export interface ComptaCtrlCreateAndReconcileBankCheckDepositBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateAndReconcileBankCheckDepositBody
     */
    deposedOn: string;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateAndReconcileBankCheckDepositBody
     */
    comment: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlCreateAndReconcileBankCheckDepositBody
     */
    rejectedCheckIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlCreateAndReconcileBankCheckDepositBody
     */
    acceptedCheckIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateAndReconcileBankCheckDepositBody
     */
    bankTransactionId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateAndReconcileCesuCheckDepositBody
 */
export interface ComptaCtrlCreateAndReconcileCesuCheckDepositBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    feesTVA: number;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    feesHT: number;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    deposedOn: string;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    comment: string | null;
    /**
     * 
     * @type {Array<ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner>}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    rejections: Array<ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    lotIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateAndReconcileCesuCheckDepositBody
     */
    bankTransactionId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody
 */
export interface ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody
     */
    deposedOn: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody
     */
    bankCheckIds: Array<number>;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody
 */
export interface ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody
     */
    deposedOn: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody
     */
    lotIds: Array<number>;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateCesuCheckLotBody
 */
export interface ComptaCtrlCreateCesuCheckLotBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCesuCheckLotBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCesuCheckLotBody
     */
    receivedOn: string;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateCesuCheckLotBody
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateCesuCheckLotBody
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlCreateCustomerBankCheckBody
 */
export interface ComptaCtrlCreateCustomerBankCheckBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCustomerBankCheckBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlCreateCustomerBankCheckBody
     */
    receivedOn: string;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateCustomerBankCheckBody
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlCreateCustomerBankCheckBody
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlReconcileBankCheckDepositBody
 */
export interface ComptaCtrlReconcileBankCheckDepositBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlReconcileBankCheckDepositBody
     */
    comment: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlReconcileBankCheckDepositBody
     */
    rejectedCheckIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ComptaCtrlReconcileBankCheckDepositBody
     */
    acceptedCheckIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileBankCheckDepositBody
     */
    bankTransactionId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlReconcileCesuCheckDepositBody
 */
export interface ComptaCtrlReconcileCesuCheckDepositBody {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBody
     */
    feesTVA: number;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBody
     */
    feesHT: number;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBody
     */
    comment: string | null;
    /**
     * 
     * @type {Array<ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner>}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBody
     */
    rejections: Array<ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner>;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBody
     */
    bankTransactionId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner
 */
export interface ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner {
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner
     */
    rejectedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlReconcileCesuCheckDepositBodyRejectionsInner
     */
    lotId: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlUpdateCesuCheckLotBody
 */
export interface ComptaCtrlUpdateCesuCheckLotBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlUpdateCesuCheckLotBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlUpdateCesuCheckLotBody
     */
    receivedOn: string;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlUpdateCesuCheckLotBody
     */
    amount: number;
}


  
/**
 * 
 * @export
 * @interface ComptaCtrlUpdateCustomerBankCheckBody
 */
export interface ComptaCtrlUpdateCustomerBankCheckBody {
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlUpdateCustomerBankCheckBody
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComptaCtrlUpdateCustomerBankCheckBody
     */
    receivedOn: string;
    /**
     * 
     * @type {number}
     * @memberof ComptaCtrlUpdateCustomerBankCheckBody
     */
    amount: number;
}


  
/**
 * 
 * @export
 * @interface ComputePayslipsMonth200Response
 */
export interface ComputePayslipsMonth200Response {
    /**
     * 
     * @type {number}
     * @memberof ComputePayslipsMonth200Response
     */
    treatedCount: number;
}


  
/**
 * 
 * @export
 * @interface ComputePayslipsMonthRequest
 */
export interface ComputePayslipsMonthRequest {
    /**
     * 
     * @type {string}
     * @memberof ComputePayslipsMonthRequest
     */
    month: string;
}


  
/**
 * 
 * @export
 * @interface ConfirmGlobalDeclaration200Response
 */
export interface ConfirmGlobalDeclaration200Response {
    /**
     * 
     * @type {string}
     * @memberof ConfirmGlobalDeclaration200Response
     */
    status: ConfirmGlobalDeclaration200ResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ConfirmGlobalDeclaration200Response
     */
    globalDeclarationId: number;
}

/**
    * @export
    * @enum {string}
    */
export const ConfirmGlobalDeclaration200ResponseStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ABANDONNED': 'ABANDONNED',
    'LOCKED': 'LOCKED',
    'DECLARED': 'DECLARED',
    'CONFIRMED': 'CONFIRMED'
} as const
export type ConfirmGlobalDeclaration200ResponseStatusEnum = typeof ConfirmGlobalDeclaration200ResponseStatusEnumEntries[keyof typeof ConfirmGlobalDeclaration200ResponseStatusEnumEntries]



  
/**
 * Lors de la 1ere étape des déclarations, on sélectionne les fiches de paie a partir desquelles la déclaration brouillon est calculée.
 * @export
 * @interface CreateDeclarationFromPayslipsPayload
 */
export interface CreateDeclarationFromPayslipsPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateDeclarationFromPayslipsPayload
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDeclarationFromPayslipsPayload
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeclarationFromPayslipsPayload
     */
    trimester: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDeclarationFromPayslipsPayload
     */
    neantFamilyIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateDeclarationFromPayslipsPayload
     */
    payslipIds: Array<number>;
}


  
/**
 * 
 * @export
 * @interface CreateDeclarationFromPayslipsResponse
 */
export interface CreateDeclarationFromPayslipsResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateDeclarationFromPayslipsResponse
     */
    createdId: number;
}


  
/**
 * 
 * @export
 * @interface CreateFromLegalInvoice200Response
 */
export interface CreateFromLegalInvoice200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateFromLegalInvoice200Response
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFromLegalInvoice200Response
     */
    idDemandePaiement: string;
    /**
     * 
     * @type {number}
     * @memberof CreateFromLegalInvoice200Response
     */
    paymentRequestId: number;
}


  
/**
 * 
 * @export
 * @interface CreateTeacherPayment200Response
 */
export interface CreateTeacherPayment200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPayment200Response
     */
    signUri: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPayment200Response
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPayment200Response
     */
    pontoPaymentUUID: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeacherPayment200Response
     */
    pontoPaymentId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTeacherPayment200Response
     */
    teacherPaymentId: number;
}


  
/**
 * 
 * @export
 * @interface CreateTeacherPaymentPayload
 */
export interface CreateTeacherPaymentPayload {
    /**
     * 
     * @type {boolean}
     * @memberof CreateTeacherPaymentPayload
     */
    withoutPonto: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTeacherPaymentPayload
     */
    fire: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPaymentPayload
     */
    redirectUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPaymentPayload
     */
    requestedExecutionDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTeacherPaymentPayload
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherPaymentPayload
     */
    remittanceInformation: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeacherPaymentPayload
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTeacherPaymentPayload
     */
    bankAccountId?: number | null;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const CreditInvoiceStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'ABORTED': 'ABORTED',
    'READY': 'READY',
    'INVOICE': 'INVOICE'
} as const
export type CreditInvoiceStatus = typeof CreditInvoiceStatusEntries[keyof typeof CreditInvoiceStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const CreditStatusEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'ABORTED': 'ABORTED',
    'AVAILABLE': 'AVAILABLE',
    'USED': 'USED'
} as const
export type CreditStatus = typeof CreditStatusEntries[keyof typeof CreditStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerPropositionStatusEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'AWAITING': 'AWAITING',
    'PENDING': 'PENDING',
    'COMPLETED': 'COMPLETED'
} as const
export type CustomerPropositionStatus = typeof CustomerPropositionStatusEntries[keyof typeof CustomerPropositionStatusEntries]

  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlAbortAwaitingBody
 */
export interface CustomerValidationCtrlAbortAwaitingBody {
    /**
     * 
     * @type {number}
     * @memberof CustomerValidationCtrlAbortAwaitingBody
     */
    customerPropositionId: number;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlAbortBody
 */
export interface CustomerValidationCtrlAbortBody {
    /**
     * 
     * @type {number}
     * @memberof CustomerValidationCtrlAbortBody
     */
    customerPropositionId: number;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlDeleteBody
 */
export interface CustomerValidationCtrlDeleteBody {
    /**
     * 
     * @type {number}
     * @memberof CustomerValidationCtrlDeleteBody
     */
    customerPropositionId: number;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlForceBody
 */
export interface CustomerValidationCtrlForceBody {
    /**
     * 
     * @type {number}
     * @memberof CustomerValidationCtrlForceBody
     */
    customerPropositionId: number;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlSaveAiciInfoFormBody
 */
export interface CustomerValidationCtrlSaveAiciInfoFormBody {
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    bic: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthDpt: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthCityCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthCityName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthCountryCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    birthName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveAiciInfoFormBody
     */
    gender: string;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlSaveAiciModeChoiceBody
 */
export interface CustomerValidationCtrlSaveAiciModeChoiceBody {
    /**
     * 
     * @type {AICIMode}
     * @memberof CustomerValidationCtrlSaveAiciModeChoiceBody
     */
    aiciMode: AICIMode;
}




  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlSaveBirthOnlyInfoFormBody
 */
export interface CustomerValidationCtrlSaveBirthOnlyInfoFormBody {
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    iban: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    bic: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    birthName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerValidationCtrlSaveBirthOnlyInfoFormBody
     */
    gender: string;
}


  
/**
 * 
 * @export
 * @interface CustomerValidationCtrlStartBody
 */
export interface CustomerValidationCtrlStartBody {
    /**
     * 
     * @type {number}
     * @memberof CustomerValidationCtrlStartBody
     */
    customerPropositionId: number;
}


  
/**
 * 
 * @export
 * @interface DeletePlannedCallParams
 */
export interface DeletePlannedCallParams {
    /**
     * 
     * @type {number}
     * @memberof DeletePlannedCallParams
     */
    plannedCallId: number;
}


  
/**
 * 
 * @export
 * @interface DeleteTeacherResearchParams
 */
export interface DeleteTeacherResearchParams {
    /**
     * 
     * @type {number}
     * @memberof DeleteTeacherResearchParams
     */
    teacherResearchId: number;
}


  
/**
 * 
 * @export
 * @interface DeleteTokenNotificationRequest
 */
export interface DeleteTokenNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteTokenNotificationRequest
     */
    tokenNotifications: string;
}


  
/**
 * 
 * @export
 * @interface DoneAffiliationRequest
 */
export interface DoneAffiliationRequest {
    /**
     * 
     * @type {string}
     * @memberof DoneAffiliationRequest
     */
    siret: string;
    /**
     * 
     * @type {string}
     * @memberof DoneAffiliationRequest
     */
    asapNumCompte: string;
    /**
     * 
     * @type {string}
     * @memberof DoneAffiliationRequest
     */
    asapAffiliationOn: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryAICIModeEntries = {
    'CIDiff': 'CIDiff',
    'AICI': 'AICI'
} as const
export type DryAICIMode = typeof DryAICIModeEntries[keyof typeof DryAICIModeEntries]

  
/**
 * 
 * @export
 * @interface DryAICIPaymentRequest
 */
export interface DryAICIPaymentRequest {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAICIPaymentRequest
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAICIPaymentRequest
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAICIPaymentRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    label?: string;
    /**
     * 
     * @type {DryLegalInvoice}
     * @memberof DryAICIPaymentRequest
     */
    LegalInvoice?: DryLegalInvoice | null;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof DryAICIPaymentRequest
     */
    StandardInvoice?: DryStandardInvoice | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryAICIPaymentRequest
     */
    Family?: DryFamily;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    idDemandePaiement?: string | null;
    /**
     * 
     * @type {DryInfoRejet}
     * @memberof DryAICIPaymentRequest
     */
    infoRejet?: DryInfoRejet | null;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    infoRejetComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    statusCode?: string;
    /**
     * 
     * @type {DryInputDemandePaiement}
     * @memberof DryAICIPaymentRequest
     */
    data?: DryInputDemandePaiement;
    /**
     * 
     * @type {number}
     * @memberof DryAICIPaymentRequest
     */
    mntVirement?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DryAICIPaymentRequest
     */
    dateVirement?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryAICIPaymentRequest
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryAICIPaymentRequest
     */
    BankTransaction?: DryBankTransaction;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryAICIPaymentRequest
     */
    BankTransactionPayback?: DryBankTransaction;
    /**
     * 
     * @type {Date}
     * @memberof DryAICIPaymentRequest
     */
    lastSyncAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryAICIPaymentRequest
     */
    isForgotten?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAICIPaymentRequest
     */
    isManual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    statusLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAICIPaymentRequest
     */
    statusDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryAICIPaymentRequest
     */
    isTransitionnal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAICIPaymentRequest
     */
    isFailed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAICIPaymentRequest
     */
    isSuccess?: boolean;
}




  
/**
 * 
 * @export
 * @interface DryAcquisitionChannel
 */
export interface DryAcquisitionChannel {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAcquisitionChannel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAcquisitionChannel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAcquisitionChannel
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryAcquisitionChannel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryAcquisitionChannel
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryAcquisitionChannel
     */
    isLocked?: boolean;
    /**
     * 
     * @type {Array<DryFamily>}
     * @memberof DryAcquisitionChannel
     */
    Families?: Array<DryFamily>;
}


  
/**
 * 
 * @export
 * @interface DryAddress
 */
export interface DryAddress {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAddress
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAddress
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAddress
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    letter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    streetTypeLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    streetTypeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    streetName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    cityCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    complement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryAddress
     */
    isMain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAddress
     */
    isVerified?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAddress
     */
    isManual?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAddress
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {DryCoordinates}
     * @memberof DryAddress
     */
    coordinates?: DryCoordinates | null;
    /**
     * 
     * @type {Ignored}
     * @memberof DryAddress
     */
    googleMaps?: Ignored | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryAddress
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryAddress
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {Array<DryMember>}
     * @memberof DryAddress
     */
    Learners?: Array<DryMember>;
    /**
     * 
     * @type {string}
     * @memberof DryAddress
     */
    fullStreet?: string;
}


  
/**
 * 
 * @export
 * @interface DryAgent
 */
export interface DryAgent {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAgent
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAgent
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAgent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryAgent
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAgent
     */
    isManager?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAgent
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    googleApiPeopleStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryAgent
     */
    googleApiPeopleLastSync?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    googleApiPeopleToken?: string | null;
    /**
     * 
     * @type {Array<DryPhoneCall>}
     * @memberof DryAgent
     */
    PhoneCalls?: Array<DryPhoneCall>;
    /**
     * 
     * @type {Array<DryFCMToken>}
     * @memberof DryAgent
     */
    FCMTokens?: Array<DryFCMToken>;
    /**
     * 
     * @type {string}
     * @memberof DryAgent
     */
    slackUserId?: string | null;
}


  
/**
 * 
 * @export
 * @interface DryAsapDebit
 */
export interface DryAsapDebit {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapDebit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapDebit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapDebit
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapDebit
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAsapDebit
     */
    opDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DryAsapDebit
     */
    label?: string;
    /**
     * 
     * @type {Array<DryAsapLettrage>}
     * @memberof DryAsapDebit
     */
    AsapLettrages1?: Array<DryAsapLettrage>;
    /**
     * 
     * @type {Array<DryAsapLettrage>}
     * @memberof DryAsapDebit
     */
    AsapLettrages2?: Array<DryAsapLettrage>;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryAsapDebit
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryAsapDebitOpType}
     * @memberof DryAsapDebit
     */
    opType?: DryAsapDebitOpType;
    /**
     * 
     * @type {DryPayslipGroup}
     * @memberof DryAsapDebit
     */
    PayslipGroup?: DryPayslipGroup | null;
    /**
     * 
     * @type {DryAsapUrssafDeclaration}
     * @memberof DryAsapDebit
     */
    AsapUrssafDeclaration?: DryAsapUrssafDeclaration | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof DryAsapDebit
     */
    $Lettrages?: Array<any>;
    /**
     * 
     * @type {any}
     * @memberof DryAsapDebit
     */
    lettredAmount?: any;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryAsapDebitOpTypeEntries = {
    'payslip': 'payslip',
    'manual-accounting': 'manual-accounting',
    'asap': 'asap'
} as const
export type DryAsapDebitOpType = typeof DryAsapDebitOpTypeEntries[keyof typeof DryAsapDebitOpTypeEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryAsapExonerationEntries = {
    'AGE70': 'AGE70',
    'HANDICAP': 'HANDICAP',
    'COMPENSATION_TIERS': 'COMPENSATION_TIERS',
    'MAJORATION_TIERS': 'MAJORATION_TIERS',
    'APA': 'APA'
} as const
export type DryAsapExoneration = typeof DryAsapExonerationEntries[keyof typeof DryAsapExonerationEntries]

  
/**
 * 
 * @export
 * @interface DryAsapFamilyDeclaration
 */
export interface DryAsapFamilyDeclaration {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapFamilyDeclaration
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapFamilyDeclaration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAsapFamilyDeclaration
     */
    trimester?: string;
    /**
     * 
     * @type {DryAsapUrssafDeclaration}
     * @memberof DryAsapFamilyDeclaration
     */
    AsapUrssafDeclaration?: DryAsapUrssafDeclaration;
    /**
     * 
     * @type {Array<DryAsapTeacherBlock>}
     * @memberof DryAsapFamilyDeclaration
     */
    AsapTeacherBlocks?: Array<DryAsapTeacherBlock>;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryAsapFamilyDeclaration
     */
    Family?: DryFamily;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    expectedPaymentAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    realPaymentAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    expectedTreatedCotisationsFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    expectedTreatedCotisationsAccess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    expectedTreatedCotisationsAccessFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    realTreatedCotisations?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryAsapFamilyDeclaration
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryAsapFamilyDeclaration
     */
    label?: string;
    /**
     * 
     * @type {DryAsapFamilyDeclarationStatus}
     * @memberof DryAsapFamilyDeclaration
     */
    status?: DryAsapFamilyDeclarationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DryAsapFamilyDeclaration
     */
    neant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryAsapFamilyDeclaration
     */
    manuallyDeclared?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryAsapFamilyDeclaration
     */
    recomputedCotisationSumFormula?: number;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryAsapFamilyDeclarationStatusEntries = {
    'DRAFT': 'DRAFT',
    'DECLARED': 'DECLARED',
    'CONFIRMED': 'CONFIRMED',
    'REJECTED': 'REJECTED'
} as const
export type DryAsapFamilyDeclarationStatus = typeof DryAsapFamilyDeclarationStatusEntries[keyof typeof DryAsapFamilyDeclarationStatusEntries]

  
/**
 * 
 * @export
 * @interface DryAsapGlobalDeclaration
 */
export interface DryAsapGlobalDeclaration {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapGlobalDeclaration
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapGlobalDeclaration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAsapGlobalDeclaration
     */
    trimester?: string;
    /**
     * 
     * @type {Array<DryAsapUrssafDeclaration>}
     * @memberof DryAsapGlobalDeclaration
     */
    AsapUrssafDeclarations?: Array<DryAsapUrssafDeclaration>;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapGlobalDeclaration
     */
    declaredAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryAsapGlobalDeclaration
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    referenceNb?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAsapGlobalDeclaration
     */
    label?: string;
    /**
     * 
     * @type {DryAsapGlobalDeclarationStatus}
     * @memberof DryAsapGlobalDeclaration
     */
    status?: DryAsapGlobalDeclarationStatus;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    expectedTreatedCotisationsTotalFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    realTreatedCotisationsTotalFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    realPaymentTotalFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapGlobalDeclaration
     */
    expectedPaymentTotalFormula?: number;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryAsapGlobalDeclarationStatusEntries = {
    'DRAFT': 'DRAFT',
    'ABANDONNED': 'ABANDONNED',
    'LOCKED': 'LOCKED',
    'DECLARED': 'DECLARED',
    'CONFIRMED': 'CONFIRMED'
} as const
export type DryAsapGlobalDeclarationStatus = typeof DryAsapGlobalDeclarationStatusEntries[keyof typeof DryAsapGlobalDeclarationStatusEntries]

  
/**
 * 
 * @export
 * @interface DryAsapLettrage
 */
export interface DryAsapLettrage {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapLettrage
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapLettrage
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapLettrage
     */
    id?: number;
    /**
     * 
     * @type {DryAsapDebit}
     * @memberof DryAsapLettrage
     */
    AsapDebit1?: DryAsapDebit;
    /**
     * 
     * @type {DryAsapDebit}
     * @memberof DryAsapLettrage
     */
    AsapDebit2?: DryAsapDebit;
    /**
     * 
     * @type {number}
     * @memberof DryAsapLettrage
     */
    amount?: number;
}


  
/**
 * 
 * @export
 * @interface DryAsapTeacherBlock
 */
export interface DryAsapTeacherBlock {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapTeacherBlock
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapTeacherBlock
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    id?: number;
    /**
     * 
     * @type {DryAsapFamilyDeclaration}
     * @memberof DryAsapTeacherBlock
     */
    AsapFamilyDeclaration?: DryAsapFamilyDeclaration;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryAsapTeacherBlock
     */
    Teacher?: DryTeacher;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    nbH?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    nbHAccess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    netHMean?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    netHMeanAccess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    recomputedCotisations?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    expectedTreatedCotisationFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapTeacherBlock
     */
    expectedTreatedCotisationsAccess?: number | null;
    /**
     * 
     * @type {Array<DryPayslipGroup>}
     * @memberof DryAsapTeacherBlock
     */
    PayslipGroups?: Array<DryPayslipGroup>;
}


  
/**
 * 
 * @export
 * @interface DryAsapUrssafDeclaration
 */
export interface DryAsapUrssafDeclaration {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAsapUrssafDeclaration
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAsapUrssafDeclaration
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    id?: number;
    /**
     * 
     * @type {DryUrssaf}
     * @memberof DryAsapUrssafDeclaration
     */
    Urssaf?: DryUrssaf;
    /**
     * 
     * @type {string}
     * @memberof DryAsapUrssafDeclaration
     */
    trimester?: string;
    /**
     * 
     * @type {DryAsapGlobalDeclaration}
     * @memberof DryAsapUrssafDeclaration
     */
    AsapGlobalDeclaration?: DryAsapGlobalDeclaration;
    /**
     * 
     * @type {Array<DryAsapFamilyDeclaration>}
     * @memberof DryAsapUrssafDeclaration
     */
    AsapFamilyDeclarations?: Array<DryAsapFamilyDeclaration>;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    expectedPaymentAmountFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    realPaymentAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    realTreatedCotisations?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    lettrageDiff?: number | null;
    /**
     * 
     * @type {DryAsapDebit}
     * @memberof DryAsapUrssafDeclaration
     */
    AsapDebit?: DryAsapDebit | null;
    /**
     * 
     * @type {DryAsapUrssafDeclarationStatus}
     * @memberof DryAsapUrssafDeclaration
     */
    status?: DryAsapUrssafDeclarationStatus;
    /**
     * 
     * @type {Array<DryBankTransaction>}
     * @memberof DryAsapUrssafDeclaration
     */
    BankTransactions?: Array<DryBankTransaction>;
    /**
     * 
     * @type {number}
     * @memberof DryAsapUrssafDeclaration
     */
    bankTransactionSumFormula?: number;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryAsapUrssafDeclarationStatusEntries = {
    'DRAFT': 'DRAFT',
    'ABANDONNED': 'ABANDONNED',
    'DECLARED': 'DECLARED',
    'CONFIRMED': 'CONFIRMED',
    'PAID': 'PAID'
} as const
export type DryAsapUrssafDeclarationStatus = typeof DryAsapUrssafDeclarationStatusEntries[keyof typeof DryAsapUrssafDeclarationStatusEntries]

  
/**
 * 
 * @export
 * @interface DryAttestationFiscale
 */
export interface DryAttestationFiscale {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryAttestationFiscale
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryAttestationFiscale
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    id?: number;
    /**
     * 
     * @type {DryAttestationFiscaleType}
     * @memberof DryAttestationFiscale
     */
    type?: DryAttestationFiscaleType;
    /**
     * 
     * @type {Array<DryAttestationFiscaleLine>}
     * @memberof DryAttestationFiscale
     */
    lines?: Array<DryAttestationFiscaleLine>;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryAttestationFiscale
     */
    Family?: DryFamily;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    totAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    paidAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    cesuAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    aiciAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscale
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof DryAttestationFiscale
     */
    signedOn?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryAttestationFiscale
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryAttestationFiscale
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryAttestationFiscale
     */
    manuallySent?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryAttestationFiscale
     */
    firstRequestedAt?: Date | null;
}




  
/**
 * 
 * @export
 * @interface DryAttestationFiscaleLine
 */
export interface DryAttestationFiscaleLine {
    /**
     * 
     * @type {boolean}
     * @memberof DryAttestationFiscaleLine
     */
    hidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    aiciAmount: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    cesuAmount: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    paidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    invoicedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    usedCredits: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryAttestationFiscaleLine
     */
    teacherId: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryAttestationFiscaleLine
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof DryAttestationFiscaleLine
     */
    month: string;
    /**
     * 
     * @type {DryAttestationFiscaleType}
     * @memberof DryAttestationFiscaleLine
     */
    aiciMode: DryAttestationFiscaleType;
    /**
     * 
     * @type {string}
     * @memberof DryAttestationFiscaleLine
     */
    type: DryAttestationFiscaleLineTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const DryAttestationFiscaleLineTypeEnumEntries = {
    'lessons': 'lessons',
    'fees': 'fees'
} as const
export type DryAttestationFiscaleLineTypeEnum = typeof DryAttestationFiscaleLineTypeEnumEntries[keyof typeof DryAttestationFiscaleLineTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryAttestationFiscaleTypeEntries = {
    'CIDiff': 'CIDiff',
    'AICI': 'AICI',
    'Mixed': 'Mixed',
    'Unknown': 'Unknown'
} as const
export type DryAttestationFiscaleType = typeof DryAttestationFiscaleTypeEntries[keyof typeof DryAttestationFiscaleTypeEntries]

  
/**
 * 
 * @export
 * @interface DryBankAccount
 */
export interface DryBankAccount {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryBankAccount
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryBankAccount
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryBankAccount
     */
    id?: number;
    /**
     * 
     * @type {Array<DryBankTransaction>}
     * @memberof DryBankAccount
     */
    BankTransaction?: Array<DryBankTransaction>;
    /**
     * 
     * @type {Array<DryPontoPayment>}
     * @memberof DryBankAccount
     */
    PontoPayment?: Array<DryPontoPayment>;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    externalAccountId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    journal?: string;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    comptaAccountNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryBankAccount
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryBankAccount
     */
    lastSyncAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryBankAccount
     */
    soldeFormula?: number;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    pennylaneJournalExtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    pennylaneJournalLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankAccount
     */
    pennylaneLedgerAccountExtId?: string | null;
}


  
/**
 * 
 * @export
 * @interface DryBankCheck
 */
export interface DryBankCheck {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryBankCheck
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryBankCheck
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryBankCheck
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DryBankCheck
     */
    amount?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryBankCheck
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryBankCheck
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryBankCheckDeposit}
     * @memberof DryBankCheck
     */
    BankCheckDeposit?: DryBankCheckDeposit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryBankCheck
     */
    DebitReceived?: DryDebit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryBankCheck
     */
    DebitRejected?: DryDebit | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryBankCheck
     */
    isExternalTreatment?: boolean;
    /**
     * 
     * @type {DryBankCheckStatus}
     * @memberof DryBankCheck
     */
    status?: DryBankCheckStatus;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    receivedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    deposedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    confirmedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    rejectedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheck
     */
    comment?: string | null;
}




  
/**
 * 
 * @export
 * @interface DryBankCheckDeposit
 */
export interface DryBankCheckDeposit {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryBankCheckDeposit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryBankCheckDeposit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryBankCheckDeposit
     */
    id?: number;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryBankCheckDeposit
     */
    BankTransaction?: DryBankTransaction | null;
    /**
     * 
     * @type {Array<DryBankCheck>}
     * @memberof DryBankCheckDeposit
     */
    BankChecks?: Array<DryBankCheck>;
    /**
     * 
     * @type {number}
     * @memberof DryBankCheckDeposit
     */
    expectedAmountSumFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryBankCheckDeposit
     */
    confirmedAmountSumFormula?: number;
    /**
     * 
     * @type {DryBankCheckDepositStatus}
     * @memberof DryBankCheckDeposit
     */
    status?: DryBankCheckDepositStatus;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheckDeposit
     */
    deposedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheckDeposit
     */
    reconcilledOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankCheckDeposit
     */
    comment?: string | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryBankCheckDepositStatusEntries = {
    'DEPOSED': 'DEPOSED',
    'RECONCILLED': 'RECONCILLED'
} as const
export type DryBankCheckDepositStatus = typeof DryBankCheckDepositStatusEntries[keyof typeof DryBankCheckDepositStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryBankCheckStatusEntries = {
    'CONFIRMED': 'CONFIRMED',
    'REJECTED': 'REJECTED',
    'RECEIVED': 'RECEIVED',
    'DEPOSED': 'DEPOSED'
} as const
export type DryBankCheckStatus = typeof DryBankCheckStatusEntries[keyof typeof DryBankCheckStatusEntries]

  
/**
 * 
 * @export
 * @interface DryBankTransaction
 */
export interface DryBankTransaction {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryBankTransaction
     */
    id?: number;
    /**
     * 
     * @type {DryBankAccount}
     * @memberof DryBankTransaction
     */
    BankAccount?: DryBankAccount;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    transactionUUID?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    valueDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    executionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    remittanceInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    proprietaryBankTransactionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    counterpartName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    bankTransactionCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryBankTransaction
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    additionalInformation?: string | null;
    /**
     * 
     * @type {DryBankTransactionTreatmentKind}
     * @memberof DryBankTransaction
     */
    treatmentKind?: DryBankTransactionTreatmentKind | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    treatedAt?: Date | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryBankTransaction
     */
    TreatedBy?: DryAgent | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryBankTransaction
     */
    helpNeededTreatment?: boolean;
    /**
     * 
     * @type {DryBankTransactionTresoKind}
     * @memberof DryBankTransaction
     */
    tresoKind?: DryBankTransactionTresoKind | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    tresoAt?: Date | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryBankTransaction
     */
    TresoBy?: DryAgent | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryBankTransaction
     */
    helpNeededTreso?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    comment?: string | null;
    /**
     * 
     * @type {DryBankCheckDeposit}
     * @memberof DryBankTransaction
     */
    BankCheckDeposit?: DryBankCheckDeposit | null;
    /**
     * 
     * @type {DryCesuCheckDeposit}
     * @memberof DryBankTransaction
     */
    CesuCheckDeposit?: DryCesuCheckDeposit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryBankTransaction
     */
    TransferDebit?: DryDebit | null;
    /**
     * 
     * @type {Array<DryTeacherPayment>}
     * @memberof DryBankTransaction
     */
    TeacherPayments?: Array<DryTeacherPayment> | null;
    /**
     * 
     * @type {DryAsapUrssafDeclaration}
     * @memberof DryBankTransaction
     */
    AsapUrssafDeclaration?: DryAsapUrssafDeclaration | null;
    /**
     * 
     * @type {DryAICIPaymentRequest}
     * @memberof DryBankTransaction
     */
    AICIPaymentRequest?: DryAICIPaymentRequest | null;
    /**
     * 
     * @type {DryAICIPaymentRequest}
     * @memberof DryBankTransaction
     */
    AICIPaymentRequestPayback?: DryAICIPaymentRequest | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    pennylaneEntryExtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    pennylaneEntryLineExtId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    pennylaneSyncedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryBankTransaction
     */
    pennylaneSyncError?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryBankTransaction
     */
    treatmentUpdatedAt?: Date | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryBankTransactionTreatmentKindEntries = {
    'MANUAL': 'MANUAL',
    'EXTERNAL': 'EXTERNAL',
    'TEACHER_PAYMENT': 'TEACHER_PAYMENT',
    'CUSTOMER_TRANSFER_CASHIN': 'CUSTOMER_TRANSFER_CASHIN',
    'CHECK_DEPOSIT': 'CHECK_DEPOSIT',
    'CESU_DEPOSIT': 'CESU_DEPOSIT',
    'GOCARDLESS_PAYOUT': 'GOCARDLESS_PAYOUT',
    'CUSTOMER_TRANSFER_PAYBACK': 'CUSTOMER_TRANSFER_PAYBACK',
    'TEACHER_TRANSFER_PAYBACK': 'TEACHER_TRANSFER_PAYBACK',
    'ASAP_PAYMENT': 'ASAP_PAYMENT',
    'AICI_PAYMENT': 'AICI_PAYMENT',
    'AICI_PAYBACK': 'AICI_PAYBACK'
} as const
export type DryBankTransactionTreatmentKind = typeof DryBankTransactionTreatmentKindEntries[keyof typeof DryBankTransactionTreatmentKindEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryBankTransactionTresoKindEntries = {
    'AICI': 'AICI',
    'TEACHER_PAYMENT': 'TEACHER_PAYMENT',
    'CUSTOMER_PAYMENT': 'CUSTOMER_PAYMENT',
    'CUSTOMER_PAYBACK': 'CUSTOMER_PAYBACK',
    'TEACHER_PAYBACK': 'TEACHER_PAYBACK',
    'TEACHER_COTISATIONS': 'TEACHER_COTISATIONS',
    'SPECIAL': 'SPECIAL',
    'IGNORE': 'IGNORE'
} as const
export type DryBankTransactionTresoKind = typeof DryBankTransactionTresoKindEntries[keyof typeof DryBankTransactionTresoKindEntries]

  
/**
 * 
 * @export
 * @interface DryBaseEntity
 */
export interface DryBaseEntity {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryBaseEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryBaseEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryBaseEntity
     */
    id?: number;
}


  
/**
 * 
 * @export
 * @interface DryBlockInvoiceLine
 */
export interface DryBlockInvoiceLine {
    /**
     * 
     * @type {DryModificationBlockLineType}
     * @memberof DryBlockInvoiceLine
     */
    type?: DryModificationBlockLineType;
    /**
     * 
     * @type {number}
     * @memberof DryBlockInvoiceLine
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof DryBlockInvoiceLine
     */
    tauxTVA: number;
    /**
     * 
     * @type {number}
     * @memberof DryBlockInvoiceLine
     */
    unitCount: number;
    /**
     * 
     * @type {number}
     * @memberof DryBlockInvoiceLine
     */
    unitPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryBlockInvoiceLine
     */
    unitPriceTTCWhole: number;
    /**
     * 
     * @type {string}
     * @memberof DryBlockInvoiceLine
     */
    description: string;
}




  
/**
 * 
 * @export
 * @interface DryCandidature
 */
export interface DryCandidature {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCandidature
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryCandidature
     */
    activityComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    pausedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    completedInfoAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryCandidature
     */
    levelsComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCandidature
     */
    otherComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    preselectResponseAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    qualifiedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    sessionPlannedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryCandidature
     */
    sessionDurationSec?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCandidature
     */
    sessionResponseAt?: Date | null;
    /**
     * 
     * @type {DryCandidatureStatus}
     * @memberof DryCandidature
     */
    status?: DryCandidatureStatus;
    /**
     * 
     * @type {string}
     * @memberof DryCandidature
     */
    teachingsComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCandidature
     */
    weekDisposComment?: string | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryCandidature
     */
    PreselectResponseManager?: DryAgent | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryCandidature
     */
    SessionResponseManager?: DryAgent | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryCandidature
     */
    Teacher?: DryTeacher;
    /**
     * 
     * @type {boolean}
     * @memberof DryCandidature
     */
    isFastTrack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryCandidature
     */
    isTrash?: boolean;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryCandidatureStatusEntries = {
    'CANDIDATURE_ASK': 'CANDIDATURE_ASK',
    'CANDIDATURE_REFUSED': 'CANDIDATURE_REFUSED',
    'CANDIDATURE_PAUSED': 'CANDIDATURE_PAUSED',
    'WAITING_TEACHER_INFO': 'WAITING_TEACHER_INFO',
    'WAITING_SESSION_SCHEDULING': 'WAITING_SESSION_SCHEDULING',
    'WAITING_SESSION_RESULT': 'WAITING_SESSION_RESULT',
    'SESSION_REFUSED': 'SESSION_REFUSED',
    'WAITING_QUALIF_AND_SIGN': 'WAITING_QUALIF_AND_SIGN',
    'WAITING_SIGN_QUALIF_OK': 'WAITING_SIGN_QUALIF_OK',
    'WAITING_QUALIF_SIGN_OK': 'WAITING_QUALIF_SIGN_OK',
    'CANDIDATURE_FINISHED': 'CANDIDATURE_FINISHED'
} as const
export type DryCandidatureStatus = typeof DryCandidatureStatusEntries[keyof typeof DryCandidatureStatusEntries]

  
/**
 * 
 * @export
 * @interface DryCesuCheckDeposit
 */
export interface DryCesuCheckDeposit {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCesuCheckDeposit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCesuCheckDeposit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckDeposit
     */
    id?: number;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryCesuCheckDeposit
     */
    BankTransaction?: DryBankTransaction | null;
    /**
     * 
     * @type {Array<DryCesuCheckLot>}
     * @memberof DryCesuCheckDeposit
     */
    CesuCheckLots?: Array<DryCesuCheckLot>;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckDeposit
     */
    expectedAmountSumFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckDeposit
     */
    confirmedAmountSumFormula?: number;
    /**
     * 
     * @type {DryCesuCheckDepositStatus}
     * @memberof DryCesuCheckDeposit
     */
    status?: DryCesuCheckDepositStatus;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckDeposit
     */
    deposedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckDeposit
     */
    reconcilledOn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckDeposit
     */
    feesHT?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckDeposit
     */
    feesTVA?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckDeposit
     */
    comment?: string | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryCesuCheckDepositStatusEntries = {
    'DEPOSED': 'DEPOSED',
    'RECONCILLED': 'RECONCILLED'
} as const
export type DryCesuCheckDepositStatus = typeof DryCesuCheckDepositStatusEntries[keyof typeof DryCesuCheckDepositStatusEntries]

  
/**
 * 
 * @export
 * @interface DryCesuCheckLot
 */
export interface DryCesuCheckLot {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCesuCheckLot
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCesuCheckLot
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckLot
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckLot
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckLot
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckLot
     */
    rejectedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCesuCheckLot
     */
    confirmedAmount?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryCesuCheckLot
     */
    Family?: DryFamily;
    /**
     * 
     * @type {DryCesuCheckDeposit}
     * @memberof DryCesuCheckLot
     */
    CesuCheckDeposit?: DryCesuCheckDeposit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryCesuCheckLot
     */
    DebitReceived?: DryDebit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryCesuCheckLot
     */
    DebitRejected?: DryDebit | null;
    /**
     * 
     * @type {DryCesuCheckStatus}
     * @memberof DryCesuCheckLot
     */
    status?: DryCesuCheckStatus;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckLot
     */
    receivedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckLot
     */
    deposedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckLot
     */
    confirmedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCesuCheckLot
     */
    comment?: string | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryCesuCheckStatusEntries = {
    'CONFIRMED': 'CONFIRMED',
    'RECEIVED': 'RECEIVED',
    'DEPOSED': 'DEPOSED'
} as const
export type DryCesuCheckStatus = typeof DryCesuCheckStatusEntries[keyof typeof DryCesuCheckStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryChipKeyEntries = {
    'convinced': 'convinced'
} as const
export type DryChipKey = typeof DryChipKeyEntries[keyof typeof DryChipKeyEntries]

  
/**
 * 
 * @export
 * @interface DryConciseLevel
 */
export interface DryConciseLevel {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryConciseLevel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryConciseLevel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryConciseLevel
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryConciseLevel
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryConciseLevel
     */
    nameLong?: string;
    /**
     * 
     * @type {string}
     * @memberof DryConciseLevel
     */
    nameShort?: string;
    /**
     * 
     * @type {number}
     * @memberof DryConciseLevel
     */
    orderNb?: number | null;
    /**
     * 
     * @type {DryLevelGroup}
     * @memberof DryConciseLevel
     */
    LevelGroup?: DryLevelGroup | null;
    /**
     * 
     * @type {Array<DryLevel>}
     * @memberof DryConciseLevel
     */
    Levels?: Array<DryLevel>;
}


  
/**
 * 
 * @export
 * @interface DryCoordinates
 */
export interface DryCoordinates {
    [key: string]: any | any;

    /**
     * 
     * @type {number}
     * @memberof DryCoordinates
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof DryCoordinates
     */
    lng: number;
}


  
/**
 * 
 * @export
 * @interface DryCreationBlock
 */
export interface DryCreationBlock {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCreationBlock
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCreationBlock
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    id?: number;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryCreationBlock
     */
    CreditInvoice?: DryCreditInvoice | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreationBlock
     */
    type?: DryCreationBlockTypeEnum;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof DryCreationBlock
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    blockPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryCreationBlock
     */
    aiciMode?: DryAICIMode | null;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryCreationBlock
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {string}
     * @memberof DryCreationBlock
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    creationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    basePrice?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryCreationBlock
     */
    CreatedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryCreationBlock
     */
    InitialMatch?: DryMatch;
    /**
     * 
     * @type {DryCreationBlockStatus}
     * @memberof DryCreationBlock
     */
    status?: DryCreationBlockStatus;
    /**
     * 
     * @type {DryCreationBlockAbortReason}
     * @memberof DryCreationBlock
     */
    abortReason?: DryCreationBlockAbortReason | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreationBlock
     */
    blockSolde?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryCreationBlockTypeEnumEntries = {
    'creation': 'creation'
} as const
export type DryCreationBlockTypeEnum = typeof DryCreationBlockTypeEnumEntries[keyof typeof DryCreationBlockTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryCreationBlockAbortReasonEntries = {
    'admin_stopped': 'admin_stopped',
    'yousign_refused': 'yousign_refused'
} as const
export type DryCreationBlockAbortReason = typeof DryCreationBlockAbortReasonEntries[keyof typeof DryCreationBlockAbortReasonEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryCreationBlockStatusEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PENDING': 'PENDING',
    'ABORTED': 'ABORTED'
} as const
export type DryCreationBlockStatus = typeof DryCreationBlockStatusEntries[keyof typeof DryCreationBlockStatusEntries]

  
/**
 * 
 * @export
 * @interface DryCredit
 */
export interface DryCredit {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCredit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCredit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    id?: number;
    /**
     * 
     * @type {DryCreationBlock}
     * @memberof DryCredit
     */
    CreationBlock?: DryCreationBlock;
    /**
     * 
     * @type {DryModificationBlock}
     * @memberof DryCredit
     */
    CancelModificationBlock?: DryModificationBlock | null;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryCredit
     */
    CurrentMatch?: DryMatch;
    /**
     * 
     * @type {DryCreditStatus}
     * @memberof DryCredit
     */
    status?: DryCreditStatus;
    /**
     * 
     * @type {DryCreditAbortReason}
     * @memberof DryCredit
     */
    abortReason?: DryCreditAbortReason | null;
    /**
     * 
     * @type {string}
     * @memberof DryCredit
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    basePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    initialPriceWhole?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    initialPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    currentPriceWhole?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCredit
     */
    currentPrice?: number | null;
    /**
     * 
     * @type {DryPayslipGroup}
     * @memberof DryCredit
     */
    PayslipGroup?: DryPayslipGroup;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryCredit
     */
    aiciMode?: DryAICIMode | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryCreditAbortReasonEntries = {
    'admin_stopped': 'admin_stopped',
    'yousign_refused': 'yousign_refused'
} as const
export type DryCreditAbortReason = typeof DryCreditAbortReasonEntries[keyof typeof DryCreditAbortReasonEntries]

  
/**
 * 
 * @export
 * @interface DryCreditInvoice
 */
export interface DryCreditInvoice {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    id?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryCreditInvoice
     */
    Family?: DryFamily;
    /**
     * 
     * @type {DryCustomerProposition}
     * @memberof DryCreditInvoice
     */
    CustomerProposition?: DryCustomerProposition | null;
    /**
     * 
     * @type {Array<DryCreditInvoiceBlocksInner>}
     * @memberof DryCreditInvoice
     */
    Blocks?: Array<DryCreditInvoiceBlocksInner>;
    /**
     * 
     * @type {DryCreditInvoiceStatus}
     * @memberof DryCreditInvoice
     */
    status?: DryCreditInvoiceStatus;
    /**
     * 
     * @type {DryDoc}
     * @memberof DryCreditInvoice
     */
    DevisDoc?: DryDoc | null;
    /**
     * 
     * @type {DryDoc}
     * @memberof DryCreditInvoice
     */
    InvoiceDoc?: DryDoc | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    totalPriceTTCWhole?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    totalPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    totTVA?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoice
     */
    devisReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoice
     */
    invoiceReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    devisReferenceNb?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    invoiceReferenceNb?: number | null;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof DryCreditInvoice
     */
    StandardInvoice?: DryStandardInvoice | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoice
     */
    devisCreatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoice
     */
    invoiceCreatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoice
     */
    devisExpireOn?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    devisSentAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    devisSendingAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    devisAbortedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    devisAnsweredAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    invoiceSentAt?: Date | null;
    /**
     * 
     * @type {Array<DryDebit>}
     * @memberof DryCreditInvoice
     */
    Echeances?: Array<DryDebit>;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoice
     */
    lastPaidAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoice
     */
    remainingAmountFormula?: number;
    /**
     * 
     * @type {Array<DryCreditInvoiceDraftEcheancesInner>}
     * @memberof DryCreditInvoice
     */
    draftEcheances?: Array<DryCreditInvoiceDraftEcheancesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof DryCreditInvoice
     */
    allowAltPaymentMethods?: boolean;
}




  
/**
 * 
 * @export
 * @interface DryCreditInvoiceBlocksInner
 */
export interface DryCreditInvoiceBlocksInner {
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryCreditInvoiceBlocksInner
     */
    aiciMode?: DryAICIMode;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoiceBlocksInner
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCreditInvoiceBlocksInner
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoiceBlocksInner
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    creationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    basePrice?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryCreditInvoiceBlocksInner
     */
    CreatedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryCreditInvoiceBlocksInner
     */
    InitialMatch?: DryMatch;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoiceBlocksInner
     */
    status?: DryCreditInvoiceBlocksInnerStatusEnum;
    /**
     * 
     * @type {DryCreationBlockAbortReason}
     * @memberof DryCreditInvoiceBlocksInner
     */
    abortReason?: DryCreationBlockAbortReason;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    blockSolde?: number;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryCreditInvoiceBlocksInner
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof DryCreditInvoiceBlocksInner
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    blockPriceTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryCreditInvoiceBlocksInner
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryCreationBlock}
     * @memberof DryCreditInvoiceBlocksInner
     */
    SourceCreationBlock?: DryCreationBlock;
    /**
     * 
     * @type {Array<PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner>}
     * @memberof DryCreditInvoiceBlocksInner
     */
    Modifications?: Array<PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner>;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryCreditInvoiceBlocksInner
     */
    CancelledCredits?: Array<DryCredit>;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    transferCount?: number;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryCreditInvoiceBlocksInner
     */
    SourceMatch?: DryMatch;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryCreditInvoiceBlocksInner
     */
    TargetMatch?: DryMatch;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    previousSourceSolde?: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceBlocksInner
     */
    previousTargetSolde?: number;
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoiceBlocksInner
     */
    type: DryCreditInvoiceBlocksInnerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const DryCreditInvoiceBlocksInnerStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PENDING': 'PENDING',
    'ABORTED': 'ABORTED'
} as const
export type DryCreditInvoiceBlocksInnerStatusEnum = typeof DryCreditInvoiceBlocksInnerStatusEnumEntries[keyof typeof DryCreditInvoiceBlocksInnerStatusEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const DryCreditInvoiceBlocksInnerTypeEnumEntries = {
    'creation': 'creation',
    'transfer': 'transfer',
    'modification': 'modification',
    'any': 'any'
} as const
export type DryCreditInvoiceBlocksInnerTypeEnum = typeof DryCreditInvoiceBlocksInnerTypeEnumEntries[keyof typeof DryCreditInvoiceBlocksInnerTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface DryCreditInvoiceBlocksInnerAllOf
 */
export interface DryCreditInvoiceBlocksInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof DryCreditInvoiceBlocksInnerAllOf
     */
    type: DryCreditInvoiceBlocksInnerAllOfTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const DryCreditInvoiceBlocksInnerAllOfTypeEnumEntries = {
    'creation': 'creation',
    'transfer': 'transfer',
    'modification': 'modification',
    'any': 'any'
} as const
export type DryCreditInvoiceBlocksInnerAllOfTypeEnum = typeof DryCreditInvoiceBlocksInnerAllOfTypeEnumEntries[keyof typeof DryCreditInvoiceBlocksInnerAllOfTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface DryCreditInvoiceDraftEcheancesInner
 */
export interface DryCreditInvoiceDraftEcheancesInner {
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceDraftEcheancesInner
     */
    amountWhole: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceDraftEcheancesInner
     */
    monthOffset: number;
    /**
     * 
     * @type {number}
     * @memberof DryCreditInvoiceDraftEcheancesInner
     */
    rank: number;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryCreditInvoiceStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'ABORTED': 'ABORTED',
    'READY': 'READY',
    'INVOICE': 'INVOICE'
} as const
export type DryCreditInvoiceStatus = typeof DryCreditInvoiceStatusEntries[keyof typeof DryCreditInvoiceStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryCreditStatusEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'ABORTED': 'ABORTED',
    'AVAILABLE': 'AVAILABLE',
    'USED': 'USED'
} as const
export type DryCreditStatus = typeof DryCreditStatusEntries[keyof typeof DryCreditStatusEntries]

  
/**
 * 
 * @export
 * @interface DryCustomerProposition
 */
export interface DryCustomerProposition {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryCustomerProposition
     */
    id?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryCustomerProposition
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryCustomerProposition
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {DryCustomerPropositionStatus}
     * @memberof DryCustomerProposition
     */
    status?: DryCustomerPropositionStatus;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    sendingAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    awaitingAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    completedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryCustomerProposition
     */
    cancelReason?: DryCustomerPropositionCancelReasonEnum;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryCustomerProposition
     */
    SentBy?: DryAgent | null;
    /**
     * 
     * @type {string}
     * @memberof DryCustomerProposition
     */
    message?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryCustomerProposition
     */
    awaitTeacherAttributions?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryCustomerProposition
     */
    skipYs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryCustomerProposition
     */
    skipInvoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryCustomerProposition
     */
    isForced?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    linkClickedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryCustomerProposition
     */
    validationFlowToken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryCustomerProposition
     */
    validationFlowTokenIAT?: Date | null;
    /**
     * 
     * @type {DryYsProc}
     * @memberof DryCustomerProposition
     */
    YsProc?: DryYsProc | null;
    /**
     * 
     * @type {Array<DryTeacherProposition>}
     * @memberof DryCustomerProposition
     */
    CheckedTeacherPropositions?: Array<DryTeacherProposition>;
    /**
     * 
     * @type {Array<DryMatch>}
     * @memberof DryCustomerProposition
     */
    ActivationMatches?: Array<DryMatch>;
    /**
     * 
     * @type {Array<DryMatchSelectedTeacherPropositionRemindersInner>}
     * @memberof DryCustomerProposition
     */
    reminders?: Array<DryMatchSelectedTeacherPropositionRemindersInner>;
}

/**
    * @export
    * @enum {string}
    */
export const DryCustomerPropositionCancelReasonEnumEntries = {
    'yousign_refused': 'yousign_refused',
    'admin_stopped': 'admin_stopped',
    'null': 'null'
} as const
export type DryCustomerPropositionCancelReasonEnum = typeof DryCustomerPropositionCancelReasonEnumEntries[keyof typeof DryCustomerPropositionCancelReasonEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryCustomerPropositionStatusEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'AWAITING': 'AWAITING',
    'PENDING': 'PENDING',
    'COMPLETED': 'COMPLETED'
} as const
export type DryCustomerPropositionStatus = typeof DryCustomerPropositionStatusEntries[keyof typeof DryCustomerPropositionStatusEntries]

  
/**
 * 
 * @export
 * @interface DryDebit
 */
export interface DryDebit {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryDebit
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryDebit
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryDebit
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DryDebit
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof DryDebit
     */
    opDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DryDebit
     */
    label?: string;
    /**
     * 
     * @type {Array<DryLettrage>}
     * @memberof DryDebit
     */
    Lettrages1?: Array<DryLettrage>;
    /**
     * 
     * @type {Array<DryLettrage>}
     * @memberof DryDebit
     */
    Lettrages2?: Array<DryLettrage>;
    /**
     * 
     * @type {number}
     * @memberof DryDebit
     */
    lettredAmountFormula?: number;
    /**
     * 
     * @type {DryTsoaPersonType}
     * @memberof DryDebit
     */
    personType?: DryTsoaPersonType;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryDebit
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryDebit
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryDebitOpType}
     * @memberof DryDebit
     */
    opType?: DryDebitOpType;
    /**
     * 
     * @type {DryPayslipGroup}
     * @memberof DryDebit
     */
    PayslipGroup?: DryPayslipGroup | null;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryDebit
     */
    CreditInvoice?: DryCreditInvoice | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryDebit
     */
    allowAltPaymentMethods?: boolean | null;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof DryDebit
     */
    StandardInvoice?: DryStandardInvoice | null;
    /**
     * 
     * @type {DryTeacherPayment}
     * @memberof DryDebit
     */
    TeacherPayment?: DryTeacherPayment | null;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryDebit
     */
    BankTransaction?: DryBankTransaction | null;
    /**
     * 
     * @type {DryBankCheck}
     * @memberof DryDebit
     */
    BankCheckReceived?: DryBankCheck | null;
    /**
     * 
     * @type {DryBankCheck}
     * @memberof DryDebit
     */
    BankCheckRejected?: DryBankCheck | null;
    /**
     * 
     * @type {DryCesuCheckLot}
     * @memberof DryDebit
     */
    CesuCheckLotReceived?: DryCesuCheckLot | null;
    /**
     * 
     * @type {DryCesuCheckLot}
     * @memberof DryDebit
     */
    CesuCheckLotRejected?: DryCesuCheckLot | null;
    /**
     * 
     * @type {DryGocardlessPayment}
     * @memberof DryDebit
     */
    GocardlessPaymentReceived?: DryGocardlessPayment | null;
    /**
     * 
     * @type {DryGocardlessPayment}
     * @memberof DryDebit
     */
    GocardlessPaymentRefunded?: DryGocardlessPayment | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryDebit
     */
    gocardlessPaymentCreatedOrDismissed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryDebit
     */
    amountC?: number;
    /**
     * 
     * @type {number}
     * @memberof DryDebit
     */
    amountD?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof DryDebit
     */
    $Lettrages?: Array<any>;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryDebitOpTypeEntries = {
    'credit-invoice': 'credit-invoice',
    'payslip': 'payslip',
    'customer-check': 'customer-check',
    'customer-check-rejected': 'customer-check-rejected',
    'bank-transfer': 'bank-transfer',
    'gocardless-prelevement': 'gocardless-prelevement',
    'gocardless-refund': 'gocardless-refund',
    'manual-accounting': 'manual-accounting',
    'standard-invoice': 'standard-invoice',
    'teacher-payment': 'teacher-payment',
    'customer-transfer-cashin': 'customer-transfer-cashin',
    'customer-transfer-payback': 'customer-transfer-payback',
    'teacher-transfer-payback': 'teacher-transfer-payback',
    'teacher-check-payback': 'teacher-check-payback',
    'cesu-check-lot': 'cesu-check-lot',
    'cesu-check-lot-rejection': 'cesu-check-lot-rejection'
} as const
export type DryDebitOpType = typeof DryDebitOpTypeEntries[keyof typeof DryDebitOpTypeEntries]

  
/**
 * 
 * @export
 * @interface DryDoc
 */
export interface DryDoc {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryDoc
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryDoc
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryDoc
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryDoc
     */
    fileName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryDoc
     */
    shouldBeSigned?: boolean;
    /**
     * 
     * @type {DrydocStatus}
     * @memberof DryDoc
     */
    status?: DrydocStatus;
    /**
     * 
     * @type {string}
     * @memberof DryDoc
     */
    storagePath?: string | null;
    /**
     * 
     * @type {DrydocType}
     * @memberof DryDoc
     */
    type?: DrydocType;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryDoc
     */
    Person?: DryPerson | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryDoc
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {string}
     * @memberof DryDoc
     */
    ysKey?: string | null;
    /**
     * 
     * @type {Array<DryYsProc>}
     * @memberof DryDoc
     */
    YsProcs?: Array<DryYsProc>;
    /**
     * 
     * @type {DryYsProc}
     * @memberof DryDoc
     */
    CurrentYsProc?: DryYsProc | null;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryDoc
     */
    CreditDevis?: DryCreditInvoice;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryDoc
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof DryDoc
     */
    StandardInvoice?: DryStandardInvoice;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof DryDoc
     */
    StandardDevis?: DryStandardInvoice;
    /**
     * 
     * @type {DryTeacherProposition}
     * @memberof DryDoc
     */
    TeacherProposition?: DryTeacherProposition;
}




  
/**
 * 
 * @export
 * @interface DryEmailTemplate
 */
export interface DryEmailTemplate {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryEmailTemplate
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryEmailTemplate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryEmailTemplate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryEmailTemplate
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DryEmailTemplate
     */
    design?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryEmailTemplate
     */
    html?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryEmailTemplate
     */
    isActive?: boolean;
    /**
     * 
     * @type {DryEmailTemplateKey}
     * @memberof DryEmailTemplate
     */
    key?: DryEmailTemplateKey | null;
    /**
     * 
     * @type {string}
     * @memberof DryEmailTemplate
     */
    subject?: string;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryEmailTemplateKeyEntries = {
    'STOREFRONT_PROSPECT_REGISTERED': 'STOREFRONT_PROSPECT_REGISTERED',
    'STOREFRONT_CANDIDATURE1_RECIEVED': 'STOREFRONT_CANDIDATURE1_RECIEVED',
    'STOREFRONT_FAST_TRACK_RECIEVED': 'STOREFRONT_FAST_TRACK_RECIEVED',
    'ACCEPT_CANDIDATURE1': 'ACCEPT_CANDIDATURE1',
    'ACCEPT_CANDIDATURE2': 'ACCEPT_CANDIDATURE2',
    'WIXCODE_REMINDER': 'WIXCODE_REMINDER',
    'REFUSE_CANDIDATURE1': 'REFUSE_CANDIDATURE1',
    'REFUSE_CANDIDATURE2': 'REFUSE_CANDIDATURE2',
    'CHARTE_REMINDER': 'CHARTE_REMINDER',
    'DELAY_CANDIDATURE1': 'DELAY_CANDIDATURE1',
    'FAST_TRACK_CANDIDATURE': 'FAST_TRACK_CANDIDATURE',
    'CUSTOMER_PRESENTATION': 'CUSTOMER_PRESENTATION',
    'CUSTOMER_PRESENTATION_RENEW': 'CUSTOMER_PRESENTATION_RENEW',
    'SEND_CREDIT_INVOICE': 'SEND_CREDIT_INVOICE',
    'SEND_TEACHER_PROPOSITION': 'SEND_TEACHER_PROPOSITION',
    'REMIND_TEACHER_PROPOSITION': 'REMIND_TEACHER_PROPOSITION',
    'REMIND_CUSTOMER_PROPOSITION': 'REMIND_CUSTOMER_PROPOSITION',
    'MONTLY_MATCH_SURVEY_REMINDER_INITIAL': 'MONTLY_MATCH_SURVEY_REMINDER_INITIAL',
    'MONTLY_MATCH_SURVEY_REMINDER_LAST_DAY': 'MONTLY_MATCH_SURVEY_REMINDER_LAST_DAY',
    'MONTLY_MATCH_SURVEY_LATE_REMINDER_1': 'MONTLY_MATCH_SURVEY_LATE_REMINDER_1',
    'SEND_RELEVE': 'SEND_RELEVE',
    'MATCH_PROPOSITION': 'MATCH_PROPOSITION',
    'FULL_MATCH_INFOS': 'FULL_MATCH_INFOS',
    'STUDENT_SELF_BIRTHDAY': 'STUDENT_SELF_BIRTHDAY',
    'FAMILY_CHILD_BIRTHDAY': 'FAMILY_CHILD_BIRTHDAY',
    'ATTESTATION_FISCALE': 'ATTESTATION_FISCALE',
    'REFRESHED_CUSTOMER_VALIDATION_TOKEN': 'REFRESHED_CUSTOMER_VALIDATION_TOKEN'
} as const
export type DryEmailTemplateKey = typeof DryEmailTemplateKeyEntries[keyof typeof DryEmailTemplateKeyEntries]

  
/**
 * 
 * @export
 * @interface DryFCMToken
 */
export interface DryFCMToken {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryFCMToken
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryFCMToken
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryFCMToken
     */
    id?: number;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryFCMToken
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryFCMToken
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryFCMToken
     */
    Agent?: DryAgent | null;
    /**
     * 
     * @type {DryFirebaseProjectStatus}
     * @memberof DryFCMToken
     */
    projectName?: DryFirebaseProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof DryFCMToken
     */
    token?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryFCMToken
     */
    lastValidAt?: Date;
}




  
/**
 * 
 * @export
 * @interface DryFamily
 */
export interface DryFamily {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    firstPayslipMonth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    firstPayslipMonthNotDeclared?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    latestReleveMonth?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    moneySoldeCFullFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    moneySoldeCTodayFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    expectedEcheancesSumFormula?: number;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    lastPaymentOnFormula?: string;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    firstUnpaidEcheanceOnFormula?: string;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    lastEcheanceOnFormula?: string;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    nextEcheanceOnFormula?: string;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    legalPaymentsSumFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    usedCreditsRealPriceSumFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    aiciAcompteSoldeFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    legalAccountingSoldeD?: number;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    cesuPaidSum?: number;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    namesSearchString?: string | null;
    /**
     * 
     * @type {Array<DryfamilyMonthHistoryItem>}
     * @memberof DryFamily
     */
    monthsHistory?: Array<DryfamilyMonthHistoryItem>;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    lastLessonAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    firstLessonAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryFamily
     */
    allMonths?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryFamily
     */
    declaredTrimesters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryFamily
     */
    undeclaredTrimesters?: Array<string>;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryFamily
     */
    Person?: DryPerson;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    activatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    temporisedAt?: Date | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryFamily
     */
    CreatedBy?: DryAgent | null;
    /**
     * 
     * @type {DryMainCity}
     * @memberof DryFamily
     */
    MainCity?: DryMainCity | null;
    /**
     * 
     * @type {DryAcquisitionChannel}
     * @memberof DryFamily
     */
    AcquisitionChannel?: DryAcquisitionChannel | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryFamily
     */
    CurrentAgent?: DryAgent | null;
    /**
     * 
     * @type {Array<DryMember>}
     * @memberof DryFamily
     */
    Members?: Array<DryMember>;
    /**
     * 
     * @type {DryMember}
     * @memberof DryFamily
     */
    MainContact?: DryMember | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryFamily
     */
    FamilyChief?: DryMember | null;
    /**
     * 
     * @type {Array<DryAddress>}
     * @memberof DryFamily
     */
    Addresses?: Array<DryAddress>;
    /**
     * 
     * @type {DryAddress}
     * @memberof DryFamily
     */
    FacturationAddress?: DryAddress | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    presentationSentAt?: Date | null;
    /**
     * 
     * @type {Array<DryPhoneCall>}
     * @memberof DryFamily
     */
    PhoneCalls?: Array<DryPhoneCall> | null;
    /**
     * 
     * @type {Array<DryPlannedCall>}
     * @memberof DryFamily
     */
    PlannedCalls?: Array<DryPlannedCall>;
    /**
     * 
     * @type {Ignored}
     * @memberof DryFamily
     */
    wixRegistrationData?: Ignored | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    notes?: string | null;
    /**
     * 
     * @type {DryFamilyCommercialStep}
     * @memberof DryFamily
     */
    commercialStep?: DryFamilyCommercialStep;
    /**
     * 
     * @type {DryFamilyTrashCategory}
     * @memberof DryFamily
     */
    trashCategory?: DryFamilyTrashCategory;
    /**
     * 
     * @type {Array<DryFamilyChipsInner>}
     * @memberof DryFamily
     */
    chips?: Array<DryFamilyChipsInner>;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    todoAction?: string | null;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof DryFamily
     */
    Docs?: Array<DryDoc>;
    /**
     * 
     * @type {Array<DryYsProc>}
     * @memberof DryFamily
     */
    YsProcs?: Array<DryYsProc>;
    /**
     * 
     * @type {Array<DryFamilyMonth>}
     * @memberof DryFamily
     */
    FamilyMonths?: Array<DryFamilyMonth>;
    /**
     * 
     * @type {Array<DryCustomerProposition>}
     * @memberof DryFamily
     */
    CustomerPropositions?: Array<DryCustomerProposition>;
    /**
     * 
     * @type {Array<DryCreditInvoice>}
     * @memberof DryFamily
     */
    CreditInvoices?: Array<DryCreditInvoice>;
    /**
     * 
     * @type {Array<DryLegalInvoice>}
     * @memberof DryFamily
     */
    LegalInvoices?: Array<DryLegalInvoice>;
    /**
     * 
     * @type {Array<DryStandardInvoice>}
     * @memberof DryFamily
     */
    StandardInvoices?: Array<DryStandardInvoice>;
    /**
     * 
     * @type {Array<DryDebit>}
     * @memberof DryFamily
     */
    Debits?: Array<DryDebit>;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    iban?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryFamily
     */
    hasIBAN?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    bic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    siret?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    asapNumCompte?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    asapAffiliationOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    asapRadiationOn?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    asapAffiliationStartedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    asapAffiliationDoneAt?: Date | null;
    /**
     * 
     * @type {DryRichComment}
     * @memberof DryFamily
     */
    asapAffiliationRichComment?: DryRichComment;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    asapNumOrdre?: number;
    /**
     * 
     * @type {DryUrssaf}
     * @memberof DryFamily
     */
    Urssaf?: DryUrssaf | null;
    /**
     * 
     * @type {Array<DryAsapFamilyDeclaration>}
     * @memberof DryFamily
     */
    AsapFamilyDeclarations?: Array<DryAsapFamilyDeclaration>;
    /**
     * 
     * @type {Array<DryFCMToken>}
     * @memberof DryFamily
     */
    FCMTokens?: Array<DryFCMToken>;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    validationFlowStartedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    validationFlowToken?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    validationFlowTokenIAT?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    aiciActivationTriedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    aiciActivationConfirmedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    aiciActivationConfirmationLastTestAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    aiciClientUUID?: string | null;
    /**
     * 
     * @type {Ignored}
     * @memberof DryFamily
     */
    aiciActivationError?: Ignored | null;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryFamily
     */
    aiciMode?: DryAICIMode | null;
    /**
     * 
     * @type {Array<DryAICIPaymentRequest>}
     * @memberof DryFamily
     */
    AICIPaymentRequests?: Array<DryAICIPaymentRequest>;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    failedCallsCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryFamily
     */
    accessTiersId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    releveLastMonth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    gocardlessCustomerUUID?: string | null;
    /**
     * 
     * @type {DryRichComment}
     * @memberof DryFamily
     */
    unpaidRichComment?: DryRichComment;
    /**
     * 
     * @type {boolean}
     * @memberof DryFamily
     */
    isUnpaidForever?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    firstAskedForAffiliationCodeAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryFamily
     */
    hasNewletterEnabled?: boolean;
    /**
     * 
     * @type {Array<DryAttestationFiscale>}
     * @memberof DryFamily
     */
    AttestationFiscales?: Array<DryAttestationFiscale>;
    /**
     * 
     * @type {DryAsapExoneration}
     * @memberof DryFamily
     */
    asapExoneration?: DryAsapExoneration | null;
    /**
     * 
     * @type {string}
     * @memberof DryFamily
     */
    registrationCalendlyEventUri?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryFamily
     */
    registrationCalendlyEventAt?: Date | null;
    /**
     * 
     * @type {any}
     * @memberof DryFamily
     */
    monthInfos?: any;
}




  
/**
 * 
 * @export
 * @interface DryFamilyChipsInner
 */
export interface DryFamilyChipsInner {
    /**
     * 
     * @type {Date}
     * @memberof DryFamilyChipsInner
     */
    finishedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryFamilyChipsInner
     */
    startedAt: Date;
    /**
     * 
     * @type {any}
     * @memberof DryFamilyChipsInner
     */
    meta: any;
    /**
     * 
     * @type {string}
     * @memberof DryFamilyChipsInner
     */
    label: string;
    /**
     * 
     * @type {DryChipKey}
     * @memberof DryFamilyChipsInner
     */
    key: DryChipKey;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryFamilyCommercialStepEntries = {
    'LEAD': 'LEAD',
    'PROSPECT': 'PROSPECT',
    'CLIENT': 'CLIENT'
} as const
export type DryFamilyCommercialStep = typeof DryFamilyCommercialStepEntries[keyof typeof DryFamilyCommercialStepEntries]

  
/**
 * 
 * @export
 * @interface DryFamilyMonth
 */
export interface DryFamilyMonth {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryFamilyMonth
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryFamilyMonth
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryFamilyMonth
     */
    id?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryFamilyMonth
     */
    Family?: DryFamily;
    /**
     * 
     * @type {string}
     * @memberof DryFamilyMonth
     */
    month?: string;
    /**
     * 
     * @type {DryReleve}
     * @memberof DryFamilyMonth
     */
    CurrentReleve?: DryReleve | null;
    /**
     * 
     * @type {Array<DryReleve>}
     * @memberof DryFamilyMonth
     */
    ReleveVersions?: Array<DryReleve>;
    /**
     * 
     * @type {boolean}
     * @memberof DryFamilyMonth
     */
    isDeclaredCI?: boolean | null;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryFamilyTrashCategoryEntries = {
    'ALIVE': 'ALIVE',
    'MAYBE_TRASH': 'MAYBE_TRASH',
    'REFUSED_TRASH': 'REFUSED_TRASH',
    'SUPERSENIOR': 'SUPERSENIOR'
} as const
export type DryFamilyTrashCategory = typeof DryFamilyTrashCategoryEntries[keyof typeof DryFamilyTrashCategoryEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryFirebaseProjectStatusEntries = {
    'teacher-app': 'teacher-app',
    'client-app': 'client-app'
} as const
export type DryFirebaseProjectStatus = typeof DryFirebaseProjectStatusEntries[keyof typeof DryFirebaseProjectStatusEntries]

  
/**
 * 
 * @export
 * @interface DryGocardlessPayment
 */
export interface DryGocardlessPayment {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryGocardlessPayment
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryGocardlessPayment
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    externalUUID?: string;
    /**
     * 
     * @type {DryGocardlessPaymentStatus}
     * @memberof DryGocardlessPayment
     */
    statusFormula?: DryGocardlessPaymentStatus | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayment
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayment
     */
    amountRefunded?: number;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryGocardlessPayment
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryGocardlessPayment
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryGocardlessPayout}
     * @memberof DryGocardlessPayment
     */
    GocardlessPayout?: DryGocardlessPayout | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryGocardlessPayment
     */
    DebitReceived?: DryDebit | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryGocardlessPayment
     */
    DebitRefunded?: DryDebit | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryGocardlessPayment
     */
    isExternalTreatment?: boolean;
    /**
     * 
     * @type {DryGocardlessPaymentExtStatus}
     * @memberof DryGocardlessPayment
     */
    externalStatus?: DryGocardlessPaymentExtStatus | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    chargedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    externalDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    externalReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayment
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryGocardlessPayment
     */
    customerNotFound?: boolean;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryGocardlessPaymentExtStatusEntries = {
    'cancelled': 'cancelled',
    'pending_customer_approval': 'pending_customer_approval',
    'pending_submission': 'pending_submission',
    'submitted': 'submitted',
    'confirmed': 'confirmed',
    'paid_out': 'paid_out',
    'customer_approval_denied': 'customer_approval_denied',
    'failed': 'failed',
    'charged_back': 'charged_back'
} as const
export type DryGocardlessPaymentExtStatus = typeof DryGocardlessPaymentExtStatusEntries[keyof typeof DryGocardlessPaymentExtStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryGocardlessPaymentStatusEntries = {
    'DRAFT': 'DRAFT',
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
    'PENDING': 'PENDING',
    'SUCCESS': 'SUCCESS',
    'CHARGED_BACK': 'CHARGED_BACK'
} as const
export type DryGocardlessPaymentStatus = typeof DryGocardlessPaymentStatusEntries[keyof typeof DryGocardlessPaymentStatusEntries]

  
/**
 * 
 * @export
 * @interface DryGocardlessPayout
 */
export interface DryGocardlessPayout {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryGocardlessPayout
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryGocardlessPayout
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayout
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayout
     */
    externalUUID?: string;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryGocardlessPayout
     */
    BankTransaction?: DryBankTransaction | null;
    /**
     * 
     * @type {Array<DryGocardlessPayment>}
     * @memberof DryGocardlessPayout
     */
    GocardlessPayments?: Array<DryGocardlessPayment>;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayout
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryGocardlessPayout
     */
    feesTTC?: number;
    /**
     * 
     * @type {DryGocardlessPayoutStatus}
     * @memberof DryGocardlessPayout
     */
    status?: DryGocardlessPayoutStatus;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayout
     */
    externalArrivalOn?: string;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayout
     */
    reconcilledOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryGocardlessPayout
     */
    comment?: string | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryGocardlessPayoutStatusEntries = {
    'PENDING': 'PENDING',
    'PAID': 'PAID',
    'RECONCILLED': 'RECONCILLED',
    'BOUNCED': 'BOUNCED'
} as const
export type DryGocardlessPayoutStatus = typeof DryGocardlessPayoutStatusEntries[keyof typeof DryGocardlessPayoutStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryInfoRejetEntries = {
    'REGUL_MNT_FACT': 'REGUL_MNT_FACT',
    'REGUL_NB_QTE_FACT': 'REGUL_NB_QTE_FACT',
    'REGUL_PRESTA_FACT': 'REGUL_PRESTA_FACT',
    'REGUL_AUTRE': 'REGUL_AUTRE',
    'CONTEST_ERR_FACT': 'CONTEST_ERR_FACT',
    'CONTEST_FACT_REGLEE': 'CONTEST_FACT_REGLEE',
    'CONTEST_AUTRE': 'CONTEST_AUTRE',
    'ANNUL_DBL': 'ANNUL_DBL',
    'ANNUL_ERR_MNT': 'ANNUL_ERR_MNT',
    'ANNUL_ERR_TECH': 'ANNUL_ERR_TECH',
    'ANNUL_AUTRE': 'ANNUL_AUTRE'
} as const
export type DryInfoRejet = typeof DryInfoRejetEntries[keyof typeof DryInfoRejetEntries]

  
/**
 * 
 * @export
 * @interface DryInputDemandePaiement
 */
export interface DryInputDemandePaiement {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    idTiersFacturation: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    idClient: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    dateNaissanceClient: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    numFactureTiers: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    dateFacture: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    dateDebutEmploi: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    dateFinEmploi: string;
    /**
     * 
     * @type {number}
     * @memberof DryInputDemandePaiement
     */
    mntAcompte?: number;
    /**
     * 
     * @type {string}
     * @memberof DryInputDemandePaiement
     */
    dateVersementAcompte?: string;
    /**
     * 
     * @type {number}
     * @memberof DryInputDemandePaiement
     */
    mntFactureTTC: number;
    /**
     * 
     * @type {number}
     * @memberof DryInputDemandePaiement
     */
    mntFactureHT: number;
    /**
     * 
     * @type {Array<DryInputPrestation>}
     * @memberof DryInputDemandePaiement
     */
    inputPrestations: Array<DryInputPrestation>;
}


  
/**
 * 
 * @export
 * @interface DryInputPrestation
 */
export interface DryInputPrestation {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    codeActivite?: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    codeNature: string;
    /**
     * 
     * @type {number}
     * @memberof DryInputPrestation
     */
    quantite: number;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    unite: string;
    /**
     * 
     * @type {number}
     * @memberof DryInputPrestation
     */
    mntUnitaireTTC: number;
    /**
     * 
     * @type {number}
     * @memberof DryInputPrestation
     */
    mntPrestationTTC: number;
    /**
     * 
     * @type {number}
     * @memberof DryInputPrestation
     */
    mntPrestationHT: number;
    /**
     * 
     * @type {number}
     * @memberof DryInputPrestation
     */
    mntPrestationTVA: number;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    dateDebutEmploi?: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    dateFinEmploi?: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    complement1?: string;
    /**
     * 
     * @type {string}
     * @memberof DryInputPrestation
     */
    complement2: string;
}


  
/**
 * 
 * @export
 * @interface DryLearnerTrait
 */
export interface DryLearnerTrait {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLearnerTrait
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLearnerTrait
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryLearnerTrait
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryLearnerTrait
     */
    name?: string;
    /**
     * 
     * @type {Array<DryTeacherAbility>}
     * @memberof DryLearnerTrait
     */
    TeacherAbilities?: Array<DryTeacherAbility>;
    /**
     * 
     * @type {Array<DryMember>}
     * @memberof DryLearnerTrait
     */
    Learners?: Array<DryMember>;
}


  
/**
 * 
 * @export
 * @interface DryLegalInvoice
 */
export interface DryLegalInvoice {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLegalInvoice
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLegalInvoice
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoice
     */
    type?: DryLegalInvoiceTypeEnum;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryLegalInvoice
     */
    Family?: DryFamily;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoice
     */
    month?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryLegalInvoice
     */
    isForgotten?: boolean;
    /**
     * 
     * @type {Array<DryPayslipGroup>}
     * @memberof DryLegalInvoice
     */
    PayslipGroups?: Array<DryPayslipGroup>;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoice
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    referenceNb?: number;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoice
     */
    invoiceCreatedOn?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryLegalInvoice
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {Array<DryLegalInvoiceComptaInner>}
     * @memberof DryLegalInvoice
     */
    compta?: Array<DryLegalInvoiceComptaInner>;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totTVA?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totMarge?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totUrssaf?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totClient?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    aiciCreditsRealAmountSumFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totClientAICIAjustedFormula?: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoice
     */
    totRem?: number;
    /**
     * 
     * @type {Array<DryAICIPaymentRequest>}
     * @memberof DryLegalInvoice
     */
    AICIPaymentRequests?: Array<DryAICIPaymentRequest>;
    /**
     * 
     * @type {DryAICIPaymentRequest}
     * @memberof DryLegalInvoice
     */
    CurrentAICIPaymentRequest?: DryAICIPaymentRequest | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryLegalInvoice
     */
    isDeclaredAICI?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryLegalInvoice
     */
    isManualAICI?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoice
     */
    cancelComment?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryLegalInvoiceTypeEnumEntries = {
    'INITIAL': 'INITIAL',
    'AVOIR': 'AVOIR',
    'A&R': 'A&R'
} as const
export type DryLegalInvoiceTypeEnum = typeof DryLegalInvoiceTypeEnumEntries[keyof typeof DryLegalInvoiceTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface DryLegalInvoiceComptaInner
 */
export interface DryLegalInvoiceComptaInner {
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoiceComptaInner
     */
    tiers: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoiceComptaInner
     */
    credit: number;
    /**
     * 
     * @type {number}
     * @memberof DryLegalInvoiceComptaInner
     */
    debit: number;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoiceComptaInner
     */
    libelle: string;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoiceComptaInner
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoiceComptaInner
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoiceComptaInner
     */
    opDate: string;
    /**
     * 
     * @type {string}
     * @memberof DryLegalInvoiceComptaInner
     */
    journal: string;
}


  
/**
 * 
 * @export
 * @interface DryLesson
 */
export interface DryLesson {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryLesson
     */
    id?: number;
    /**
     * 
     * @type {DryLessonStatus}
     * @memberof DryLesson
     */
    status?: DryLessonStatus;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryLesson
     */
    Match?: DryMatch;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    startedAtOfficial?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    startedAtUnofficial?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryLesson
     */
    durationMin?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    startedAtPlanned?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryLesson
     */
    durationMinPlanned?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryLesson
     */
    createdByAgent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {Array<DryPayslip>}
     * @memberof DryLesson
     */
    PayslipVersions?: Array<DryPayslip>;
    /**
     * 
     * @type {DryLesson}
     * @memberof DryLesson
     */
    PreviousProposition?: DryLesson | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryLesson
     */
    isFictive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    proposedAt?: Date | null;
    /**
     * 
     * @type {DryLessonProposedBy}
     * @memberof DryLesson
     */
    proposedBy?: DryLessonProposedBy | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    abortedAt?: Date | null;
    /**
     * 
     * @type {DryLessonAbortReason}
     * @memberof DryLesson
     */
    abortReason?: DryLessonAbortReason | null;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    abortComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    bookedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    teacherAcceptedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    learnerAcceptedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    learnerForcedBy?: DryLessonLearnerForcedByEnum;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    teacherForcedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    learnerForcedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {DryLessonProposedBy}
     * @memberof DryLesson
     */
    cancelledBy?: DryLessonProposedBy | null;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    cancelComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryLesson
     */
    submittedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    monthOfficialFormula?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryLesson
     */
    monthPlannedFormula?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryLesson
     */
    teacherDismissedAbortedCancelled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryLesson
     */
    learnerDismissedAbortedCancelled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export const DryLessonLearnerForcedByEnumEntries = {
    'teacher': 'teacher',
    'admin': 'admin',
    'null': 'null'
} as const
export type DryLessonLearnerForcedByEnum = typeof DryLessonLearnerForcedByEnumEntries[keyof typeof DryLessonLearnerForcedByEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryLessonAbortReasonEntries = {
    'refused_by_teacher': 'refused_by_teacher',
    'refused_by_learner': 'refused_by_learner',
    'aborted_by_teacher': 'aborted_by_teacher',
    'aborted_by_learner': 'aborted_by_learner',
    'aborted_by_admin': 'aborted_by_admin',
    'counterproposed_by_teacher': 'counterproposed_by_teacher',
    'counterproposed_by_learner': 'counterproposed_by_learner',
    'reproposed_by_teacher': 'reproposed_by_teacher',
    'reproposed_by_learner': 'reproposed_by_learner',
    'reproposed_by_admin': 'reproposed_by_admin',
    'too_late_teacher': 'too_late_teacher',
    'too_late_learner': 'too_late_learner',
    'too_late_both': 'too_late_both'
} as const
export type DryLessonAbortReason = typeof DryLessonAbortReasonEntries[keyof typeof DryLessonAbortReasonEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryLessonProposedByEntries = {
    'teacher': 'teacher',
    'learner': 'learner',
    'admin': 'admin'
} as const
export type DryLessonProposedBy = typeof DryLessonProposedByEntries[keyof typeof DryLessonProposedByEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryLessonStatusEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'ABORTED': 'ABORTED',
    'BOOKED': 'BOOKED',
    'SUBMITTED': 'SUBMITTED',
    'PAYSLIPPED': 'PAYSLIPPED',
    'FORGOTTEN': 'FORGOTTEN'
} as const
export type DryLessonStatus = typeof DryLessonStatusEntries[keyof typeof DryLessonStatusEntries]

  
/**
 * 
 * @export
 * @interface DryLettrage
 */
export interface DryLettrage {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLettrage
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLettrage
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<any>}
     * @memberof DryLettrage
     */
    undefined?: Array<any>;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryLettrage
     */
    Debit1?: DryDebit;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryLettrage
     */
    Debit2?: DryDebit;
    /**
     * 
     * @type {number}
     * @memberof DryLettrage
     */
    amount?: number;
}


  
/**
 * 
 * @export
 * @interface DryLevel
 */
export interface DryLevel {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLevel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLevel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryLevel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryLevel
     */
    nameShort?: string;
    /**
     * 
     * @type {string}
     * @memberof DryLevel
     */
    nameLong?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryLevel
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryLevel
     */
    orderNb?: number | null;
    /**
     * 
     * @type {DryLevelGroup}
     * @memberof DryLevel
     */
    LevelGroup?: DryLevelGroup | null;
    /**
     * 
     * @type {DryConciseLevel}
     * @memberof DryLevel
     */
    ConciseLevel?: DryConciseLevel | null;
    /**
     * 
     * @type {Array<DryTeachingLevel>}
     * @memberof DryLevel
     */
    TeachingLevels?: Array<DryTeachingLevel>;
    /**
     * 
     * @type {number}
     * @memberof DryLevel
     */
    basePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryLevel
     */
    baseRem?: number | null;
}


  
/**
 * 
 * @export
 * @interface DryLevelGroup
 */
export interface DryLevelGroup {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryLevelGroup
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryLevelGroup
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryLevelGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryLevelGroup
     */
    name?: string;
    /**
     * 
     * @type {Array<DryLevel>}
     * @memberof DryLevelGroup
     */
    Levels?: Array<DryLevel>;
    /**
     * 
     * @type {Array<DryConciseLevel>}
     * @memberof DryLevelGroup
     */
    ConciseLevels?: Array<DryConciseLevel>;
    /**
     * 
     * @type {number}
     * @memberof DryLevelGroup
     */
    orderNb?: number | null;
}


  
/**
 * 
 * @export
 * @interface DryMainCity
 */
export interface DryMainCity {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryMainCity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMainCity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMainCity
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryMainCity
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMainCity
     */
    name?: string;
    /**
     * 
     * @type {Array<DryTeacher>}
     * @memberof DryMainCity
     */
    Teachers?: Array<DryTeacher>;
    /**
     * 
     * @type {Array<DryFamily>}
     * @memberof DryMainCity
     */
    Families?: Array<DryFamily>;
    /**
     * 
     * @type {boolean}
     * @memberof DryMainCity
     */
    isActiveTeachers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMainCity
     */
    isActiveProspects?: boolean;
}


  
/**
 * 
 * @export
 * @interface DryMatch
 */
export interface DryMatch {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    legalSolde?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    pendingCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    draftCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    lessonsDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    payslippedLessonsAndBonusDuration?: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    latestPayslipMonth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    latestReleveMonth?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    naturalSolde?: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    draftSolde?: number;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    lastLessonAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    firstLessonAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    minMonth?: string;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    maxMonth?: string;
    /**
     * 
     * @type {DryMatchSelectedTeacherProposition}
     * @memberof DryMatch
     */
    selectedTeacherProposition?: DryMatchSelectedTeacherProposition;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatch
     */
    hasPotentialTransferTarget?: boolean;
    /**
     * 
     * @type {Array<DryMatchPayslipsHistoryInner>}
     * @memberof DryMatch
     */
    payslipsHistory?: Array<DryMatchPayslipsHistoryInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryMatch
     */
    lockedMonths?: Array<string>;
    /**
     * 
     * @type {Array<DryMatchSoldeReleveHistoryInner>}
     * @memberof DryMatch
     */
    soldeReleveHistory?: Array<DryMatchSoldeReleveHistoryInner>;
    /**
     * 
     * @type {Array<DryMatchLessonsHistoryInner>}
     * @memberof DryMatch
     */
    lessonsHistory?: Array<DryMatchLessonsHistoryInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryMatch
     */
    allMonths?: Array<string>;
    /**
     * 
     * @type {DryMatchStatus}
     * @memberof DryMatch
     */
    status?: DryMatchStatus;
    /**
     * 
     * @type {Array<DryMatchStatusHistoryInner>}
     * @memberof DryMatch
     */
    statusHistory?: Array<DryMatchStatusHistoryInner>;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    statusExpireOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    planningLastMonth?: string | null;
    /**
     * 
     * @type {DryProgram}
     * @memberof DryMatch
     */
    Program?: DryProgram;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryMatch
     */
    Family?: DryFamily;
    /**
     * 
     * @type {Array<DryCreationBlock>}
     * @memberof DryMatch
     */
    CreationBlocks?: Array<DryCreationBlock>;
    /**
     * 
     * @type {Array<DryTransferBlock>}
     * @memberof DryMatch
     */
    TransferBlocksAsTarget?: Array<DryTransferBlock>;
    /**
     * 
     * @type {Array<DryTransferBlock>}
     * @memberof DryMatch
     */
    TransferBlocksAsSource?: Array<DryTransferBlock>;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryMatch
     */
    CurrentCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryMatch
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {Array<DryLesson>}
     * @memberof DryMatch
     */
    Lessons?: Array<DryLesson>;
    /**
     * 
     * @type {DryLevel}
     * @memberof DryMatch
     */
    Level?: DryLevel | null;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    levelManual?: string | null;
    /**
     * 
     * @type {DryConciseLevel}
     * @memberof DryMatch
     */
    ConciseLevel?: DryConciseLevel;
    /**
     * 
     * @type {string}
     * @memberof DryMatch
     */
    label?: string;
    /**
     * 
     * @type {Array<DryTeacherProposition>}
     * @memberof DryMatch
     */
    TeacherPropositions?: Array<DryTeacherProposition>;
    /**
     * 
     * @type {DryCustomerProposition}
     * @memberof DryMatch
     */
    ActivationCustomerProposition?: DryCustomerProposition | null;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    basePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    netH?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    pedagoH?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    transpH?: number | null;
    /**
     * 
     * @type {Array<DryMonthlyMatchSurvey>}
     * @memberof DryMatch
     */
    MonthlyMatchSurveys?: Array<DryMonthlyMatchSurvey>;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    creditRestraintMin?: number;
    /**
     * 
     * @type {Array<DryPayslipGroup>}
     * @memberof DryMatch
     */
    PayslipGroups?: Array<DryPayslipGroup>;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    teacherActivatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    customerActivatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryMatch
     */
    matchActivatedAt?: Date | null;
    /**
     * 
     * @type {DryRichComment}
     * @memberof DryMatch
     */
    finishedWithCreditsComment?: DryRichComment;
    /**
     * 
     * @type {DryRichComment}
     * @memberof DryMatch
     */
    negativeSoldeComment?: DryRichComment;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatch
     */
    teacherDismissedAbandonned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatch
     */
    learnerDismissedAbandonned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatch
     */
    shouldFillPlanning?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryMatch
     */
    baseRem?: number;
    /**
     * 
     * @type {any}
     * @memberof DryMatch
     */
    monthInfos?: any;
}




  
/**
 * 
 * @export
 * @interface DryMatchLessonsHistoryInner
 */
export interface DryMatchLessonsHistoryInner {
    /**
     * 
     * @type {number}
     * @memberof DryMatchLessonsHistoryInner
     */
    storedDurationMin: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchLessonsHistoryInner
     */
    draftDurationMin: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatchLessonsHistoryInner
     */
    month: string;
}


  
/**
 * 
 * @export
 * @interface DryMatchPayslipsHistoryInner
 */
export interface DryMatchPayslipsHistoryInner {
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    bonusMin: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    treatedLessonDurationMin: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    oldRestraintMin: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    newRestraintMin: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatchPayslipsHistoryInner
     */
    isInvoiced: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchPayslipsHistoryInner
     */
    sentAt: Date;
    /**
     * 
     * @type {string}
     * @memberof DryMatchPayslipsHistoryInner
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    nbH: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatchPayslipsHistoryInner
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    groupId: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchPayslipsHistoryInner
     */
    payslipId: number;
}


  
/**
 * 
 * @export
 * @interface DryMatchSelectedTeacherProposition
 */
export interface DryMatchSelectedTeacherProposition {
    /**
     * 
     * @type {Array<DryMatchSelectedTeacherPropositionRemindersInner>}
     * @memberof DryMatchSelectedTeacherProposition
     */
    reminders: Array<DryMatchSelectedTeacherPropositionRemindersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof DryMatchSelectedTeacherProposition
     */
    skipYs: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    docId: number;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    forcedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    acceptedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    sentAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    firstLessonPlannedDurationMin: number;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    firstLessonPlannedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof DryMatchSelectedTeacherProposition
     */
    teacherInstructions: string;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    ysProcId: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    transpH: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    pedagoH: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    netH: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatchSelectedTeacherProposition
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof DryMatchSelectedTeacherProposition
     */
    propositionOn: string;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherProposition
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSelectedTeacherProposition
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface DryMatchSelectedTeacherPropositionRemindersInner
 */
export interface DryMatchSelectedTeacherPropositionRemindersInner {
    /**
     * 
     * @type {string}
     * @memberof DryMatchSelectedTeacherPropositionRemindersInner
     */
    sentByEmail: string;
    /**
     * 
     * @type {Date}
     * @memberof DryMatchSelectedTeacherPropositionRemindersInner
     */
    sentAt: Date;
}


  
/**
 * 
 * @export
 * @interface DryMatchSoldeReleveHistoryInner
 */
export interface DryMatchSoldeReleveHistoryInner {
    /**
     * 
     * @type {number}
     * @memberof DryMatchSoldeReleveHistoryInner
     */
    oldCreditCount: number;
    /**
     * 
     * @type {number}
     * @memberof DryMatchSoldeReleveHistoryInner
     */
    newCreditCount: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatchSoldeReleveHistoryInner
     */
    month: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryMatchStatusEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'FINISHED': 'FINISHED',
    'ON_HOLD': 'ON_HOLD',
    'ABANDONNED': 'ABANDONNED'
} as const
export type DryMatchStatus = typeof DryMatchStatusEntries[keyof typeof DryMatchStatusEntries]

  
/**
 * 
 * @export
 * @interface DryMatchStatusHistoryInner
 */
export interface DryMatchStatusHistoryInner {
    /**
     * 
     * @type {string}
     * @memberof DryMatchStatusHistoryInner
     */
    setOn: string;
    /**
     * 
     * @type {number}
     * @memberof DryMatchStatusHistoryInner
     */
    setByAgentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DryMatchStatusHistoryInner
     */
    comment?: string;
    /**
     * 
     * @type {DryMatchStatus}
     * @memberof DryMatchStatusHistoryInner
     */
    status: DryMatchStatus;
}




  
/**
 * 
 * @export
 * @interface DryMember
 */
export interface DryMember {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryMember
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMember
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMember
     */
    id?: number;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryMember
     */
    Person?: DryPerson;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    gender?: DryMemberGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryMember
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    role?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    privateComment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryMember
     */
    isLearner?: boolean | null;
    /**
     * 
     * @type {Array<DryProgram>}
     * @memberof DryMember
     */
    Programs?: Array<DryProgram>;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthCityCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthDpt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    birthCountryCode?: string | null;
    /**
     * 
     * @type {Array<DryLearnerTrait>}
     * @memberof DryMember
     */
    LearnerTraits?: Array<DryLearnerTrait>;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryMember
     */
    Family?: DryFamily;
    /**
     * 
     * @type {Array<DryAddress>}
     * @memberof DryMember
     */
    LessonAddresses?: Array<DryAddress>;
    /**
     * 
     * @type {Array<DryPhoneNumber>}
     * @memberof DryMember
     */
    PhoneNumbers?: Array<DryPhoneNumber>;
    /**
     * 
     * @type {DryPhoneNumber}
     * @memberof DryMember
     */
    MainPhoneNumber?: DryPhoneNumber | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    scolarityClassLabel?: string | null;
    /**
     * 
     * @type {DryConciseLevel}
     * @memberof DryMember
     */
    scolarityConciseLevel?: DryConciseLevel | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    scolaritySchool?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    scolarityExpireOn?: string | null;
    /**
     * 
     * @type {Array<DryYearDispo>}
     * @memberof DryMember
     */
    YearDispos?: Array<DryYearDispo>;
    /**
     * 
     * @type {Array<DryWeekDispo>}
     * @memberof DryMember
     */
    WeekDispos?: Array<DryWeekDispo>;
    /**
     * 
     * @type {string}
     * @memberof DryMember
     */
    disposComment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryMember
     */
    accessTiersId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryMember
     */
    ringoverId?: number | null;
    /**
     * 
     * @type {Array<DryMemberFamilyPhoneNumbersInner>}
     * @memberof DryMember
     */
    familyPhoneNumbers?: Array<DryMemberFamilyPhoneNumbersInner>;
}

/**
    * @export
    * @enum {string}
    */
export const DryMemberGenderEnumEntries = {
    'M': 'M',
    'F': 'F',
    'null': 'null'
} as const
export type DryMemberGenderEnum = typeof DryMemberGenderEnumEntries[keyof typeof DryMemberGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface DryMemberFamilyPhoneNumbersInner
 */
export interface DryMemberFamilyPhoneNumbersInner {
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    formatedNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    ownerMemberRole: string;
    /**
     * 
     * @type {number}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    ownerId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    isOwner: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    ownerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    ownerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof DryMemberFamilyPhoneNumbersInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface DryMobility
 */
export interface DryMobility {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryMobility
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMobility
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMobility
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryMobility
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMobility
     */
    name?: string;
    /**
     * 
     * @type {Array<DryTeacher>}
     * @memberof DryMobility
     */
    Teachers?: Array<DryTeacher>;
}


  
/**
 * 
 * @export
 * @interface DryModificationBlock
 */
export interface DryModificationBlock {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryModificationBlock
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryModificationBlock
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlock
     */
    id?: number;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryModificationBlock
     */
    CreditInvoice?: DryCreditInvoice | null;
    /**
     * 
     * @type {string}
     * @memberof DryModificationBlock
     */
    type?: DryModificationBlockTypeEnum;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof DryModificationBlock
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlock
     */
    blockPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlock
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryModificationBlock
     */
    aiciMode?: DryAICIMode | null;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryModificationBlock
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryCreationBlock}
     * @memberof DryModificationBlock
     */
    SourceCreationBlock?: DryCreationBlock;
    /**
     * 
     * @type {Array<DryModificationBlockModificationsInner>}
     * @memberof DryModificationBlock
     */
    Modifications?: Array<DryModificationBlockModificationsInner>;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryModificationBlock
     */
    CancelledCredits?: Array<DryCredit>;
}

/**
    * @export
    * @enum {string}
    */
export const DryModificationBlockTypeEnumEntries = {
    'modification': 'modification'
} as const
export type DryModificationBlockTypeEnum = typeof DryModificationBlockTypeEnumEntries[keyof typeof DryModificationBlockTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryModificationBlockLineTypeEntries = {
    'credit-mod': 'credit-mod',
    'custom-std': 'custom-std'
} as const
export type DryModificationBlockLineType = typeof DryModificationBlockLineTypeEntries[keyof typeof DryModificationBlockLineTypeEntries]

  
/**
 * 
 * @export
 * @interface DryModificationBlockModificationsInner
 */
export interface DryModificationBlockModificationsInner {
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlockModificationsInner
     */
    newRealPrice: number;
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlockModificationsInner
     */
    oldRealPrice: number;
    /**
     * 
     * @type {string}
     * @memberof DryModificationBlockModificationsInner
     */
    modificationType: DryModificationBlockModificationsInnerModificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DryModificationBlockModificationsInner
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof DryModificationBlockModificationsInner
     */
    status: DryModificationBlockModificationsInnerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DryModificationBlockModificationsInner
     */
    creditId: number;
}

/**
    * @export
    * @enum {string}
    */
export const DryModificationBlockModificationsInnerModificationTypeEnumEntries = {
    'cancellation': 'cancellation',
    'modification': 'modification',
    'none': 'none',
    'reactivation': 'reactivation'
} as const
export type DryModificationBlockModificationsInnerModificationTypeEnum = typeof DryModificationBlockModificationsInnerModificationTypeEnumEntries[keyof typeof DryModificationBlockModificationsInnerModificationTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const DryModificationBlockModificationsInnerStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ABORTED': 'ABORTED',
    'AVAILABLE': 'AVAILABLE',
    'USED': 'USED',
    'CANCELLED': 'CANCELLED'
} as const
export type DryModificationBlockModificationsInnerStatusEnum = typeof DryModificationBlockModificationsInnerStatusEnumEntries[keyof typeof DryModificationBlockModificationsInnerStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface DryMonthlyMatchSurvey
 */
export interface DryMonthlyMatchSurvey {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryMonthlyMatchSurvey
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryMonthlyMatchSurvey
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryMonthlyMatchSurvey
     */
    id?: number;
    /**
     * 
     * @type {DryMonthlyMatchSurveyStatus}
     * @memberof DryMonthlyMatchSurvey
     */
    status?: DryMonthlyMatchSurveyStatus;
    /**
     * 
     * @type {Date}
     * @memberof DryMonthlyMatchSurvey
     */
    submittedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurvey
     */
    month?: string;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryMonthlyMatchSurvey
     */
    Match?: DryMatch;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfig}
     * @memberof DryMonthlyMatchSurvey
     */
    questions?: DryMonthlyMatchSurveyConfig;
    /**
     * 
     * @type {Date}
     * @memberof DryMonthlyMatchSurvey
     */
    reviewedAt?: Date | null;
    /**
     * 
     * @type {DryRichComment}
     * @memberof DryMonthlyMatchSurvey
     */
    reviewRichComment?: DryRichComment;
    /**
     * 
     * @type {number}
     * @memberof DryMonthlyMatchSurvey
     */
    lessonsDurationSum?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurvey
     */
    isComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurvey
     */
    isFinishing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryMonthlyMatchSurvey
     */
    incidentsCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryMonthlyMatchSurvey
     */
    incidentsList?: Array<string>;
}




  
/**
 * 
 * @export
 * @interface DryMonthlyMatchSurveyConfig
 */
export interface DryMonthlyMatchSurveyConfig extends Array<DryMonthlyMatchSurveyConfigBlock> {
}


  
/**
 * 
 * @export
 * @interface DryMonthlyMatchSurveyConfigBlock
 */
export interface DryMonthlyMatchSurveyConfigBlock {
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    visited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    helper?: string;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfigBlockFreeComment}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    freeComment?: DryMonthlyMatchSurveyConfigBlockFreeComment;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    answer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    _options?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    question?: string;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfigBlockIf}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    _if?: DryMonthlyMatchSurveyConfigBlockIf;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    skipped?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    skipWhenMatchFinishing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    skipWhenNoLessons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    type: DryMonthlyMatchSurveyConfigBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    header: string;
    /**
     * 
     * @type {number}
     * @memberof DryMonthlyMatchSurveyConfigBlock
     */
    rank: number;
}

/**
    * @export
    * @enum {string}
    */
export const DryMonthlyMatchSurveyConfigBlockTypeEnumEntries = {
    'text-long': 'text-long',
    'text-short': 'text-short',
    'radio': 'radio'
} as const
export type DryMonthlyMatchSurveyConfigBlockTypeEnum = typeof DryMonthlyMatchSurveyConfigBlockTypeEnumEntries[keyof typeof DryMonthlyMatchSurveyConfigBlockTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface DryMonthlyMatchSurveyConfigBlockFreeComment
 */
export interface DryMonthlyMatchSurveyConfigBlockFreeComment {
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockFreeComment
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockFreeComment
     */
    question: string;
}


  
/**
 * 
 * @export
 * @interface DryMonthlyMatchSurveyConfigBlockIf
 */
export interface DryMonthlyMatchSurveyConfigBlockIf {
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockIf
     */
    first: DryMonthlyMatchSurveyConfigBlockIfFirstEnum;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockIf
     */
    answer?: DryMonthlyMatchSurveyConfigBlockIfAnswerEnum;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockIf
     */
    _false: string;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockIf
     */
    _true: string;
    /**
     * 
     * @type {string}
     * @memberof DryMonthlyMatchSurveyConfigBlockIf
     */
    question: string;
}

/**
    * @export
    * @enum {string}
    */
export const DryMonthlyMatchSurveyConfigBlockIfFirstEnumEntries = {
    'true': 'true',
    'false': 'false'
} as const
export type DryMonthlyMatchSurveyConfigBlockIfFirstEnum = typeof DryMonthlyMatchSurveyConfigBlockIfFirstEnumEntries[keyof typeof DryMonthlyMatchSurveyConfigBlockIfFirstEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const DryMonthlyMatchSurveyConfigBlockIfAnswerEnumEntries = {
    'true': 'true',
    'false': 'false'
} as const
export type DryMonthlyMatchSurveyConfigBlockIfAnswerEnum = typeof DryMonthlyMatchSurveyConfigBlockIfAnswerEnumEntries[keyof typeof DryMonthlyMatchSurveyConfigBlockIfAnswerEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryMonthlyMatchSurveyStatusEntries = {
    'DRAFT': 'DRAFT',
    'SUBMITTED': 'SUBMITTED'
} as const
export type DryMonthlyMatchSurveyStatus = typeof DryMonthlyMatchSurveyStatusEntries[keyof typeof DryMonthlyMatchSurveyStatusEntries]

  
/**
 * 
 * @export
 * @interface DryPaySlipDetails
 */
export interface DryPaySlipDetails {
    /**
     * 
     * @type {DryPaySlipDetailsTaux}
     * @memberof DryPaySlipDetails
     */
    taux: DryPaySlipDetailsTaux;
    /**
     * 
     * @type {boolean}
     * @memberof DryPaySlipDetails
     */
    regimeLocal: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    tauxPAS: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryPaySlipDetails
     */
    hasExoneration: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    allegements: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    transportTot: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    transportH: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    pedagoTot: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    pedagoH: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    primeNet: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    brutTot: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    netTot: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    netH: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    nbH: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetails
     */
    heuresNormales: number;
}


  
/**
 * 
 * @export
 * @interface DryPaySlipDetailsTaux
 */
export interface DryPaySlipDetailsTaux {
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    reductionForfaitaire: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    csgDeductible: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    csgCrds: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    baseCsg: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    cds: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    fnal: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    cfp: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    chomageS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    chomageP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    allocFamP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    accidentP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    cegS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    cegP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    ircemS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    ircemP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    vieillesseDepS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    vieillesseDepP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    vieillessePlafS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    vieillessePlafP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    ircemPrevoyanceS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    ircemPrevoyanceP: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    maladieLocal: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    maladieS: number;
    /**
     * 
     * @type {number}
     * @memberof DryPaySlipDetailsTaux
     */
    maladieP: number;
}


  
/**
 * 
 * @export
 * @interface DryPayslip
 */
export interface DryPayslip {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPayslip
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPayslip
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    id?: number;
    /**
     * 
     * @type {DryPayslipGroup}
     * @memberof DryPayslip
     */
    Group?: DryPayslipGroup | null;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    version?: number;
    /**
     * 
     * @type {DryPayslip}
     * @memberof DryPayslip
     */
    Previous?: DryPayslip | null;
    /**
     * 
     * @type {Array<DryLesson>}
     * @memberof DryPayslip
     */
    Lessons?: Array<DryLesson>;
    /**
     * 
     * @type {Date}
     * @memberof DryPayslip
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryPayslip
     */
    paidOn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryPayslip
     */
    isForgotten?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    nbH?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    treatedLessonDurationMinQuery?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    netH?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    brut?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    cotisP?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    cotisS?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    cotisBoth?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    remTot?: number;
    /**
     * 
     * @type {DryPaySlipDetails}
     * @memberof DryPayslip
     */
    details?: DryPaySlipDetails;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    newRestraintMin?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    oldRestraintMin?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    treatedLessonDurationMin?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    bonusMin?: number;
    /**
     * 
     * @type {number}
     * @memberof DryPayslip
     */
    reportedDurationMin?: number;
}


  
/**
 * 
 * @export
 * @interface DryPayslipGroup
 */
export interface DryPayslipGroup {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPayslipGroup
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPayslipGroup
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPayslipGroup
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroup
     */
    referenceNb?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPayslipGroup
     */
    month?: string;
    /**
     * 
     * @type {DryPayslip}
     * @memberof DryPayslipGroup
     */
    CurrentPayslip?: DryPayslip | null;
    /**
     * 
     * @type {Array<DryPayslip>}
     * @memberof DryPayslipGroup
     */
    Versions?: Array<DryPayslip>;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryPayslipGroup
     */
    Match?: DryMatch;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryPayslipGroup
     */
    Debit?: DryDebit;
    /**
     * 
     * @type {DryAsapDebit}
     * @memberof DryPayslipGroup
     */
    AsapDebit?: DryAsapDebit;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryPayslipGroup
     */
    Credits?: Array<DryCredit>;
    /**
     * 
     * @type {DryLegalInvoice}
     * @memberof DryPayslipGroup
     */
    LegalInvoice?: DryLegalInvoice;
    /**
     * 
     * @type {DryPayslipGroupMandateLine}
     * @memberof DryPayslipGroup
     */
    mandateLine?: DryPayslipGroupMandateLine;
    /**
     * 
     * @type {DryPayslipGroupMarginLine}
     * @memberof DryPayslipGroup
     */
    marginLine?: DryPayslipGroupMarginLine;
    /**
     * 
     * @type {Array<DryAsapTeacherBlock>}
     * @memberof DryPayslipGroup
     */
    AsapTeacherBlocks?: Array<DryAsapTeacherBlock>;
    /**
     * 
     * @type {DryAsapTeacherBlock}
     * @memberof DryPayslipGroup
     */
    AsapTeacherBlockFinal?: DryAsapTeacherBlock | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryPayslipGroup
     */
    isAsapSelectedFormula?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryPayslipGroup
     */
    isAsapDeclaredFormula?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroup
     */
    aiciCreditsRealAmountSumFormula?: number;
}


  
/**
 * 
 * @export
 * @interface DryPayslipGroupMandateLine
 */
export interface DryPayslipGroupMandateLine {
    /**
     * 
     * @type {string}
     * @memberof DryPayslipGroupMandateLine
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroupMandateLine
     */
    ttc: number;
}


  
/**
 * 
 * @export
 * @interface DryPayslipGroupMarginLine
 */
export interface DryPayslipGroupMarginLine {
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroupMarginLine
     */
    tauxTVA: number;
    /**
     * 
     * @type {string}
     * @memberof DryPayslipGroupMarginLine
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof DryPayslipGroupMarginLine
     */
    ttc: number;
}


  
/**
 * 
 * @export
 * @interface DryPerson
 */
export interface DryPerson {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPerson
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPerson
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPerson
     */
    id?: number;
    /**
     * 
     * @type {DryTsoaPersonType}
     * @memberof DryPerson
     */
    type?: DryTsoaPersonType;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryPerson
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryPerson
     */
    Member?: DryMember | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryPerson
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {Array<DryPhoneCall>}
     * @memberof DryPerson
     */
    PhoneCalls?: Array<DryPhoneCall>;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof DryPerson
     */
    Docs?: Array<DryDoc>;
    /**
     * 
     * @type {Array<DryYsProc>}
     * @memberof DryPerson
     */
    YsProcs?: Array<DryYsProc>;
}




  
/**
 * 
 * @export
 * @interface DryPhoneCall
 */
export interface DryPhoneCall {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPhoneCall
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPhoneCall
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPhoneCall
     */
    id?: number;
    /**
     * 
     * @type {DryPhoneCallReponseType}
     * @memberof DryPhoneCall
     */
    reponseStatus?: DryPhoneCallReponseType | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    comment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryPhoneCall
     */
    commentedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryPhoneCall
     */
    durationSec?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof DryPhoneCall
     */
    cdrExtId?: number | null;
    /**
     * 
     * @type {DryPhoneCallOperator}
     * @memberof DryPhoneCall
     */
    operator?: DryPhoneCallOperator;
    /**
     * 
     * @type {DryRingoverLastState}
     * @memberof DryPhoneCall
     */
    lastState?: DryRingoverLastState | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    externalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    internalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    io?: DryPhoneCallIoEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DryPhoneCall
     */
    isCommented?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryPhoneCall
     */
    isMatched?: boolean;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryPhoneCall
     */
    Person?: DryPerson | null;
    /**
     * 
     * @type {DryTsoaPersonType}
     * @memberof DryPhoneCall
     */
    personType?: DryTsoaPersonType | null;
    /**
     * 
     * @type {Date}
     * @memberof DryPhoneCall
     */
    startedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DryPhoneCall
     */
    throughSwitchboard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    recordUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    voicemailUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryPhoneCall
     */
    userExtId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    scenarioExtId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneCall
     */
    scenarioName?: string | null;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryPhoneCall
     */
    TreatedBy?: DryAgent | null;
    /**
     * 
     * @type {DryPlannedCall}
     * @memberof DryPhoneCall
     */
    PlannedCall?: DryPlannedCall | null;
    /**
     * 
     * @type {DryPhoneNumber}
     * @memberof DryPhoneCall
     */
    PhoneNumber?: DryPhoneNumber | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryPhoneCallIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type DryPhoneCallIoEnum = typeof DryPhoneCallIoEnumEntries[keyof typeof DryPhoneCallIoEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryPhoneCallOperatorEntries = {
    'ringover': 'ringover',
    'keyyo': 'keyyo'
} as const
export type DryPhoneCallOperator = typeof DryPhoneCallOperatorEntries[keyof typeof DryPhoneCallOperatorEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DryPhoneCallReponseTypeEntries = {
    'FAILED': 'FAILED',
    'NODISPO': 'NODISPO',
    'RESPONDEDBAD': 'RESPONDEDBAD',
    'RESPONDEDWELL': 'RESPONDEDWELL'
} as const
export type DryPhoneCallReponseType = typeof DryPhoneCallReponseTypeEntries[keyof typeof DryPhoneCallReponseTypeEntries]

  
/**
 * 
 * @export
 * @interface DryPhoneNumber
 */
export interface DryPhoneNumber {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPhoneNumber
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPhoneNumber
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPhoneNumber
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneNumber
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneNumber
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof DryPhoneNumber
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryPhoneNumber
     */
    isMain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryPhoneNumber
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryPhoneNumber
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryPhoneNumber
     */
    Member?: DryMember | null;
    /**
     * 
     * @type {Array<DryPhoneCall>}
     * @memberof DryPhoneNumber
     */
    PhoneCalls?: Array<DryPhoneCall>;
    /**
     * 
     * @type {any}
     * @memberof DryPhoneNumber
     */
    formatedNumber?: any;
}


  
/**
 * 
 * @export
 * @interface DryPlannedCall
 */
export interface DryPlannedCall {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPlannedCall
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPlannedCall
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPlannedCall
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPlannedCall
     */
    io?: DryPlannedCallIoEnum;
    /**
     * 
     * @type {Date}
     * @memberof DryPlannedCall
     */
    plannedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPlannedCall
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryPlannedCall
     */
    doneAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryPlannedCall
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryPlannedCall
     */
    calendly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryPlannedCall
     */
    commentaire?: string | null;
    /**
     * 
     * @type {Ignored}
     * @memberof DryPlannedCall
     */
    calendlyObj?: Ignored | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryPlannedCall
     */
    Family?: DryFamily;
    /**
     * 
     * @type {DryPhoneCall}
     * @memberof DryPlannedCall
     */
    PhoneCall?: DryPhoneCall | null;
    /**
     * 
     * @type {string}
     * @memberof DryPlannedCall
     */
    calendarId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryPlannedCall
     */
    eventId?: string | null;
    /**
     * 
     * @type {DryPlannedCallStatus}
     * @memberof DryPlannedCall
     */
    status?: DryPlannedCallStatus;
}

/**
    * @export
    * @enum {string}
    */
export const DryPlannedCallIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type DryPlannedCallIoEnum = typeof DryPlannedCallIoEnumEntries[keyof typeof DryPlannedCallIoEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryPlannedCallStatusEntries = {
    'CANCELLED': 'CANCELLED',
    'PLANNED': 'PLANNED',
    'DONE': 'DONE'
} as const
export type DryPlannedCallStatus = typeof DryPlannedCallStatusEntries[keyof typeof DryPlannedCallStatusEntries]

  
/**
 * 
 * @export
 * @interface DryPontoPayment
 */
export interface DryPontoPayment {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryPontoPayment
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryPontoPayment
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryPontoPayment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryPontoPayment
     */
    type?: DryPontoPaymentTypeEnum;
    /**
     * 
     * @type {DryBankAccount}
     * @memberof DryPontoPayment
     */
    BankAccount?: DryBankAccount;
    /**
     * 
     * @type {Array<DryTeacherPayment>}
     * @memberof DryPontoPayment
     */
    TeacherPayments?: Array<DryTeacherPayment>;
    /**
     * 
     * @type {DryPontoPaymentStatus}
     * @memberof DryPontoPayment
     */
    status?: DryPontoPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof DryPontoPayment
     */
    paymentUUID?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryPontoPayment
     */
    lastSyncAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryPontoPayment
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof DryPontoPayment
     */
    requestedExecutionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryPontoPayment
     */
    signUri?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryPontoPaymentTypeEnumEntries = {
    'single': 'single',
    'bulk': 'bulk'
} as const
export type DryPontoPaymentTypeEnum = typeof DryPontoPaymentTypeEnumEntries[keyof typeof DryPontoPaymentTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryPontoPaymentStatusEntries = {
    'unsigned': 'unsigned',
    'accepted-customer-profile': 'accepted-customer-profile',
    'accepted-settlement-completed': 'accepted-settlement-completed',
    'accepted-settlement-in-process': 'accepted-settlement-in-process',
    'accepted-technical-validation': 'accepted-technical-validation',
    'accepted-with-change': 'accepted-with-change',
    'accepted-without-posting': 'accepted-without-posting',
    'accepted-funds-checked': 'accepted-funds-checked',
    'received': 'received',
    'pending': 'pending',
    'rejected': 'rejected',
    'waiting-for-signature': 'waiting-for-signature',
    'cancelled': 'cancelled',
    'partial-acceptance': 'partial-acceptance',
    'unknown': 'unknown'
} as const
export type DryPontoPaymentStatus = typeof DryPontoPaymentStatusEntries[keyof typeof DryPontoPaymentStatusEntries]

  
/**
 * 
 * @export
 * @interface DryProgram
 */
export interface DryProgram {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryProgram
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryProgram
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryProgram
     */
    id?: number;
    /**
     * 
     * @type {DryProgramStatus}
     * @memberof DryProgram
     */
    status?: DryProgramStatus;
    /**
     * 
     * @type {Array<DryProgramStatusHistoryInner>}
     * @memberof DryProgram
     */
    statusHistory?: Array<DryProgramStatusHistoryInner>;
    /**
     * 
     * @type {string}
     * @memberof DryProgram
     */
    statusExpireOn?: string | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryProgram
     */
    Learner?: DryMember;
    /**
     * 
     * @type {Array<DryMatch>}
     * @memberof DryProgram
     */
    Matches?: Array<DryMatch>;
    /**
     * 
     * @type {DryTeaching}
     * @memberof DryProgram
     */
    Teaching?: DryTeaching;
    /**
     * 
     * @type {string}
     * @memberof DryProgram
     */
    teachingLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryProgram
     */
    isInfoComplete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryProgram
     */
    wishedDurationMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryProgram
     */
    wishedFrequency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryProgram
     */
    wishedFormula?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryProgram
     */
    goals?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryProgram
     */
    defaultTeacherInstructions?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryProgram
     */
    initialMean?: number | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryProgramStatusEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'FINISHED': 'FINISHED'
} as const
export type DryProgramStatus = typeof DryProgramStatusEntries[keyof typeof DryProgramStatusEntries]

  
/**
 * 
 * @export
 * @interface DryProgramStatusHistoryInner
 */
export interface DryProgramStatusHistoryInner {
    /**
     * 
     * @type {string}
     * @memberof DryProgramStatusHistoryInner
     */
    setOn: string;
    /**
     * 
     * @type {number}
     * @memberof DryProgramStatusHistoryInner
     */
    setByAgentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DryProgramStatusHistoryInner
     */
    comment?: string;
    /**
     * 
     * @type {DryProgramStatus}
     * @memberof DryProgramStatusHistoryInner
     */
    status: DryProgramStatus;
}




  
/**
 * 
 * @export
 * @interface DryReleve
 */
export interface DryReleve {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryReleve
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryReleve
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryReleve
     */
    id?: number;
    /**
     * 
     * @type {DryReleve}
     * @memberof DryReleve
     */
    Previous?: DryReleve | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleve
     */
    isForgotten?: boolean;
    /**
     * 
     * @type {DryFamilyMonth}
     * @memberof DryReleve
     */
    Group?: DryFamilyMonth | null;
    /**
     * 
     * @type {number}
     * @memberof DryReleve
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof DryReleve
     */
    generatedOn?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryReleve
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleve
     */
    skipSending?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleve
     */
    isEmpty?: boolean;
    /**
     * 
     * @type {Array<DryReleveMatchesInner>}
     * @memberof DryReleve
     */
    matches?: Array<DryReleveMatchesInner>;
}


  
/**
 * 
 * @export
 * @interface DryReleveMatchesInner
 */
export interface DryReleveMatchesInner {
    /**
     * 
     * @type {Array<DryReleveMatchesInnerVariationsInner>}
     * @memberof DryReleveMatchesInner
     */
    variations: Array<DryReleveMatchesInnerVariationsInner>;
    /**
     * 
     * @type {number}
     * @memberof DryReleveMatchesInner
     */
    newCreditCount: number;
    /**
     * 
     * @type {number}
     * @memberof DryReleveMatchesInner
     */
    oldCreditCount: number;
    /**
     * 
     * @type {Array<DryReleveMatchesInnerLessonsInner>}
     * @memberof DryReleveMatchesInner
     */
    lessons: Array<DryReleveMatchesInnerLessonsInner>;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    monthlyComment: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleveMatchesInner
     */
    notAttributedYet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    learnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    learnerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    teacherLastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    teacherFirstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInner
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof DryReleveMatchesInner
     */
    matchId: number;
}


  
/**
 * 
 * @export
 * @interface DryReleveMatchesInnerLessonsInner
 */
export interface DryReleveMatchesInnerLessonsInner {
    /**
     * 
     * @type {number}
     * @memberof DryReleveMatchesInnerLessonsInner
     */
    durationMin: number;
    /**
     * 
     * @type {Date}
     * @memberof DryReleveMatchesInnerLessonsInner
     */
    startedAt: Date;
}


  
/**
 * 
 * @export
 * @interface DryReleveMatchesInnerVariationsInner
 */
export interface DryReleveMatchesInnerVariationsInner {
    /**
     * 
     * @type {DryReleveMatchesInnerVariationsInnerCancellationDetail}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    cancellationDetail?: DryReleveMatchesInnerVariationsInnerCancellationDetail;
    /**
     * 
     * @type {DryReleveMatchesInnerVariationsInnerTransferDetail}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    transferDetail?: DryReleveMatchesInnerVariationsInnerTransferDetail;
    /**
     * 
     * @type {DryReleveMatchesInnerVariationsInnerCreationDetail}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    creationDetail?: DryReleveMatchesInnerVariationsInnerCreationDetail;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    reason: string;
    /**
     * 
     * @type {number}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInner
     */
    type: DryReleveMatchesInnerVariationsInnerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const DryReleveMatchesInnerVariationsInnerTypeEnumEntries = {
    'creation': 'creation',
    'transfer': 'transfer',
    'cancellation': 'cancellation'
} as const
export type DryReleveMatchesInnerVariationsInnerTypeEnum = typeof DryReleveMatchesInnerVariationsInnerTypeEnumEntries[keyof typeof DryReleveMatchesInnerVariationsInnerTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface DryReleveMatchesInnerVariationsInnerCancellationDetail
 */
export interface DryReleveMatchesInnerVariationsInnerCancellationDetail {
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerCancellationDetail
     */
    invoiceCreatedOn: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerCancellationDetail
     */
    invoiceReference: string;
}


  
/**
 * 
 * @export
 * @interface DryReleveMatchesInnerVariationsInnerCreationDetail
 */
export interface DryReleveMatchesInnerVariationsInnerCreationDetail {
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerCreationDetail
     */
    invoiceCreatedOn: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerCreationDetail
     */
    invoiceReference: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerCreationDetail
     */
    blockReference: string;
}


  
/**
 * 
 * @export
 * @interface DryReleveMatchesInnerVariationsInnerTransferDetail
 */
export interface DryReleveMatchesInnerVariationsInnerTransferDetail {
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    invoiceCreatedOn: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    invoiceReference: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchNotAttributedYet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchLearnerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchLearnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchTeacherFirstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchTeacherLastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    otherMatchLabel: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryReleveMatchesInnerVariationsInnerTransferDetail
     */
    isTarget: boolean;
}


  
/**
 * 
 * @export
 * @interface DryRichComment
 */
export interface DryRichComment {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof DryRichComment
     */
    message: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryRichComment
     */
    writtenAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryRichComment
     */
    status: DryRichCommentStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof DryRichComment
     */
    snoozeUntil: Date | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryRichCommentStatusEnumEntries = {
    'done': 'done',
    'snoozed': 'snoozed',
    'todo': 'todo',
    'empty': 'empty'
} as const
export type DryRichCommentStatusEnum = typeof DryRichCommentStatusEnumEntries[keyof typeof DryRichCommentStatusEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryRingoverLastStateEntries = {
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
    'ANSWERED': 'ANSWERED',
    'MISSED': 'MISSED',
    'QUEUE_TIMEOUT': 'QUEUE_TIMEOUT',
    'BLIND_TRANSFERED': 'BLIND_TRANSFERED',
    'VOICEMAIL': 'VOICEMAIL',
    'PERMANENT_TRANSFERED': 'PERMANENT_TRANSFERED',
    'NOANSWER_TRANSFERED': 'NOANSWER_TRANSFERED',
    'FAX_RECEIVED': 'FAX_RECEIVED',
    'FAX_FAILED': 'FAX_FAILED',
    'FAX_OUT_SENT': 'FAX_OUT_SENT',
    'INCORRECT_PINCODE': 'INCORRECT_PINCODE',
    'FAX_OUT_NOT_SENT': 'FAX_OUT_NOT_SENT',
    'ANNOUNCE': 'ANNOUNCE'
} as const
export type DryRingoverLastState = typeof DryRingoverLastStateEntries[keyof typeof DryRingoverLastStateEntries]

  
/**
 * 
 * @export
 * @interface DryStandardInvoice
 */
export interface DryStandardInvoice {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    id?: number;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryStandardInvoice
     */
    aiciMode?: DryAICIMode | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryStandardInvoice
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {Array<DryStandardInvoiceInvoiceLinesInner>}
     * @memberof DryStandardInvoice
     */
    invoiceLines?: Array<DryStandardInvoiceInvoiceLinesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof DryStandardInvoice
     */
    isExpirationFees?: boolean;
    /**
     * 
     * @type {DryStandardInvoiceStatus}
     * @memberof DryStandardInvoice
     */
    status?: DryStandardInvoiceStatus;
    /**
     * 
     * @type {DryDoc}
     * @memberof DryStandardInvoice
     */
    DevisDoc?: DryDoc | null;
    /**
     * 
     * @type {DryDoc}
     * @memberof DryStandardInvoice
     */
    InvoiceDoc?: DryDoc | null;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    totalPriceTTCWhole?: number;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    totalPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    totTVA?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    devisReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    invoiceReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    devisReferenceNb?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    invoiceReferenceNb?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    devisCreatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    invoiceCreatedOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    devisExpirationDate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    devisSentAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    devisAbortedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    devisAnsweredAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryStandardInvoice
     */
    invoiceSentAt?: Date | null;
    /**
     * 
     * @type {Array<DryDebit>}
     * @memberof DryStandardInvoice
     */
    Echeances?: Array<DryDebit>;
    /**
     * 
     * @type {Array<DryCreditInvoiceDraftEcheancesInner>}
     * @memberof DryStandardInvoice
     */
    draftEcheances?: Array<DryCreditInvoiceDraftEcheancesInner>;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    customCustomerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    customCityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    customPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    customCity?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoice
     */
    remainingAmountFormula?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryStandardInvoice
     */
    noDoppleDebit?: boolean;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryStandardInvoice
     */
    CreditInvoice?: DryCreditInvoice | null;
    /**
     * 
     * @type {Array<DryAICIPaymentRequest>}
     * @memberof DryStandardInvoice
     */
    AICIPaymentRequests?: Array<DryAICIPaymentRequest>;
    /**
     * 
     * @type {DryAICIPaymentRequest}
     * @memberof DryStandardInvoice
     */
    CurrentAICIPaymentRequest?: DryAICIPaymentRequest | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryStandardInvoice
     */
    isDeclaredAICI?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryStandardInvoice
     */
    allowAltPaymentMethods?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoice
     */
    comment?: string | null;
}




  
/**
 * 
 * @export
 * @interface DryStandardInvoiceInvoiceLinesInner
 */
export interface DryStandardInvoiceInvoiceLinesInner {
    /**
     * 
     * @type {boolean}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    is7718?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    tauxTVA: number;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    unitCount: number;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    unitPriceTTCWhole: number;
    /**
     * 
     * @type {number}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    unitPriceTTC: number;
    /**
     * 
     * @type {string}
     * @memberof DryStandardInvoiceInvoiceLinesInner
     */
    description: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryStandardInvoiceStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'REFUSED': 'REFUSED',
    'ABORTED': 'ABORTED',
    'READY': 'READY',
    'INVOICE': 'INVOICE'
} as const
export type DryStandardInvoiceStatus = typeof DryStandardInvoiceStatusEntries[keyof typeof DryStandardInvoiceStatusEntries]

  
/**
 * 
 * @export
 * @interface DryStudyLevel
 */
export interface DryStudyLevel {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryStudyLevel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryStudyLevel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryStudyLevel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryStudyLevel
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof DryStudyLevel
     */
    novaLevel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryStudyLevel
     */
    orderNb?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryStudyLevel
     */
    isActive?: boolean;
}


  
/**
 * 
 * @export
 * @interface DryTeacher
 */
export interface DryTeacher {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    moneySoldeC?: number;
    /**
     * 
     * @type {Array<DryTeacherTeachingsLevelsGroupedIdsInner>}
     * @memberof DryTeacher
     */
    teachingsLevelsGroupedIds?: Array<DryTeacherTeachingsLevelsGroupedIdsInner> | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    maxYearDispoEndOn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    activeMatchesCount?: number | null;
    /**
     * 
     * @type {Array<DryTeacherTeachingsLevelsGroupedPopulatedInner>}
     * @memberof DryTeacher
     */
    teachingsLevelsGroupedPopulated?: Array<DryTeacherTeachingsLevelsGroupedPopulatedInner>;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    firstLessonAtFormula?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    lastLessonAtFormula?: Date;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    firstPayslipMonthFormula?: string;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    lastPayslipMonthFormula?: string;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryTeacher
     */
    Person?: DryPerson;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    gender?: DryTeacherGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    activatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    scolarityComplementManual?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    scolarityMainManual?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    scolarityFinished?: boolean;
    /**
     * 
     * @type {DryTeacherScolarityMain}
     * @memberof DryTeacher
     */
    ScolarityMain?: DryTeacherScolarityMain | null;
    /**
     * 
     * @type {DryTeacherScolarityComplement}
     * @memberof DryTeacher
     */
    ScolarityComplement?: DryTeacherScolarityComplement | null;
    /**
     * 
     * @type {DryStudyLevel}
     * @memberof DryTeacher
     */
    StudyLevel?: DryStudyLevel | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    birthCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    birthCountryCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    birthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    hasMessenger?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    iban?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    hasIBAN?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    numSecu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    privateComment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    shouldContactSync?: boolean;
    /**
     * 
     * @type {DryTeacherStatus}
     * @memberof DryTeacher
     */
    status?: DryTeacherStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    isTeacher?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    terminatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    wishedStudentsNb?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    wishVisio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    wixCode?: string | null;
    /**
     * 
     * @type {DryMainCity}
     * @memberof DryTeacher
     */
    MainCity?: DryMainCity | null;
    /**
     * 
     * @type {Array<DryMobility>}
     * @memberof DryTeacher
     */
    Mobilities?: Array<DryMobility>;
    /**
     * 
     * @type {DryAgent}
     * @memberof DryTeacher
     */
    ActiveManager?: DryAgent | null;
    /**
     * 
     * @type {DryCandidature}
     * @memberof DryTeacher
     */
    Candidature?: DryCandidature;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    dispoNextYear?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    dispoNextYearAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    dispoNextYearNowFormula?: boolean | null;
    /**
     * 
     * @type {Array<DryYearDispo>}
     * @memberof DryTeacher
     */
    YearDispos?: Array<DryYearDispo>;
    /**
     * 
     * @type {Array<DryWeekDispo>}
     * @memberof DryTeacher
     */
    WeekDispos?: Array<DryWeekDispo>;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    hasUnstableWeekDispos?: boolean;
    /**
     * 
     * @type {Array<DryTeachingLevel>}
     * @memberof DryTeacher
     */
    TeachingLevels?: Array<DryTeachingLevel>;
    /**
     * 
     * @type {Array<DryTeacherAbility>}
     * @memberof DryTeacher
     */
    TeacherAbilities?: Array<DryTeacherAbility>;
    /**
     * 
     * @type {Array<DryAddress>}
     * @memberof DryTeacher
     */
    Addresses?: Array<DryAddress>;
    /**
     * 
     * @type {DryAddress}
     * @memberof DryTeacher
     */
    MainAddress?: DryAddress | null;
    /**
     * 
     * @type {Array<DryPhoneNumber>}
     * @memberof DryTeacher
     */
    PhoneNumbers?: Array<DryPhoneNumber>;
    /**
     * 
     * @type {DryPhoneNumber}
     * @memberof DryTeacher
     */
    MainPhoneNumber?: DryPhoneNumber | null;
    /**
     * 
     * @type {Array<PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity>}
     * @memberof DryTeacher
     */
    $PhoneCalls?: Array<PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity> | null;
    /**
     * 
     * @type {DryTeacherTeachings}
     * @memberof DryTeacher
     */
    $Teachings?: DryTeacherTeachings | null;
    /**
     * 
     * @type {Array<PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity>}
     * @memberof DryTeacher
     */
    $YsProcs?: Array<PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity> | null;
    /**
     * 
     * @type {PickDryDocExcludeKeyofDryDocKeyofRootEntity}
     * @memberof DryTeacher
     */
    $Charte?: PickDryDocExcludeKeyofDryDocKeyofRootEntity | null;
    /**
     * 
     * @type {Array<DryMatch>}
     * @memberof DryTeacher
     */
    Matches?: Array<DryMatch>;
    /**
     * 
     * @type {Array<DryTeacherProposition>}
     * @memberof DryTeacher
     */
    Propositions?: Array<DryTeacherProposition>;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    tauxPAS?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    accessTiersId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    ringoverId?: number | null;
    /**
     * 
     * @type {Array<DryTeacherPayment>}
     * @memberof DryTeacher
     */
    TeacherPayments?: Array<DryTeacherPayment>;
    /**
     * 
     * @type {Array<DryDebit>}
     * @memberof DryTeacher
     */
    Debits?: Array<DryDebit>;
    /**
     * 
     * @type {Array<DryFCMToken>}
     * @memberof DryTeacher
     */
    FCMTokens?: Array<DryFCMToken>;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    newProcessEnabledAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    tutoViewedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacher
     */
    gocardlessCustomerUUID?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    firstAskedForAffiliationCodeAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    scolarityLastUpdatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    scolarityExpiredAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    weekDispoLastUpdatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    weekDispoExpiredAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    yearDispoLastUpdatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    yearDispoExpiredAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    miscInfosLastUpdatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    miscInfosExpiredAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    deviceDetectionDesktopCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacher
     */
    deviceDetectionMobileCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacher
     */
    hasNewletterEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacher
     */
    isDispoHolidaysAt?: Date | null;
}

/**
    * @export
    * @enum {string}
    */
export const DryTeacherGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type DryTeacherGenderEnum = typeof DryTeacherGenderEnumEntries[keyof typeof DryTeacherGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface DryTeacherAbility
 */
export interface DryTeacherAbility {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacherAbility
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherAbility
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherAbility
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherAbility
     */
    publicName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherAbility
     */
    privateName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherAbility
     */
    isPrivateOnly?: boolean;
    /**
     * 
     * @type {Array<DryLearnerTrait>}
     * @memberof DryTeacherAbility
     */
    LearnerTraits?: Array<DryLearnerTrait>;
    /**
     * 
     * @type {Array<DryTeacher>}
     * @memberof DryTeacherAbility
     */
    Teachers?: Array<DryTeacher>;
}


  
/**
 * 
 * @export
 * @interface DryTeacherPayment
 */
export interface DryTeacherPayment {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacherPayment
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherPayment
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherPayment
     */
    id?: number;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryTeacherPayment
     */
    Teacher?: DryTeacher;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherPayment
     */
    month?: string | null;
    /**
     * 
     * @type {DryPontoPayment}
     * @memberof DryTeacherPayment
     */
    PontoPayment?: DryPontoPayment | null;
    /**
     * 
     * @type {DryBankTransaction}
     * @memberof DryTeacherPayment
     */
    BankTransaction?: DryBankTransaction | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherPayment
     */
    withoutPonto?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherPayment
     */
    manualySentAt?: Date | null;
    /**
     * 
     * @type {DryTeacherPaymentStatus}
     * @memberof DryTeacherPayment
     */
    status?: DryTeacherPaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherPayment
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherPayment
     */
    restraint?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherPayment
     */
    description?: string | null;
    /**
     * 
     * @type {DryDebit}
     * @memberof DryTeacherPayment
     */
    Debit?: DryDebit | null;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryTeacherPaymentStatusEntries = {
    'DRAFT': 'DRAFT',
    'TO_SIGN': 'TO_SIGN',
    'PROCESSING': 'PROCESSING',
    'EXECUTED': 'EXECUTED',
    'RECONCILIED': 'RECONCILIED',
    'UNKNOWN': 'UNKNOWN',
    'CANCELLED': 'CANCELLED',
    'FAILED': 'FAILED'
} as const
export type DryTeacherPaymentStatus = typeof DryTeacherPaymentStatusEntries[keyof typeof DryTeacherPaymentStatusEntries]

  
/**
 * 
 * @export
 * @interface DryTeacherProposition
 */
export interface DryTeacherProposition {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherProposition
     */
    propositionOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherProposition
     */
    reference?: string | null;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryTeacherProposition
     */
    Teacher?: DryTeacher;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryTeacherProposition
     */
    Match?: DryMatch;
    /**
     * 
     * @type {DryTeacherPropositionStatus}
     * @memberof DryTeacherProposition
     */
    status?: DryTeacherPropositionStatus;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    sendingAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    acceptedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    forcedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    netH?: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    pedagoH?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    transpH?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherProposition
     */
    teacherInstructions?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherProposition
     */
    firstLessonPlannedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    firstLessonPlannedDurationMin?: number | null;
    /**
     * 
     * @type {DryDoc}
     * @memberof DryTeacherProposition
     */
    Doc?: DryDoc | null;
    /**
     * 
     * @type {DryYsProc}
     * @memberof DryTeacherProposition
     */
    YsProc?: DryYsProc;
    /**
     * 
     * @type {Array<DryCustomerProposition>}
     * @memberof DryTeacherProposition
     */
    CheckedFromCustomerPropositions?: Array<DryCustomerProposition>;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherProposition
     */
    skipYs?: boolean;
    /**
     * 
     * @type {Array<DryMatchSelectedTeacherPropositionRemindersInner>}
     * @memberof DryTeacherProposition
     */
    reminders?: Array<DryMatchSelectedTeacherPropositionRemindersInner>;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherProposition
     */
    dismissedAborted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherProposition
     */
    baseRem?: number;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DryTeacherPropositionStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'REFUSED': 'REFUSED',
    'ABORTED': 'ABORTED',
    'READY': 'READY',
    'SENDING': 'SENDING',
    'APPROVED': 'APPROVED',
    'EXPIRED': 'EXPIRED'
} as const
export type DryTeacherPropositionStatus = typeof DryTeacherPropositionStatusEntries[keyof typeof DryTeacherPropositionStatusEntries]

  
/**
 * 
 * @export
 * @interface DryTeacherScolarityComplement
 */
export interface DryTeacherScolarityComplement {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacherScolarityComplement
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherScolarityComplement
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherScolarityComplement
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherScolarityComplement
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherScolarityComplement
     */
    orderNb?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherScolarityComplement
     */
    isActive?: boolean;
    /**
     * 
     * @type {DryTeacherScolarityMain}
     * @memberof DryTeacherScolarityComplement
     */
    Main?: DryTeacherScolarityMain;
}


  
/**
 * 
 * @export
 * @interface DryTeacherScolarityMain
 */
export interface DryTeacherScolarityMain {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeacherScolarityMain
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeacherScolarityMain
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherScolarityMain
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherScolarityMain
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherScolarityMain
     */
    orderNb?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeacherScolarityMain
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<DryTeacherScolarityComplement>}
     * @memberof DryTeacherScolarityMain
     */
    Complements?: Array<DryTeacherScolarityComplement>;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryTeacherStatusEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PAUSED': 'PAUSED',
    'FINISHED': 'FINISHED'
} as const
export type DryTeacherStatus = typeof DryTeacherStatusEntries[keyof typeof DryTeacherStatusEntries]

  
/**
 * 
 * @export
 * @interface DryTeacherTeachings
 */
export interface DryTeacherTeachings {
}


  
/**
 * 
 * @export
 * @interface DryTeacherTeachingsLevelsGroupedIdsInner
 */
export interface DryTeacherTeachingsLevelsGroupedIdsInner {
    /**
     * 
     * @type {Array<number>}
     * @memberof DryTeacherTeachingsLevelsGroupedIdsInner
     */
    levelIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedIdsInner
     */
    teachingId: number;
}


  
/**
 * 
 * @export
 * @interface DryTeacherTeachingsLevelsGroupedPopulatedInner
 */
export interface DryTeacherTeachingsLevelsGroupedPopulatedInner {
    /**
     * 
     * @type {DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInner
     */
    MaxLevel: DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel;
    /**
     * 
     * @type {DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInner
     */
    MinLevel: DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInner
     */
    teachingName: string;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInner
     */
    teachingGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInner
     */
    teachingId: number;
}


  
/**
 * 
 * @export
 * @interface DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
 */
export interface DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel {
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    orderNb: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    nameShort: string;
    /**
     * 
     * @type {string}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    nameLong: string;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    conciseLevelId: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    levelGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof DryTeacherTeachingsLevelsGroupedPopulatedInnerMaxLevel
     */
    levelId: number;
}


  
/**
 * 
 * @export
 * @interface DryTeaching
 */
export interface DryTeaching {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeaching
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeaching
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeaching
     */
    id?: number;
    /**
     * 
     * @type {DryTeachingGroup}
     * @memberof DryTeaching
     */
    TeachingGroup?: DryTeachingGroup | null;
    /**
     * 
     * @type {Array<DryTeachingLevel>}
     * @memberof DryTeaching
     */
    TeachingLevels?: Array<DryTeachingLevel>;
    /**
     * 
     * @type {string}
     * @memberof DryTeaching
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DryTeaching
     */
    aiciCodeNature?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeaching
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DryTeaching
     */
    taux?: number | null;
}


  
/**
 * 
 * @export
 * @interface DryTeachingGroup
 */
export interface DryTeachingGroup {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeachingGroup
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeachingGroup
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTeachingGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeachingGroup
     */
    name?: string;
    /**
     * 
     * @type {Array<DryTeaching>}
     * @memberof DryTeachingGroup
     */
    Teachings?: Array<DryTeaching>;
}


  
/**
 * 
 * @export
 * @interface DryTeachingLevel
 */
export interface DryTeachingLevel {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTeachingLevel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTeachingLevel
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<any>}
     * @memberof DryTeachingLevel
     */
    undefined?: Array<any>;
    /**
     * 
     * @type {DryTeaching}
     * @memberof DryTeachingLevel
     */
    Teaching?: DryTeaching;
    /**
     * 
     * @type {DryLevel}
     * @memberof DryTeachingLevel
     */
    Level?: DryLevel;
    /**
     * 
     * @type {boolean}
     * @memberof DryTeachingLevel
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<DryTeacher>}
     * @memberof DryTeachingLevel
     */
    Teachers?: Array<DryTeacher>;
}


  
/**
 * 
 * @export
 * @interface DryTeachingWithLevelsKeys
 */
export interface DryTeachingWithLevelsKeys {
    [key: string]: any | any;

    /**
     * 
     * @type {number}
     * @memberof DryTeachingWithLevelsKeys
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DryTeachingWithLevelsKeys
     */
    Levels: Array<number>;
}


  
/**
 * 
 * @export
 * @interface DryTeachingWithLevelsPopulated
 */
export interface DryTeachingWithLevelsPopulated {
    [key: string]: any | any;

    /**
     * 
     * @type {number}
     * @memberof DryTeachingWithLevelsPopulated
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeachingWithLevelsPopulated
     */
    name: string;
    /**
     * 
     * @type {Array<DryTeachingWithLevelsPopulatedLevelsInner>}
     * @memberof DryTeachingWithLevelsPopulated
     */
    Levels: Array<DryTeachingWithLevelsPopulatedLevelsInner>;
}


  
/**
 * 
 * @export
 * @interface DryTeachingWithLevelsPopulatedLevelsInner
 */
export interface DryTeachingWithLevelsPopulatedLevelsInner {
    /**
     * 
     * @type {number}
     * @memberof DryTeachingWithLevelsPopulatedLevelsInner
     */
    orderNb?: number;
    /**
     * 
     * @type {string}
     * @memberof DryTeachingWithLevelsPopulatedLevelsInner
     */
    nameLong: string;
    /**
     * 
     * @type {string}
     * @memberof DryTeachingWithLevelsPopulatedLevelsInner
     */
    nameShort: string;
    /**
     * 
     * @type {number}
     * @memberof DryTeachingWithLevelsPopulatedLevelsInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface DryTransferBlock
 */
export interface DryTransferBlock {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryTransferBlock
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryTransferBlock
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    id?: number;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof DryTransferBlock
     */
    CreditInvoice?: DryCreditInvoice | null;
    /**
     * 
     * @type {string}
     * @memberof DryTransferBlock
     */
    type?: DryTransferBlockTypeEnum;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof DryTransferBlock
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    blockPriceTTC?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {DryAICIMode}
     * @memberof DryTransferBlock
     */
    aiciMode?: DryAICIMode | null;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof DryTransferBlock
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    transferCount?: number;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryTransferBlock
     */
    SourceMatch?: DryMatch;
    /**
     * 
     * @type {DryMatch}
     * @memberof DryTransferBlock
     */
    TargetMatch?: DryMatch;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    previousSourceSolde?: number;
    /**
     * 
     * @type {number}
     * @memberof DryTransferBlock
     */
    previousTargetSolde?: number;
}

/**
    * @export
    * @enum {string}
    */
export const DryTransferBlockTypeEnumEntries = {
    'transfer': 'transfer'
} as const
export type DryTransferBlockTypeEnum = typeof DryTransferBlockTypeEnumEntries[keyof typeof DryTransferBlockTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const DryTsoaPersonTypeEntries = {
    'teacher': 'teacher',
    'member': 'member',
    'family': 'family',
    'supplier': 'supplier'
} as const
export type DryTsoaPersonType = typeof DryTsoaPersonTypeEntries[keyof typeof DryTsoaPersonTypeEntries]

  
/**
 * 
 * @export
 * @interface DryUrssaf
 */
export interface DryUrssaf {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryUrssaf
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryUrssaf
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryUrssaf
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryUrssaf
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DryUrssaf
     */
    departements?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DryUrssaf
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof DryUrssaf
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryUrssaf
     */
    isReady?: boolean;
    /**
     * 
     * @type {Array<DryFamily>}
     * @memberof DryUrssaf
     */
    Families?: Array<DryFamily>;
    /**
     * 
     * @type {Array<DryAsapUrssafDeclaration>}
     * @memberof DryUrssaf
     */
    AsapUrssafDeclarations?: Array<DryAsapUrssafDeclaration>;
    /**
     * 
     * @type {string}
     * @memberof DryUrssaf
     */
    comment?: string | null;
}


  
/**
 * 
 * @export
 * @interface DryWeekDispo
 */
export interface DryWeekDispo {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryWeekDispo
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryWeekDispo
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryWeekDispo
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DryWeekDispo
     */
    dayId?: number;
    /**
     * 
     * @type {string}
     * @memberof DryWeekDispo
     */
    endTime?: string;
    /**
     * 
     * @type {string}
     * @memberof DryWeekDispo
     */
    startTime?: string;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryWeekDispo
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryWeekDispo
     */
    Member?: DryMember | null;
}


  
/**
 * 
 * @export
 * @interface DryYearDispo
 */
export interface DryYearDispo {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryYearDispo
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryYearDispo
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryYearDispo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DryYearDispo
     */
    comments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryYearDispo
     */
    startOn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DryYearDispo
     */
    endOn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DryYearDispo
     */
    isActive?: boolean;
    /**
     * 
     * @type {DryTeacher}
     * @memberof DryYearDispo
     */
    Teacher?: DryTeacher | null;
    /**
     * 
     * @type {DryMember}
     * @memberof DryYearDispo
     */
    Member?: DryMember | null;
}


  
/**
 * 
 * @export
 * @interface DryYsProc
 */
export interface DryYsProc {
    [key: string]: any | any;

    /**
     * 
     * @type {Date}
     * @memberof DryYsProc
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryYsProc
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DryYsProc
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof DryYsProc
     */
    finishedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DryYsProc
     */
    refusedComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DryYsProc
     */
    sentAt?: Date | null;
    /**
     * 
     * @type {DryysProcStatus}
     * @memberof DryYsProc
     */
    status?: DryysProcStatus;
    /**
     * 
     * @type {string}
     * @memberof DryYsProc
     */
    ysKey?: string;
    /**
     * 
     * @type {string}
     * @memberof DryYsProc
     */
    ysLink?: string;
    /**
     * 
     * @type {DryPerson}
     * @memberof DryYsProc
     */
    Person?: DryPerson | null;
    /**
     * 
     * @type {DryFamily}
     * @memberof DryYsProc
     */
    Family?: DryFamily | null;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof DryYsProc
     */
    Docs?: Array<DryDoc>;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof DryYsProc
     */
    CurrentDocs?: Array<DryDoc>;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const DrydocStatusEntries = {
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'FORCED': 'FORCED',
    'ABORTED': 'ABORTED',
    'TO_SIGN': 'TO_SIGN',
    'TO_SEND': 'TO_SEND',
    'SENT': 'SENT',
    'NONE': 'NONE'
} as const
export type DrydocStatus = typeof DrydocStatusEntries[keyof typeof DrydocStatusEntries]


/**
 * 
 * @export
 * @enum {string}
 */

export const DrydocTypeEntries = {
    'UNIQUE': 'UNIQUE',
    'CHARTE': 'CHARTE',
    'FACTURE_CREDITS': 'FACTURE_CREDITS',
    'DEVIS_CREDITS': 'DEVIS_CREDITS',
    'FACTURE_STANDARD': 'FACTURE_STANDARD',
    'CGS': 'CGS',
    'MANDAT_CLIENT': 'MANDAT_CLIENT',
    'MANDAT_PROF': 'MANDAT_PROF',
    'PROPOSITION_PROF': 'PROPOSITION_PROF',
    'MANDAT_SEPA': 'MANDAT_SEPA',
    'DEVIS_STANDARD': 'DEVIS_STANDARD'
} as const
export type DrydocType = typeof DrydocTypeEntries[keyof typeof DrydocTypeEntries]

  
/**
 * 
 * @export
 * @interface DryfamilyMonthHistoryItem
 */
export interface DryfamilyMonthHistoryItem {
    /**
     * 
     * @type {number}
     * @memberof DryfamilyMonthHistoryItem
     */
    groupId: number;
    /**
     * 
     * @type {number}
     * @memberof DryfamilyMonthHistoryItem
     */
    releveId: number;
    /**
     * 
     * @type {number}
     * @memberof DryfamilyMonthHistoryItem
     */
    invoiceId: number;
    /**
     * 
     * @type {boolean}
     * @memberof DryfamilyMonthHistoryItem
     */
    isDeclaredCI: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DryfamilyMonthHistoryItem
     */
    invoiceSentAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DryfamilyMonthHistoryItem
     */
    releveSentAt: Date;
    /**
     * 
     * @type {string}
     * @memberof DryfamilyMonthHistoryItem
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof DryfamilyMonthHistoryItem
     */
    month: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const DryysProcStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'FORCED': 'FORCED',
    'ABORTED': 'ABORTED'
} as const
export type DryysProcStatus = typeof DryysProcStatusEntries[keyof typeof DryysProcStatusEntries]

  
/**
 * 
 * @export
 * @interface EditPayslipServiceCreateLessonByAdminSVPL
 */
export interface EditPayslipServiceCreateLessonByAdminSVPL {
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceCreateLessonByAdminSVPL
     */
    matchId: number;
    /**
     * 
     * @type {Date}
     * @memberof EditPayslipServiceCreateLessonByAdminSVPL
     */
    startedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceCreateLessonByAdminSVPL
     */
    durationMin: number;
}


  
/**
 * 
 * @export
 * @interface EditPayslipServiceDeleteLessonByAdminSVPL
 */
export interface EditPayslipServiceDeleteLessonByAdminSVPL {
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceDeleteLessonByAdminSVPL
     */
    lessonId: number;
}


  
/**
 * 
 * @export
 * @interface EditPayslipServiceDraftLesson
 */
export interface EditPayslipServiceDraftLesson {
    /**
     * 
     * @type {Date}
     * @memberof EditPayslipServiceDraftLesson
     */
    startedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceDraftLesson
     */
    durationMin: number;
}


  
/**
 * 
 * @export
 * @interface EditPayslipServiceEditPayslipPayload
 */
export interface EditPayslipServiceEditPayslipPayload {
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceEditPayslipPayload
     */
    matchId: number;
    /**
     * 
     * @type {string}
     * @memberof EditPayslipServiceEditPayslipPayload
     */
    month: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditPayslipServiceEditPayslipPayload
     */
    deleteIds: Array<number>;
    /**
     * 
     * @type {Array<EditPayslipServiceUpdateLesson>}
     * @memberof EditPayslipServiceEditPayslipPayload
     */
    updateLesssons: Array<EditPayslipServiceUpdateLesson>;
    /**
     * 
     * @type {Array<EditPayslipServiceDraftLesson>}
     * @memberof EditPayslipServiceEditPayslipPayload
     */
    draftLessons: Array<EditPayslipServiceDraftLesson>;
}


  
/**
 * 
 * @export
 * @interface EditPayslipServiceUpdateLesson
 */
export interface EditPayslipServiceUpdateLesson {
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceUpdateLesson
     */
    lessonId: number;
    /**
     * 
     * @type {Date}
     * @memberof EditPayslipServiceUpdateLesson
     */
    startedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceUpdateLesson
     */
    durationMin: number;
}


  
/**
 * 
 * @export
 * @interface EditPayslipServiceUpdateLessonByAdminSVPL
 */
export interface EditPayslipServiceUpdateLessonByAdminSVPL {
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceUpdateLessonByAdminSVPL
     */
    lessonId: number;
    /**
     * 
     * @type {Date}
     * @memberof EditPayslipServiceUpdateLessonByAdminSVPL
     */
    startedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof EditPayslipServiceUpdateLessonByAdminSVPL
     */
    durationMin: number;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const EmailTemplateKeyEntries = {
    'STOREFRONT_PROSPECT_REGISTERED': 'STOREFRONT_PROSPECT_REGISTERED',
    'STOREFRONT_CANDIDATURE1_RECIEVED': 'STOREFRONT_CANDIDATURE1_RECIEVED',
    'STOREFRONT_FAST_TRACK_RECIEVED': 'STOREFRONT_FAST_TRACK_RECIEVED',
    'ACCEPT_CANDIDATURE1': 'ACCEPT_CANDIDATURE1',
    'ACCEPT_CANDIDATURE2': 'ACCEPT_CANDIDATURE2',
    'WIXCODE_REMINDER': 'WIXCODE_REMINDER',
    'REFUSE_CANDIDATURE1': 'REFUSE_CANDIDATURE1',
    'REFUSE_CANDIDATURE2': 'REFUSE_CANDIDATURE2',
    'CHARTE_REMINDER': 'CHARTE_REMINDER',
    'DELAY_CANDIDATURE1': 'DELAY_CANDIDATURE1',
    'FAST_TRACK_CANDIDATURE': 'FAST_TRACK_CANDIDATURE',
    'CUSTOMER_PRESENTATION': 'CUSTOMER_PRESENTATION',
    'CUSTOMER_PRESENTATION_RENEW': 'CUSTOMER_PRESENTATION_RENEW',
    'SEND_CREDIT_INVOICE': 'SEND_CREDIT_INVOICE',
    'SEND_TEACHER_PROPOSITION': 'SEND_TEACHER_PROPOSITION',
    'REMIND_TEACHER_PROPOSITION': 'REMIND_TEACHER_PROPOSITION',
    'REMIND_CUSTOMER_PROPOSITION': 'REMIND_CUSTOMER_PROPOSITION',
    'MONTLY_MATCH_SURVEY_REMINDER_INITIAL': 'MONTLY_MATCH_SURVEY_REMINDER_INITIAL',
    'MONTLY_MATCH_SURVEY_REMINDER_LAST_DAY': 'MONTLY_MATCH_SURVEY_REMINDER_LAST_DAY',
    'MONTLY_MATCH_SURVEY_LATE_REMINDER_1': 'MONTLY_MATCH_SURVEY_LATE_REMINDER_1',
    'SEND_RELEVE': 'SEND_RELEVE',
    'MATCH_PROPOSITION': 'MATCH_PROPOSITION',
    'FULL_MATCH_INFOS': 'FULL_MATCH_INFOS',
    'STUDENT_SELF_BIRTHDAY': 'STUDENT_SELF_BIRTHDAY',
    'FAMILY_CHILD_BIRTHDAY': 'FAMILY_CHILD_BIRTHDAY',
    'ATTESTATION_FISCALE': 'ATTESTATION_FISCALE',
    'REFRESHED_CUSTOMER_VALIDATION_TOKEN': 'REFRESHED_CUSTOMER_VALIDATION_TOKEN'
} as const
export type EmailTemplateKey = typeof EmailTemplateKeyEntries[keyof typeof EmailTemplateKeyEntries]

  
/**
 * 
 * @export
 * @interface EmailsSendParams
 */
export interface EmailsSendParams {
    /**
     * 
     * @type {Array<EmailsSendParamsAttachementsInner>}
     * @memberof EmailsSendParams
     */
    attachements?: Array<EmailsSendParamsAttachementsInner>;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParams
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParams
     */
    html?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParams
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParams
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParams
     */
    sender: string;
}


  
/**
 * 
 * @export
 * @interface EmailsSendParamsAttachementsInner
 */
export interface EmailsSendParamsAttachementsInner {
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParamsAttachementsInner
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof EmailsSendParamsAttachementsInner
     */
    base64: string;
}


  
/**
 * 
 * @export
 * @interface Erreur
 */
export interface Erreur {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof Erreur
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Erreur
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Erreur
     */
    description?: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const FamilyCommercialStepEntries = {
    'LEAD': 'LEAD',
    'PROSPECT': 'PROSPECT',
    'CLIENT': 'CLIENT'
} as const
export type FamilyCommercialStep = typeof FamilyCommercialStepEntries[keyof typeof FamilyCommercialStepEntries]

  
/**
 * 
 * @export
 * @interface FamilyPhoneCallsItem
 */
export interface FamilyPhoneCallsItem {
    /**
     * 
     * @type {FamilyPhoneCallsItemPlannedCall}
     * @memberof FamilyPhoneCallsItem
     */
    PlannedCall?: FamilyPhoneCallsItemPlannedCall;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    numberRole: string;
    /**
     * 
     * @type {any}
     * @memberof FamilyPhoneCallsItem
     */
    member: any;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItem
     */
    PhoneNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItem
     */
    TreatedBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FamilyPhoneCallsItem
     */
    isMatched: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FamilyPhoneCallsItem
     */
    isCommented: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItem
     */
    commentedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    comment?: string;
    /**
     * 
     * @type {DryPhoneCallReponseType}
     * @memberof FamilyPhoneCallsItem
     */
    reponseStatus?: DryPhoneCallReponseType;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    personType?: FamilyPhoneCallsItemPersonTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItem
     */
    Person?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FamilyPhoneCallsItem
     */
    throughSwitchboard: boolean;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    io: FamilyPhoneCallsItemIoEnum;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    internalNumber: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    externalNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItem
     */
    startedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItem
     */
    durationSec: number;
    /**
     * id appel keyyo
     * @type {string}
     * @memberof FamilyPhoneCallsItem
     */
    externalId: string;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItem
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItem
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItem
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const FamilyPhoneCallsItemPersonTypeEnumEntries = {
    'member': 'member'
} as const
export type FamilyPhoneCallsItemPersonTypeEnum = typeof FamilyPhoneCallsItemPersonTypeEnumEntries[keyof typeof FamilyPhoneCallsItemPersonTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const FamilyPhoneCallsItemIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type FamilyPhoneCallsItemIoEnum = typeof FamilyPhoneCallsItemIoEnumEntries[keyof typeof FamilyPhoneCallsItemIoEnumEntries]



  
/**
 * 
 * @export
 * @interface FamilyPhoneCallsItemPlannedCall
 */
export interface FamilyPhoneCallsItemPlannedCall {
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    Family: number;
    /**
     * 
     * @type {any}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    calendlyObj?: any | null;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    commentaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    calendly: boolean;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    reason: string;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    doneAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    plannedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    io: FamilyPhoneCallsItemPlannedCallIoEnum;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FamilyPhoneCallsItemPlannedCall
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const FamilyPhoneCallsItemPlannedCallIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type FamilyPhoneCallsItemPlannedCallIoEnum = typeof FamilyPhoneCallsItemPlannedCallIoEnumEntries[keyof typeof FamilyPhoneCallsItemPlannedCallIoEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const FireTeacherPaymentsErrorTypeEntries = {
    'missing_iban': 'missing_iban',
    'invalid_iban': 'invalid_iban',
    'insufficient_solde': 'insufficient_solde',
    'wrong_status': 'wrong_status'
} as const
export type FireTeacherPaymentsErrorType = typeof FireTeacherPaymentsErrorTypeEntries[keyof typeof FireTeacherPaymentsErrorTypeEntries]

  
/**
 * 
 * @export
 * @interface FireTeacherPaymentsPayload
 */
export interface FireTeacherPaymentsPayload {
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsPayload
     */
    requestedExecutionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsPayload
     */
    bulkName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsPayload
     */
    redirectUri?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FireTeacherPaymentsPayload
     */
    teacherPaymentIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FireTeacherPaymentsPayload
     */
    bankAccountId: number;
}


  
/**
 * 
 * @export
 * @interface FireTeacherPaymentsResult
 */
export interface FireTeacherPaymentsResult {
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsResult
     */
    signUri: string;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsResult
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsResult
     */
    pontoPaymentUUID: string;
    /**
     * 
     * @type {number}
     * @memberof FireTeacherPaymentsResult
     */
    pontoPaymentId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FireTeacherPaymentsResult
     */
    teacherPaymentIds: Array<number>;
    /**
     * 
     * @type {Array<FireTeacherPaymentsValidationError>}
     * @memberof FireTeacherPaymentsResult
     */
    errors: Array<FireTeacherPaymentsValidationError>;
    /**
     * 
     * @type {boolean}
     * @memberof FireTeacherPaymentsResult
     */
    error: boolean;
}


  
/**
 * 
 * @export
 * @interface FireTeacherPaymentsValidationError
 */
export interface FireTeacherPaymentsValidationError {
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsValidationError
     */
    messageFr: string;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsValidationError
     */
    message: string;
    /**
     * 
     * @type {FireTeacherPaymentsErrorType}
     * @memberof FireTeacherPaymentsValidationError
     */
    errorType: FireTeacherPaymentsErrorType;
    /**
     * 
     * @type {string}
     * @memberof FireTeacherPaymentsValidationError
     */
    teacherName: string;
    /**
     * 
     * @type {number}
     * @memberof FireTeacherPaymentsValidationError
     */
    teacherId: number;
}




  
/**
 * 
 * @export
 * @interface ForceCharteYsProcRequest
 */
export interface ForceCharteYsProcRequest {
    /**
     * 
     * @type {number}
     * @memberof ForceCharteYsProcRequest
     */
    ysProcId: number;
}


  
/**
 * 
 * @export
 * @interface FullMessage
 */
export interface FullMessage {
    /**
     * 
     * @type {string}
     * @memberof FullMessage
     */
    io: FullMessageIoEnum;
    /**
     * 
     * @type {Array<any>}
     * @memberof FullMessage
     */
    attachmentParts: Array<any>;
    /**
     * 
     * @type {Array<FullMessageTextPartsInner>}
     * @memberof FullMessage
     */
    textParts: Array<FullMessageTextPartsInner>;
    /**
     * 
     * @type {Date}
     * @memberof FullMessage
     */
    date: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullMessage
     */
    to: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullMessage
     */
    from: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FullMessage
     */
    subject: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullMessage
     */
    flags: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FullMessage
     */
    uid: number;
    /**
     * 
     * @type {string}
     * @memberof FullMessage
     */
    folder: string;
    /**
     * 
     * @type {string}
     * @memberof FullMessage
     */
    account: string;
}

/**
    * @export
    * @enum {string}
    */
export const FullMessageIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type FullMessageIoEnum = typeof FullMessageIoEnumEntries[keyof typeof FullMessageIoEnumEntries]



  
/**
 * 
 * @export
 * @interface FullMessageTextPartsInner
 */
export interface FullMessageTextPartsInner {
    /**
     * 
     * @type {string}
     * @memberof FullMessageTextPartsInner
     */
    mime: string;
    /**
     * 
     * @type {string}
     * @memberof FullMessageTextPartsInner
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof FullMessageTextPartsInner
     */
    partID: string;
}


  
/**
 * 
 * @export
 * @interface GenerateLegalInvoicesMonth200Response
 */
export interface GenerateLegalInvoicesMonth200Response {
    /**
     * 
     * @type {Array<number>}
     * @memberof GenerateLegalInvoicesMonth200Response
     */
    createdInvoiceIds: Array<number>;
}


  
/**
 * 
 * @export
 * @interface GenerateTeacherPaymentsXML200Response
 */
export interface GenerateTeacherPaymentsXML200Response {
    /**
     * 
     * @type {string}
     * @memberof GenerateTeacherPaymentsXML200Response
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateTeacherPaymentsXML200Response
     */
    xmlString: string;
}


  
/**
 * 
 * @export
 * @interface GetFormulasStatsTotals200Response
 */
export interface GetFormulasStatsTotals200Response {
    /**
     * 
     * @type {object}
     * @memberof GetFormulasStatsTotals200Response
     */
    detail: object;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetFormulasStatsTotals200Response
     */
    monthList: Array<any>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof GetFormulasStatsTotals200Response
     */
    rows: Array<{ [key: string]: string; }>;
}


  
/**
 * 
 * @export
 * @interface GetInputDemandePaimentFromLegalInvoice200Response
 */
export interface GetInputDemandePaimentFromLegalInvoice200Response {
    /**
     * 
     * @type {LegalInvoiceCkeckInfo}
     * @memberof GetInputDemandePaimentFromLegalInvoice200Response
     */
    checkInfo: LegalInvoiceCkeckInfo;
    /**
     * 
     * @type {InputDemandePaiement}
     * @memberof GetInputDemandePaimentFromLegalInvoice200Response
     */
    inputDemandePaiement: InputDemandePaiement;
}


  
/**
 * 
 * @export
 * @interface GetInputDemandePaimentFromStandardInvoice200Response
 */
export interface GetInputDemandePaimentFromStandardInvoice200Response {
    /**
     * 
     * @type {StandardInvoiceCkeckInfo}
     * @memberof GetInputDemandePaimentFromStandardInvoice200Response
     */
    checkInfo: StandardInvoiceCkeckInfo;
    /**
     * 
     * @type {InputDemandePaiement}
     * @memberof GetInputDemandePaimentFromStandardInvoice200Response
     */
    inputDemandePaiement: InputDemandePaiement;
}


  
/**
 * 
 * @export
 * @interface GetLegalInvoicesMonthStatusRequest
 */
export interface GetLegalInvoicesMonthStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetLegalInvoicesMonthStatusRequest
     */
    month?: string;
}


  
/**
 * 
 * @export
 * @interface GetPayslipsInfos200ResponseInner
 */
export interface GetPayslipsInfos200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    teacherLastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    teacherFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    teacherId: number;
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    learnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    learnerFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    familyId: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    learnerId: number;
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    matchLabel: string;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    matchId: number;
    /**
     * 
     * @type {string}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    payslippedDurationMin: number;
    /**
     * 
     * @type {number}
     * @memberof GetPayslipsInfos200ResponseInner
     */
    unpayslippedDurationMin: number;
}


  
/**
 * 
 * @export
 * @interface GetPreviewListRequest
 */
export interface GetPreviewListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetPreviewListRequest
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface GetShouldGenerateList200ResponseInner
 */
export interface GetShouldGenerateList200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    releveVersion: number;
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    releveId: number;
    /**
     * 
     * @type {string}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    firstName: string;
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    familyId: number;
    /**
     * 
     * @type {Date}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    releveSentAt: Date;
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    unpayslippedLessonsMinSum: number;
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    payslippedLessonsMinSum: number;
    /**
     * 
     * @type {number}
     * @memberof GetShouldGenerateList200ResponseInner
     */
    invoicesCount: number;
}


  
/**
 * 
 * @export
 * @interface GetStats200Response
 */
export interface GetStats200Response {
    /**
     * 
     * @type {number}
     * @memberof GetStats200Response
     */
    durationSum: number;
    /**
     * 
     * @type {number}
     * @memberof GetStats200Response
     */
    countNumbers: number;
    /**
     * 
     * @type {number}
     * @memberof GetStats200Response
     */
    countCalls: number;
}


  
/**
 * 
 * @export
 * @interface GetStatsRequest
 */
export interface GetStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStatsRequest
     */
    order: string;
    /**
     * 
     * @type {any}
     * @memberof GetStatsRequest
     */
    where: any;
}


  
/**
 * 
 * @export
 * @interface GocarlessAffiliationServiceBeginRedirectFlowOutput
 */
export interface GocarlessAffiliationServiceBeginRedirectFlowOutput {
    /**
     * 
     * @type {string}
     * @memberof GocarlessAffiliationServiceBeginRedirectFlowOutput
     */
    redirectFlowId: string;
    /**
     * 
     * @type {string}
     * @memberof GocarlessAffiliationServiceBeginRedirectFlowOutput
     */
    success_redirect_url: string;
    /**
     * 
     * @type {string}
     * @memberof GocarlessAffiliationServiceBeginRedirectFlowOutput
     */
    redirect_url: string;
}


  
/**
 * 
 * @export
 * @interface GooglePeopleReturnCodeParams
 */
export interface GooglePeopleReturnCodeParams {
    /**
     * 
     * @type {string}
     * @memberof GooglePeopleReturnCodeParams
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePeopleReturnCodeParams
     */
    code: string;
}


  
/**
 * 
 * @export
 * @interface GoogleRatingsServiceReview
 */
export interface GoogleRatingsServiceReview {
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReview
     */
    publishTime: string;
    /**
     * 
     * @type {GoogleRatingsServiceReviewAuthorAttribution}
     * @memberof GoogleRatingsServiceReview
     */
    authorAttribution: GoogleRatingsServiceReviewAuthorAttribution;
    /**
     * 
     * @type {GoogleRatingsServiceReviewOriginalText}
     * @memberof GoogleRatingsServiceReview
     */
    originalText: GoogleRatingsServiceReviewOriginalText;
    /**
     * 
     * @type {GoogleRatingsServiceReviewOriginalText}
     * @memberof GoogleRatingsServiceReview
     */
    text: GoogleRatingsServiceReviewOriginalText;
    /**
     * 
     * @type {number}
     * @memberof GoogleRatingsServiceReview
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReview
     */
    relativePublishTimeDescription: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReview
     */
    name: string;
}


  
/**
 * 
 * @export
 * @interface GoogleRatingsServiceReviewAuthorAttribution
 */
export interface GoogleRatingsServiceReviewAuthorAttribution {
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReviewAuthorAttribution
     */
    photoUri: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReviewAuthorAttribution
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReviewAuthorAttribution
     */
    displayName: string;
}


  
/**
 * 
 * @export
 * @interface GoogleRatingsServiceReviewOriginalText
 */
export interface GoogleRatingsServiceReviewOriginalText {
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReviewOriginalText
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleRatingsServiceReviewOriginalText
     */
    text: string;
}


  
/**
 * 
 * @export
 * @interface GoogleRatingsServiceReviewsResult
 */
export interface GoogleRatingsServiceReviewsResult {
    /**
     * 
     * @type {Array<GoogleRatingsServiceReview>}
     * @memberof GoogleRatingsServiceReviewsResult
     */
    reviews: Array<GoogleRatingsServiceReview>;
    /**
     * 
     * @type {number}
     * @memberof GoogleRatingsServiceReviewsResult
     */
    userRatingCount: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleRatingsServiceReviewsResult
     */
    rating: number;
}


  
/**
 * 
 * @export
 * @interface GoogleRatingsServiceSummaryResult
 */
export interface GoogleRatingsServiceSummaryResult {
    /**
     * 
     * @type {number}
     * @memberof GoogleRatingsServiceSummaryResult
     */
    userRatingCount: number;
    /**
     * 
     * @type {number}
     * @memberof GoogleRatingsServiceSummaryResult
     */
    rating: number;
}


  
/**
 * 
 * @export
 * @interface HandleCharteRequest
 */
export interface HandleCharteRequest {
    /**
     * 
     * @type {string}
     * @memberof HandleCharteRequest
     */
    eventName?: string;
    /**
     * 
     * @type {any}
     * @memberof HandleCharteRequest
     */
    procedure: any;
}


  
/**
 * 
 * @export
 * @interface HiringStatsRow
 */
export interface HiringStatsRow {
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    teachersCount: number;
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    freePlaces: number;
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    desiredPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    occupiedPlaces: number;
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    teachingTaux: number;
    /**
     * 
     * @type {string}
     * @memberof HiringStatsRow
     */
    teachingName: string;
    /**
     * 
     * @type {number}
     * @memberof HiringStatsRow
     */
    teachingId: number;
}


  
/**
 * 
 * @export
 * @interface HolidayServiceGetHolidaysOutput
 */
export interface HolidayServiceGetHolidaysOutput extends Array<HolidayServiceGetHolidaysOutputInner> {
}


  
/**
 * 
 * @export
 * @interface HolidayServiceGetHolidaysOutputInner
 */
export interface HolidayServiceGetHolidaysOutputInner {
    /**
     * 
     * @type {number}
     * @memberof HolidayServiceGetHolidaysOutputInner
     */
    certainty: number;
    /**
     * 
     * @type {Date}
     * @memberof HolidayServiceGetHolidaysOutputInner
     */
    endDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof HolidayServiceGetHolidaysOutputInner
     */
    begDate: Date;
    /**
     * 
     * @type {string}
     * @memberof HolidayServiceGetHolidaysOutputInner
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof HolidayServiceGetHolidaysOutputInner
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface HolidayServiceSetHolidayOutput
 */
export interface HolidayServiceSetHolidayOutput {
    /**
     * 
     * @type {number}
     * @memberof HolidayServiceSetHolidayOutput
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface HolidayServiceSetHolidayPayload
 */
export interface HolidayServiceSetHolidayPayload {
    /**
     * 
     * @type {number}
     * @memberof HolidayServiceSetHolidayPayload
     */
    certainty: number;
    /**
     * 
     * @type {Date}
     * @memberof HolidayServiceSetHolidayPayload
     */
    endDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof HolidayServiceSetHolidayPayload
     */
    begDate: Date;
    /**
     * 
     * @type {string}
     * @memberof HolidayServiceSetHolidayPayload
     */
    name: string;
}


  
/**
 * 
 * @export
 * @interface Ignored
 */
export interface Ignored {
}


  
/**
 * 
 * @export
 * @interface InitYousignProcedure200Response
 */
export interface InitYousignProcedure200Response {
    /**
     * 
     * @type {string}
     * @memberof InitYousignProcedure200Response
     */
    ysKey: string;
    /**
     * 
     * @type {string}
     * @memberof InitYousignProcedure200Response
     */
    ysLink: string;
    /**
     * 
     * @type {number}
     * @memberof InitYousignProcedure200Response
     */
    ysProcId: number;
}


  
/**
 * 
 * @export
 * @interface InputDemandePaiement
 */
export interface InputDemandePaiement {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    idTiersFacturation: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    idClient: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    dateNaissanceClient: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    numFactureTiers: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    dateFacture: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    dateDebutEmploi: string;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    dateFinEmploi: string;
    /**
     * 
     * @type {number}
     * @memberof InputDemandePaiement
     */
    mntAcompte?: number;
    /**
     * 
     * @type {string}
     * @memberof InputDemandePaiement
     */
    dateVersementAcompte?: string;
    /**
     * 
     * @type {number}
     * @memberof InputDemandePaiement
     */
    mntFactureTTC: number;
    /**
     * 
     * @type {number}
     * @memberof InputDemandePaiement
     */
    mntFactureHT: number;
    /**
     * Listes des prestations effectuées pour cette demande de paiement pour ce client.
     * @type {Array<InputPrestation>}
     * @memberof InputDemandePaiement
     */
    inputPrestations: Array<InputPrestation>;
}


  
/**
 * 
 * @export
 * @interface InputPrestation
 */
export interface InputPrestation {
    [key: string]: any | any;

    /**
     * Code d\\\'activité lié à la nature de l\\\'emploi
     * @type {string}
     * @memberof InputPrestation
     */
    codeActivite?: string;
    /**
     * Code nature qui correspond aux natures d\\\'emploi définit pas le code du travail (article D7231-1)
     * @type {string}
     * @memberof InputPrestation
     */
    codeNature: string;
    /**
     * Nombre d\\\'unitée pour cette prestation.
     * @type {number}
     * @memberof InputPrestation
     */
    quantite: number;
    /**
     * Correspond à l\\\'unité de la prestation effectuée. Peut avoir deux valeurs : \\\'HEURE\\\' si la prestation correspond à un nombre d\\\'heures effectuées ou \\\'FORFAIT\\\' s\\\'il s\\\'agit d\\\'un forfait.
     * @type {string}
     * @memberof InputPrestation
     */
    unite: string;
    /**
     * 
     * @type {number}
     * @memberof InputPrestation
     */
    mntUnitaireTTC: number;
    /**
     * 
     * @type {number}
     * @memberof InputPrestation
     */
    mntPrestationTTC: number;
    /**
     * 
     * @type {number}
     * @memberof InputPrestation
     */
    mntPrestationHT: number;
    /**
     * 
     * @type {number}
     * @memberof InputPrestation
     */
    mntPrestationTVA: number;
    /**
     * 
     * @type {string}
     * @memberof InputPrestation
     */
    dateDebutEmploi?: string;
    /**
     * 
     * @type {string}
     * @memberof InputPrestation
     */
    dateFinEmploi?: string;
    /**
     * 
     * @type {string}
     * @memberof InputPrestation
     */
    complement1?: string;
    /**
     * Numéro Nova ou SIRET de l’intervenant ayant effectué la prestation. <br/> Le champ doit satisfaire l’un des 2 formats suivants : <br/> * 12 caractères alphanumériques, se décomposant comme suit : <br/> Position 1 à 3 : caractères SAP <br/> Position 4 à 12 : chiffres correspondant au SIREN de l\\\'intervenant<br/> * 17 caractères alphanumériques, se décomposant comme suit : <br/> Position 1 à 3 : caractères SIR <br/> Position 4 à 12 : chiffres correspondant au SIRET de l\\\'intervenant
     * @type {string}
     * @memberof InputPrestation
     */
    complement2: string;
}


  
/**
 * 
 * @export
 * @interface InsertPlannedCallParams
 */
export interface InsertPlannedCallParams {
    /**
     * 
     * @type {InsertPlannedCallParamsPlannedCallData}
     * @memberof InsertPlannedCallParams
     */
    plannedCallData: InsertPlannedCallParamsPlannedCallData;
}


  
/**
 * 
 * @export
 * @interface InsertPlannedCallParamsPlannedCallData
 */
export interface InsertPlannedCallParamsPlannedCallData {
    /**
     * 
     * @type {number}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    familyId: number;
    /**
     * 
     * @type {any}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    calendlyObj?: any | null;
    /**
     * 
     * @type {string}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    commentaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    calendly?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    reason: string;
    /**
     * 
     * @type {Date}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    doneAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    plannedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof InsertPlannedCallParamsPlannedCallData
     */
    io: InsertPlannedCallParamsPlannedCallDataIoEnum;
}

/**
    * @export
    * @enum {string}
    */
export const InsertPlannedCallParamsPlannedCallDataIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type InsertPlannedCallParamsPlannedCallDataIoEnum = typeof InsertPlannedCallParamsPlannedCallDataIoEnumEntries[keyof typeof InsertPlannedCallParamsPlannedCallDataIoEnumEntries]



  
/**
 * 
 * @export
 * @interface InsertTeacherResearchParams
 */
export interface InsertTeacherResearchParams {
    /**
     * 
     * @type {number}
     * @memberof InsertTeacherResearchParams
     */
    createdById: number;
    /**
     * 
     * @type {number}
     * @memberof InsertTeacherResearchParams
     */
    currentMatchId: number;
    /**
     * 
     * @type {string}
     * @memberof InsertTeacherResearchParams
     */
    comment: string;
    /**
     * 
     * @type {Date}
     * @memberof InsertTeacherResearchParams
     */
    treatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof InsertTeacherResearchParams
     */
    delayUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof InsertTeacherResearchParams
     */
    status: InsertTeacherResearchParamsStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export const InsertTeacherResearchParamsStatusEnumEntries = {
    'URGENT': 'URGENT',
    'DELAYED': 'DELAYED',
    'FINISH': 'FINISH'
} as const
export type InsertTeacherResearchParamsStatusEnum = typeof InsertTeacherResearchParamsStatusEnumEntries[keyof typeof InsertTeacherResearchParamsStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface InsertTokenNotificationParams
 */
export interface InsertTokenNotificationParams {
    /**
     * 
     * @type {string}
     * @memberof InsertTokenNotificationParams
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof InsertTokenNotificationParams
     */
    agentId?: number;
    /**
     * 
     * @type {number}
     * @memberof InsertTokenNotificationParams
     */
    familyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InsertTokenNotificationParams
     */
    teacherId?: number;
    /**
     * 
     * @type {Date}
     * @memberof InsertTokenNotificationParams
     */
    lastValidAt?: Date;
    /**
     * 
     * @type {DryFirebaseProjectStatus}
     * @memberof InsertTokenNotificationParams
     */
    projectName: DryFirebaseProjectStatus;
}




  
/**
 * 
 * @export
 * @interface LegalInvoiceCkeckInfo
 */
export interface LegalInvoiceCkeckInfo {
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    finEmploiModified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    debutEmploiModified: boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoiceCkeckInfo
     */
    lastLessonOn: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoiceCkeckInfo
     */
    firstLessonOn: string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    notFullyPrepaid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasDraftPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasSucceededPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasPendingPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    aiciActivationNotOnTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    aiciActivationNotConfirmed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasZeroNegativeAmount: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasNoCredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasMixedCredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasAICICredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoiceCkeckInfo
     */
    hasCIDiffCredits: boolean;
}


  
/**
 * 
 * @export
 * @interface LegalInvoicesTreamentServiceGetMonthStatusOutput
 */
export interface LegalInvoicesTreamentServiceGetMonthStatusOutput {
    /**
     * 
     * @type {Array<LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner>}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutput
     */
    unpayslippedLessons: Array<LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner>;
    /**
     * 
     * @type {Array<LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner>}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutput
     */
    invoices: Array<LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutput
     */
    treatable: boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutput
     */
    firstToTreatMonth: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutput
     */
    month: string;
}


  
/**
 * 
 * @export
 * @interface LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
 */
export interface LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner {
    /**
     * 
     * @type {Array<LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner>}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    payslips: Array<LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    mixable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    treatable: boolean;
    /**
     * 
     * @type {AICIMode}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    mode: AICIMode;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    convertibleMixedCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    missingBothCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    missingModeCreditsCount: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    contactLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    contactFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    contactId: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInner
     */
    familyId: number;
}




  
/**
 * 
 * @export
 * @interface LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
 */
export interface LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner {
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    mixable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    treatable: boolean;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    convertibleMixedCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    missingBothCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    missingModeCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    availableModeCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    availableCIDiffCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    availableAICICreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    availableBothCreditsCount: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    payslipNBH: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    payslipReference: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    payslipGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    payslipId: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    teacherLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    teacherFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    teacherId: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    learnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    learnerFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    learnerId: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    matchLabel: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputInvoicesInnerPayslipsInner
     */
    matchId: number;
}


  
/**
 * 
 * @export
 * @interface LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
 */
export interface LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner {
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    teacherLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    teacherFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    teacherId: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    learnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    learnerFirstName: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    learnerId: number;
    /**
     * 
     * @type {string}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    matchLabel: string;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof LegalInvoicesTreamentServiceGetMonthStatusOutputUnpayslippedLessonsInner
     */
    unpayslippedCount: number;
}


  
/**
 * 
 * @export
 * @interface LessonSubmissionMutatorEditSubmittingLessonPayload
 */
export interface LessonSubmissionMutatorEditSubmittingLessonPayload {
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorEditSubmittingLessonPayload
     */
    durationMin: number;
    /**
     * 
     * @type {string}
     * @memberof LessonSubmissionMutatorEditSubmittingLessonPayload
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorEditSubmittingLessonPayload
     */
    startedAtUnofficial?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorEditSubmittingLessonPayload
     */
    startedAtOfficial: Date;
}


  
/**
 * 
 * @export
 * @interface LessonSubmissionMutatorSubmitBookedLessonPayload
 */
export interface LessonSubmissionMutatorSubmitBookedLessonPayload {
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorSubmitBookedLessonPayload
     */
    durationMin: number;
    /**
     * 
     * @type {string}
     * @memberof LessonSubmissionMutatorSubmitBookedLessonPayload
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorSubmitBookedLessonPayload
     */
    startedAtUnofficial?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorSubmitBookedLessonPayload
     */
    startedAtOfficial: Date;
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorSubmitBookedLessonPayload
     */
    lessonId: number;
}


  
/**
 * 
 * @export
 * @interface LessonSubmissionMutatorSubmitFastLessonPayload
 */
export interface LessonSubmissionMutatorSubmitFastLessonPayload {
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    durationMin: number;
    /**
     * 
     * @type {string}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    teacherComment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    startedAtUnofficial?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LessonSubmissionMutatorSubmitFastLessonPayload
     */
    startedAtOfficial: Date;
}


  
/**
 * 
 * @export
 * @interface LevelsServiceConciseLevelDTO
 */
export interface LevelsServiceConciseLevelDTO {
    /**
     * 
     * @type {number}
     * @memberof LevelsServiceConciseLevelDTO
     */
    orderNb: number;
    /**
     * 
     * @type {number}
     * @memberof LevelsServiceConciseLevelDTO
     */
    LevelGroup: number;
    /**
     * 
     * @type {string}
     * @memberof LevelsServiceConciseLevelDTO
     */
    nameShort: string;
    /**
     * 
     * @type {string}
     * @memberof LevelsServiceConciseLevelDTO
     */
    nameLong: string;
    /**
     * 
     * @type {number}
     * @memberof LevelsServiceConciseLevelDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface LinkBankTransactions200Response
 */
export interface LinkBankTransactions200Response {
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200Response
     */
    page: number;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200Response
     */
    syncToUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200Response
     */
    syncFromUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200Response
     */
    lastResult: LinkBankTransactions200ResponseLastResultEnum;
    /**
     * 
     * @type {LinkBankTransactions200ResponseAnyOf1LastProcessed}
     * @memberof LinkBankTransactions200Response
     */
    lastProcessed: LinkBankTransactions200ResponseAnyOf1LastProcessed;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200Response
     */
    alreadyLinkedCount: number;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200Response
     */
    linkedCount: number;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200Response
     */
    message: any;
}

/**
    * @export
    * @enum {string}
    */
export const LinkBankTransactions200ResponseLastResultEnumEntries = {
    'matched': 'matched',
    'not-matched': 'not-matched',
    'already-linked': 'already-linked',
    'not-in-a-bank-journal': 'not-in-a-bank-journal'
} as const
export type LinkBankTransactions200ResponseLastResultEnum = typeof LinkBankTransactions200ResponseLastResultEnumEntries[keyof typeof LinkBankTransactions200ResponseLastResultEnumEntries]



  
/**
 * 
 * @export
 * @interface LinkBankTransactions200ResponseAnyOf
 */
export interface LinkBankTransactions200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    page?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    syncToUpdatedAt?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    syncFromUpdatedAt?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    lastResult?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    lastProcessed?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    alreadyLinkedCount?: any;
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    linkedCount?: any;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf
     */
    message: string;
}


  
/**
 * 
 * @export
 * @interface LinkBankTransactions200ResponseAnyOf1
 */
export interface LinkBankTransactions200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    message?: any;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    page: number;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    syncToUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    syncFromUpdatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    lastResult: LinkBankTransactions200ResponseAnyOf1LastResultEnum;
    /**
     * 
     * @type {LinkBankTransactions200ResponseAnyOf1LastProcessed}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    lastProcessed: LinkBankTransactions200ResponseAnyOf1LastProcessed;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    alreadyLinkedCount: number;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1
     */
    linkedCount: number;
}

/**
    * @export
    * @enum {string}
    */
export const LinkBankTransactions200ResponseAnyOf1LastResultEnumEntries = {
    'matched': 'matched',
    'not-matched': 'not-matched',
    'already-linked': 'already-linked',
    'not-in-a-bank-journal': 'not-in-a-bank-journal'
} as const
export type LinkBankTransactions200ResponseAnyOf1LastResultEnum = typeof LinkBankTransactions200ResponseAnyOf1LastResultEnumEntries[keyof typeof LinkBankTransactions200ResponseAnyOf1LastResultEnumEntries]



  
/**
 * 
 * @export
 * @interface LinkBankTransactions200ResponseAnyOf1LastProcessed
 */
export interface LinkBankTransactions200ResponseAnyOf1LastProcessed {
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    ledger_attachment_filename: string;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    journal_id: number;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    id: number;
    /**
     * 
     * @type {LinkBankTransactions200ResponseAnyOf1LastProcessedLine}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessed
     */
    line: LinkBankTransactions200ResponseAnyOf1LastProcessedLine;
}


  
/**
 * 
 * @export
 * @interface LinkBankTransactions200ResponseAnyOf1LastProcessedLine
 */
export interface LinkBankTransactions200ResponseAnyOf1LastProcessedLine {
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessedLine
     */
    ledger_account_id: number;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessedLine
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessedLine
     */
    credit: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessedLine
     */
    debit: string;
    /**
     * 
     * @type {number}
     * @memberof LinkBankTransactions200ResponseAnyOf1LastProcessedLine
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface ManualAICIRequestServiceCreateManualAICIRequestPayload
 */
export interface ManualAICIRequestServiceCreateManualAICIRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    privateComment: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    privateLabel: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    dateVersementAcompte: string | null;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    mntAcompte: number;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    dateFinEmploi: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    dateDebutEmploi: string;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    totTVA: number;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    totClient: number;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    invoiceCreatedOn: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    familyId: number;
    /**
     * 
     * @type {Array<ManualAICIRequestServicePrestation>}
     * @memberof ManualAICIRequestServiceCreateManualAICIRequestPayload
     */
    prestations: Array<ManualAICIRequestServicePrestation>;
}


  
/**
 * 
 * @export
 * @interface ManualAICIRequestServicePrestation
 */
export interface ManualAICIRequestServicePrestation {
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServicePrestation
     */
    dateFinEmploi: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServicePrestation
     */
    dateDebutEmploi: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualAICIRequestServicePrestation
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServicePrestation
     */
    mntPrestationTVA: number;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServicePrestation
     */
    unitCount: number;
    /**
     * 
     * @type {number}
     * @memberof ManualAICIRequestServicePrestation
     */
    unitPriceTTC: number;
    /**
     * 
     * @type {AICIPrestationType}
     * @memberof ManualAICIRequestServicePrestation
     */
    type: AICIPrestationType;
}




  
/**
 * 
 * @export
 * @interface MarginSimulatorServiceSimulatePayslipPayload
 */
export interface MarginSimulatorServiceSimulatePayslipPayload {
    /**
     * 
     * @type {number}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    primeNet?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    hasExoneration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    hasRegimeLocal: boolean;
    /**
     * 
     * @type {number}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    pedagoH: number;
    /**
     * 
     * @type {number}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    transpH: number;
    /**
     * 
     * @type {number}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    netH: number;
    /**
     * 
     * @type {string}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof MarginSimulatorServiceSimulatePayslipPayload
     */
    nbH: number;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const ModificationBlockLineTypeEntries = {
    'credit-mod': 'credit-mod',
    'custom-std': 'custom-std'
} as const
export type ModificationBlockLineType = typeof ModificationBlockLineTypeEntries[keyof typeof ModificationBlockLineTypeEntries]

  
/**
 * 
 * @export
 * @interface ModifyCreditsServiceDraftCreditModification
 */
export interface ModifyCreditsServiceDraftCreditModification {
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    newRealPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    oldRealPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    modificationType: ModifyCreditsServiceDraftCreditModificationModificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    status: ModifyCreditsServiceDraftCreditModificationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftCreditModification
     */
    creditId: number;
}

/**
    * @export
    * @enum {string}
    */
export const ModifyCreditsServiceDraftCreditModificationModificationTypeEnumEntries = {
    'cancellation': 'cancellation',
    'modification': 'modification',
    'none': 'none',
    'reactivation': 'reactivation'
} as const
export type ModifyCreditsServiceDraftCreditModificationModificationTypeEnum = typeof ModifyCreditsServiceDraftCreditModificationModificationTypeEnumEntries[keyof typeof ModifyCreditsServiceDraftCreditModificationModificationTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ModifyCreditsServiceDraftCreditModificationStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ABORTED': 'ABORTED',
    'AVAILABLE': 'AVAILABLE',
    'USED': 'USED',
    'CANCELLED': 'CANCELLED'
} as const
export type ModifyCreditsServiceDraftCreditModificationStatusEnum = typeof ModifyCreditsServiceDraftCreditModificationStatusEnumEntries[keyof typeof ModifyCreditsServiceDraftCreditModificationStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface ModifyCreditsServiceDraftInvoiceLine
 */
export interface ModifyCreditsServiceDraftInvoiceLine {
    /**
     * 
     * @type {ModificationBlockLineType}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    type: ModificationBlockLineType;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    tauxTVA: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    unitCount: number;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    unitPriceTTC: number;
    /**
     * 
     * @type {string}
     * @memberof ModifyCreditsServiceDraftInvoiceLine
     */
    description: string;
}




  
/**
 * 
 * @export
 * @interface ModifyCreditsServiceDraftModificationBlock
 */
export interface ModifyCreditsServiceDraftModificationBlock {
    /**
     * 
     * @type {Array<ModifyCreditsServiceDraftInvoiceLine>}
     * @memberof ModifyCreditsServiceDraftModificationBlock
     */
    invoiceLines: Array<ModifyCreditsServiceDraftInvoiceLine>;
    /**
     * 
     * @type {Array<ModifyCreditsServiceDraftCreditModification>}
     * @memberof ModifyCreditsServiceDraftModificationBlock
     */
    modifications: Array<ModifyCreditsServiceDraftCreditModification>;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceDraftModificationBlock
     */
    creationBlockId: number;
}


  
/**
 * 
 * @export
 * @interface ModifyCreditsServiceModifyCreditsPayload
 */
export interface ModifyCreditsServiceModifyCreditsPayload {
    /**
     * 
     * @type {boolean}
     * @memberof ModifyCreditsServiceModifyCreditsPayload
     */
    markAsDoNotSend: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModifyCreditsServiceModifyCreditsPayload
     */
    isExpirationFees: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModifyCreditsServiceModifyCreditsPayload
     */
    allowAltPaymentMethods: boolean;
    /**
     * 
     * @type {Array<ModifyCreditsServiceDraftModificationBlock>}
     * @memberof ModifyCreditsServiceModifyCreditsPayload
     */
    modificationBlocks: Array<ModifyCreditsServiceDraftModificationBlock>;
    /**
     * 
     * @type {number}
     * @memberof ModifyCreditsServiceModifyCreditsPayload
     */
    creditInvoiceId: number;
}


  
/**
 * 
 * @export
 * @interface ModifyPhoneCallParams
 */
export interface ModifyPhoneCallParams {
    /**
     * 
     * @type {ModifyPhoneCallParamsCreatePlannedCallData}
     * @memberof ModifyPhoneCallParams
     */
    createPlannedCallData?: ModifyPhoneCallParamsCreatePlannedCallData;
    /**
     * 
     * @type {ModifyPhoneCallParamsPatchPhoneCallData}
     * @memberof ModifyPhoneCallParams
     */
    patchPhoneCallData: ModifyPhoneCallParamsPatchPhoneCallData;
}


  
/**
 * 
 * @export
 * @interface ModifyPhoneCallParamsCreatePlannedCallData
 */
export interface ModifyPhoneCallParamsCreatePlannedCallData {
    /**
     * 
     * @type {number}
     * @memberof ModifyPhoneCallParamsCreatePlannedCallData
     */
    familyId: number;
    /**
     * 
     * @type {string}
     * @memberof ModifyPhoneCallParamsCreatePlannedCallData
     */
    commentaire?: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyPhoneCallParamsCreatePlannedCallData
     */
    reason: string;
    /**
     * 
     * @type {Date}
     * @memberof ModifyPhoneCallParamsCreatePlannedCallData
     */
    plannedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ModifyPhoneCallParamsCreatePlannedCallData
     */
    io: ModifyPhoneCallParamsCreatePlannedCallDataIoEnum;
}

/**
    * @export
    * @enum {string}
    */
export const ModifyPhoneCallParamsCreatePlannedCallDataIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type ModifyPhoneCallParamsCreatePlannedCallDataIoEnum = typeof ModifyPhoneCallParamsCreatePlannedCallDataIoEnumEntries[keyof typeof ModifyPhoneCallParamsCreatePlannedCallDataIoEnumEntries]



  
/**
 * 
 * @export
 * @interface ModifyPhoneCallParamsPatchPhoneCallData
 */
export interface ModifyPhoneCallParamsPatchPhoneCallData {
    /**
     * 
     * @type {number}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    treatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    plannedCallId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    reponseStatus?: ModifyPhoneCallParamsPatchPhoneCallDataReponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    commentedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    isCommented?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModifyPhoneCallParamsPatchPhoneCallData
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const ModifyPhoneCallParamsPatchPhoneCallDataReponseStatusEnumEntries = {
    'FAILED': 'FAILED',
    'NODISPO': 'NODISPO',
    'RESPONDEDBAD': 'RESPONDEDBAD',
    'RESPONDEDWELL': 'RESPONDEDWELL'
} as const
export type ModifyPhoneCallParamsPatchPhoneCallDataReponseStatusEnum = typeof ModifyPhoneCallParamsPatchPhoneCallDataReponseStatusEnumEntries[keyof typeof ModifyPhoneCallParamsPatchPhoneCallDataReponseStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface ModifyTeacherResearchParams
 */
export interface ModifyTeacherResearchParams {
    /**
     * 
     * @type {number}
     * @memberof ModifyTeacherResearchParams
     */
    foundTeacherId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModifyTeacherResearchParams
     */
    comment: string;
    /**
     * 
     * @type {Date}
     * @memberof ModifyTeacherResearchParams
     */
    treatedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ModifyTeacherResearchParams
     */
    delayUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof ModifyTeacherResearchParams
     */
    status: ModifyTeacherResearchParamsStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ModifyTeacherResearchParams
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const ModifyTeacherResearchParamsStatusEnumEntries = {
    'URGENT': 'URGENT',
    'DELAYED': 'DELAYED',
    'FINISH': 'FINISH'
} as const
export type ModifyTeacherResearchParamsStatusEnum = typeof ModifyTeacherResearchParamsStatusEnumEntries[keyof typeof ModifyTeacherResearchParamsStatusEnumEntries]



  
/**
 * 
 * @export
 * @interface MonthlyMatchSurveyConfigBlock
 */
export interface MonthlyMatchSurveyConfigBlock {
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    visited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    helper?: string;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfigBlockFreeComment}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    freeComment?: DryMonthlyMatchSurveyConfigBlockFreeComment;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    answer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    _options?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    question?: string;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfigBlockIf}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    _if?: DryMonthlyMatchSurveyConfigBlockIf;
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    skipped?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    skipWhenMatchFinishing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    skipWhenNoLessons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    type: MonthlyMatchSurveyConfigBlockTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    header: string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyMatchSurveyConfigBlock
     */
    rank: number;
}

/**
    * @export
    * @enum {string}
    */
export const MonthlyMatchSurveyConfigBlockTypeEnumEntries = {
    'text-long': 'text-long',
    'text-short': 'text-short',
    'radio': 'radio'
} as const
export type MonthlyMatchSurveyConfigBlockTypeEnum = typeof MonthlyMatchSurveyConfigBlockTypeEnumEntries[keyof typeof MonthlyMatchSurveyConfigBlockTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface PatchPlannedCallParams
 */
export interface PatchPlannedCallParams {
    /**
     * 
     * @type {PatchPlannedCallParamsPlannedCallData}
     * @memberof PatchPlannedCallParams
     */
    plannedCallData: PatchPlannedCallParamsPlannedCallData;
}


  
/**
 * 
 * @export
 * @interface PatchPlannedCallParamsPlannedCallData
 */
export interface PatchPlannedCallParamsPlannedCallData {
    /**
     * 
     * @type {string}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    eventId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    commentaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    reason?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    doneAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    cancelledAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    plannedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    io?: PatchPlannedCallParamsPlannedCallDataIoEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchPlannedCallParamsPlannedCallData
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const PatchPlannedCallParamsPlannedCallDataIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type PatchPlannedCallParamsPlannedCallDataIoEnum = typeof PatchPlannedCallParamsPlannedCallDataIoEnumEntries[keyof typeof PatchPlannedCallParamsPlannedCallDataIoEnumEntries]



  
/**
 * 
 * @export
 * @interface PennylaneAuthServiceOAuthData
 */
export interface PennylaneAuthServiceOAuthData {
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthData
     */
    refresh_token: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthData
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthData
     */
    token_type: PennylaneAuthServiceOAuthDataTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthData
     */
    access_token: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthData
     */
    created_at: number;
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthData
     */
    expires_at: number;
}

/**
    * @export
    * @enum {string}
    */
export const PennylaneAuthServiceOAuthDataTokenTypeEnumEntries = {
    'Bearer': 'Bearer'
} as const
export type PennylaneAuthServiceOAuthDataTokenTypeEnum = typeof PennylaneAuthServiceOAuthDataTokenTypeEnumEntries[keyof typeof PennylaneAuthServiceOAuthDataTokenTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface PennylaneAuthServiceOAuthDataAllOf
 */
export interface PennylaneAuthServiceOAuthDataAllOf {
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthDataAllOf
     */
    expires_at: number;
}


  
/**
 * 
 * @export
 * @interface PennylaneAuthServiceOAuthResponse
 */
export interface PennylaneAuthServiceOAuthResponse {
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthResponse
     */
    refresh_token: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthResponse
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthResponse
     */
    token_type: PennylaneAuthServiceOAuthResponseTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PennylaneAuthServiceOAuthResponse
     */
    access_token: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneAuthServiceOAuthResponse
     */
    created_at: number;
}

/**
    * @export
    * @enum {string}
    */
export const PennylaneAuthServiceOAuthResponseTokenTypeEnumEntries = {
    'Bearer': 'Bearer'
} as const
export type PennylaneAuthServiceOAuthResponseTokenTypeEnum = typeof PennylaneAuthServiceOAuthResponseTokenTypeEnumEntries[keyof typeof PennylaneAuthServiceOAuthResponseTokenTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface PennylaneSyncServicePennylaneCategory
 */
export interface PennylaneSyncServicePennylaneCategory {
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    updated_at?: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    created_at?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    is_editable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    direction?: PennylaneSyncServicePennylaneCategoryDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    group_label?: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    group_source_id?: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    source_id?: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServicePennylaneCategory
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const PennylaneSyncServicePennylaneCategoryDirectionEnumEntries = {
    'cash_in': 'cash_in',
    'cash_out': 'cash_out'
} as const
export type PennylaneSyncServicePennylaneCategoryDirectionEnum = typeof PennylaneSyncServicePennylaneCategoryDirectionEnumEntries[keyof typeof PennylaneSyncServicePennylaneCategoryDirectionEnumEntries]



  
/**
 * 
 * @export
 * @interface PennylaneSyncServicePennylaneJournal
 */
export interface PennylaneSyncServicePennylaneJournal {
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneJournal
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServicePennylaneJournal
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneJournal
     */
    code: string;
}


  
/**
 * 
 * @export
 * @interface PennylaneSyncServicePennylaneLedgerAccount
 */
export interface PennylaneSyncServicePennylaneLedgerAccount {
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneLedgerAccount
     */
    country_alpha2: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneLedgerAccount
     */
    vat_rate: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneLedgerAccount
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServicePennylaneLedgerAccount
     */
    number: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServicePennylaneLedgerAccount
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface PennylaneSyncServiceSyncBankTransactionsResult
 */
export interface PennylaneSyncServiceSyncBankTransactionsResult {
    /**
     * 
     * @type {Array<PennylaneSyncServiceSyncBankTransactionsResultFailuresInner>}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResult
     */
    failures: Array<PennylaneSyncServiceSyncBankTransactionsResultFailuresInner>;
    /**
     * 
     * @type {string}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResult
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResult
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResult
     */
    failed: number;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResult
     */
    synced: number;
}


  
/**
 * 
 * @export
 * @interface PennylaneSyncServiceSyncBankTransactionsResultFailuresInner
 */
export interface PennylaneSyncServiceSyncBankTransactionsResultFailuresInner {
    /**
     * 
     * @type {any}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResultFailuresInner
     */
    error: any;
    /**
     * 
     * @type {number}
     * @memberof PennylaneSyncServiceSyncBankTransactionsResultFailuresInner
     */
    bankTransactionId: number;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryCreationBlockExcludeKeyofDryCreationBlockType
 */
export interface PickDryCreationBlockExcludeKeyofDryCreationBlockType {
    /**
     * 
     * @type {DryAICIMode}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    aiciMode?: DryAICIMode;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    reference?: string;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    creationCount?: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    basePrice?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    CreatedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryMatch}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    InitialMatch?: DryMatch;
    /**
     * 
     * @type {string}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    status?: PickDryCreationBlockExcludeKeyofDryCreationBlockTypeStatusEnum;
    /**
     * 
     * @type {DryCreationBlockAbortReason}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    abortReason?: DryCreationBlockAbortReason;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    blockSolde?: number;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    blockPriceTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof PickDryCreationBlockExcludeKeyofDryCreationBlockType
     */
    TransferedCredits?: Array<DryCredit>;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryCreationBlockExcludeKeyofDryCreationBlockTypeStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'ACTIVE': 'ACTIVE',
    'PENDING': 'PENDING',
    'ABORTED': 'ABORTED'
} as const
export type PickDryCreationBlockExcludeKeyofDryCreationBlockTypeStatusEnum = typeof PickDryCreationBlockExcludeKeyofDryCreationBlockTypeStatusEnumEntries[keyof typeof PickDryCreationBlockExcludeKeyofDryCreationBlockTypeStatusEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryDocExcludeKeyofDryDocKeyofRootEntity
 */
export interface PickDryDocExcludeKeyofDryDocKeyofRootEntity {
    /**
     * 
     * @type {DryPerson}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    Person?: DryPerson;
    /**
     * 
     * @type {Array<DryYsProc>}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    YsProcs?: Array<DryYsProc>;
    /**
     * 
     * @type {number}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    type?: PickDryDocExcludeKeyofDryDocKeyofRootEntityTypeEnum;
    /**
     * 
     * @type {DryFamily}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    Family?: DryFamily;
    /**
     * 
     * @type {string}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    status?: PickDryDocExcludeKeyofDryDocKeyofRootEntityStatusEnum;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {string}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    ysKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    fileName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    shouldBeSigned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    storagePath?: string;
    /**
     * 
     * @type {DryYsProc}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    CurrentYsProc?: DryYsProc;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    CreditDevis?: DryCreditInvoice;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    StandardInvoice?: DryStandardInvoice;
    /**
     * 
     * @type {DryStandardInvoice}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    StandardDevis?: DryStandardInvoice;
    /**
     * 
     * @type {DryTeacherProposition}
     * @memberof PickDryDocExcludeKeyofDryDocKeyofRootEntity
     */
    TeacherProposition?: DryTeacherProposition;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryDocExcludeKeyofDryDocKeyofRootEntityTypeEnumEntries = {
    'UNIQUE': 'UNIQUE',
    'CHARTE': 'CHARTE',
    'FACTURE_CREDITS': 'FACTURE_CREDITS',
    'DEVIS_CREDITS': 'DEVIS_CREDITS',
    'FACTURE_STANDARD': 'FACTURE_STANDARD',
    'CGS': 'CGS',
    'MANDAT_CLIENT': 'MANDAT_CLIENT',
    'MANDAT_PROF': 'MANDAT_PROF',
    'PROPOSITION_PROF': 'PROPOSITION_PROF',
    'MANDAT_SEPA': 'MANDAT_SEPA',
    'DEVIS_STANDARD': 'DEVIS_STANDARD'
} as const
export type PickDryDocExcludeKeyofDryDocKeyofRootEntityTypeEnum = typeof PickDryDocExcludeKeyofDryDocKeyofRootEntityTypeEnumEntries[keyof typeof PickDryDocExcludeKeyofDryDocKeyofRootEntityTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PickDryDocExcludeKeyofDryDocKeyofRootEntityStatusEnumEntries = {
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'FORCED': 'FORCED',
    'ABORTED': 'ABORTED',
    'TO_SIGN': 'TO_SIGN',
    'TO_SEND': 'TO_SEND',
    'SENT': 'SENT',
    'NONE': 'NONE'
} as const
export type PickDryDocExcludeKeyofDryDocKeyofRootEntityStatusEnum = typeof PickDryDocExcludeKeyofDryDocKeyofRootEntityStatusEnumEntries[keyof typeof PickDryDocExcludeKeyofDryDocKeyofRootEntityStatusEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
 */
export interface PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels {
    /**
     * 
     * @type {number}
     * @memberof PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels
     */
    orderNb?: number;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryMobilityExcludeKeyofDryMobilityTeachers
 */
export interface PickDryMobilityExcludeKeyofDryMobilityTeachers {
    /**
     * 
     * @type {number}
     * @memberof PickDryMobilityExcludeKeyofDryMobilityTeachers
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMobilityExcludeKeyofDryMobilityTeachers
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMobilityExcludeKeyofDryMobilityTeachers
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryMobilityExcludeKeyofDryMobilityTeachers
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryMobilityExcludeKeyofDryMobilityTeachers
     */
    isActive?: boolean;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryModificationBlockExcludeKeyofDryModificationBlockType
 */
export interface PickDryModificationBlockExcludeKeyofDryModificationBlockType {
    /**
     * 
     * @type {DryAICIMode}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    aiciMode?: DryAICIMode;
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    updatedAt?: Date;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    blockPriceTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {DryCreationBlock}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    SourceCreationBlock?: DryCreationBlock;
    /**
     * 
     * @type {Array<PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner>}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    Modifications?: Array<PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner>;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockType
     */
    CancelledCredits?: Array<DryCredit>;
}




  
/**
 * 
 * @export
 * @interface PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
 */
export interface PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner {
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    newRealPrice: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    oldRealPrice: number;
    /**
     * 
     * @type {string}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    modificationType: PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerModificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    status: PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInner
     */
    creditId: number;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerModificationTypeEnumEntries = {
    'modification': 'modification',
    'cancellation': 'cancellation',
    'none': 'none',
    'reactivation': 'reactivation'
} as const
export type PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerModificationTypeEnum = typeof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerModificationTypeEnumEntries[keyof typeof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerModificationTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'CANCELLED': 'CANCELLED',
    'ABORTED': 'ABORTED',
    'AVAILABLE': 'AVAILABLE',
    'USED': 'USED'
} as const
export type PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerStatusEnum = typeof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerStatusEnumEntries[keyof typeof PickDryModificationBlockExcludeKeyofDryModificationBlockTypeModificationsInnerStatusEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
 */
export interface PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch {
    /**
     * 
     * @type {number}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    month?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    status?: PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatchStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    submittedAt?: Date;
    /**
     * 
     * @type {DryMonthlyMatchSurveyConfig}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    questions?: DryMonthlyMatchSurveyConfig;
    /**
     * 
     * @type {Date}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    reviewedAt?: Date;
    /**
     * 
     * @type {DryRichComment}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    reviewRichComment?: DryRichComment;
    /**
     * 
     * @type {number}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    lessonsDurationSum?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    isComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    isFinishing?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    incidentsCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch
     */
    incidentsList?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatchStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'SUBMITTED': 'SUBMITTED'
} as const
export type PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatchStatusEnum = typeof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatchStatusEnumEntries[keyof typeof PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatchStatusEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
 */
export interface PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity {
    /**
     * 
     * @type {DryPerson}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    Person?: DryPerson;
    /**
     * 
     * @type {number}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    reponseStatus?: PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityReponseStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    commentedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    durationSec?: number;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    cdrExtId?: number;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    operator?: PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    lastState?: PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityLastStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    externalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    internalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    io?: PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityIoEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    isCommented?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    isMatched?: boolean;
    /**
     * 
     * @type {DryTsoaPersonType}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    personType?: DryTsoaPersonType;
    /**
     * 
     * @type {Date}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    startedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    throughSwitchboard?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    recordUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    voicemailUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    userExtId?: number;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    scenarioExtId?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    scenarioName?: string;
    /**
     * 
     * @type {DryAgent}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    TreatedBy?: DryAgent;
    /**
     * 
     * @type {DryPlannedCall}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    PlannedCall?: DryPlannedCall;
    /**
     * 
     * @type {DryPhoneNumber}
     * @memberof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntity
     */
    PhoneNumber?: DryPhoneNumber;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityReponseStatusEnumEntries = {
    'FAILED': 'FAILED',
    'NODISPO': 'NODISPO',
    'RESPONDEDBAD': 'RESPONDEDBAD',
    'RESPONDEDWELL': 'RESPONDEDWELL'
} as const
export type PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityReponseStatusEnum = typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityReponseStatusEnumEntries[keyof typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityReponseStatusEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityOperatorEnumEntries = {
    'ringover': 'ringover',
    'keyyo': 'keyyo'
} as const
export type PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityOperatorEnum = typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityOperatorEnumEntries[keyof typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityOperatorEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityLastStateEnumEntries = {
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
    'ANSWERED': 'ANSWERED',
    'MISSED': 'MISSED',
    'QUEUE_TIMEOUT': 'QUEUE_TIMEOUT',
    'BLIND_TRANSFERED': 'BLIND_TRANSFERED',
    'VOICEMAIL': 'VOICEMAIL',
    'PERMANENT_TRANSFERED': 'PERMANENT_TRANSFERED',
    'NOANSWER_TRANSFERED': 'NOANSWER_TRANSFERED',
    'FAX_RECEIVED': 'FAX_RECEIVED',
    'FAX_FAILED': 'FAX_FAILED',
    'FAX_OUT_SENT': 'FAX_OUT_SENT',
    'INCORRECT_PINCODE': 'INCORRECT_PINCODE',
    'FAX_OUT_NOT_SENT': 'FAX_OUT_NOT_SENT',
    'ANNOUNCE': 'ANNOUNCE'
} as const
export type PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityLastStateEnum = typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityLastStateEnumEntries[keyof typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityLastStateEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityIoEnum = typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityIoEnumEntries[keyof typeof PickDryPhoneCallExcludeKeyofDryPhoneCallKeyofRootEntityIoEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings
 */
export interface PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings {
    /**
     * 
     * @type {number}
     * @memberof PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings
     */
    name?: string;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryTransferBlockExcludeKeyofDryTransferBlockType
 */
export interface PickDryTransferBlockExcludeKeyofDryTransferBlockType {
    /**
     * 
     * @type {DryAICIMode}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    aiciMode?: DryAICIMode;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    updatedAt?: Date;
    /**
     * 
     * @type {DryCreditInvoice}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    CreditInvoice?: DryCreditInvoice;
    /**
     * 
     * @type {Array<DryBlockInvoiceLine>}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    invoiceLines?: Array<DryBlockInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    blockPriceTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    blockPriceTTCWhole?: number;
    /**
     * 
     * @type {Array<DryCredit>}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    TransferedCredits?: Array<DryCredit>;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    transferCount?: number;
    /**
     * 
     * @type {DryMatch}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    SourceMatch?: DryMatch;
    /**
     * 
     * @type {DryMatch}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    TargetMatch?: DryMatch;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    previousSourceSolde?: number;
    /**
     * 
     * @type {number}
     * @memberof PickDryTransferBlockExcludeKeyofDryTransferBlockType
     */
    previousTargetSolde?: number;
}




  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
 */
export interface PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity {
    /**
     * 
     * @type {DryPerson}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    Person?: DryPerson;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    Docs?: Array<DryDoc>;
    /**
     * 
     * @type {number}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    updatedAt?: Date;
    /**
     * 
     * @type {DryFamily}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    Family?: DryFamily;
    /**
     * 
     * @type {string}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    status?: PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntityStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    finishedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    refusedComment?: string;
    /**
     * 
     * @type {Date}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    sentAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    ysKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    ysLink?: string;
    /**
     * 
     * @type {Array<DryDoc>}
     * @memberof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntity
     */
    CurrentDocs?: Array<DryDoc>;
}

/**
    * @export
    * @enum {string}
    */
export const PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntityStatusEnumEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'FORCED': 'FORCED',
    'ABORTED': 'ABORTED'
} as const
export type PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntityStatusEnum = typeof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntityStatusEnumEntries[keyof typeof PickDryYsProcExcludeKeyofDryYsProcKeyofRootEntityStatusEnumEntries]



  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId
 */
export interface PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId {
    /**
     * 
     * @type {string}
     * @memberof PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId
     */
    abortComment?: string;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId
 */
export interface PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId {
    /**
     * 
     * @type {number}
     * @memberof PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId
     */
    matchId: number;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId
 */
export interface PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId {
    /**
     * 
     * @type {string}
     * @memberof PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId
     */
    cancelComment?: string;
}


  
/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId
 */
export interface PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId {
    /**
     * 
     * @type {string}
     * @memberof PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId
     */
    abortComment?: string;
}


  
/**
 * 
 * @export
 * @interface PontoGetBulkPaymentResponse
 */
export interface PontoGetBulkPaymentResponse {
    /**
     * 
     * @type {PontoGetBulkPaymentResponseData}
     * @memberof PontoGetBulkPaymentResponse
     */
    data: PontoGetBulkPaymentResponseData;
}


  
/**
 * 
 * @export
 * @interface PontoGetBulkPaymentResponseData
 */
export interface PontoGetBulkPaymentResponseData {
    /**
     * 
     * @type {PontoGetBulkPaymentResponseDataAttributes}
     * @memberof PontoGetBulkPaymentResponseData
     */
    attributes: PontoGetBulkPaymentResponseDataAttributes;
    /**
     * 
     * @type {string}
     * @memberof PontoGetBulkPaymentResponseData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetBulkPaymentResponseData
     */
    type: PontoGetBulkPaymentResponseDataTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const PontoGetBulkPaymentResponseDataTypeEnumEntries = {
    'bulkPayment': 'bulkPayment'
} as const
export type PontoGetBulkPaymentResponseDataTypeEnum = typeof PontoGetBulkPaymentResponseDataTypeEnumEntries[keyof typeof PontoGetBulkPaymentResponseDataTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface PontoGetBulkPaymentResponseDataAttributes
 */
export interface PontoGetBulkPaymentResponseDataAttributes {
    /**
     * 
     * @type {boolean}
     * @memberof PontoGetBulkPaymentResponseDataAttributes
     */
    batchBookingPreferred: boolean;
    /**
     * 
     * @type {string}
     * @memberof PontoGetBulkPaymentResponseDataAttributes
     */
    requestedExecutionDate: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetBulkPaymentResponseDataAttributes
     */
    reference: string;
    /**
     * 
     * @type {PontoPaymentStatus}
     * @memberof PontoGetBulkPaymentResponseDataAttributes
     */
    status: PontoPaymentStatus;
}




  
/**
 * 
 * @export
 * @interface PontoGetPaymentAttrubutes
 */
export interface PontoGetPaymentAttrubutes {
    [key: string]: any | any;

    /**
     * 2024-02-05
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    remittanceInformationType?: PontoGetPaymentAttrubutesRemittanceInformationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    remittanceInformation: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    currency: PontoGetPaymentAttrubutesCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    creditorName: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    creditorAgentType?: PontoGetPaymentAttrubutesCreditorAgentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    creditorAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    creditorAccountReferenceType: PontoGetPaymentAttrubutesCreditorAccountReferenceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    creditorAccountReference: string;
    /**
     * 
     * @type {number}
     * @memberof PontoGetPaymentAttrubutes
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentAttrubutes
     */
    requestedExecutionDate?: string;
    /**
     * 
     * @type {PontoPaymentStatus}
     * @memberof PontoGetPaymentAttrubutes
     */
    status: PontoPaymentStatus;
}

/**
    * @export
    * @enum {string}
    */
export const PontoGetPaymentAttrubutesRemittanceInformationTypeEnumEntries = {
    'structured': 'structured',
    'unstructured': 'unstructured'
} as const
export type PontoGetPaymentAttrubutesRemittanceInformationTypeEnum = typeof PontoGetPaymentAttrubutesRemittanceInformationTypeEnumEntries[keyof typeof PontoGetPaymentAttrubutesRemittanceInformationTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PontoGetPaymentAttrubutesCurrencyEnumEntries = {
    'EUR': 'EUR'
} as const
export type PontoGetPaymentAttrubutesCurrencyEnum = typeof PontoGetPaymentAttrubutesCurrencyEnumEntries[keyof typeof PontoGetPaymentAttrubutesCurrencyEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PontoGetPaymentAttrubutesCreditorAgentTypeEnumEntries = {
    'BIC': 'BIC'
} as const
export type PontoGetPaymentAttrubutesCreditorAgentTypeEnum = typeof PontoGetPaymentAttrubutesCreditorAgentTypeEnumEntries[keyof typeof PontoGetPaymentAttrubutesCreditorAgentTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const PontoGetPaymentAttrubutesCreditorAccountReferenceTypeEnumEntries = {
    'IBAN': 'IBAN'
} as const
export type PontoGetPaymentAttrubutesCreditorAccountReferenceTypeEnum = typeof PontoGetPaymentAttrubutesCreditorAccountReferenceTypeEnumEntries[keyof typeof PontoGetPaymentAttrubutesCreditorAccountReferenceTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface PontoGetPaymentResponse
 */
export interface PontoGetPaymentResponse {
    /**
     * 
     * @type {PontoGetPaymentResponseData}
     * @memberof PontoGetPaymentResponse
     */
    data: PontoGetPaymentResponseData;
}


  
/**
 * 
 * @export
 * @interface PontoGetPaymentResponseData
 */
export interface PontoGetPaymentResponseData {
    /**
     * 
     * @type {PontoGetPaymentAttrubutes}
     * @memberof PontoGetPaymentResponseData
     */
    attributes: PontoGetPaymentAttrubutes;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentResponseData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PontoGetPaymentResponseData
     */
    type: PontoGetPaymentResponseDataTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export const PontoGetPaymentResponseDataTypeEnumEntries = {
    'payment': 'payment'
} as const
export type PontoGetPaymentResponseDataTypeEnum = typeof PontoGetPaymentResponseDataTypeEnumEntries[keyof typeof PontoGetPaymentResponseDataTypeEnumEntries]




/**
 * 
 * @export
 * @enum {string}
 */

export const PontoPaymentStatusEntries = {
    'unsigned': 'unsigned',
    'accepted-customer-profile': 'accepted-customer-profile',
    'accepted-settlement-completed': 'accepted-settlement-completed',
    'accepted-settlement-in-process': 'accepted-settlement-in-process',
    'accepted-technical-validation': 'accepted-technical-validation',
    'accepted-with-change': 'accepted-with-change',
    'accepted-without-posting': 'accepted-without-posting',
    'accepted-funds-checked': 'accepted-funds-checked',
    'received': 'received',
    'pending': 'pending',
    'rejected': 'rejected',
    'waiting-for-signature': 'waiting-for-signature',
    'cancelled': 'cancelled',
    'partial-acceptance': 'partial-acceptance',
    'unknown': 'unknown'
} as const
export type PontoPaymentStatus = typeof PontoPaymentStatusEntries[keyof typeof PontoPaymentStatusEntries]

  
/**
 * 
 * @export
 * @interface PreparedListItem
 */
export interface PreparedListItem {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof PreparedListItem
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof PreparedListItem
     */
    folder: string;
    /**
     * 
     * @type {number}
     * @memberof PreparedListItem
     */
    uid: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreparedListItem
     */
    flags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PreparedListItem
     */
    subject: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreparedListItem
     */
    from: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreparedListItem
     */
    to: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PreparedListItem
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof PreparedListItem
     */
    io: PreparedListItemIoEnum;
    /**
     * 
     * @type {Array<any>}
     * @memberof PreparedListItem
     */
    attachmentCount: Array<any>;
}

/**
    * @export
    * @enum {string}
    */
export const PreparedListItemIoEnumEntries = {
    'i': 'i',
    'o': 'o'
} as const
export type PreparedListItemIoEnum = typeof PreparedListItemIoEnumEntries[keyof typeof PreparedListItemIoEnumEntries]



  
/**
 * 
 * @export
 * @interface PropositionsCtrlAddFirstLessonByAdminBody
 */
export interface PropositionsCtrlAddFirstLessonByAdminBody {
    /**
     * 
     * @type {number}
     * @memberof PropositionsCtrlAddFirstLessonByAdminBody
     */
    durationMinPlanned: number;
    /**
     * 
     * @type {Date}
     * @memberof PropositionsCtrlAddFirstLessonByAdminBody
     */
    startedAtPlanned: Date;
    /**
     * 
     * @type {number}
     * @memberof PropositionsCtrlAddFirstLessonByAdminBody
     */
    matchId: number;
}


  
/**
 * 
 * @export
 * @interface ProspectRegistrationServiceHandleCalendlyScheduledOutput
 */
export interface ProspectRegistrationServiceHandleCalendlyScheduledOutput {
    /**
     * 
     * @type {CalendlyScheduledEvent}
     * @memberof ProspectRegistrationServiceHandleCalendlyScheduledOutput
     */
    event: CalendlyScheduledEvent;
}


  
/**
 * 
 * @export
 * @interface ProspectRegistrationServiceHandleCalendlyScheduledPayload
 */
export interface ProspectRegistrationServiceHandleCalendlyScheduledPayload {
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceHandleCalendlyScheduledPayload
     */
    familyId: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceHandleCalendlyScheduledPayload
     */
    inviteeUri: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceHandleCalendlyScheduledPayload
     */
    eventUri: string;
}


  
/**
 * 
 * @export
 * @interface ProspectRegistrationServiceRegistrationData
 */
export interface ProspectRegistrationServiceRegistrationData {
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    nbrHeures: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    entryPoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    mainCityName: string;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    mainCityId: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    gender: ProspectRegistrationServiceRegistrationDataGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    wishedPeriod: string;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    wishedFrequency?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    wishedDurationMin?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    levelManual?: string | null;
    /**
     * 
     * @type {Array<ProspectRegistrationServiceRegistrationDataCustomQuestionsInner>}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    customQuestions: Array<ProspectRegistrationServiceRegistrationDataCustomQuestionsInner>;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    moyenne?: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    flowType: ProspectRegistrationServiceRegistrationDataFlowTypeEnum;
    /**
     * 
     * @type {ProspectRegistrationServiceRegistrationType}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    registrationType: ProspectRegistrationServiceRegistrationType;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    levelName: string;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    levelId: number;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    teachingName: string;
    /**
     * 
     * @type {number}
     * @memberof ProspectRegistrationServiceRegistrationData
     */
    teachingId: number;
}

/**
    * @export
    * @enum {string}
    */
export const ProspectRegistrationServiceRegistrationDataGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type ProspectRegistrationServiceRegistrationDataGenderEnum = typeof ProspectRegistrationServiceRegistrationDataGenderEnumEntries[keyof typeof ProspectRegistrationServiceRegistrationDataGenderEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ProspectRegistrationServiceRegistrationDataFlowTypeEnumEntries = {
    'music': 'music',
    'scolar': 'scolar',
    'postbac': 'postbac',
    'divers': 'divers',
    'info': 'info',
    'lang': 'lang'
} as const
export type ProspectRegistrationServiceRegistrationDataFlowTypeEnum = typeof ProspectRegistrationServiceRegistrationDataFlowTypeEnumEntries[keyof typeof ProspectRegistrationServiceRegistrationDataFlowTypeEnumEntries]



  
/**
 * 
 * @export
 * @interface ProspectRegistrationServiceRegistrationDataCustomQuestionsInner
 */
export interface ProspectRegistrationServiceRegistrationDataCustomQuestionsInner {
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationDataCustomQuestionsInner
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof ProspectRegistrationServiceRegistrationDataCustomQuestionsInner
     */
    question: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const ProspectRegistrationServiceRegistrationTypeEntries = {
    'self': 'self',
    'child': 'child',
    'peer': 'peer'
} as const
export type ProspectRegistrationServiceRegistrationType = typeof ProspectRegistrationServiceRegistrationTypeEntries[keyof typeof ProspectRegistrationServiceRegistrationTypeEntries]

  
/**
 * 
 * @export
 * @interface RadiationAffiliationPayload
 */
export interface RadiationAffiliationPayload {
    /**
     * 
     * @type {string}
     * @memberof RadiationAffiliationPayload
     */
    asapRadiationOn: string;
    /**
     * 
     * @type {number}
     * @memberof RadiationAffiliationPayload
     */
    familyId: number;
}


  
/**
 * 
 * @export
 * @interface Rates
 */
export interface Rates {
    [key: string]: any | any;

    /**
     * Santé : Sécurité sociale
     * @type {number}
     * @memberof Rates
     */
    maladieP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    maladieS: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    maladieLocal: number;
    /**
     * Santé:Complémentaire incapacité invalidité décès
     * @type {number}
     * @memberof Rates
     */
    ircemPrevoyanceP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    ircemPrevoyanceS: number;
    /**
     * Retraite : Sécurité sociale plafonnée
     * @type {number}
     * @memberof Rates
     */
    vieillessePlafP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    vieillessePlafS: number;
    /**
     * Retraite : Sécurité sociale déplafonnée
     * @type {number}
     * @memberof Rates
     */
    vieillesseDepP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    vieillesseDepS: number;
    /**
     * Retraite : Complémentaire IRCEM
     * @type {number}
     * @memberof Rates
     */
    ircemP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    ircemS: number;
    /**
     * Retraite : Contribution d\'équilibre générale
     * @type {number}
     * @memberof Rates
     */
    cegP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    cegS: number;
    /**
     * Accidents du travail - Maladies prof.
     * @type {number}
     * @memberof Rates
     */
    accidentP: number;
    /**
     * Famille
     * @type {number}
     * @memberof Rates
     */
    allocFamP: number;
    /**
     * Chomage
     * @type {number}
     * @memberof Rates
     */
    chomageP: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    chomageS: number;
    /**
     * Autres contributions dues par l\'employeur : brutTot * (cfp + fnal + cds)
     * @type {number}
     * @memberof Rates
     */
    cfp: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    fnal: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    cds: number;
    /**
     * CSG
     * @type {number}
     * @memberof Rates
     */
    baseCsg: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    csgCrds: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    csgDeductible: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    reductionForfaitaire: number;
    /**
     * 
     * @type {number}
     * @memberof Rates
     */
    tauxTVA: number;
}


  
/**
 * Construct a type with a set of properties K of type T
 * @export
 * @interface RecordStringAny
 */
export interface RecordStringAny {
    [key: string]: any;

}


  
/**
 * Construct a type with a set of properties K of type T
 * @export
 * @interface RecordStringStringOrNumber
 */
export interface RecordStringStringOrNumber {
    [key: string]: RecordStringStringOrNumberValue;

}


  
/**
 * 
 * @export
 * @interface RecordStringStringOrNumberValue
 */
export interface RecordStringStringOrNumberValue {
}


  
/**
 * 
 * @export
 * @interface RecruitmentRecap
 */
export interface RecruitmentRecap {
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbRefused2: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbRefused1: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbFinished: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    totCandidatures: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbAccepted2: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbWaitingSign: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbWaitingQualif: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbWaitingSession: number;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbWaitingScheduling: number;
    /**
     * 
     * @type {string}
     * @memberof RecruitmentRecap
     */
    nbWaitingTeacherInfo: string;
    /**
     * 
     * @type {number}
     * @memberof RecruitmentRecap
     */
    nbNewCandidatures: number;
}


  
/**
 * 
 * @export
 * @interface RefuseLessonProposition200Response
 */
export interface RefuseLessonProposition200Response {
    /**
     * 
     * @type {number}
     * @memberof RefuseLessonProposition200Response
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof RefuseLessonProposition200Response
     */
    lessonId: number;
}


  
/**
 * 
 * @export
 * @interface RegisterProspect200Response
 */
export interface RegisterProspect200Response {
    /**
     * 
     * @type {number}
     * @memberof RegisterProspect200Response
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface RelevesServiceGenerateRelevesPayload
 */
export interface RelevesServiceGenerateRelevesPayload {
    /**
     * 
     * @type {Array<number>}
     * @memberof RelevesServiceGenerateRelevesPayload
     */
    familyIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceGenerateRelevesPayload
     */
    month: string;
}


  
/**
 * 
 * @export
 * @interface RelevesServiceSendReleveBatchResult
 */
export interface RelevesServiceSendReleveBatchResult extends Array<RelevesServiceSendReleveBatchResultInner> {
}


  
/**
 * 
 * @export
 * @interface RelevesServiceSendReleveBatchResultInner
 */
export interface RelevesServiceSendReleveBatchResultInner {
    /**
     * 
     * @type {any}
     * @memberof RelevesServiceSendReleveBatchResultInner
     */
    error?: any;
    /**
     * 
     * @type {boolean}
     * @memberof RelevesServiceSendReleveBatchResultInner
     */
    failed?: boolean;
    /**
     * 
     * @type {RelevesServiceSendReleveResult}
     * @memberof RelevesServiceSendReleveBatchResultInner
     */
    data?: RelevesServiceSendReleveResult;
    /**
     * 
     * @type {boolean}
     * @memberof RelevesServiceSendReleveBatchResultInner
     */
    success?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelevesServiceSendReleveBatchResultInner
     */
    releveId: number;
}


  
/**
 * 
 * @export
 * @interface RelevesServiceSendReleveBatchSVPL
 */
export interface RelevesServiceSendReleveBatchSVPL {
    /**
     * 
     * @type {Array<number>}
     * @memberof RelevesServiceSendReleveBatchSVPL
     */
    releveIds: Array<number>;
}


  
/**
 * 
 * @export
 * @interface RelevesServiceSendReleveResult
 */
export interface RelevesServiceSendReleveResult {
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveResult
     */
    recipient: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveResult
     */
    sender: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveResult
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveResult
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof RelevesServiceSendReleveResult
     */
    familyId: number;
    /**
     * 
     * @type {number}
     * @memberof RelevesServiceSendReleveResult
     */
    releveId: number;
}


  
/**
 * 
 * @export
 * @interface RelevesServiceSendReleveSVPL
 */
export interface RelevesServiceSendReleveSVPL {
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveSVPL
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveSVPL
     */
    recipient?: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveSVPL
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof RelevesServiceSendReleveSVPL
     */
    html?: string;
    /**
     * 
     * @type {number}
     * @memberof RelevesServiceSendReleveSVPL
     */
    releveId: number;
}


  
/**
 * 
 * @export
 * @interface ResyncPontoAccounts200Response
 */
export interface ResyncPontoAccounts200Response {
    /**
     * 
     * @type {number}
     * @memberof ResyncPontoAccounts200Response
     */
    updatedRowCount: number;
    /**
     * 
     * @type {number}
     * @memberof ResyncPontoAccounts200Response
     */
    insertedRowCount: number;
}


  
/**
 * 
 * @export
 * @interface RingoverServiceContactSearchItem
 */
export interface RingoverServiceContactSearchItem {
    /**
     * 
     * @type {Array<RingoverServiceNumberObj>}
     * @memberof RingoverServiceContactSearchItem
     */
    numbers: Array<RingoverServiceNumberObj>;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceContactSearchItem
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceContactSearchItem
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceContactSearchItem
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceContactSearchItem
     */
    firstname: string;
}


  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactEventOutput
 */
export interface RingoverServiceHandleContactEventOutput {
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    is_shared: RingoverServiceHandleContactEventOutputIsSharedEnum;
    /**
     * 
     * @type {RecordStringStringOrNumber}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    data: RecordStringStringOrNumber;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventOutput
     */
    uuid: string;
}

/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactEventOutputIsSharedEnumEntries = {
    0: 0,
    1: 1
} as const
export type RingoverServiceHandleContactEventOutputIsSharedEnum = typeof RingoverServiceHandleContactEventOutputIsSharedEnumEntries[keyof typeof RingoverServiceHandleContactEventOutputIsSharedEnumEntries]



  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactEventPayload
 */
export interface RingoverServiceHandleContactEventPayload {
    /**
     * 
     * @type {RingoverServiceHandleContactEventPayloadData}
     * @memberof RingoverServiceHandleContactEventPayload
     */
    data: RingoverServiceHandleContactEventPayloadData;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceHandleContactEventPayload
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventPayload
     */
    resource: RingoverServiceHandleContactEventPayloadResourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventPayload
     */
    event: RingoverServiceHandleContactEventPayloadEventEnum;
}

/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactEventPayloadResourceEnumEntries = {
    'call': 'call'
} as const
export type RingoverServiceHandleContactEventPayloadResourceEnum = typeof RingoverServiceHandleContactEventPayloadResourceEnumEntries[keyof typeof RingoverServiceHandleContactEventPayloadResourceEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactEventPayloadEventEnumEntries = {
    'contact': 'contact'
} as const
export type RingoverServiceHandleContactEventPayloadEventEnum = typeof RingoverServiceHandleContactEventPayloadEventEnumEntries[keyof typeof RingoverServiceHandleContactEventPayloadEventEnumEntries]



  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactEventPayloadData
 */
export interface RingoverServiceHandleContactEventPayloadData {
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventPayloadData
     */
    to_number: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventPayloadData
     */
    from_number: string;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactEventPayloadData
     */
    direction: RingoverServiceHandleContactEventPayloadDataDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceHandleContactEventPayloadData
     */
    call_id: number;
}

/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactEventPayloadDataDirectionEnumEntries = {
    'inbound': 'inbound',
    'outbound': 'outbound'
} as const
export type RingoverServiceHandleContactEventPayloadDataDirectionEnum = typeof RingoverServiceHandleContactEventPayloadDataDirectionEnumEntries[keyof typeof RingoverServiceHandleContactEventPayloadDataDirectionEnumEntries]



  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactSearchEventOutput
 */
export interface RingoverServiceHandleContactSearchEventOutput extends Array<RingoverServiceContactSearchItem> {
}


  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactSearchEventPayload
 */
export interface RingoverServiceHandleContactSearchEventPayload {
    /**
     * 
     * @type {RingoverServiceHandleContactSearchEventPayloadData}
     * @memberof RingoverServiceHandleContactSearchEventPayload
     */
    data: RingoverServiceHandleContactSearchEventPayloadData;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceHandleContactSearchEventPayload
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactSearchEventPayload
     */
    ressource: RingoverServiceHandleContactSearchEventPayloadRessourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactSearchEventPayload
     */
    event: RingoverServiceHandleContactSearchEventPayloadEventEnum;
}

/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactSearchEventPayloadRessourceEnumEntries = {
    'search': 'search'
} as const
export type RingoverServiceHandleContactSearchEventPayloadRessourceEnum = typeof RingoverServiceHandleContactSearchEventPayloadRessourceEnumEntries[keyof typeof RingoverServiceHandleContactSearchEventPayloadRessourceEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const RingoverServiceHandleContactSearchEventPayloadEventEnumEntries = {
    'contact': 'contact'
} as const
export type RingoverServiceHandleContactSearchEventPayloadEventEnum = typeof RingoverServiceHandleContactSearchEventPayloadEventEnumEntries[keyof typeof RingoverServiceHandleContactSearchEventPayloadEventEnumEntries]



  
/**
 * 
 * @export
 * @interface RingoverServiceHandleContactSearchEventPayloadData
 */
export interface RingoverServiceHandleContactSearchEventPayloadData {
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceHandleContactSearchEventPayloadData
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof RingoverServiceHandleContactSearchEventPayloadData
     */
    query_search: string;
}


  
/**
 * 
 * @export
 * @interface RingoverServiceNumberObj
 */
export interface RingoverServiceNumberObj {
    /**
     * 
     * @type {RingoverServiceNumberType}
     * @memberof RingoverServiceNumberObj
     */
    type: RingoverServiceNumberType;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceNumberObj
     */
    number: number;
}





/**
 * 
 * @export
 * @enum {string}
 */

export const RingoverServiceNumberTypeEntries = {
    'home': 'home',
    'office': 'office',
    'mobile': 'mobile',
    'fax': 'fax',
    'other': 'other'
} as const
export type RingoverServiceNumberType = typeof RingoverServiceNumberTypeEntries[keyof typeof RingoverServiceNumberTypeEntries]

  
/**
 * 
 * @export
 * @interface RingoverServiceSyncPhoneCallsOutput
 */
export interface RingoverServiceSyncPhoneCallsOutput {
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceSyncPhoneCallsOutput
     */
    new_last_id_returned: number | null;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceSyncPhoneCallsOutput
     */
    previous_last_id_returned: number | null;
    /**
     * 
     * @type {Date}
     * @memberof RingoverServiceSyncPhoneCallsOutput
     */
    newMaxAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RingoverServiceSyncPhoneCallsOutput
     */
    previousMaxAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RingoverServiceSyncPhoneCallsOutput
     */
    count: number;
}


  
/**
 * 
 * @export
 * @interface SendTeacherProposition200Response
 */
export interface SendTeacherProposition200Response {
    /**
     * 
     * @type {number}
     * @memberof SendTeacherProposition200Response
     */
    propositionId: number;
}


  
/**
 * 
 * @export
 * @interface SessionServiceAcceptSessionPayload
 */
export interface SessionServiceAcceptSessionPayload {
    /**
     * 
     * @type {Date}
     * @memberof SessionServiceAcceptSessionPayload
     */
    deferEmailAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SessionServiceAcceptSessionPayload
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof SessionServiceAcceptSessionPayload
     */
    currentUserId: number;
}


  
/**
 * 
 * @export
 * @interface SessionServiceRefuseSessionPayload
 */
export interface SessionServiceRefuseSessionPayload {
    /**
     * 
     * @type {Date}
     * @memberof SessionServiceRefuseSessionPayload
     */
    deferEmailAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SessionServiceRefuseSessionPayload
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof SessionServiceRefuseSessionPayload
     */
    currentUserId: number;
}


  
/**
 * 
 * @export
 * @interface SimulatePayslip200Response
 */
export interface SimulatePayslip200Response {
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    cotisTot: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    allegements: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    cotisP: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    cotisS: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    brutTot: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    netTot: number;
    /**
     * 
     * @type {number}
     * @memberof SimulatePayslip200Response
     */
    remTot: number;
    /**
     * 
     * @type {Rates}
     * @memberof SimulatePayslip200Response
     */
    rates: Rates;
}


  
/**
 * 
 * @export
 * @interface SlackCtrlNotifyErrorBody
 */
export interface SlackCtrlNotifyErrorBody {
    /**
     * 
     * @type {any}
     * @memberof SlackCtrlNotifyErrorBody
     */
    context?: any;
    /**
     * 
     * @type {any}
     * @memberof SlackCtrlNotifyErrorBody
     */
    error: any;
    /**
     * 
     * @type {string}
     * @memberof SlackCtrlNotifyErrorBody
     */
    source: string;
}


  
/**
 * 
 * @export
 * @interface SoldeOn200Response
 */
export interface SoldeOn200Response {
    /**
     * 
     * @type {number}
     * @memberof SoldeOn200Response
     */
    aiciPaid: number;
    /**
     * 
     * @type {number}
     * @memberof SoldeOn200Response
     */
    cesuPaid: number;
    /**
     * 
     * @type {number}
     * @memberof SoldeOn200Response
     */
    anyPaid: number;
    /**
     * 
     * @type {number}
     * @memberof SoldeOn200Response
     */
    invoiced: number;
}


  
/**
 * 
 * @export
 * @interface StandardInvoiceCkeckInfo
 */
export interface StandardInvoiceCkeckInfo {
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    finEmploiModified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    debutEmploiModified: boolean;
    /**
     * 
     * @type {string}
     * @memberof StandardInvoiceCkeckInfo
     */
    lastLessonOn: string;
    /**
     * 
     * @type {string}
     * @memberof StandardInvoiceCkeckInfo
     */
    firstLessonOn: string;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    notFullyPrepaid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasDraftPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasSucceededPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasPendingPaymentRequest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    aiciActivationNotOnTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    aiciActivationNotConfirmed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasZeroNegativeAmount: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasNoCredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasMixedCredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasAICICredits: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StandardInvoiceCkeckInfo
     */
    hasCIDiffCredits: boolean;
}


  
/**
 * 
 * @export
 * @interface StartCustomerProposition200Response
 */
export interface StartCustomerProposition200Response {
    /**
     * 
     * @type {number}
     * @memberof StartCustomerProposition200Response
     */
    failures: number;
    /**
     * 
     * @type {number}
     * @memberof StartCustomerProposition200Response
     */
    success: number;
}


  
/**
 * 
 * @export
 * @interface StorefrontProspectDataServiceConciseLevelDTO
 */
export interface StorefrontProspectDataServiceConciseLevelDTO {
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    LevelGroup?: number;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    orderNb?: number;
    /**
     * 
     * @type {string}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    nameShort: string;
    /**
     * 
     * @type {string}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    nameLong: string;
    /**
     * 
     * @type {boolean}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    isActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceConciseLevelDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface StorefrontProspectDataServiceMainCityDTO
 */
export interface StorefrontProspectDataServiceMainCityDTO {
    /**
     * 
     * @type {string}
     * @memberof StorefrontProspectDataServiceMainCityDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceMainCityDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface StorefrontProspectDataServiceTeachingWithConciseLevels
 */
export interface StorefrontProspectDataServiceTeachingWithConciseLevels {
    /**
     * 
     * @type {Array<StorefrontProspectDataServiceConciseLevelDTO>}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    ConciseLevels: Array<StorefrontProspectDataServiceConciseLevelDTO>;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    taux?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    aiciCodeNature?: string;
    /**
     * 
     * @type {string}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    TeachingGroup: number;
    /**
     * 
     * @type {Date}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof StorefrontProspectDataServiceTeachingWithConciseLevels
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface StorefrontProspectDataServiceTeachingsWithConciseLevels
 */
export interface StorefrontProspectDataServiceTeachingsWithConciseLevels extends Array<StorefrontProspectDataServiceTeachingWithConciseLevels> {
}


  
/**
 * 
 * @export
 * @interface StorefrontTeacherLoggingServiceSigninSVPL
 */
export interface StorefrontTeacherLoggingServiceSigninSVPL {
    /**
     * 
     * @type {string}
     * @memberof StorefrontTeacherLoggingServiceSigninSVPL
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof StorefrontTeacherLoggingServiceSigninSVPL
     */
    email: string;
}


  
/**
 * 
 * @export
 * @interface SubmitFastLesson200Response
 */
export interface SubmitFastLesson200Response {
    /**
     * 
     * @type {number}
     * @memberof SubmitFastLesson200Response
     */
    lessonId: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitFastLesson200Response
     */
    teacherId: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitFastLesson200Response
     */
    matchId: number;
}


  
/**
 * 
 * @export
 * @interface SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload
 */
export interface SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload {
    /**
     * 
     * @type {AICIMode}
     * @memberof SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload
     */
    toAICIMode: AICIMode;
    /**
     * 
     * @type {number}
     * @memberof SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload
     */
    creditInvoiceId: number;
}




  
/**
 * 
 * @export
 * @interface Sync200Response
 */
export interface Sync200Response {
    /**
     * 
     * @type {number}
     * @memberof Sync200Response
     */
    fresh: number;
    /**
     * 
     * @type {number}
     * @memberof Sync200Response
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof Sync200Response
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof Sync200Response
     */
    created: number;
}


  
/**
 * 
 * @export
 * @interface SyncContacts200Response
 */
export interface SyncContacts200Response {
    /**
     * 
     * @type {number}
     * @memberof SyncContacts200Response
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof SyncContacts200Response
     */
    updated: number;
}


  
/**
 * 
 * @export
 * @interface SyncGocardlessPayoutsAndPayments200Response
 */
export interface SyncGocardlessPayoutsAndPayments200Response {
    /**
     * 
     * @type {number}
     * @memberof SyncGocardlessPayoutsAndPayments200Response
     */
    createdPayoutsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncGocardlessPayoutsAndPayments200Response
     */
    createdPaymentsCount: number;
}


  
/**
 * 
 * @export
 * @interface SyncPlannedCallFromGoogleCalendar200Response
 */
export interface SyncPlannedCallFromGoogleCalendar200Response {
    /**
     * 
     * @type {string}
     * @memberof SyncPlannedCallFromGoogleCalendar200Response
     */
    lastSync: string;
    /**
     * 
     * @type {number}
     * @memberof SyncPlannedCallFromGoogleCalendar200Response
     */
    deletedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SyncPlannedCallFromGoogleCalendar200Response
     */
    updatedCount: number;
}


  
/**
 * 
 * @export
 * @interface SyncPontoPayment200Response
 */
export interface SyncPontoPayment200Response {
    /**
     * 
     * @type {PontoGetBulkPaymentResponseData}
     * @memberof SyncPontoPayment200Response
     */
    data: PontoGetBulkPaymentResponseData;
}


  
/**
 * 
 * @export
 * @interface TeacherLessonPropositionServiceCreateSVPL
 */
export interface TeacherLessonPropositionServiceCreateSVPL {
    /**
     * 
     * @type {number}
     * @memberof TeacherLessonPropositionServiceCreateSVPL
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherLessonPropositionServiceCreateSVPL
     */
    durationMinPlanned: number;
    /**
     * 
     * @type {Date}
     * @memberof TeacherLessonPropositionServiceCreateSVPL
     */
    startedAtPlanned: Date;
}


  
/**
 * 
 * @export
 * @interface TeacherLoggingServiceResendPasswordSVPL
 */
export interface TeacherLoggingServiceResendPasswordSVPL {
    /**
     * 
     * @type {string}
     * @memberof TeacherLoggingServiceResendPasswordSVPL
     */
    email: string;
}


  
/**
 * 
 * @export
 * @interface TeacherLoggingServiceSigninSVPL
 */
export interface TeacherLoggingServiceSigninSVPL {
    /**
     * 
     * @type {string}
     * @memberof TeacherLoggingServiceSigninSVPL
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherLoggingServiceSigninSVPL
     */
    email: string;
}


  
/**
 * 
 * @export
 * @interface TeachersAppCtrlCounterproposeLessonPropositionBody
 */
export interface TeachersAppCtrlCounterproposeLessonPropositionBody {
    /**
     * 
     * @type {number}
     * @memberof TeachersAppCtrlCounterproposeLessonPropositionBody
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof TeachersAppCtrlCounterproposeLessonPropositionBody
     */
    durationMinPlanned: number;
    /**
     * 
     * @type {Date}
     * @memberof TeachersAppCtrlCounterproposeLessonPropositionBody
     */
    startedAtPlanned: Date;
    /**
     * 
     * @type {string}
     * @memberof TeachersAppCtrlCounterproposeLessonPropositionBody
     */
    abortComment: string;
}


  
/**
 * 
 * @export
 * @interface TeachersAppCtrlReproposeLessonPropositionBody
 */
export interface TeachersAppCtrlReproposeLessonPropositionBody {
    /**
     * 
     * @type {number}
     * @memberof TeachersAppCtrlReproposeLessonPropositionBody
     */
    matchId: number;
    /**
     * 
     * @type {number}
     * @memberof TeachersAppCtrlReproposeLessonPropositionBody
     */
    durationMinPlanned: number;
    /**
     * 
     * @type {Date}
     * @memberof TeachersAppCtrlReproposeLessonPropositionBody
     */
    startedAtPlanned: Date;
    /**
     * 
     * @type {string}
     * @memberof TeachersAppCtrlReproposeLessonPropositionBody
     */
    abortComment: string;
}


  
/**
 * 
 * @export
 * @interface TeachersAppCtrlSlackNotificationBody
 */
export interface TeachersAppCtrlSlackNotificationBody {
    /**
     * 
     * @type {string}
     * @memberof TeachersAppCtrlSlackNotificationBody
     */
    channel: string;
    /**
     * 
     * @type {string}
     * @memberof TeachersAppCtrlSlackNotificationBody
     */
    message?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof TeachersAppCtrlSlackNotificationBody
     */
    blocks?: Array<any>;
}


  
/**
 * 
 * @export
 * @interface TeachingsServiceTeachingDTO
 */
export interface TeachingsServiceTeachingDTO {
    /**
     * 
     * @type {number}
     * @memberof TeachingsServiceTeachingDTO
     */
    TeachingGroup: number;
    /**
     * 
     * @type {string}
     * @memberof TeachingsServiceTeachingDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeachingsServiceTeachingDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface TeachingsWithConciseLevelsServiceConciseLevelDTO
 */
export interface TeachingsWithConciseLevelsServiceConciseLevelDTO {
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    LevelGroup?: number;
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    orderNb?: number;
    /**
     * 
     * @type {string}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    nameShort: string;
    /**
     * 
     * @type {string}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    nameLong: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    isActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceConciseLevelDTO
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
 */
export interface TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem {
    /**
     * 
     * @type {Array<TeachingsWithConciseLevelsServiceConciseLevelDTO>}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    ConciseLevels: Array<TeachingsWithConciseLevelsServiceConciseLevelDTO>;
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    taux?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    aiciCodeNature?: string;
    /**
     * 
     * @type {string}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    TeachingGroup: number;
    /**
     * 
     * @type {Date}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem
     */
    id: number;
}


  
/**
 * 
 * @export
 * @interface TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsOutput
 */
export interface TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsOutput extends Array<TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsItem> {
}


  
/**
 * 
 * @export
 * @interface TransferCredits200Response
 */
export interface TransferCredits200Response {
    /**
     * 
     * @type {number}
     * @memberof TransferCredits200Response
     */
    invoiceId: number;
}


  
/**
 * 
 * @export
 * @interface TransferCreditsServiceToggleTransferDiscountPayload
 */
export interface TransferCreditsServiceToggleTransferDiscountPayload {
    /**
     * 
     * @type {boolean}
     * @memberof TransferCreditsServiceToggleTransferDiscountPayload
     */
    withDiscount: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransferCreditsServiceToggleTransferDiscountPayload
     */
    creditInvoiceId: number;
}


  
/**
 * 
 * @export
 * @interface TransferCreditsServiceTransferCreditsPayload
 */
export interface TransferCreditsServiceTransferCreditsPayload {
    /**
     * 
     * @type {string}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    emailText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    skipSending: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    withDiscount: boolean;
    /**
     * 
     * @type {AICIMode}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    aiciMode: AICIMode;
    /**
     * 
     * @type {number}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    targetMatchId: number;
    /**
     * 
     * @type {number}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    sourceMatchId: number;
    /**
     * 
     * @type {number}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    familyId: number;
    /**
     * 
     * @type {number}
     * @memberof TransferCreditsServiceTransferCreditsPayload
     */
    transferCount: number;
}




  
/**
 * 
 * @export
 * @interface UpdateDeclarationFromPayslipsPayload
 */
export interface UpdateDeclarationFromPayslipsPayload {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    comment: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    trimester: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    neantFamilyIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    payslipIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeclarationFromPayslipsPayload
     */
    globalDeclarationId: number;
}


  
/**
 * 
 * @export
 * @interface UsersSigninParams
 */
export interface UsersSigninParams {
    /**
     * 
     * @type {string}
     * @memberof UsersSigninParams
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSigninParams
     */
    email: string;
}


  
/**
 * 
 * @export
 * @interface UsersSignupParams
 */
export interface UsersSignupParams {
    /**
     * 
     * @type {string}
     * @memberof UsersSignupParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignupParams
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UsersSignupParams
     */
    username: string;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutput
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutput {
    /**
     * 
     * @type {ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutput
     */
    currentProposition: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition | null;
    /**
     * 
     * @type {boolean}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutput
     */
    noCurrentProposition: boolean;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition {
    /**
     * 
     * @type {ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    invoice: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice;
    /**
     * 
     * @type {ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    yousign: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign;
    /**
     * 
     * @type {boolean}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    skipYs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    isForced: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    validationFlowTokenIAT: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    linkClickedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    cancelReason: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    cancelledAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    completedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    sentAt: Date | null;
    /**
     * 
     * @type {CustomerPropositionStatus}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    status: CustomerPropositionStatus;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentProposition
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice {
    /**
     * 
     * @type {Array<ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner>}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    blocks: Array<ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner>;
    /**
     * 
     * @type {boolean}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    allowAltPaymentMethods: boolean;
    /**
     * 
     * @type {Array<DryCreditInvoiceDraftEcheancesInner>}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    draftEcheances: Array<DryCreditInvoiceDraftEcheancesInner>;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisSentAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisReferenceNb: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisReference: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisExpireOn: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisCreatedOn: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisAnsweredAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    devisAbortedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    totalPriceTTCWhole: number | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    totTVA: number | null;
    /**
     * 
     * @type {CreditInvoiceStatus}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    status: CreditInvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoice
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner {
    /**
     * 
     * @type {Array<ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner>}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner
     */
    credits: Array<ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner>;
    /**
     * 
     * @type {ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner
     */
    block: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock;
    /**
     * 
     * @type {ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInner
     */
    match: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock {
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    creationCount: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    blockPriceTTCWhole: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    basePrice: number;
    /**
     * 
     * @type {AICIMode}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    aiciMode: AICIMode | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerBlock
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner {
    /**
     * 
     * @type {CreditStatus}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    status: CreditStatus;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    reference: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    currentPriceWhole: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    basePrice: number;
    /**
     * 
     * @type {AICIMode}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    aiciMode: AICIMode | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerCreditsInner
     */
    id: number;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch {
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    learnerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    learnerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    learnerGender: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchLearnerGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    teacherLastName: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherLastNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    teacherFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    teacherGender: ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    teacherId: number;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatch
     */
    id: number;
}

/**
    * @export
    * @enum {string}
    */
export const ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchLearnerGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchLearnerGenderEnum = typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchLearnerGenderEnumEntries[keyof typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchLearnerGenderEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherLastNameEnumEntries = {
    null: null
} as const
export type ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherLastNameEnum = typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherLastNameEnumEntries[keyof typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherLastNameEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherGenderEnum = typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherGenderEnumEntries[keyof typeof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionInvoiceBlocksInnerMatchTeacherGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
 */
export interface ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign {
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    finishedAt: Date | null;
    /**
     * 
     * @type {YsProcStatus}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    status: YsProcStatus | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    sentAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    ysLink: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    ysKey: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCurrentProcedureDataOutputCurrentPropositionYousign
     */
    procId: number | null;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutput
 */
export interface ValidationProcessServiceGetCustomerInfosOutput {
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputGocardless}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    gocardless: ValidationProcessServiceGetCustomerInfosOutputGocardless;
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputContracts}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    contracts: ValidationProcessServiceGetCustomerInfosOutputContracts;
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputAici}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    aici: ValidationProcessServiceGetCustomerInfosOutputAici;
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputChief}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    chief: ValidationProcessServiceGetCustomerInfosOutputChief;
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputContact}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    contact: ValidationProcessServiceGetCustomerInfosOutputContact;
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputFacturationAddress}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    facturationAddress: ValidationProcessServiceGetCustomerInfosOutputFacturationAddress;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    bic: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    validationFlowTokenIAT: Date;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    validationFlowStartedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    activatedAt: Date;
    /**
     * 
     * @type {FamilyCommercialStep}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    commercialStep: FamilyCommercialStep;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutput
     */
    familyId: number;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputAici
 */
export interface ValidationProcessServiceGetCustomerInfosOutputAici {
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputAici
     */
    aiciClientUUID: string;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputAici
     */
    aiciActivationTriedAt: Date | null;
    /**
     * 
     * @type {RecordStringAny}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputAici
     */
    aiciActivationError: RecordStringAny | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputAici
     */
    aiciActivationConfirmedAt: Date | null;
    /**
     * 
     * @type {AICIMode}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputAici
     */
    aiciMode: AICIMode | null;
}




  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputChief
 */
export interface ValidationProcessServiceGetCustomerInfosOutputChief {
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthDpt: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthCountryCode: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthCityCode: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthCityName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    birthName: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    gender: ValidationProcessServiceGetCustomerInfosOutputChiefGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputChief
     */
    memberId: number;
}

/**
    * @export
    * @enum {string}
    */
export const ValidationProcessServiceGetCustomerInfosOutputChiefGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type ValidationProcessServiceGetCustomerInfosOutputChiefGenderEnum = typeof ValidationProcessServiceGetCustomerInfosOutputChiefGenderEnumEntries[keyof typeof ValidationProcessServiceGetCustomerInfosOutputChiefGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputContact
 */
export interface ValidationProcessServiceGetCustomerInfosOutputContact {
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContact
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContact
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContact
     */
    gender: ValidationProcessServiceGetCustomerInfosOutputContactGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContact
     */
    memberId: number;
}

/**
    * @export
    * @enum {string}
    */
export const ValidationProcessServiceGetCustomerInfosOutputContactGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type ValidationProcessServiceGetCustomerInfosOutputContactGenderEnum = typeof ValidationProcessServiceGetCustomerInfosOutputContactGenderEnumEntries[keyof typeof ValidationProcessServiceGetCustomerInfosOutputContactGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputContracts
 */
export interface ValidationProcessServiceGetCustomerInfosOutputContracts {
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContracts
     */
    sepaSignedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContracts
     */
    cgsSignedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputContracts
     */
    mandateSignedAt: Date | null;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
 */
export interface ValidationProcessServiceGetCustomerInfosOutputFacturationAddress {
    /**
     * 
     * @type {ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    coordinates: ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    complement: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    cityCode: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    cityName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    streetTypeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    streetTypeCode: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    streetName: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    letter: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    fullStreet: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddress
     */
    addressId: number;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates
 */
export interface ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates {
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates
     */
    lng: number;
    /**
     * 
     * @type {number}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputFacturationAddressCoordinates
     */
    lat: number;
}


  
/**
 * 
 * @export
 * @interface ValidationProcessServiceGetCustomerInfosOutputGocardless
 */
export interface ValidationProcessServiceGetCustomerInfosOutputGocardless {
    /**
     * 
     * @type {Date}
     * @memberof ValidationProcessServiceGetCustomerInfosOutputGocardless
     */
    mandateSignedAt: Date | null;
}


  
/**
 * 
 * @export
 * @interface WixPartenairesServiceInsertBusinessContactPayload
 */
export interface WixPartenairesServiceInsertBusinessContactPayload {
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    richComment?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    siret?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    teachingId?: number;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    teachingManual?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    registrationType?: WixPartenairesServiceInsertBusinessContactPayloadRegistrationTypeEnum;
    /**
     * 
     * @type {Array<WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner>}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    customQuestions?: Array<WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner>;
    /**
     * 
     * @type {number}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    wishedFrequency?: number;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    wishedPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    gender?: WixPartenairesServiceInsertBusinessContactPayloadGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    entryPoint?: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayload
     */
    entityName: string;
}

/**
    * @export
    * @enum {string}
    */
export const WixPartenairesServiceInsertBusinessContactPayloadRegistrationTypeEnumEntries = {
    'coll': 'coll',
    'comp': 'comp'
} as const
export type WixPartenairesServiceInsertBusinessContactPayloadRegistrationTypeEnum = typeof WixPartenairesServiceInsertBusinessContactPayloadRegistrationTypeEnumEntries[keyof typeof WixPartenairesServiceInsertBusinessContactPayloadRegistrationTypeEnumEntries]
/**
    * @export
    * @enum {string}
    */
export const WixPartenairesServiceInsertBusinessContactPayloadGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type WixPartenairesServiceInsertBusinessContactPayloadGenderEnum = typeof WixPartenairesServiceInsertBusinessContactPayloadGenderEnumEntries[keyof typeof WixPartenairesServiceInsertBusinessContactPayloadGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner
 */
export interface WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner {
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof WixPartenairesServiceInsertBusinessContactPayloadCustomQuestionsInner
     */
    answer: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateCompleteParams
 */
export interface WixTeachersPostulateCompleteParams {
    /**
     * 
     * @type {boolean}
     * @memberof WixTeachersPostulateCompleteParams
     */
    dispoNextYear: boolean;
    /**
     * 
     * @type {RegisterProspect200Response}
     * @memberof WixTeachersPostulateCompleteParams
     */
    Candidature: RegisterProspect200Response;
    /**
     * 
     * @type {Array<WixTeachersPostulateCompleteParamsAddressesInner>}
     * @memberof WixTeachersPostulateCompleteParams
     */
    Addresses: Array<WixTeachersPostulateCompleteParamsAddressesInner>;
    /**
     * 
     * @type {boolean}
     * @memberof WixTeachersPostulateCompleteParams
     */
    hasUnstableWeekDispos: boolean;
    /**
     * 
     * @type {Array<WixTeachersPostulateCompleteParamsWeekDisposInner>}
     * @memberof WixTeachersPostulateCompleteParams
     */
    WeekDispos: Array<WixTeachersPostulateCompleteParamsWeekDisposInner>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    numSecu: string;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateCompleteParams
     */
    StudyLevel: number;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    scolarityComplementManual?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    scolarityMainManual?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateCompleteParams
     */
    ScolarityComplement: number | null;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateCompleteParams
     */
    ScolarityMain: number | null;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    birthCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    birthCity: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    birthDate: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParams
     */
    firstName: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateCompleteParamsAddressesInner
 */
export interface WixTeachersPostulateCompleteParamsAddressesInner {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParamsAddressesInner
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParamsAddressesInner
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParamsAddressesInner
     */
    city: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateCompleteParamsWeekDisposInner
 */
export interface WixTeachersPostulateCompleteParamsWeekDisposInner {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParamsWeekDisposInner
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateCompleteParamsWeekDisposInner
     */
    startTime: string;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateCompleteParamsWeekDisposInner
     */
    dayId: number;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateFastParams
 */
export interface WixTeachersPostulateFastParams {
    /**
     * 
     * @type {boolean}
     * @memberof WixTeachersPostulateFastParams
     */
    hasUnstableWeekDispos: boolean;
    /**
     * 
     * @type {Array<WixTeachersPostulateCompleteParamsWeekDisposInner>}
     * @memberof WixTeachersPostulateFastParams
     */
    WeekDispos: Array<WixTeachersPostulateCompleteParamsWeekDisposInner>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    numSecu: string;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateFastParams
     */
    StudyLevel: number;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    scolarityComplementManual?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    scolarityMainManual?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateFastParams
     */
    ScolarityComplement: number | null;
    /**
     * 
     * @type {number}
     * @memberof WixTeachersPostulateFastParams
     */
    ScolarityMain: number | null;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    birthCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    birthCity: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    birthDate: string;
    /**
     * 
     * @type {Array<WixTeachersPostulateParamsYearDisposInner>}
     * @memberof WixTeachersPostulateFastParams
     */
    YearDispos: Array<WixTeachersPostulateParamsYearDisposInner>;
    /**
     * 
     * @type {boolean}
     * @memberof WixTeachersPostulateFastParams
     */
    dispoNextYear: boolean;
    /**
     * 
     * @type {WixTeachersPostulateFastParamsCandidature}
     * @memberof WixTeachersPostulateFastParams
     */
    Candidature: WixTeachersPostulateFastParamsCandidature;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    wishVisio: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WixTeachersPostulateFastParams
     */
    Mobilities: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    wishedStudentsNb: string;
    /**
     * 
     * @type {RegisterProspect200Response}
     * @memberof WixTeachersPostulateFastParams
     */
    MainCity: RegisterProspect200Response;
    /**
     * 
     * @type {Array<WixTeachersPostulateCompleteParamsAddressesInner>}
     * @memberof WixTeachersPostulateFastParams
     */
    Addresses: Array<WixTeachersPostulateCompleteParamsAddressesInner>;
    /**
     * 
     * @type {Array<WixTeachersPostulateFastParamsPhoneNumbersInner>}
     * @memberof WixTeachersPostulateFastParams
     */
    PhoneNumbers: Array<WixTeachersPostulateFastParamsPhoneNumbersInner>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParams
     */
    gender: WixTeachersPostulateFastParamsGenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export const WixTeachersPostulateFastParamsGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type WixTeachersPostulateFastParamsGenderEnum = typeof WixTeachersPostulateFastParamsGenderEnumEntries[keyof typeof WixTeachersPostulateFastParamsGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface WixTeachersPostulateFastParamsCandidature
 */
export interface WixTeachersPostulateFastParamsCandidature {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParamsCandidature
     */
    otherComment?: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParamsCandidature
     */
    weekDisposComment: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParamsCandidature
     */
    levelsComment: string;
    /**
     * \"Anglais, Maths\"
     * @type {string}
     * @memberof WixTeachersPostulateFastParamsCandidature
     */
    teachingsComment: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateFastParamsPhoneNumbersInner
 */
export interface WixTeachersPostulateFastParamsPhoneNumbersInner {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateFastParamsPhoneNumbersInner
     */
    number: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateParams
 */
export interface WixTeachersPostulateParams {
    /**
     * 
     * @type {Array<WixTeachersPostulateParamsYearDisposInner>}
     * @memberof WixTeachersPostulateParams
     */
    YearDispos: Array<WixTeachersPostulateParamsYearDisposInner>;
    /**
     * 
     * @type {WixTeachersPostulateParamsCandidature}
     * @memberof WixTeachersPostulateParams
     */
    Candidature: WixTeachersPostulateParamsCandidature;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    wishVisio: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WixTeachersPostulateParams
     */
    Mobilities: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    wishedStudentsNb: string;
    /**
     * 
     * @type {RegisterProspect200Response}
     * @memberof WixTeachersPostulateParams
     */
    MainCity: RegisterProspect200Response;
    /**
     * 
     * @type {Array<WixTeachersPostulateParamsPhoneNumbersInner>}
     * @memberof WixTeachersPostulateParams
     */
    PhoneNumbers: Array<WixTeachersPostulateParamsPhoneNumbersInner>;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParams
     */
    gender: WixTeachersPostulateParamsGenderEnum;
}

/**
    * @export
    * @enum {string}
    */
export const WixTeachersPostulateParamsGenderEnumEntries = {
    'M': 'M',
    'F': 'F'
} as const
export type WixTeachersPostulateParamsGenderEnum = typeof WixTeachersPostulateParamsGenderEnumEntries[keyof typeof WixTeachersPostulateParamsGenderEnumEntries]



  
/**
 * 
 * @export
 * @interface WixTeachersPostulateParamsCandidature
 */
export interface WixTeachersPostulateParamsCandidature {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsCandidature
     */
    otherComment?: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsCandidature
     */
    weekDisposComment: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsCandidature
     */
    levelsComment: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsCandidature
     */
    teachingsComment: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsCandidature
     */
    activityComment: string;
}


  
/**
 * 
 * @export
 * @interface WixTeachersPostulateParamsPhoneNumbersInner
 */
export interface WixTeachersPostulateParamsPhoneNumbersInner {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsPhoneNumbersInner
     */
    number: string;
    /**
     * 
     * @type {boolean}
     * @memberof WixTeachersPostulateParamsPhoneNumbersInner
     */
    isMain: WixTeachersPostulateParamsPhoneNumbersInnerIsMainEnum;
}

/**
    * @export
    * @enum {string}
    */
export const WixTeachersPostulateParamsPhoneNumbersInnerIsMainEnumEntries = {
    true: true
} as const
export type WixTeachersPostulateParamsPhoneNumbersInnerIsMainEnum = typeof WixTeachersPostulateParamsPhoneNumbersInnerIsMainEnumEntries[keyof typeof WixTeachersPostulateParamsPhoneNumbersInnerIsMainEnumEntries]



  
/**
 * 
 * @export
 * @interface WixTeachersPostulateParamsYearDisposInner
 */
export interface WixTeachersPostulateParamsYearDisposInner {
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsYearDisposInner
     */
    endOn: string;
    /**
     * 
     * @type {string}
     * @memberof WixTeachersPostulateParamsYearDisposInner
     */
    startOn: string;
}



/**
 * 
 * @export
 * @enum {string}
 */

export const YsProcStatusEntries = {
    'DRAFT': 'DRAFT',
    'PENDING': 'PENDING',
    'SIGNED': 'SIGNED',
    'REFUSED': 'REFUSED',
    'FORCED': 'FORCED',
    'ABORTED': 'ABORTED'
} as const
export type YsProcStatus = typeof YsProcStatusEntries[keyof typeof YsProcStatusEntries]


/**
 * AICIApi - axios parameter creator
 * @export
 */
export const AICIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('activate', 'familyId', familyId)
            const localVarPath = `/aici/activate/{familyId}`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} startInvoicesOn 
         * @param {string} stopInvoicesOn 
         * @param {boolean} [aici] 
         * @param {boolean} [cidiff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attestationAutoLines: async (familyId: number, startInvoicesOn: string, stopInvoicesOn: string, aici?: boolean, cidiff?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('attestationAutoLines', 'familyId', familyId)
            // verify required parameter 'startInvoicesOn' is not null or undefined
            assertParamExists('attestationAutoLines', 'startInvoicesOn', startInvoicesOn)
            // verify required parameter 'stopInvoicesOn' is not null or undefined
            assertParamExists('attestationAutoLines', 'stopInvoicesOn', stopInvoicesOn)
            const localVarPath = `/aici/attestation-fiscale/auto-lines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (familyId !== undefined) {
                localVarQueryParameter['familyId'] = familyId;
            }

            if (startInvoicesOn !== undefined) {
                localVarQueryParameter['startInvoicesOn'] = startInvoicesOn;
            }

            if (stopInvoicesOn !== undefined) {
                localVarQueryParameter['stopInvoicesOn'] = stopInvoicesOn;
            }

            if (aici !== undefined) {
                localVarQueryParameter['aici'] = aici;
            }

            if (cidiff !== undefined) {
                localVarQueryParameter['cidiff'] = cidiff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} legalInvoiceId 
         * @param {AICICtrlCreateFromLegalInvoiceBody} aICICtrlCreateFromLegalInvoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromLegalInvoice: async (legalInvoiceId: number, aICICtrlCreateFromLegalInvoiceBody: AICICtrlCreateFromLegalInvoiceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInvoiceId' is not null or undefined
            assertParamExists('createFromLegalInvoice', 'legalInvoiceId', legalInvoiceId)
            // verify required parameter 'aICICtrlCreateFromLegalInvoiceBody' is not null or undefined
            assertParamExists('createFromLegalInvoice', 'aICICtrlCreateFromLegalInvoiceBody', aICICtrlCreateFromLegalInvoiceBody)
            const localVarPath = `/aici/create-from-legal-invoice/{legalInvoiceId}`
                .replace(`{${"legalInvoiceId"}}`, encodeURIComponent(String(legalInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aICICtrlCreateFromLegalInvoiceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromStandardInvoice: async (standardInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardInvoiceId' is not null or undefined
            assertParamExists('createFromStandardInvoice', 'standardInvoiceId', standardInvoiceId)
            const localVarPath = `/aici/create-from-standard-invoice/{standardInvoiceId}`
                .replace(`{${"standardInvoiceId"}}`, encodeURIComponent(String(standardInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualAICIRequestServiceCreateManualAICIRequestPayload} manualAICIRequestServiceCreateManualAICIRequestPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManualAICIRequest: async (manualAICIRequestServiceCreateManualAICIRequestPayload: ManualAICIRequestServiceCreateManualAICIRequestPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualAICIRequestServiceCreateManualAICIRequestPayload' is not null or undefined
            assertParamExists('createManualAICIRequest', 'manualAICIRequestServiceCreateManualAICIRequestPayload', manualAICIRequestServiceCreateManualAICIRequestPayload)
            const localVarPath = `/aici/create-manual-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualAICIRequestServiceCreateManualAICIRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputDemandePaimentFromLegalInvoice: async (legalInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInvoiceId' is not null or undefined
            assertParamExists('getInputDemandePaimentFromLegalInvoice', 'legalInvoiceId', legalInvoiceId)
            const localVarPath = `/aici/input-demande-paiement-from-legal-invoice/{legalInvoiceId}`
                .replace(`{${"legalInvoiceId"}}`, encodeURIComponent(String(legalInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputDemandePaimentFromStandardInvoice: async (standardInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardInvoiceId' is not null or undefined
            assertParamExists('getInputDemandePaimentFromStandardInvoice', 'standardInvoiceId', standardInvoiceId)
            const localVarPath = `/aici/input-demande-paiement-from-standard-invoice/{standardInvoiceId}`
                .replace(`{${"standardInvoiceId"}}`, encodeURIComponent(String(standardInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} stopInvoicesOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soldeOn: async (familyId: number, stopInvoicesOn: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('soldeOn', 'familyId', familyId)
            // verify required parameter 'stopInvoicesOn' is not null or undefined
            assertParamExists('soldeOn', 'stopInvoicesOn', stopInvoicesOn)
            const localVarPath = `/aici/solde-on`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (familyId !== undefined) {
                localVarQueryParameter['familyId'] = familyId;
            }

            if (stopInvoicesOn !== undefined) {
                localVarQueryParameter['stopInvoicesOn'] = stopInvoicesOn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPaymentRequest: async (paymentRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentRequestId' is not null or undefined
            assertParamExists('syncPaymentRequest', 'paymentRequestId', paymentRequestId)
            const localVarPath = `/aici/sync/{paymentRequestId}`
                .replace(`{${"paymentRequestId"}}`, encodeURIComponent(String(paymentRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConfirmation: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('testConfirmation', 'familyId', familyId)
            const localVarPath = `/aici/test-confirmation/{familyId}`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AICIApi - functional programming interface
 * @export
 */
export const AICIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AICIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateCreditImpotResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} startInvoicesOn 
         * @param {string} stopInvoicesOn 
         * @param {boolean} [aici] 
         * @param {boolean} [cidiff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attestationAutoLines(familyId: number, startInvoicesOn: string, stopInvoicesOn: string, aici?: boolean, cidiff?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttestationFiscaleLine>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attestationAutoLines(familyId, startInvoicesOn, stopInvoicesOn, aici, cidiff, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} legalInvoiceId 
         * @param {AICICtrlCreateFromLegalInvoiceBody} aICICtrlCreateFromLegalInvoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFromLegalInvoice(legalInvoiceId: number, aICICtrlCreateFromLegalInvoiceBody: AICICtrlCreateFromLegalInvoiceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFromLegalInvoice200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFromLegalInvoice(legalInvoiceId, aICICtrlCreateFromLegalInvoiceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFromStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFromStandardInvoice(standardInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManualAICIRequestServiceCreateManualAICIRequestPayload} manualAICIRequestServiceCreateManualAICIRequestPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload: ManualAICIRequestServiceCreateManualAICIRequestPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateFromLegalInvoice200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputDemandePaimentFromLegalInvoice(legalInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInputDemandePaimentFromLegalInvoice200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputDemandePaimentFromLegalInvoice(legalInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputDemandePaimentFromStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInputDemandePaimentFromStandardInvoice200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputDemandePaimentFromStandardInvoice(standardInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} stopInvoicesOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soldeOn(familyId: number, stopInvoicesOn: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoldeOn200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soldeOn(familyId, stopInvoicesOn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPaymentRequest(paymentRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiciSyncServiceSyncAiciPaymentRequestOuput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPaymentRequest(paymentRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testConfirmation(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AiciActivationServiceTestConfirmationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testConfirmation(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AICIApi - factory interface
 * @export
 */
export const AICIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AICIApiFp(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(familyId: number, options?: any): AxiosPromise<ActivateCreditImpotResponse> {
            return localVarFp.activate(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} startInvoicesOn 
         * @param {string} stopInvoicesOn 
         * @param {boolean} [aici] 
         * @param {boolean} [cidiff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attestationAutoLines(familyId: number, startInvoicesOn: string, stopInvoicesOn: string, aici?: boolean, cidiff?: boolean, options?: any): AxiosPromise<Array<AttestationFiscaleLine>> {
            return localVarFp.attestationAutoLines(familyId, startInvoicesOn, stopInvoicesOn, aici, cidiff, options).then((request) => request(axios, basePath));
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} legalInvoiceId 
         * @param {AICICtrlCreateFromLegalInvoiceBody} aICICtrlCreateFromLegalInvoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromLegalInvoice(legalInvoiceId: number, aICICtrlCreateFromLegalInvoiceBody: AICICtrlCreateFromLegalInvoiceBody, options?: any): AxiosPromise<CreateFromLegalInvoice200Response> {
            return localVarFp.createFromLegalInvoice(legalInvoiceId, aICICtrlCreateFromLegalInvoiceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Errors : * PaymentRequestRejectedError
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFromStandardInvoice(standardInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.createFromStandardInvoice(standardInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualAICIRequestServiceCreateManualAICIRequestPayload} manualAICIRequestServiceCreateManualAICIRequestPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload: ManualAICIRequestServiceCreateManualAICIRequestPayload, options?: any): AxiosPromise<CreateFromLegalInvoice200Response> {
            return localVarFp.createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputDemandePaimentFromLegalInvoice(legalInvoiceId: number, options?: any): AxiosPromise<GetInputDemandePaimentFromLegalInvoice200Response> {
            return localVarFp.getInputDemandePaimentFromLegalInvoice(legalInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputDemandePaimentFromStandardInvoice(standardInvoiceId: number, options?: any): AxiosPromise<GetInputDemandePaimentFromStandardInvoice200Response> {
            return localVarFp.getInputDemandePaimentFromStandardInvoice(standardInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} stopInvoicesOn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soldeOn(familyId: number, stopInvoicesOn: string, options?: any): AxiosPromise<SoldeOn200Response> {
            return localVarFp.soldeOn(familyId, stopInvoicesOn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} paymentRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPaymentRequest(paymentRequestId: number, options?: any): AxiosPromise<AiciSyncServiceSyncAiciPaymentRequestOuput> {
            return localVarFp.syncPaymentRequest(paymentRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testConfirmation(familyId: number, options?: any): AxiosPromise<AiciActivationServiceTestConfirmationResult> {
            return localVarFp.testConfirmation(familyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AICIApi - object-oriented interface
 * @export
 * @class AICIApi
 * @extends {BaseAPI}
 */
export class AICIApi extends BaseAPI {
    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public activate(familyId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).activate(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {string} startInvoicesOn 
     * @param {string} stopInvoicesOn 
     * @param {boolean} [aici] 
     * @param {boolean} [cidiff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public attestationAutoLines(familyId: number, startInvoicesOn: string, stopInvoicesOn: string, aici?: boolean, cidiff?: boolean, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).attestationAutoLines(familyId, startInvoicesOn, stopInvoicesOn, aici, cidiff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Errors : * PaymentRequestRejectedError
     * @param {number} legalInvoiceId 
     * @param {AICICtrlCreateFromLegalInvoiceBody} aICICtrlCreateFromLegalInvoiceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public createFromLegalInvoice(legalInvoiceId: number, aICICtrlCreateFromLegalInvoiceBody: AICICtrlCreateFromLegalInvoiceBody, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).createFromLegalInvoice(legalInvoiceId, aICICtrlCreateFromLegalInvoiceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Errors : * PaymentRequestRejectedError
     * @param {number} standardInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public createFromStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).createFromStandardInvoice(standardInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualAICIRequestServiceCreateManualAICIRequestPayload} manualAICIRequestServiceCreateManualAICIRequestPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload: ManualAICIRequestServiceCreateManualAICIRequestPayload, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).createManualAICIRequest(manualAICIRequestServiceCreateManualAICIRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
     * @param {number} legalInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public getInputDemandePaimentFromLegalInvoice(legalInvoiceId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).getInputDemandePaimentFromLegalInvoice(legalInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retourne l\'objet inputDemandePaiement correspondant a la facture. Utile pour afficher les données avant envoi (le montant de l\'acompte notamment)
     * @param {number} standardInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public getInputDemandePaimentFromStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).getInputDemandePaimentFromStandardInvoice(standardInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {string} stopInvoicesOn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public soldeOn(familyId: number, stopInvoicesOn: string, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).soldeOn(familyId, stopInvoicesOn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} paymentRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public syncPaymentRequest(paymentRequestId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).syncPaymentRequest(paymentRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AICIApi
     */
    public testConfirmation(familyId: number, options?: AxiosRequestConfig) {
        return AICIApiFp(this.configuration).testConfirmation(familyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AffiliationCodeApi - axios parameter creator
 * @export
 */
export const AffiliationCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invoiceReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAffiliationCode: async (invoiceReference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceReference' is not null or undefined
            assertParamExists('getCustomerAffiliationCode', 'invoiceReference', invoiceReference)
            const localVarPath = `/affiliation-code/get-customer-affiliation-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (invoiceReference !== undefined) {
                localVarQueryParameter['invoiceReference'] = invoiceReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} payslipReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherAffiliationCode: async (payslipReference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payslipReference' is not null or undefined
            assertParamExists('getTeacherAffiliationCode', 'payslipReference', payslipReference)
            const localVarPath = `/affiliation-code/get-teacher-affiliation-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (payslipReference !== undefined) {
                localVarQueryParameter['payslipReference'] = payslipReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AffiliationCodeApi - functional programming interface
 * @export
 */
export const AffiliationCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AffiliationCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} invoiceReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerAffiliationCode(invoiceReference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliationCodeServiceGetCustomerAffiliationCodeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerAffiliationCode(invoiceReference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} payslipReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherAffiliationCode(payslipReference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliationCodeServiceGetTeacherAffiliationCodeOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacherAffiliationCode(payslipReference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AffiliationCodeApi - factory interface
 * @export
 */
export const AffiliationCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AffiliationCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} invoiceReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerAffiliationCode(invoiceReference: string, options?: any): AxiosPromise<AffiliationCodeServiceGetCustomerAffiliationCodeOutput> {
            return localVarFp.getCustomerAffiliationCode(invoiceReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} payslipReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherAffiliationCode(payslipReference: string, options?: any): AxiosPromise<AffiliationCodeServiceGetTeacherAffiliationCodeOutput> {
            return localVarFp.getTeacherAffiliationCode(payslipReference, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AffiliationCodeApi - object-oriented interface
 * @export
 * @class AffiliationCodeApi
 * @extends {BaseAPI}
 */
export class AffiliationCodeApi extends BaseAPI {
    /**
     * 
     * @param {string} invoiceReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationCodeApi
     */
    public getCustomerAffiliationCode(invoiceReference: string, options?: AxiosRequestConfig) {
        return AffiliationCodeApiFp(this.configuration).getCustomerAffiliationCode(invoiceReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} payslipReference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AffiliationCodeApi
     */
    public getTeacherAffiliationCode(payslipReference: string, options?: AxiosRequestConfig) {
        return AffiliationCodeApiFp(this.configuration).getTeacherAffiliationCode(payslipReference, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentsApi - axios parameter creator
 * @export
 */
export const AgentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Echange jwt token against agent credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {UsersSigninParams} usersSigninParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin: async (usersSigninParams: UsersSigninParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersSigninParams' is not null or undefined
            assertParamExists('signin', 'usersSigninParams', usersSigninParams)
            const localVarPath = `/agents/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersSigninParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Errors : UserAlreadyExistError
         * @param {UsersSignupParams} usersSignupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (usersSignupParams: UsersSignupParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'usersSignupParams' is not null or undefined
            assertParamExists('signup', 'usersSignupParams', usersSignupParams)
            const localVarPath = `/agents/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersSignupParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentsApi - functional programming interface
 * @export
 */
export const AgentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Echange jwt token against agent credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {UsersSigninParams} usersSigninParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signin(usersSigninParams: UsersSigninParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signin(usersSigninParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Errors : UserAlreadyExistError
         * @param {UsersSignupParams} usersSignupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(usersSignupParams: UsersSignupParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DryAgent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(usersSignupParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentsApi - factory interface
 * @export
 */
export const AgentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentsApiFp(configuration)
    return {
        /**
         * Echange jwt token against agent credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {UsersSigninParams} usersSigninParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(usersSigninParams: UsersSigninParams, options?: any): AxiosPromise<string> {
            return localVarFp.signin(usersSigninParams, options).then((request) => request(axios, basePath));
        },
        /**
         * Errors : UserAlreadyExistError
         * @param {UsersSignupParams} usersSignupParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(usersSignupParams: UsersSignupParams, options?: any): AxiosPromise<DryAgent> {
            return localVarFp.signup(usersSignupParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentsApi - object-oriented interface
 * @export
 * @class AgentsApi
 * @extends {BaseAPI}
 */
export class AgentsApi extends BaseAPI {
    /**
     * Echange jwt token against agent credentials  Errors : UnknownUserError, InvalidPasswordError
     * @param {UsersSigninParams} usersSigninParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentsApi
     */
    public signin(usersSigninParams: UsersSigninParams, options?: AxiosRequestConfig) {
        return AgentsApiFp(this.configuration).signin(usersSigninParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Errors : UserAlreadyExistError
     * @param {UsersSignupParams} usersSignupParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentsApi
     */
    public signup(usersSignupParams: UsersSignupParams, options?: AxiosRequestConfig) {
        return AgentsApiFp(this.configuration).signup(usersSignupParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsapApi - axios parameter creator
 * @export
 */
export const AsapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortAffiliation: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('abortAffiliation', 'familyId', familyId)
            const localVarPath = `/asap/affiliations/{familyId}/abort`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDeclarationFileDeposit: async (globalDeclarationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('cancelDeclarationFileDeposit', 'globalDeclarationId', globalDeclarationId)
            const localVarPath = `/asap/declarations/{globalDeclarationId}/cancel-deposit`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFamilyDeclarationResult: async (body: AsapDeclarationServiceClearFamilyDeclarationResultPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('clearFamilyDeclarationResult', 'body', body)
            const localVarPath = `/asap/declarations/clear-family-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFamilyDeclaratonResultBatch: async (body: AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('clearFamilyDeclaratonResultBatch', 'body', body)
            const localVarPath = `/asap/declarations/clear-family-result-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmDeclarationFileDeposit: async (globalDeclarationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('confirmDeclarationFileDeposit', 'globalDeclarationId', globalDeclarationId)
            const localVarPath = `/asap/declarations/{globalDeclarationId}/confirm-deposit`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmGlobalDeclaration: async (globalDeclarationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('confirmGlobalDeclaration', 'globalDeclarationId', globalDeclarationId)
            const localVarPath = `/asap/declarations/{globalDeclarationId}/confirm`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDeclarationFromPayslipsPayload} createDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeclarationFromPayslips: async (createDeclarationFromPayslipsPayload: CreateDeclarationFromPayslipsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeclarationFromPayslipsPayload' is not null or undefined
            assertParamExists('createDeclarationFromPayslips', 'createDeclarationFromPayslipsPayload', createDeclarationFromPayslipsPayload)
            const localVarPath = `/asap/declarations/create-from-payslips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeclarationFromPayslipsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeclaration: async (globalDeclarationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('deleteDeclaration', 'globalDeclarationId', globalDeclarationId)
            const localVarPath = `/asap/declarations/{globalDeclarationId}`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {DoneAffiliationRequest} doneAffiliationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doneAffiliation: async (familyId: number, doneAffiliationRequest: DoneAffiliationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('doneAffiliation', 'familyId', familyId)
            // verify required parameter 'doneAffiliationRequest' is not null or undefined
            assertParamExists('doneAffiliation', 'doneAffiliationRequest', doneAffiliationRequest)
            const localVarPath = `/asap/affiliations/{familyId}/done`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doneAffiliationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {boolean} real 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsapDeclarationFile: async (globalDeclarationId: number, real: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('generateAsapDeclarationFile', 'globalDeclarationId', globalDeclarationId)
            // verify required parameter 'real' is not null or undefined
            assertParamExists('generateAsapDeclarationFile', 'real', real)
            const localVarPath = `/asap/declarations/{globalDeclarationId}/file`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (real !== undefined) {
                localVarQueryParameter['real'] = real;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {RadiationAffiliationPayload} radiationAffiliationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        radiationAffiliation: async (familyId: number, radiationAffiliationPayload: RadiationAffiliationPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('radiationAffiliation', 'familyId', familyId)
            // verify required parameter 'radiationAffiliationPayload' is not null or undefined
            assertParamExists('radiationAffiliation', 'radiationAffiliationPayload', radiationAffiliationPayload)
            const localVarPath = `/asap/affiliations/{familyId}/radiation`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(radiationAffiliationPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFamilyDeclaratonResult: async (body: AsapDeclarationServiceSetFamilyDeclarationResultPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('setFamilyDeclaratonResult', 'body', body)
            const localVarPath = `/asap/declarations/set-family-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFamilyDeclaratonResultBatch: async (body: AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('setFamilyDeclaratonResultBatch', 'body', body)
            const localVarPath = `/asap/declarations/set-family-result-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAffiliation: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('startAffiliation', 'familyId', familyId)
            const localVarPath = `/asap/affiliations/{familyId}/start`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unconfirmGlobalDeclaration: async (globalDeclarationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'globalDeclarationId' is not null or undefined
            assertParamExists('unconfirmGlobalDeclaration', 'globalDeclarationId', globalDeclarationId)
            const localVarPath = `/asap/declarations/{globalDeclarationId}/unconfirm`
                .replace(`{${"globalDeclarationId"}}`, encodeURIComponent(String(globalDeclarationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDeclarationFromPayslipsPayload} updateDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationFromPayslips: async (updateDeclarationFromPayslipsPayload: UpdateDeclarationFromPayslipsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeclarationFromPayslipsPayload' is not null or undefined
            assertParamExists('updateDeclarationFromPayslips', 'updateDeclarationFromPayslipsPayload', updateDeclarationFromPayslipsPayload)
            const localVarPath = `/asap/declarations/update-from-payslips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeclarationFromPayslipsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsapApi - functional programming interface
 * @export
 */
export const AsapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AsapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortAffiliation(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortAffiliation(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDeclarationFileDeposit(globalDeclarationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDeclarationFileDeposit(globalDeclarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearFamilyDeclarationResult(body: AsapDeclarationServiceClearFamilyDeclarationResultPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearFamilyDeclarationResult(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearFamilyDeclaratonResultBatch(body: AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearFamilyDeclaratonResultBatch(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmDeclarationFileDeposit(globalDeclarationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmDeclarationFileDeposit(globalDeclarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmGlobalDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmGlobalDeclaration200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmGlobalDeclaration(globalDeclarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDeclarationFromPayslipsPayload} createDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeclarationFromPayslips(createDeclarationFromPayslipsPayload: CreateDeclarationFromPayslipsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDeclarationFromPayslipsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeclarationFromPayslips(createDeclarationFromPayslipsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeclaration(globalDeclarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {DoneAffiliationRequest} doneAffiliationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async doneAffiliation(familyId: number, doneAffiliationRequest: DoneAffiliationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.doneAffiliation(familyId, doneAffiliationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {boolean} real 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAsapDeclarationFile(globalDeclarationId: number, real: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAsapDeclarationFile(globalDeclarationId, real, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {RadiationAffiliationPayload} radiationAffiliationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async radiationAffiliation(familyId: number, radiationAffiliationPayload: RadiationAffiliationPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.radiationAffiliation(familyId, radiationAffiliationPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFamilyDeclaratonResult(body: AsapDeclarationServiceSetFamilyDeclarationResultPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFamilyDeclaratonResult(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setFamilyDeclaratonResultBatch(body: AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setFamilyDeclaratonResultBatch(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startAffiliation(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startAffiliation(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unconfirmGlobalDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unconfirmGlobalDeclaration(globalDeclarationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDeclarationFromPayslipsPayload} updateDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload: UpdateDeclarationFromPayslipsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AsapApi - factory interface
 * @export
 */
export const AsapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AsapApiFp(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortAffiliation(familyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.abortAffiliation(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDeclarationFileDeposit(globalDeclarationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cancelDeclarationFileDeposit(globalDeclarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFamilyDeclarationResult(body: AsapDeclarationServiceClearFamilyDeclarationResultPayload, options?: any): AxiosPromise<void> {
            return localVarFp.clearFamilyDeclarationResult(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFamilyDeclaratonResultBatch(body: AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload, options?: any): AxiosPromise<void> {
            return localVarFp.clearFamilyDeclaratonResultBatch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmDeclarationFileDeposit(globalDeclarationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.confirmDeclarationFileDeposit(globalDeclarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmGlobalDeclaration(globalDeclarationId: number, options?: any): AxiosPromise<ConfirmGlobalDeclaration200Response> {
            return localVarFp.confirmGlobalDeclaration(globalDeclarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDeclarationFromPayslipsPayload} createDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeclarationFromPayslips(createDeclarationFromPayslipsPayload: CreateDeclarationFromPayslipsPayload, options?: any): AxiosPromise<CreateDeclarationFromPayslipsResponse> {
            return localVarFp.createDeclarationFromPayslips(createDeclarationFromPayslipsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeclaration(globalDeclarationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeclaration(globalDeclarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {DoneAffiliationRequest} doneAffiliationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        doneAffiliation(familyId: number, doneAffiliationRequest: DoneAffiliationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.doneAffiliation(familyId, doneAffiliationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {boolean} real 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAsapDeclarationFile(globalDeclarationId: number, real: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.generateAsapDeclarationFile(globalDeclarationId, real, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {RadiationAffiliationPayload} radiationAffiliationPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        radiationAffiliation(familyId: number, radiationAffiliationPayload: RadiationAffiliationPayload, options?: any): AxiosPromise<void> {
            return localVarFp.radiationAffiliation(familyId, radiationAffiliationPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFamilyDeclaratonResult(body: AsapDeclarationServiceSetFamilyDeclarationResultPayload, options?: any): AxiosPromise<void> {
            return localVarFp.setFamilyDeclaratonResult(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setFamilyDeclaratonResultBatch(body: AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload, options?: any): AxiosPromise<void> {
            return localVarFp.setFamilyDeclaratonResultBatch(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startAffiliation(familyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.startAffiliation(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} globalDeclarationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unconfirmGlobalDeclaration(globalDeclarationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unconfirmGlobalDeclaration(globalDeclarationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDeclarationFromPayslipsPayload} updateDeclarationFromPayslipsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload: UpdateDeclarationFromPayslipsPayload, options?: any): AxiosPromise<void> {
            return localVarFp.updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsapApi - object-oriented interface
 * @export
 * @class AsapApi
 * @extends {BaseAPI}
 */
export class AsapApi extends BaseAPI {
    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public abortAffiliation(familyId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).abortAffiliation(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public cancelDeclarationFileDeposit(globalDeclarationId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).cancelDeclarationFileDeposit(globalDeclarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AsapDeclarationServiceClearFamilyDeclarationResultPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public clearFamilyDeclarationResult(body: AsapDeclarationServiceClearFamilyDeclarationResultPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).clearFamilyDeclarationResult(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public clearFamilyDeclaratonResultBatch(body: AsapDeclarationServiceClearFamilyDeclarationResultBatchPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).clearFamilyDeclaratonResultBatch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public confirmDeclarationFileDeposit(globalDeclarationId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).confirmDeclarationFileDeposit(globalDeclarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public confirmGlobalDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).confirmGlobalDeclaration(globalDeclarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDeclarationFromPayslipsPayload} createDeclarationFromPayslipsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public createDeclarationFromPayslips(createDeclarationFromPayslipsPayload: CreateDeclarationFromPayslipsPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).createDeclarationFromPayslips(createDeclarationFromPayslipsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public deleteDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).deleteDeclaration(globalDeclarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {DoneAffiliationRequest} doneAffiliationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public doneAffiliation(familyId: number, doneAffiliationRequest: DoneAffiliationRequest, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).doneAffiliation(familyId, doneAffiliationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {boolean} real 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public generateAsapDeclarationFile(globalDeclarationId: number, real: boolean, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).generateAsapDeclarationFile(globalDeclarationId, real, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {RadiationAffiliationPayload} radiationAffiliationPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public radiationAffiliation(familyId: number, radiationAffiliationPayload: RadiationAffiliationPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).radiationAffiliation(familyId, radiationAffiliationPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AsapDeclarationServiceSetFamilyDeclarationResultPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public setFamilyDeclaratonResult(body: AsapDeclarationServiceSetFamilyDeclarationResultPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).setFamilyDeclaratonResult(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public setFamilyDeclaratonResultBatch(body: AsapDeclarationServiceSetFamilyDeclarationResultBatchPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).setFamilyDeclaratonResultBatch(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public startAffiliation(familyId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).startAffiliation(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} globalDeclarationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public unconfirmGlobalDeclaration(globalDeclarationId: number, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).unconfirmGlobalDeclaration(globalDeclarationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDeclarationFromPayslipsPayload} updateDeclarationFromPayslipsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsapApi
     */
    public updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload: UpdateDeclarationFromPayslipsPayload, options?: AxiosRequestConfig) {
        return AsapApiFp(this.configuration).updateDeclarationFromPayslips(updateDeclarationFromPayslipsPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendlyCtrlApi - axios parameter creator
 * @export
 */
export const CalendlyCtrlApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendly: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('handleCalendly', 'body', body)
            const localVarPath = `/calendly-webhooks/wix-registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendlyCtrlApi - functional programming interface
 * @export
 */
export const CalendlyCtrlApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendlyCtrlApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCalendly(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCalendly(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendlyCtrlApi - factory interface
 * @export
 */
export const CalendlyCtrlApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendlyCtrlApiFp(configuration)
    return {
        /**
         * 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendly(body: any, options?: any): AxiosPromise<void> {
            return localVarFp.handleCalendly(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendlyCtrlApi - object-oriented interface
 * @export
 * @class CalendlyCtrlApi
 * @extends {BaseAPI}
 */
export class CalendlyCtrlApi extends BaseAPI {
    /**
     * 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendlyCtrlApi
     */
    public handleCalendly(body: any, options?: AxiosRequestConfig) {
        return CalendlyCtrlApiFp(this.configuration).handleCalendly(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidaturesApi - axios parameter creator
 * @export
 */
export const CandidaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortCharteYsProc: async (forceCharteYsProcRequest: ForceCharteYsProcRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forceCharteYsProcRequest' is not null or undefined
            assertParamExists('abortCharteYsProc', 'forceCharteYsProcRequest', forceCharteYsProcRequest)
            const localVarPath = `/candidatures/abort-charte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceCharteYsProcRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * accepte une pré-demande (envoi d\'un email avec code wix et mise a jour bdd)
         * @param {CandidaturesAcceptAskParams} candidaturesAcceptAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAsk: async (candidaturesAcceptAskParams: CandidaturesAcceptAskParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidaturesAcceptAskParams' is not null or undefined
            assertParamExists('acceptAsk', 'candidaturesAcceptAskParams', candidaturesAcceptAskParams)
            const localVarPath = `/candidatures/accept-ask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidaturesAcceptAskParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionServiceAcceptSessionPayload} sessionServiceAcceptSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSession: async (sessionServiceAcceptSessionPayload: SessionServiceAcceptSessionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionServiceAcceptSessionPayload' is not null or undefined
            assertParamExists('acceptSession', 'sessionServiceAcceptSessionPayload', sessionServiceAcceptSessionPayload)
            const localVarPath = `/candidatures/accept-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionServiceAcceptSessionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCharteYsProc: async (forceCharteYsProcRequest: ForceCharteYsProcRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forceCharteYsProcRequest' is not null or undefined
            assertParamExists('forceCharteYsProc', 'forceCharteYsProcRequest', forceCharteYsProcRequest)
            const localVarPath = `/candidatures/force-charte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceCharteYsProcRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * refuse une pré-demande (envoi d\'un email et mise a jour bdd)
         * @param {CandidaturesRefuseAskParams} candidaturesRefuseAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseAsk: async (candidaturesRefuseAskParams: CandidaturesRefuseAskParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidaturesRefuseAskParams' is not null or undefined
            assertParamExists('refuseAsk', 'candidaturesRefuseAskParams', candidaturesRefuseAskParams)
            const localVarPath = `/candidatures/refuse-ask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidaturesRefuseAskParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionServiceRefuseSessionPayload} sessionServiceRefuseSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseSession: async (sessionServiceRefuseSessionPayload: SessionServiceRefuseSessionPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionServiceRefuseSessionPayload' is not null or undefined
            assertParamExists('refuseSession', 'sessionServiceRefuseSessionPayload', sessionServiceRefuseSessionPayload)
            const localVarPath = `/candidatures/refuse-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionServiceRefuseSessionPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidaturesApi - functional programming interface
 * @export
 */
export const CandidaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortCharteYsProc(forceCharteYsProcRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * accepte une pré-demande (envoi d\'un email avec code wix et mise a jour bdd)
         * @param {CandidaturesAcceptAskParams} candidaturesAcceptAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptAsk(candidaturesAcceptAskParams: CandidaturesAcceptAskParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptAsk(candidaturesAcceptAskParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionServiceAcceptSessionPayload} sessionServiceAcceptSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptSession(sessionServiceAcceptSessionPayload: SessionServiceAcceptSessionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptSession(sessionServiceAcceptSessionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceCharteYsProc(forceCharteYsProcRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * refuse une pré-demande (envoi d\'un email et mise a jour bdd)
         * @param {CandidaturesRefuseAskParams} candidaturesRefuseAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuseAsk(candidaturesRefuseAskParams: CandidaturesRefuseAskParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refuseAsk(candidaturesRefuseAskParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionServiceRefuseSessionPayload} sessionServiceRefuseSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuseSession(sessionServiceRefuseSessionPayload: SessionServiceRefuseSessionPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refuseSession(sessionServiceRefuseSessionPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidaturesApi - factory interface
 * @export
 */
export const CandidaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidaturesApiFp(configuration)
    return {
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: any): AxiosPromise<any> {
            return localVarFp.abortCharteYsProc(forceCharteYsProcRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * accepte une pré-demande (envoi d\'un email avec code wix et mise a jour bdd)
         * @param {CandidaturesAcceptAskParams} candidaturesAcceptAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAsk(candidaturesAcceptAskParams: CandidaturesAcceptAskParams, options?: any): AxiosPromise<void> {
            return localVarFp.acceptAsk(candidaturesAcceptAskParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionServiceAcceptSessionPayload} sessionServiceAcceptSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptSession(sessionServiceAcceptSessionPayload: SessionServiceAcceptSessionPayload, options?: any): AxiosPromise<void> {
            return localVarFp.acceptSession(sessionServiceAcceptSessionPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: any): AxiosPromise<any> {
            return localVarFp.forceCharteYsProc(forceCharteYsProcRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * refuse une pré-demande (envoi d\'un email et mise a jour bdd)
         * @param {CandidaturesRefuseAskParams} candidaturesRefuseAskParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseAsk(candidaturesRefuseAskParams: CandidaturesRefuseAskParams, options?: any): AxiosPromise<void> {
            return localVarFp.refuseAsk(candidaturesRefuseAskParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionServiceRefuseSessionPayload} sessionServiceRefuseSessionPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseSession(sessionServiceRefuseSessionPayload: SessionServiceRefuseSessionPayload, options?: any): AxiosPromise<void> {
            return localVarFp.refuseSession(sessionServiceRefuseSessionPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidaturesApi - object-oriented interface
 * @export
 * @class CandidaturesApi
 * @extends {BaseAPI}
 */
export class CandidaturesApi extends BaseAPI {
    /**
     * 
     * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public abortCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).abortCharteYsProc(forceCharteYsProcRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * accepte une pré-demande (envoi d\'un email avec code wix et mise a jour bdd)
     * @param {CandidaturesAcceptAskParams} candidaturesAcceptAskParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public acceptAsk(candidaturesAcceptAskParams: CandidaturesAcceptAskParams, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).acceptAsk(candidaturesAcceptAskParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionServiceAcceptSessionPayload} sessionServiceAcceptSessionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public acceptSession(sessionServiceAcceptSessionPayload: SessionServiceAcceptSessionPayload, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).acceptSession(sessionServiceAcceptSessionPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForceCharteYsProcRequest} forceCharteYsProcRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public forceCharteYsProc(forceCharteYsProcRequest: ForceCharteYsProcRequest, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).forceCharteYsProc(forceCharteYsProcRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * refuse une pré-demande (envoi d\'un email et mise a jour bdd)
     * @param {CandidaturesRefuseAskParams} candidaturesRefuseAskParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public refuseAsk(candidaturesRefuseAskParams: CandidaturesRefuseAskParams, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).refuseAsk(candidaturesRefuseAskParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionServiceRefuseSessionPayload} sessionServiceRefuseSessionPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public refuseSession(sessionServiceRefuseSessionPayload: SessionServiceRefuseSessionPayload, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).refuseSession(sessionServiceRefuseSessionPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ComptaApi - axios parameter creator
 * @export
 */
export const ComptaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaybackBody} comptaCtrlAffectAiciPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAiciPayback: async (bankTransactionId: number, comptaCtrlAffectAiciPaybackBody: ComptaCtrlAffectAiciPaybackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectAiciPayback', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectAiciPaybackBody' is not null or undefined
            assertParamExists('affectAiciPayback', 'comptaCtrlAffectAiciPaybackBody', comptaCtrlAffectAiciPaybackBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-aici-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectAiciPaybackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaymentBody} comptaCtrlAffectAiciPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAiciPayment: async (bankTransactionId: number, comptaCtrlAffectAiciPaymentBody: ComptaCtrlAffectAiciPaymentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectAiciPayment', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectAiciPaymentBody' is not null or undefined
            assertParamExists('affectAiciPayment', 'comptaCtrlAffectAiciPaymentBody', comptaCtrlAffectAiciPaymentBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-aici-payment`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectAiciPaymentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAsapPaymentBody} comptaCtrlAffectAsapPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAsapPayment: async (bankTransactionId: number, comptaCtrlAffectAsapPaymentBody: ComptaCtrlAffectAsapPaymentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectAsapPayment', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectAsapPaymentBody' is not null or undefined
            assertParamExists('affectAsapPayment', 'comptaCtrlAffectAsapPaymentBody', comptaCtrlAffectAsapPaymentBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-asap-payment`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectAsapPaymentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferCashinBody} comptaCtrlAffectCustomerTransferCashinBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectCustomerTransferCashin: async (bankTransactionId: number, comptaCtrlAffectCustomerTransferCashinBody: ComptaCtrlAffectCustomerTransferCashinBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectCustomerTransferCashin', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectCustomerTransferCashinBody' is not null or undefined
            assertParamExists('affectCustomerTransferCashin', 'comptaCtrlAffectCustomerTransferCashinBody', comptaCtrlAffectCustomerTransferCashinBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-customer-transfer-cashin`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectCustomerTransferCashinBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferPaybackBody} comptaCtrlAffectCustomerTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectCustomerTransferPayback: async (bankTransactionId: number, comptaCtrlAffectCustomerTransferPaybackBody: ComptaCtrlAffectCustomerTransferPaybackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectCustomerTransferPayback', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectCustomerTransferPaybackBody' is not null or undefined
            assertParamExists('affectCustomerTransferPayback', 'comptaCtrlAffectCustomerTransferPaybackBody', comptaCtrlAffectCustomerTransferPaybackBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-customer-transfer-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectCustomerTransferPaybackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectTeacherTransferPaybackBody} comptaCtrlAffectTeacherTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectTeacherTransferPayback: async (bankTransactionId: number, comptaCtrlAffectTeacherTransferPaybackBody: ComptaCtrlAffectTeacherTransferPaybackBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('affectTeacherTransferPayback', 'bankTransactionId', bankTransactionId)
            // verify required parameter 'comptaCtrlAffectTeacherTransferPaybackBody' is not null or undefined
            assertParamExists('affectTeacherTransferPayback', 'comptaCtrlAffectTeacherTransferPaybackBody', comptaCtrlAffectTeacherTransferPaybackBody)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/affect-teacher-transfer-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlAffectTeacherTransferPaybackBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileBankCheckDepositBody} comptaCtrlCreateAndReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndReconcileBankCheckDeposit: async (comptaCtrlCreateAndReconcileBankCheckDepositBody: ComptaCtrlCreateAndReconcileBankCheckDepositBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateAndReconcileBankCheckDepositBody' is not null or undefined
            assertParamExists('createAndReconcileBankCheckDeposit', 'comptaCtrlCreateAndReconcileBankCheckDepositBody', comptaCtrlCreateAndReconcileBankCheckDepositBody)
            const localVarPath = `/compta/bank-check-deposit/create-and-reconcile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateAndReconcileBankCheckDepositBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileCesuCheckDepositBody} comptaCtrlCreateAndReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndReconcileCesuCheckDeposit: async (comptaCtrlCreateAndReconcileCesuCheckDepositBody: ComptaCtrlCreateAndReconcileCesuCheckDepositBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateAndReconcileCesuCheckDepositBody' is not null or undefined
            assertParamExists('createAndReconcileCesuCheckDeposit', 'comptaCtrlCreateAndReconcileCesuCheckDepositBody', comptaCtrlCreateAndReconcileCesuCheckDepositBody)
            const localVarPath = `/compta/cesu-check-deposit/create-and-reconcile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateAndReconcileCesuCheckDepositBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody} comptaCtrlCreateBankCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankCheckDepositNotReconciliedYet: async (comptaCtrlCreateBankCheckDepositNotReconciliedYetBody: ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateBankCheckDepositNotReconciliedYetBody' is not null or undefined
            assertParamExists('createBankCheckDepositNotReconciliedYet', 'comptaCtrlCreateBankCheckDepositNotReconciliedYetBody', comptaCtrlCreateBankCheckDepositNotReconciliedYetBody)
            const localVarPath = `/compta/bank-check-deposit/create-not-reconcilied-yet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody} comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCesuCheckDepositNotReconciliedYet: async (comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody: ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody' is not null or undefined
            assertParamExists('createCesuCheckDepositNotReconciliedYet', 'comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody', comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody)
            const localVarPath = `/compta/cesu-check-deposit/create-not-reconcilied-yet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckLotBody} comptaCtrlCreateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCesuCheckLot: async (comptaCtrlCreateCesuCheckLotBody: ComptaCtrlCreateCesuCheckLotBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateCesuCheckLotBody' is not null or undefined
            assertParamExists('createCesuCheckLot', 'comptaCtrlCreateCesuCheckLotBody', comptaCtrlCreateCesuCheckLotBody)
            const localVarPath = `/compta/cesu-check-lots/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateCesuCheckLotBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComptaCtrlCreateCustomerBankCheckBody} comptaCtrlCreateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerBankCheck: async (comptaCtrlCreateCustomerBankCheckBody: ComptaCtrlCreateCustomerBankCheckBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'comptaCtrlCreateCustomerBankCheckBody' is not null or undefined
            assertParamExists('createCustomerBankCheck', 'comptaCtrlCreateCustomerBankCheckBody', comptaCtrlCreateCustomerBankCheckBody)
            const localVarPath = `/compta/customer-bank-check/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlCreateCustomerBankCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankCheckDepositNotReconciliedYet: async (bankCheckDepositId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankCheckDepositId' is not null or undefined
            assertParamExists('deleteBankCheckDepositNotReconciliedYet', 'bankCheckDepositId', bankCheckDepositId)
            const localVarPath = `/compta/bank-check-deposit/{bankCheckDepositId}/delete-not-reconcilied-yet`
                .replace(`{${"bankCheckDepositId"}}`, encodeURIComponent(String(bankCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankCheckDepositReconcilied: async (bankCheckDepositId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankCheckDepositId' is not null or undefined
            assertParamExists('deleteBankCheckDepositReconcilied', 'bankCheckDepositId', bankCheckDepositId)
            const localVarPath = `/compta/bank-check-deposit/{bankCheckDepositId}/delete-reconciled`
                .replace(`{${"bankCheckDepositId"}}`, encodeURIComponent(String(bankCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckDepositNotReconciliedYet: async (cesuCheckDepositId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cesuCheckDepositId' is not null or undefined
            assertParamExists('deleteCesuCheckDepositNotReconciliedYet', 'cesuCheckDepositId', cesuCheckDepositId)
            const localVarPath = `/compta/cesu-check-deposit/{cesuCheckDepositId}/delete-not-reconcilied-yet`
                .replace(`{${"cesuCheckDepositId"}}`, encodeURIComponent(String(cesuCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckDepositReconcilied: async (cesuCheckDepositId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cesuCheckDepositId' is not null or undefined
            assertParamExists('deleteCesuCheckDepositReconcilied', 'cesuCheckDepositId', cesuCheckDepositId)
            const localVarPath = `/compta/cesu-check-deposit/{cesuCheckDepositId}/delete-reconciled`
                .replace(`{${"cesuCheckDepositId"}}`, encodeURIComponent(String(cesuCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckLot: async (lotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotId' is not null or undefined
            assertParamExists('deleteCesuCheckLot', 'lotId', lotId)
            const localVarPath = `/compta/cesu-check-lots/{lotId}/delete`
                .replace(`{${"lotId"}}`, encodeURIComponent(String(lotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerBankCheck: async (bankCheckId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankCheckId' is not null or undefined
            assertParamExists('deleteCustomerBankCheck', 'bankCheckId', bankCheckId)
            const localVarPath = `/compta/customer-bank-check/{bankCheckId}/delete`
                .replace(`{${"bankCheckId"}}`, encodeURIComponent(String(bankCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAiciPayback: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachAiciPayback', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-aici-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAiciPayment: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachAiciPayment', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-aici-payment`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAsapPayment: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachAsapPayment', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-asap-payment`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachCustomerTransferCashin: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachCustomerTransferCashin', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-customer-transfer-cashin`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachCustomerTransferPayback: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachCustomerTransferPayback', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-customer-transfer-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTeacherPayment: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachTeacherPayment', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-teacher-payment`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTeacherTransferPayback: async (bankTransactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            assertParamExists('detachTeacherTransferPayback', 'bankTransactionId', bankTransactionId)
            const localVarPath = `/compta/bank-transactions/{bankTransactionId}/detach-teacher-transfer-payback`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAFFJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getAFFJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/affectations-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJournalsArchive: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getAllJournalsArchive', 'exercice', exercice)
            const localVarPath = `/compta/exports/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceClientsCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getBalanceClientsCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/balance-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceProfsCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getBalanceProfsCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/balance-profs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankJournalsCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getBankJournalsCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/banques-journaux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCESUJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getCESUJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/cesu-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCHQJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getCHQJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/cheques-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGCLJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getGCLJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/gocardless-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrandLivreClientsCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getGrandLivreClientsCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/grand-livre-clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrandLivreProfsCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getGrandLivreProfsCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/grand-livre-profs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixedJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getMixedJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/mixed-journals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVENJournalCSV: async (exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercice' is not null or undefined
            assertParamExists('getVENJournalCSV', 'exercice', exercice)
            const localVarPath = `/compta/exports/ventes-journal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exercice !== undefined) {
                localVarQueryParameter['exercice'] = exercice;
            }

            if (encoding !== undefined) {
                localVarQueryParameter['encoding'] = encoding;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (tiers !== undefined) {
                localVarQueryParameter['tiers'] = tiers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recomputeCustomerAutoLettrage: async (getPreviewListRequest: GetPreviewListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPreviewListRequest' is not null or undefined
            assertParamExists('recomputeCustomerAutoLettrage', 'getPreviewListRequest', getPreviewListRequest)
            const localVarPath = `/compta/recompute-customer-auto-lettrage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPreviewListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {ComptaCtrlReconcileBankCheckDepositBody} comptaCtrlReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileBankCheckDeposit: async (bankCheckDepositId: number, comptaCtrlReconcileBankCheckDepositBody: ComptaCtrlReconcileBankCheckDepositBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankCheckDepositId' is not null or undefined
            assertParamExists('reconcileBankCheckDeposit', 'bankCheckDepositId', bankCheckDepositId)
            // verify required parameter 'comptaCtrlReconcileBankCheckDepositBody' is not null or undefined
            assertParamExists('reconcileBankCheckDeposit', 'comptaCtrlReconcileBankCheckDepositBody', comptaCtrlReconcileBankCheckDepositBody)
            const localVarPath = `/compta/bank-check-deposit/{bankCheckDepositId}/reconcile`
                .replace(`{${"bankCheckDepositId"}}`, encodeURIComponent(String(bankCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlReconcileBankCheckDepositBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {ComptaCtrlReconcileCesuCheckDepositBody} comptaCtrlReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileCesuCheckDeposit: async (cesuCheckDepositId: number, comptaCtrlReconcileCesuCheckDepositBody: ComptaCtrlReconcileCesuCheckDepositBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cesuCheckDepositId' is not null or undefined
            assertParamExists('reconcileCesuCheckDeposit', 'cesuCheckDepositId', cesuCheckDepositId)
            // verify required parameter 'comptaCtrlReconcileCesuCheckDepositBody' is not null or undefined
            assertParamExists('reconcileCesuCheckDeposit', 'comptaCtrlReconcileCesuCheckDepositBody', comptaCtrlReconcileCesuCheckDepositBody)
            const localVarPath = `/compta/cesu-check-deposit/{cesuCheckDepositId}/reconcile`
                .replace(`{${"cesuCheckDepositId"}}`, encodeURIComponent(String(cesuCheckDepositId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlReconcileCesuCheckDepositBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resyncPontoAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/compta/resync-ponto-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MarginSimulatorServiceSimulatePayslipPayload} marginSimulatorServiceSimulatePayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatePayslip: async (marginSimulatorServiceSimulatePayslipPayload: MarginSimulatorServiceSimulatePayslipPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'marginSimulatorServiceSimulatePayslipPayload' is not null or undefined
            assertParamExists('simulatePayslip', 'marginSimulatorServiceSimulatePayslipPayload', marginSimulatorServiceSimulatePayslipPayload)
            const localVarPath = `/compta/simulator/payslip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(marginSimulatorServiceSimulatePayslipPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGocardlessPayoutsAndPayments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/compta/gocardless-payments/sync-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} gocardlessPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleGocarlessPayment: async (gocardlessPaymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gocardlessPaymentId' is not null or undefined
            assertParamExists('syncSingleGocarlessPayment', 'gocardlessPaymentId', gocardlessPaymentId)
            const localVarPath = `/compta/gocardless-payments/{gocardlessPaymentId}/retry-single-sync`
                .replace(`{${"gocardlessPaymentId"}}`, encodeURIComponent(String(gocardlessPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lotId 
         * @param {ComptaCtrlUpdateCesuCheckLotBody} comptaCtrlUpdateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCesuCheckLot: async (lotId: number, comptaCtrlUpdateCesuCheckLotBody: ComptaCtrlUpdateCesuCheckLotBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotId' is not null or undefined
            assertParamExists('updateCesuCheckLot', 'lotId', lotId)
            // verify required parameter 'comptaCtrlUpdateCesuCheckLotBody' is not null or undefined
            assertParamExists('updateCesuCheckLot', 'comptaCtrlUpdateCesuCheckLotBody', comptaCtrlUpdateCesuCheckLotBody)
            const localVarPath = `/compta/cesu-check-lots/{lotId}/update`
                .replace(`{${"lotId"}}`, encodeURIComponent(String(lotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlUpdateCesuCheckLotBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {ComptaCtrlUpdateCustomerBankCheckBody} comptaCtrlUpdateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBankCheck: async (bankCheckId: number, comptaCtrlUpdateCustomerBankCheckBody: ComptaCtrlUpdateCustomerBankCheckBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankCheckId' is not null or undefined
            assertParamExists('updateCustomerBankCheck', 'bankCheckId', bankCheckId)
            // verify required parameter 'comptaCtrlUpdateCustomerBankCheckBody' is not null or undefined
            assertParamExists('updateCustomerBankCheck', 'comptaCtrlUpdateCustomerBankCheckBody', comptaCtrlUpdateCustomerBankCheckBody)
            const localVarPath = `/compta/customer-bank-check/{bankCheckId}/update`
                .replace(`{${"bankCheckId"}}`, encodeURIComponent(String(bankCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comptaCtrlUpdateCustomerBankCheckBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComptaApi - functional programming interface
 * @export
 */
export const ComptaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComptaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaybackBody} comptaCtrlAffectAiciPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectAiciPayback(bankTransactionId: number, comptaCtrlAffectAiciPaybackBody: ComptaCtrlAffectAiciPaybackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectAiciPayback(bankTransactionId, comptaCtrlAffectAiciPaybackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaymentBody} comptaCtrlAffectAiciPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectAiciPayment(bankTransactionId: number, comptaCtrlAffectAiciPaymentBody: ComptaCtrlAffectAiciPaymentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectAiciPayment(bankTransactionId, comptaCtrlAffectAiciPaymentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAsapPaymentBody} comptaCtrlAffectAsapPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectAsapPayment(bankTransactionId: number, comptaCtrlAffectAsapPaymentBody: ComptaCtrlAffectAsapPaymentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectAsapPayment(bankTransactionId, comptaCtrlAffectAsapPaymentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferCashinBody} comptaCtrlAffectCustomerTransferCashinBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectCustomerTransferCashin(bankTransactionId: number, comptaCtrlAffectCustomerTransferCashinBody: ComptaCtrlAffectCustomerTransferCashinBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectCustomerTransferCashin(bankTransactionId, comptaCtrlAffectCustomerTransferCashinBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferPaybackBody} comptaCtrlAffectCustomerTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectCustomerTransferPayback(bankTransactionId: number, comptaCtrlAffectCustomerTransferPaybackBody: ComptaCtrlAffectCustomerTransferPaybackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectCustomerTransferPayback(bankTransactionId, comptaCtrlAffectCustomerTransferPaybackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectTeacherTransferPaybackBody} comptaCtrlAffectTeacherTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affectTeacherTransferPayback(bankTransactionId: number, comptaCtrlAffectTeacherTransferPaybackBody: ComptaCtrlAffectTeacherTransferPaybackBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affectTeacherTransferPayback(bankTransactionId, comptaCtrlAffectTeacherTransferPaybackBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileBankCheckDepositBody} comptaCtrlCreateAndReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody: ComptaCtrlCreateAndReconcileBankCheckDepositBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankChecksServiceCreateAndReconcileBankCheckDepositOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileCesuCheckDepositBody} comptaCtrlCreateAndReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody: ComptaCtrlCreateAndReconcileCesuCheckDepositBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CesuChecksServiceCreateAndReconcileCesuCheckDepositOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody} comptaCtrlCreateBankCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody: ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankChecksServiceCreateBankCheckDepositNotReconciliedYetOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody} comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody: ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CesuChecksServiceCreateCesuCheckDepositNotReconciliedYetOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckLotBody} comptaCtrlCreateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody: ComptaCtrlCreateCesuCheckLotBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CesuChecksServiceCreateCesuCheckLotOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComptaCtrlCreateCustomerBankCheckBody} comptaCtrlCreateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody: ComptaCtrlCreateCustomerBankCheckBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankChecksServiceCreateCustomerBankCheckOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankCheckDepositReconcilied(bankCheckDepositId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBankCheckDepositReconcilied(bankCheckDepositId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCesuCheckDepositReconcilied(cesuCheckDepositId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCesuCheckDepositReconcilied(cesuCheckDepositId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCesuCheckLot(lotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCesuCheckLot(lotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerBankCheck(bankCheckId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerBankCheck(bankCheckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachAiciPayback(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachAiciPayback(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachAiciPayment(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachAiciPayment(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachAsapPayment(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachAsapPayment(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachCustomerTransferCashin(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachCustomerTransferCashin(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachCustomerTransferPayback(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachCustomerTransferPayback(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachTeacherPayment(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachTeacherPayment(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachTeacherTransferPayback(bankTransactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachTeacherTransferPayback(bankTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAFFJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAFFJournalCSV(exercice, encoding, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllJournalsArchive(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllJournalsArchive(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalanceClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalanceClientsCSV(exercice, encoding, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalanceProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalanceProfsCSV(exercice, encoding, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankJournalsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankJournalsCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCESUJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCESUJournalCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCHQJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCHQJournalCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGCLJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGCLJournalCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrandLivreClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrandLivreClientsCSV(exercice, encoding, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrandLivreProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrandLivreProfsCSV(exercice, encoding, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMixedJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMixedJournalCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVENJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVENJournalCSV(exercice, encoding, format, tiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recomputeCustomerAutoLettrage(getPreviewListRequest: GetPreviewListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recomputeCustomerAutoLettrage(getPreviewListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {ComptaCtrlReconcileBankCheckDepositBody} comptaCtrlReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reconcileBankCheckDeposit(bankCheckDepositId: number, comptaCtrlReconcileBankCheckDepositBody: ComptaCtrlReconcileBankCheckDepositBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reconcileBankCheckDeposit(bankCheckDepositId, comptaCtrlReconcileBankCheckDepositBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {ComptaCtrlReconcileCesuCheckDepositBody} comptaCtrlReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reconcileCesuCheckDeposit(cesuCheckDepositId: number, comptaCtrlReconcileCesuCheckDepositBody: ComptaCtrlReconcileCesuCheckDepositBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reconcileCesuCheckDeposit(cesuCheckDepositId, comptaCtrlReconcileCesuCheckDepositBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resyncPontoAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResyncPontoAccounts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resyncPontoAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MarginSimulatorServiceSimulatePayslipPayload} marginSimulatorServiceSimulatePayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulatePayslip(marginSimulatorServiceSimulatePayslipPayload: MarginSimulatorServiceSimulatePayslipPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulatePayslip200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulatePayslip(marginSimulatorServiceSimulatePayslipPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncGocardlessPayoutsAndPayments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncGocardlessPayoutsAndPayments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncGocardlessPayoutsAndPayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} gocardlessPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSingleGocarlessPayment(gocardlessPaymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSingleGocarlessPayment(gocardlessPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lotId 
         * @param {ComptaCtrlUpdateCesuCheckLotBody} comptaCtrlUpdateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCesuCheckLot(lotId: number, comptaCtrlUpdateCesuCheckLotBody: ComptaCtrlUpdateCesuCheckLotBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCesuCheckLot(lotId, comptaCtrlUpdateCesuCheckLotBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {ComptaCtrlUpdateCustomerBankCheckBody} comptaCtrlUpdateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerBankCheck(bankCheckId: number, comptaCtrlUpdateCustomerBankCheckBody: ComptaCtrlUpdateCustomerBankCheckBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerBankCheck(bankCheckId, comptaCtrlUpdateCustomerBankCheckBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComptaApi - factory interface
 * @export
 */
export const ComptaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComptaApiFp(configuration)
    return {
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaybackBody} comptaCtrlAffectAiciPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAiciPayback(bankTransactionId: number, comptaCtrlAffectAiciPaybackBody: ComptaCtrlAffectAiciPaybackBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectAiciPayback(bankTransactionId, comptaCtrlAffectAiciPaybackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAiciPaymentBody} comptaCtrlAffectAiciPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAiciPayment(bankTransactionId: number, comptaCtrlAffectAiciPaymentBody: ComptaCtrlAffectAiciPaymentBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectAiciPayment(bankTransactionId, comptaCtrlAffectAiciPaymentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectAsapPaymentBody} comptaCtrlAffectAsapPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectAsapPayment(bankTransactionId: number, comptaCtrlAffectAsapPaymentBody: ComptaCtrlAffectAsapPaymentBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectAsapPayment(bankTransactionId, comptaCtrlAffectAsapPaymentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferCashinBody} comptaCtrlAffectCustomerTransferCashinBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectCustomerTransferCashin(bankTransactionId: number, comptaCtrlAffectCustomerTransferCashinBody: ComptaCtrlAffectCustomerTransferCashinBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectCustomerTransferCashin(bankTransactionId, comptaCtrlAffectCustomerTransferCashinBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectCustomerTransferPaybackBody} comptaCtrlAffectCustomerTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectCustomerTransferPayback(bankTransactionId: number, comptaCtrlAffectCustomerTransferPaybackBody: ComptaCtrlAffectCustomerTransferPaybackBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectCustomerTransferPayback(bankTransactionId, comptaCtrlAffectCustomerTransferPaybackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {ComptaCtrlAffectTeacherTransferPaybackBody} comptaCtrlAffectTeacherTransferPaybackBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affectTeacherTransferPayback(bankTransactionId: number, comptaCtrlAffectTeacherTransferPaybackBody: ComptaCtrlAffectTeacherTransferPaybackBody, options?: any): AxiosPromise<void> {
            return localVarFp.affectTeacherTransferPayback(bankTransactionId, comptaCtrlAffectTeacherTransferPaybackBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileBankCheckDepositBody} comptaCtrlCreateAndReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody: ComptaCtrlCreateAndReconcileBankCheckDepositBody, options?: any): AxiosPromise<BankChecksServiceCreateAndReconcileBankCheckDepositOutput> {
            return localVarFp.createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateAndReconcileCesuCheckDepositBody} comptaCtrlCreateAndReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody: ComptaCtrlCreateAndReconcileCesuCheckDepositBody, options?: any): AxiosPromise<CesuChecksServiceCreateAndReconcileCesuCheckDepositOutput> {
            return localVarFp.createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody} comptaCtrlCreateBankCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody: ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options?: any): AxiosPromise<BankChecksServiceCreateBankCheckDepositNotReconciliedYetOutput> {
            return localVarFp.createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody} comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody: ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options?: any): AxiosPromise<CesuChecksServiceCreateCesuCheckDepositNotReconciliedYetOutput> {
            return localVarFp.createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateCesuCheckLotBody} comptaCtrlCreateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody: ComptaCtrlCreateCesuCheckLotBody, options?: any): AxiosPromise<CesuChecksServiceCreateCesuCheckLotOutput> {
            return localVarFp.createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComptaCtrlCreateCustomerBankCheckBody} comptaCtrlCreateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody: ComptaCtrlCreateCustomerBankCheckBody, options?: any): AxiosPromise<BankChecksServiceCreateCustomerBankCheckOutput> {
            return localVarFp.createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankCheckDepositReconcilied(bankCheckDepositId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBankCheckDepositReconcilied(bankCheckDepositId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckDepositReconcilied(cesuCheckDepositId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCesuCheckDepositReconcilied(cesuCheckDepositId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCesuCheckLot(lotId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCesuCheckLot(lotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerBankCheck(bankCheckId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerBankCheck(bankCheckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAiciPayback(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachAiciPayback(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAiciPayment(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachAiciPayment(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachAsapPayment(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachAsapPayment(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachCustomerTransferCashin(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachCustomerTransferCashin(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachCustomerTransferPayback(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachCustomerTransferPayback(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTeacherPayment(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachTeacherPayment(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachTeacherTransferPayback(bankTransactionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.detachTeacherTransferPayback(bankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAFFJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: any): AxiosPromise<void> {
            return localVarFp.getAFFJournalCSV(exercice, encoding, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllJournalsArchive(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getAllJournalsArchive(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: any): AxiosPromise<void> {
            return localVarFp.getBalanceClientsCSV(exercice, encoding, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalanceProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: any): AxiosPromise<void> {
            return localVarFp.getBalanceProfsCSV(exercice, encoding, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankJournalsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getBankJournalsCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCESUJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getCESUJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCHQJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getCHQJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGCLJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getGCLJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrandLivreClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: any): AxiosPromise<void> {
            return localVarFp.getGrandLivreClientsCSV(exercice, encoding, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrandLivreProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: any): AxiosPromise<void> {
            return localVarFp.getGrandLivreProfsCSV(exercice, encoding, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMixedJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getMixedJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exercice 
         * @param {BufferBufferEncoding} [encoding] 
         * @param {'fr' | 'en'} [format] 
         * @param {boolean} [tiers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVENJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getVENJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recomputeCustomerAutoLettrage(getPreviewListRequest: GetPreviewListRequest, options?: any): AxiosPromise<void> {
            return localVarFp.recomputeCustomerAutoLettrage(getPreviewListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankCheckDepositId 
         * @param {ComptaCtrlReconcileBankCheckDepositBody} comptaCtrlReconcileBankCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileBankCheckDeposit(bankCheckDepositId: number, comptaCtrlReconcileBankCheckDepositBody: ComptaCtrlReconcileBankCheckDepositBody, options?: any): AxiosPromise<void> {
            return localVarFp.reconcileBankCheckDeposit(bankCheckDepositId, comptaCtrlReconcileBankCheckDepositBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} cesuCheckDepositId 
         * @param {ComptaCtrlReconcileCesuCheckDepositBody} comptaCtrlReconcileCesuCheckDepositBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconcileCesuCheckDeposit(cesuCheckDepositId: number, comptaCtrlReconcileCesuCheckDepositBody: ComptaCtrlReconcileCesuCheckDepositBody, options?: any): AxiosPromise<void> {
            return localVarFp.reconcileCesuCheckDeposit(cesuCheckDepositId, comptaCtrlReconcileCesuCheckDepositBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resyncPontoAccounts(options?: any): AxiosPromise<ResyncPontoAccounts200Response> {
            return localVarFp.resyncPontoAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MarginSimulatorServiceSimulatePayslipPayload} marginSimulatorServiceSimulatePayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulatePayslip(marginSimulatorServiceSimulatePayslipPayload: MarginSimulatorServiceSimulatePayslipPayload, options?: any): AxiosPromise<SimulatePayslip200Response> {
            return localVarFp.simulatePayslip(marginSimulatorServiceSimulatePayslipPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGocardlessPayoutsAndPayments(options?: any): AxiosPromise<SyncGocardlessPayoutsAndPayments200Response> {
            return localVarFp.syncGocardlessPayoutsAndPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} gocardlessPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleGocarlessPayment(gocardlessPaymentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.syncSingleGocarlessPayment(gocardlessPaymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lotId 
         * @param {ComptaCtrlUpdateCesuCheckLotBody} comptaCtrlUpdateCesuCheckLotBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCesuCheckLot(lotId: number, comptaCtrlUpdateCesuCheckLotBody: ComptaCtrlUpdateCesuCheckLotBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateCesuCheckLot(lotId, comptaCtrlUpdateCesuCheckLotBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} bankCheckId 
         * @param {ComptaCtrlUpdateCustomerBankCheckBody} comptaCtrlUpdateCustomerBankCheckBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerBankCheck(bankCheckId: number, comptaCtrlUpdateCustomerBankCheckBody: ComptaCtrlUpdateCustomerBankCheckBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateCustomerBankCheck(bankCheckId, comptaCtrlUpdateCustomerBankCheckBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComptaApi - object-oriented interface
 * @export
 * @class ComptaApi
 * @extends {BaseAPI}
 */
export class ComptaApi extends BaseAPI {
    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectAiciPaybackBody} comptaCtrlAffectAiciPaybackBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectAiciPayback(bankTransactionId: number, comptaCtrlAffectAiciPaybackBody: ComptaCtrlAffectAiciPaybackBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectAiciPayback(bankTransactionId, comptaCtrlAffectAiciPaybackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectAiciPaymentBody} comptaCtrlAffectAiciPaymentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectAiciPayment(bankTransactionId: number, comptaCtrlAffectAiciPaymentBody: ComptaCtrlAffectAiciPaymentBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectAiciPayment(bankTransactionId, comptaCtrlAffectAiciPaymentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectAsapPaymentBody} comptaCtrlAffectAsapPaymentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectAsapPayment(bankTransactionId: number, comptaCtrlAffectAsapPaymentBody: ComptaCtrlAffectAsapPaymentBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectAsapPayment(bankTransactionId, comptaCtrlAffectAsapPaymentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectCustomerTransferCashinBody} comptaCtrlAffectCustomerTransferCashinBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectCustomerTransferCashin(bankTransactionId: number, comptaCtrlAffectCustomerTransferCashinBody: ComptaCtrlAffectCustomerTransferCashinBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectCustomerTransferCashin(bankTransactionId, comptaCtrlAffectCustomerTransferCashinBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectCustomerTransferPaybackBody} comptaCtrlAffectCustomerTransferPaybackBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectCustomerTransferPayback(bankTransactionId: number, comptaCtrlAffectCustomerTransferPaybackBody: ComptaCtrlAffectCustomerTransferPaybackBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectCustomerTransferPayback(bankTransactionId, comptaCtrlAffectCustomerTransferPaybackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {ComptaCtrlAffectTeacherTransferPaybackBody} comptaCtrlAffectTeacherTransferPaybackBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public affectTeacherTransferPayback(bankTransactionId: number, comptaCtrlAffectTeacherTransferPaybackBody: ComptaCtrlAffectTeacherTransferPaybackBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).affectTeacherTransferPayback(bankTransactionId, comptaCtrlAffectTeacherTransferPaybackBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateAndReconcileBankCheckDepositBody} comptaCtrlCreateAndReconcileBankCheckDepositBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody: ComptaCtrlCreateAndReconcileBankCheckDepositBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createAndReconcileBankCheckDeposit(comptaCtrlCreateAndReconcileBankCheckDepositBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateAndReconcileCesuCheckDepositBody} comptaCtrlCreateAndReconcileCesuCheckDepositBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody: ComptaCtrlCreateAndReconcileCesuCheckDepositBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createAndReconcileCesuCheckDeposit(comptaCtrlCreateAndReconcileCesuCheckDepositBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody} comptaCtrlCreateBankCheckDepositNotReconciliedYetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody: ComptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createBankCheckDepositNotReconciliedYet(comptaCtrlCreateBankCheckDepositNotReconciliedYetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody} comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody: ComptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createCesuCheckDepositNotReconciliedYet(comptaCtrlCreateCesuCheckDepositNotReconciliedYetBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateCesuCheckLotBody} comptaCtrlCreateCesuCheckLotBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody: ComptaCtrlCreateCesuCheckLotBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createCesuCheckLot(comptaCtrlCreateCesuCheckLotBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComptaCtrlCreateCustomerBankCheckBody} comptaCtrlCreateCustomerBankCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody: ComptaCtrlCreateCustomerBankCheckBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).createCustomerBankCheck(comptaCtrlCreateCustomerBankCheckBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankCheckDepositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteBankCheckDepositNotReconciliedYet(bankCheckDepositId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankCheckDepositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteBankCheckDepositReconcilied(bankCheckDepositId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteBankCheckDepositReconcilied(bankCheckDepositId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cesuCheckDepositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteCesuCheckDepositNotReconciliedYet(cesuCheckDepositId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cesuCheckDepositId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteCesuCheckDepositReconcilied(cesuCheckDepositId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteCesuCheckDepositReconcilied(cesuCheckDepositId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lotId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteCesuCheckLot(lotId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteCesuCheckLot(lotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankCheckId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public deleteCustomerBankCheck(bankCheckId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).deleteCustomerBankCheck(bankCheckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachAiciPayback(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachAiciPayback(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachAiciPayment(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachAiciPayment(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachAsapPayment(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachAsapPayment(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachCustomerTransferCashin(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachCustomerTransferCashin(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachCustomerTransferPayback(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachCustomerTransferPayback(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachTeacherPayment(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachTeacherPayment(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public detachTeacherTransferPayback(bankTransactionId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).detachTeacherTransferPayback(bankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getAFFJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getAFFJournalCSV(exercice, encoding, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getAllJournalsArchive(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getAllJournalsArchive(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getBalanceClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getBalanceClientsCSV(exercice, encoding, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getBalanceProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getBalanceProfsCSV(exercice, encoding, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getBankJournalsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getBankJournalsCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getCESUJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getCESUJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getCHQJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getCHQJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getGCLJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getGCLJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getGrandLivreClientsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getGrandLivreClientsCSV(exercice, encoding, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getGrandLivreProfsCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getGrandLivreProfsCSV(exercice, encoding, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getMixedJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getMixedJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exercice 
     * @param {BufferBufferEncoding} [encoding] 
     * @param {'fr' | 'en'} [format] 
     * @param {boolean} [tiers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public getVENJournalCSV(exercice: number, encoding?: BufferBufferEncoding, format?: 'fr' | 'en', tiers?: boolean, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).getVENJournalCSV(exercice, encoding, format, tiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPreviewListRequest} getPreviewListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public recomputeCustomerAutoLettrage(getPreviewListRequest: GetPreviewListRequest, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).recomputeCustomerAutoLettrage(getPreviewListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankCheckDepositId 
     * @param {ComptaCtrlReconcileBankCheckDepositBody} comptaCtrlReconcileBankCheckDepositBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public reconcileBankCheckDeposit(bankCheckDepositId: number, comptaCtrlReconcileBankCheckDepositBody: ComptaCtrlReconcileBankCheckDepositBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).reconcileBankCheckDeposit(bankCheckDepositId, comptaCtrlReconcileBankCheckDepositBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} cesuCheckDepositId 
     * @param {ComptaCtrlReconcileCesuCheckDepositBody} comptaCtrlReconcileCesuCheckDepositBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public reconcileCesuCheckDeposit(cesuCheckDepositId: number, comptaCtrlReconcileCesuCheckDepositBody: ComptaCtrlReconcileCesuCheckDepositBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).reconcileCesuCheckDeposit(cesuCheckDepositId, comptaCtrlReconcileCesuCheckDepositBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public resyncPontoAccounts(options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).resyncPontoAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MarginSimulatorServiceSimulatePayslipPayload} marginSimulatorServiceSimulatePayslipPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public simulatePayslip(marginSimulatorServiceSimulatePayslipPayload: MarginSimulatorServiceSimulatePayslipPayload, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).simulatePayslip(marginSimulatorServiceSimulatePayslipPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public syncGocardlessPayoutsAndPayments(options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).syncGocardlessPayoutsAndPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} gocardlessPaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public syncSingleGocarlessPayment(gocardlessPaymentId: number, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).syncSingleGocarlessPayment(gocardlessPaymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lotId 
     * @param {ComptaCtrlUpdateCesuCheckLotBody} comptaCtrlUpdateCesuCheckLotBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public updateCesuCheckLot(lotId: number, comptaCtrlUpdateCesuCheckLotBody: ComptaCtrlUpdateCesuCheckLotBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).updateCesuCheckLot(lotId, comptaCtrlUpdateCesuCheckLotBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} bankCheckId 
     * @param {ComptaCtrlUpdateCustomerBankCheckBody} comptaCtrlUpdateCustomerBankCheckBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComptaApi
     */
    public updateCustomerBankCheck(bankCheckId: number, comptaCtrlUpdateCustomerBankCheckBody: ComptaCtrlUpdateCustomerBankCheckBody, options?: AxiosRequestConfig) {
        return ComptaApiFp(this.configuration).updateCustomerBankCheck(bankCheckId, comptaCtrlUpdateCustomerBankCheckBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerValidationApi - axios parameter creator
 * @export
 */
export const CustomerValidationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CustomerValidationCtrlAbortAwaitingBody} customerValidationCtrlAbortAwaitingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortAwaitingCustomerProposition: async (customerValidationCtrlAbortAwaitingBody: CustomerValidationCtrlAbortAwaitingBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerValidationCtrlAbortAwaitingBody' is not null or undefined
            assertParamExists('abortAwaitingCustomerProposition', 'customerValidationCtrlAbortAwaitingBody', customerValidationCtrlAbortAwaitingBody)
            const localVarPath = `/customer-validation/abort-awaiting-customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlAbortAwaitingBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerValidationCtrlAbortBody} customerValidationCtrlAbortBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortCustomerProposition: async (customerValidationCtrlAbortBody: CustomerValidationCtrlAbortBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerValidationCtrlAbortBody' is not null or undefined
            assertParamExists('abortCustomerProposition', 'customerValidationCtrlAbortBody', customerValidationCtrlAbortBody)
            const localVarPath = `/customer-validation/abort-customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlAbortBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerValidationCtrlDeleteBody} customerValidationCtrlDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProposition: async (customerValidationCtrlDeleteBody: CustomerValidationCtrlDeleteBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerValidationCtrlDeleteBody' is not null or undefined
            assertParamExists('deleteCustomerProposition', 'customerValidationCtrlDeleteBody', customerValidationCtrlDeleteBody)
            const localVarPath = `/customer-validation/delete-customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlDeleteBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerValidationCtrlForceBody} customerValidationCtrlForceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCustomerProposition: async (customerValidationCtrlForceBody: CustomerValidationCtrlForceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerValidationCtrlForceBody' is not null or undefined
            assertParamExists('forceCustomerProposition', 'customerValidationCtrlForceBody', customerValidationCtrlForceBody)
            const localVarPath = `/customer-validation/force-customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlForceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProcedureData: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getCurrentProcedureData', 'token', token)
            const localVarPath = `/customer-validation/current-procedure-data/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInfos: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getCustomerInfos', 'token', token)
            const localVarPath = `/customer-validation/customer-infos/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initYousignProcedure: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('initYousignProcedure', 'token', token)
            const localVarPath = `/customer-validation/init-yousign-procedure/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAndSendToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('refreshAndSendToken', 'token', token)
            const localVarPath = `/customer-validation/refresh-and-send-token/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciInfoFormBody} customerValidationCtrlSaveAiciInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAiciInfoForm: async (token: string, customerValidationCtrlSaveAiciInfoFormBody: CustomerValidationCtrlSaveAiciInfoFormBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('saveAiciInfoForm', 'token', token)
            // verify required parameter 'customerValidationCtrlSaveAiciInfoFormBody' is not null or undefined
            assertParamExists('saveAiciInfoForm', 'customerValidationCtrlSaveAiciInfoFormBody', customerValidationCtrlSaveAiciInfoFormBody)
            const localVarPath = `/customer-validation/save-aici-info-form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlSaveAiciInfoFormBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciModeChoiceBody} customerValidationCtrlSaveAiciModeChoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAiciModeChoice: async (token: string, customerValidationCtrlSaveAiciModeChoiceBody: CustomerValidationCtrlSaveAiciModeChoiceBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('saveAiciModeChoice', 'token', token)
            // verify required parameter 'customerValidationCtrlSaveAiciModeChoiceBody' is not null or undefined
            assertParamExists('saveAiciModeChoice', 'customerValidationCtrlSaveAiciModeChoiceBody', customerValidationCtrlSaveAiciModeChoiceBody)
            const localVarPath = `/customer-validation/save-aici-mode-choice/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlSaveAiciModeChoiceBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveBirthOnlyInfoFormBody} customerValidationCtrlSaveBirthOnlyInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBirthOnlyInfoForm: async (token: string, customerValidationCtrlSaveBirthOnlyInfoFormBody: CustomerValidationCtrlSaveBirthOnlyInfoFormBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('saveBirthOnlyInfoForm', 'token', token)
            // verify required parameter 'customerValidationCtrlSaveBirthOnlyInfoFormBody' is not null or undefined
            assertParamExists('saveBirthOnlyInfoForm', 'customerValidationCtrlSaveBirthOnlyInfoFormBody', customerValidationCtrlSaveBirthOnlyInfoFormBody)
            const localVarPath = `/customer-validation/save-birth-only-info-form/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlSaveBirthOnlyInfoFormBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLinkClicked: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('saveLinkClicked', 'token', token)
            const localVarPath = `/customer-validation/save-link-clicked/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerValidationCtrlStartBody} customerValidationCtrlStartBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCustomerProposition: async (customerValidationCtrlStartBody: CustomerValidationCtrlStartBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerValidationCtrlStartBody' is not null or undefined
            assertParamExists('startCustomerProposition', 'customerValidationCtrlStartBody', customerValidationCtrlStartBody)
            const localVarPath = `/customer-validation/start-customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerValidationCtrlStartBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerValidationApi - functional programming interface
 * @export
 */
export const CustomerValidationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerValidationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CustomerValidationCtrlAbortAwaitingBody} customerValidationCtrlAbortAwaitingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody: CustomerValidationCtrlAbortAwaitingBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerValidationCtrlAbortBody} customerValidationCtrlAbortBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortCustomerProposition(customerValidationCtrlAbortBody: CustomerValidationCtrlAbortBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortCustomerProposition(customerValidationCtrlAbortBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerValidationCtrlDeleteBody} customerValidationCtrlDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomerProposition(customerValidationCtrlDeleteBody: CustomerValidationCtrlDeleteBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomerProposition(customerValidationCtrlDeleteBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerValidationCtrlForceBody} customerValidationCtrlForceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceCustomerProposition(customerValidationCtrlForceBody: CustomerValidationCtrlForceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceCustomerProposition(customerValidationCtrlForceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentProcedureData(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationProcessServiceGetCurrentProcedureDataOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentProcedureData(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerInfos(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationProcessServiceGetCustomerInfosOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerInfos(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initYousignProcedure(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitYousignProcedure200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initYousignProcedure(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshAndSendToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshAndSendToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciInfoFormBody} customerValidationCtrlSaveAiciInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAiciInfoForm(token: string, customerValidationCtrlSaveAiciInfoFormBody: CustomerValidationCtrlSaveAiciInfoFormBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAiciInfoForm(token, customerValidationCtrlSaveAiciInfoFormBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciModeChoiceBody} customerValidationCtrlSaveAiciModeChoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAiciModeChoice(token: string, customerValidationCtrlSaveAiciModeChoiceBody: CustomerValidationCtrlSaveAiciModeChoiceBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAiciModeChoice(token, customerValidationCtrlSaveAiciModeChoiceBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveBirthOnlyInfoFormBody} customerValidationCtrlSaveBirthOnlyInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveBirthOnlyInfoForm(token: string, customerValidationCtrlSaveBirthOnlyInfoFormBody: CustomerValidationCtrlSaveBirthOnlyInfoFormBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveBirthOnlyInfoForm(token, customerValidationCtrlSaveBirthOnlyInfoFormBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLinkClicked(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLinkClicked(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerValidationCtrlStartBody} customerValidationCtrlStartBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startCustomerProposition(customerValidationCtrlStartBody: CustomerValidationCtrlStartBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartCustomerProposition200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startCustomerProposition(customerValidationCtrlStartBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerValidationApi - factory interface
 * @export
 */
export const CustomerValidationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerValidationApiFp(configuration)
    return {
        /**
         * 
         * @param {CustomerValidationCtrlAbortAwaitingBody} customerValidationCtrlAbortAwaitingBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody: CustomerValidationCtrlAbortAwaitingBody, options?: any): AxiosPromise<void> {
            return localVarFp.abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerValidationCtrlAbortBody} customerValidationCtrlAbortBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortCustomerProposition(customerValidationCtrlAbortBody: CustomerValidationCtrlAbortBody, options?: any): AxiosPromise<void> {
            return localVarFp.abortCustomerProposition(customerValidationCtrlAbortBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerValidationCtrlDeleteBody} customerValidationCtrlDeleteBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerProposition(customerValidationCtrlDeleteBody: CustomerValidationCtrlDeleteBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomerProposition(customerValidationCtrlDeleteBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerValidationCtrlForceBody} customerValidationCtrlForceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceCustomerProposition(customerValidationCtrlForceBody: CustomerValidationCtrlForceBody, options?: any): AxiosPromise<void> {
            return localVarFp.forceCustomerProposition(customerValidationCtrlForceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentProcedureData(token: string, options?: any): AxiosPromise<ValidationProcessServiceGetCurrentProcedureDataOutput> {
            return localVarFp.getCurrentProcedureData(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInfos(token: string, options?: any): AxiosPromise<ValidationProcessServiceGetCustomerInfosOutput> {
            return localVarFp.getCustomerInfos(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initYousignProcedure(token: string, options?: any): AxiosPromise<InitYousignProcedure200Response> {
            return localVarFp.initYousignProcedure(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAndSendToken(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.refreshAndSendToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciInfoFormBody} customerValidationCtrlSaveAiciInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAiciInfoForm(token: string, customerValidationCtrlSaveAiciInfoFormBody: CustomerValidationCtrlSaveAiciInfoFormBody, options?: any): AxiosPromise<void> {
            return localVarFp.saveAiciInfoForm(token, customerValidationCtrlSaveAiciInfoFormBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveAiciModeChoiceBody} customerValidationCtrlSaveAiciModeChoiceBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAiciModeChoice(token: string, customerValidationCtrlSaveAiciModeChoiceBody: CustomerValidationCtrlSaveAiciModeChoiceBody, options?: any): AxiosPromise<void> {
            return localVarFp.saveAiciModeChoice(token, customerValidationCtrlSaveAiciModeChoiceBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {CustomerValidationCtrlSaveBirthOnlyInfoFormBody} customerValidationCtrlSaveBirthOnlyInfoFormBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveBirthOnlyInfoForm(token: string, customerValidationCtrlSaveBirthOnlyInfoFormBody: CustomerValidationCtrlSaveBirthOnlyInfoFormBody, options?: any): AxiosPromise<void> {
            return localVarFp.saveBirthOnlyInfoForm(token, customerValidationCtrlSaveBirthOnlyInfoFormBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLinkClicked(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.saveLinkClicked(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerValidationCtrlStartBody} customerValidationCtrlStartBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startCustomerProposition(customerValidationCtrlStartBody: CustomerValidationCtrlStartBody, options?: any): AxiosPromise<StartCustomerProposition200Response> {
            return localVarFp.startCustomerProposition(customerValidationCtrlStartBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerValidationApi - object-oriented interface
 * @export
 * @class CustomerValidationApi
 * @extends {BaseAPI}
 */
export class CustomerValidationApi extends BaseAPI {
    /**
     * 
     * @param {CustomerValidationCtrlAbortAwaitingBody} customerValidationCtrlAbortAwaitingBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody: CustomerValidationCtrlAbortAwaitingBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).abortAwaitingCustomerProposition(customerValidationCtrlAbortAwaitingBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerValidationCtrlAbortBody} customerValidationCtrlAbortBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public abortCustomerProposition(customerValidationCtrlAbortBody: CustomerValidationCtrlAbortBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).abortCustomerProposition(customerValidationCtrlAbortBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerValidationCtrlDeleteBody} customerValidationCtrlDeleteBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public deleteCustomerProposition(customerValidationCtrlDeleteBody: CustomerValidationCtrlDeleteBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).deleteCustomerProposition(customerValidationCtrlDeleteBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerValidationCtrlForceBody} customerValidationCtrlForceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public forceCustomerProposition(customerValidationCtrlForceBody: CustomerValidationCtrlForceBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).forceCustomerProposition(customerValidationCtrlForceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public getCurrentProcedureData(token: string, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).getCurrentProcedureData(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public getCustomerInfos(token: string, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).getCustomerInfos(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public initYousignProcedure(token: string, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).initYousignProcedure(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public refreshAndSendToken(token: string, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).refreshAndSendToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {CustomerValidationCtrlSaveAiciInfoFormBody} customerValidationCtrlSaveAiciInfoFormBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public saveAiciInfoForm(token: string, customerValidationCtrlSaveAiciInfoFormBody: CustomerValidationCtrlSaveAiciInfoFormBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).saveAiciInfoForm(token, customerValidationCtrlSaveAiciInfoFormBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {CustomerValidationCtrlSaveAiciModeChoiceBody} customerValidationCtrlSaveAiciModeChoiceBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public saveAiciModeChoice(token: string, customerValidationCtrlSaveAiciModeChoiceBody: CustomerValidationCtrlSaveAiciModeChoiceBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).saveAiciModeChoice(token, customerValidationCtrlSaveAiciModeChoiceBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {CustomerValidationCtrlSaveBirthOnlyInfoFormBody} customerValidationCtrlSaveBirthOnlyInfoFormBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public saveBirthOnlyInfoForm(token: string, customerValidationCtrlSaveBirthOnlyInfoFormBody: CustomerValidationCtrlSaveBirthOnlyInfoFormBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).saveBirthOnlyInfoForm(token, customerValidationCtrlSaveBirthOnlyInfoFormBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public saveLinkClicked(token: string, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).saveLinkClicked(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerValidationCtrlStartBody} customerValidationCtrlStartBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerValidationApi
     */
    public startCustomerProposition(customerValidationCtrlStartBody: CustomerValidationCtrlStartBody, options?: AxiosRequestConfig) {
        return CustomerValidationApiFp(this.configuration).startCustomerProposition(customerValidationCtrlStartBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DbApi - axios parameter creator
 * @export
 */
export const DbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/db/level-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/db/mobilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachingGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/db/teaching-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DbApi - functional programming interface
 * @export
 */
export const DbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async levelGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.levelGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobilities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PickDryMobilityExcludeKeyofDryMobilityTeachers>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobilities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teachingGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teachingGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DbApi - factory interface
 * @export
 */
export const DbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DbApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        levelGroups(options?: any): AxiosPromise<Array<PickDryLevelGroupExcludeKeyofDryLevelGroupLevelsOrConciseLevels>> {
            return localVarFp.levelGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobilities(options?: any): AxiosPromise<Array<PickDryMobilityExcludeKeyofDryMobilityTeachers>> {
            return localVarFp.mobilities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachingGroups(options?: any): AxiosPromise<Array<PickDryTeachingGroupExcludeKeyofDryTeachingGroupTeachings>> {
            return localVarFp.teachingGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DbApi - object-oriented interface
 * @export
 * @class DbApi
 * @extends {BaseAPI}
 */
export class DbApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DbApi
     */
    public levelGroups(options?: AxiosRequestConfig) {
        return DbApiFp(this.configuration).levelGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DbApi
     */
    public mobilities(options?: AxiosRequestConfig) {
        return DbApiFp(this.configuration).mobilities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DbApi
     */
    public teachingGroups(options?: AxiosRequestConfig) {
        return DbApiFp(this.configuration).teachingGroups(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Date} startMmt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachers: async (startMmt: Date, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startMmt' is not null or undefined
            assertParamExists('teachers', 'startMmt', startMmt)
            const localVarPath = `/sync-access/teachers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startMmt !== undefined) {
                localVarQueryParameter['startMmt'] = (startMmt as any instanceof Date) ?
                    (startMmt as any).toISOString() :
                    startMmt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Date} startMmt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teachers(startMmt: Date, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<{ [key: string]: any; }>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teachers(startMmt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {Date} startMmt 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teachers(startMmt: Date, options?: any): AxiosPromise<Array<{ [key: string]: any; }>> {
            return localVarFp.teachers(startMmt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {Date} startMmt 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public teachers(startMmt: Date, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).teachers(startMmt, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailsApi - axios parameter creator
 * @export
 */
export const EmailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmailsSendParams} emailsSendParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send: async (emailsSendParams: EmailsSendParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailsSendParams' is not null or undefined
            assertParamExists('send', 'emailsSendParams', emailsSendParams)
            const localVarPath = `/emails/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailsSendParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailTemplateKey} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        template: async (templateKey: EmailTemplateKey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateKey' is not null or undefined
            assertParamExists('template', 'templateKey', templateKey)
            const localVarPath = `/emails/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateKey !== undefined) {
                localVarQueryParameter['templateKey'] = templateKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailsApi - functional programming interface
 * @export
 */
export const EmailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmailsSendParams} emailsSendParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async send(emailsSendParams: EmailsSendParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.send(emailsSendParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailTemplateKey} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async template(templateKey: EmailTemplateKey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DryEmailTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.template(templateKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailsApi - factory interface
 * @export
 */
export const EmailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailsApiFp(configuration)
    return {
        /**
         * 
         * @param {EmailsSendParams} emailsSendParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        send(emailsSendParams: EmailsSendParams, options?: any): AxiosPromise<void> {
            return localVarFp.send(emailsSendParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailTemplateKey} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        template(templateKey: EmailTemplateKey, options?: any): AxiosPromise<DryEmailTemplate> {
            return localVarFp.template(templateKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailsApi - object-oriented interface
 * @export
 * @class EmailsApi
 * @extends {BaseAPI}
 */
export class EmailsApi extends BaseAPI {
    /**
     * 
     * @param {EmailsSendParams} emailsSendParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApi
     */
    public send(emailsSendParams: EmailsSendParams, options?: AxiosRequestConfig) {
        return EmailsApiFp(this.configuration).send(emailsSendParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailTemplateKey} templateKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApi
     */
    public template(templateKey: EmailTemplateKey, options?: AxiosRequestConfig) {
        return EmailsApiFp(this.configuration).template(templateKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FamiliesApi - axios parameter creator
 * @export
 */
export const FamiliesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginGocardlessRedirectFlow: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('beginGocardlessRedirectFlow', 'familyId', familyId)
            const localVarPath = `/families/{familyId}/gocardless/begin-redirect-flow`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeWeekDispoCustomerParams} changeWeekDispoCustomerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWeekDispoCustomer: async (changeWeekDispoCustomerParams: ChangeWeekDispoCustomerParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeWeekDispoCustomerParams' is not null or undefined
            assertParamExists('changeWeekDispoCustomer', 'changeWeekDispoCustomerParams', changeWeekDispoCustomerParams)
            const localVarPath = `/families/change-week-dispo-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeWeekDispoCustomerParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} redirectFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGocardlessRedirectFlow: async (familyId: number, redirectFlowId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('completeGocardlessRedirectFlow', 'familyId', familyId)
            // verify required parameter 'redirectFlowId' is not null or undefined
            assertParamExists('completeGocardlessRedirectFlow', 'redirectFlowId', redirectFlowId)
            const localVarPath = `/families/{familyId}/gocardless/complete-redirect-flow`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectFlowId !== undefined) {
                localVarQueryParameter['redirect_flow_id'] = redirectFlowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPayslipServiceCreateLessonByAdminSVPL} editPayslipServiceCreateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLessonByAdmin: async (editPayslipServiceCreateLessonByAdminSVPL: EditPayslipServiceCreateLessonByAdminSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPayslipServiceCreateLessonByAdminSVPL' is not null or undefined
            assertParamExists('createLessonByAdmin', 'editPayslipServiceCreateLessonByAdminSVPL', editPayslipServiceCreateLessonByAdminSVPL)
            const localVarPath = `/families/create-lesson-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPayslipServiceCreateLessonByAdminSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPayslipServiceDeleteLessonByAdminSVPL} editPayslipServiceDeleteLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLessonByAdmin: async (editPayslipServiceDeleteLessonByAdminSVPL: EditPayslipServiceDeleteLessonByAdminSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPayslipServiceDeleteLessonByAdminSVPL' is not null or undefined
            assertParamExists('deleteLessonByAdmin', 'editPayslipServiceDeleteLessonByAdminSVPL', editPayslipServiceDeleteLessonByAdminSVPL)
            const localVarPath = `/families/delete-lesson-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPayslipServiceDeleteLessonByAdminSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeletePlannedCallParams} deletePlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlannedCall: async (deletePlannedCallParams: DeletePlannedCallParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deletePlannedCallParams' is not null or undefined
            assertParamExists('deletePlannedCall', 'deletePlannedCallParams', deletePlannedCallParams)
            const localVarPath = `/families/delete-planned-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePlannedCallParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPayslipServiceEditPayslipPayload} editPayslipServiceEditPayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPayslip: async (editPayslipServiceEditPayslipPayload: EditPayslipServiceEditPayslipPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPayslipServiceEditPayslipPayload' is not null or undefined
            assertParamExists('editPayslip', 'editPayslipServiceEditPayslipPayload', editPayslipServiceEditPayslipPayload)
            const localVarPath = `/families/edit-payslip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPayslipServiceEditPayslipPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCalls: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('getPhoneCalls', 'familyId', familyId)
            const localVarPath = `/families/{familyId}/phone-calls`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertPlannedCallParams} insertPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPlannedCall: async (insertPlannedCallParams: InsertPlannedCallParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertPlannedCallParams' is not null or undefined
            assertParamExists('insertPlannedCall', 'insertPlannedCallParams', insertPlannedCallParams)
            const localVarPath = `/families/add-planned-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertPlannedCallParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyCreditsServiceModifyCreditsPayload} modifyCreditsServiceModifyCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCredits: async (modifyCreditsServiceModifyCreditsPayload: ModifyCreditsServiceModifyCreditsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyCreditsServiceModifyCreditsPayload' is not null or undefined
            assertParamExists('modifyCredits', 'modifyCreditsServiceModifyCreditsPayload', modifyCreditsServiceModifyCreditsPayload)
            const localVarPath = `/families/modify-credits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyCreditsServiceModifyCreditsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyPhoneCallParams} modifyPhoneCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPhoneCall: async (modifyPhoneCallParams: ModifyPhoneCallParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyPhoneCallParams' is not null or undefined
            assertParamExists('modifyPhoneCall', 'modifyPhoneCallParams', modifyPhoneCallParams)
            const localVarPath = `/families/modify-phone-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyPhoneCallParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchPlannedCallParams} patchPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPlannedCall: async (patchPlannedCallParams: PatchPlannedCallParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchPlannedCallParams' is not null or undefined
            assertParamExists('patchPlannedCall', 'patchPlannedCallParams', patchPlannedCallParams)
            const localVarPath = `/families/modify-planned-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPlannedCallParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload} swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapCreditInvoiceAICIMode: async (swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload: SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload' is not null or undefined
            assertParamExists('swapCreditInvoiceAICIMode', 'swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload', swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload)
            const localVarPath = `/families/swap-credit-invoice-aici-mode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPlannedCallFromGoogleCalendar: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/families/sync-planned-call`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransferCreditsServiceToggleTransferDiscountPayload} transferCreditsServiceToggleTransferDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleTransferInvoiceDiscount: async (transferCreditsServiceToggleTransferDiscountPayload: TransferCreditsServiceToggleTransferDiscountPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferCreditsServiceToggleTransferDiscountPayload' is not null or undefined
            assertParamExists('toggleTransferInvoiceDiscount', 'transferCreditsServiceToggleTransferDiscountPayload', transferCreditsServiceToggleTransferDiscountPayload)
            const localVarPath = `/families/toggle-transfer-invoice-discount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferCreditsServiceToggleTransferDiscountPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransferCreditsServiceTransferCreditsPayload} transferCreditsServiceTransferCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferCredits: async (transferCreditsServiceTransferCreditsPayload: TransferCreditsServiceTransferCreditsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferCreditsServiceTransferCreditsPayload' is not null or undefined
            assertParamExists('transferCredits', 'transferCreditsServiceTransferCreditsPayload', transferCreditsServiceTransferCreditsPayload)
            const localVarPath = `/families/transfer-credits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferCreditsServiceTransferCreditsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditPayslipServiceUpdateLessonByAdminSVPL} editPayslipServiceUpdateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLessonByAdmin: async (editPayslipServiceUpdateLessonByAdminSVPL: EditPayslipServiceUpdateLessonByAdminSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPayslipServiceUpdateLessonByAdminSVPL' is not null or undefined
            assertParamExists('updateLessonByAdmin', 'editPayslipServiceUpdateLessonByAdminSVPL', editPayslipServiceUpdateLessonByAdminSVPL)
            const localVarPath = `/families/update-lesson-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPayslipServiceUpdateLessonByAdminSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FamiliesApi - functional programming interface
 * @export
 */
export const FamiliesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FamiliesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beginGocardlessRedirectFlow(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GocarlessAffiliationServiceBeginRedirectFlowOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beginGocardlessRedirectFlow(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangeWeekDispoCustomerParams} changeWeekDispoCustomerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeWeekDispoCustomer(changeWeekDispoCustomerParams: ChangeWeekDispoCustomerParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeWeekDispoCustomer(changeWeekDispoCustomerParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} redirectFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeGocardlessRedirectFlow(familyId: number, redirectFlowId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeGocardlessRedirectFlow(familyId, redirectFlowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPayslipServiceCreateLessonByAdminSVPL} editPayslipServiceCreateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL: EditPayslipServiceCreateLessonByAdminSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPayslipServiceDeleteLessonByAdminSVPL} editPayslipServiceDeleteLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL: EditPayslipServiceDeleteLessonByAdminSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeletePlannedCallParams} deletePlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlannedCall(deletePlannedCallParams: DeletePlannedCallParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlannedCall(deletePlannedCallParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPayslipServiceEditPayslipPayload} editPayslipServiceEditPayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editPayslip(editPayslipServiceEditPayslipPayload: EditPayslipServiceEditPayslipPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editPayslip(editPayslipServiceEditPayslipPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoneCalls(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FamilyPhoneCallsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhoneCalls(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InsertPlannedCallParams} insertPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertPlannedCall(insertPlannedCallParams: InsertPlannedCallParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertPlannedCall(insertPlannedCallParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyCreditsServiceModifyCreditsPayload} modifyCreditsServiceModifyCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyCredits(modifyCreditsServiceModifyCreditsPayload: ModifyCreditsServiceModifyCreditsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferCredits200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyCredits(modifyCreditsServiceModifyCreditsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyPhoneCallParams} modifyPhoneCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyPhoneCall(modifyPhoneCallParams: ModifyPhoneCallParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyPhoneCall(modifyPhoneCallParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PatchPlannedCallParams} patchPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPlannedCall(patchPlannedCallParams: PatchPlannedCallParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPlannedCall(patchPlannedCallParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload} swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload: SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPlannedCallFromGoogleCalendar(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncPlannedCallFromGoogleCalendar200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPlannedCallFromGoogleCalendar(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TransferCreditsServiceToggleTransferDiscountPayload} transferCreditsServiceToggleTransferDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload: TransferCreditsServiceToggleTransferDiscountPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TransferCreditsServiceTransferCreditsPayload} transferCreditsServiceTransferCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferCredits(transferCreditsServiceTransferCreditsPayload: TransferCreditsServiceTransferCreditsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransferCredits200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferCredits(transferCreditsServiceTransferCreditsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditPayslipServiceUpdateLessonByAdminSVPL} editPayslipServiceUpdateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL: EditPayslipServiceUpdateLessonByAdminSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FamiliesApi - factory interface
 * @export
 */
export const FamiliesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FamiliesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beginGocardlessRedirectFlow(familyId: number, options?: any): AxiosPromise<GocarlessAffiliationServiceBeginRedirectFlowOutput> {
            return localVarFp.beginGocardlessRedirectFlow(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeWeekDispoCustomerParams} changeWeekDispoCustomerParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWeekDispoCustomer(changeWeekDispoCustomerParams: ChangeWeekDispoCustomerParams, options?: any): AxiosPromise<void> {
            return localVarFp.changeWeekDispoCustomer(changeWeekDispoCustomerParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {string} redirectFlowId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGocardlessRedirectFlow(familyId: number, redirectFlowId: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeGocardlessRedirectFlow(familyId, redirectFlowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPayslipServiceCreateLessonByAdminSVPL} editPayslipServiceCreateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL: EditPayslipServiceCreateLessonByAdminSVPL, options?: any): AxiosPromise<void> {
            return localVarFp.createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPayslipServiceDeleteLessonByAdminSVPL} editPayslipServiceDeleteLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL: EditPayslipServiceDeleteLessonByAdminSVPL, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeletePlannedCallParams} deletePlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlannedCall(deletePlannedCallParams: DeletePlannedCallParams, options?: any): AxiosPromise<void> {
            return localVarFp.deletePlannedCall(deletePlannedCallParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPayslipServiceEditPayslipPayload} editPayslipServiceEditPayslipPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editPayslip(editPayslipServiceEditPayslipPayload: EditPayslipServiceEditPayslipPayload, options?: any): AxiosPromise<void> {
            return localVarFp.editPayslip(editPayslipServiceEditPayslipPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCalls(familyId: number, options?: any): AxiosPromise<Array<FamilyPhoneCallsItem>> {
            return localVarFp.getPhoneCalls(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertPlannedCallParams} insertPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertPlannedCall(insertPlannedCallParams: InsertPlannedCallParams, options?: any): AxiosPromise<any> {
            return localVarFp.insertPlannedCall(insertPlannedCallParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyCreditsServiceModifyCreditsPayload} modifyCreditsServiceModifyCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyCredits(modifyCreditsServiceModifyCreditsPayload: ModifyCreditsServiceModifyCreditsPayload, options?: any): AxiosPromise<TransferCredits200Response> {
            return localVarFp.modifyCredits(modifyCreditsServiceModifyCreditsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyPhoneCallParams} modifyPhoneCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyPhoneCall(modifyPhoneCallParams: ModifyPhoneCallParams, options?: any): AxiosPromise<any> {
            return localVarFp.modifyPhoneCall(modifyPhoneCallParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchPlannedCallParams} patchPlannedCallParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPlannedCall(patchPlannedCallParams: PatchPlannedCallParams, options?: any): AxiosPromise<any> {
            return localVarFp.patchPlannedCall(patchPlannedCallParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload} swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload: SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options?: any): AxiosPromise<void> {
            return localVarFp.swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPlannedCallFromGoogleCalendar(options?: any): AxiosPromise<SyncPlannedCallFromGoogleCalendar200Response> {
            return localVarFp.syncPlannedCallFromGoogleCalendar(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransferCreditsServiceToggleTransferDiscountPayload} transferCreditsServiceToggleTransferDiscountPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload: TransferCreditsServiceToggleTransferDiscountPayload, options?: any): AxiosPromise<void> {
            return localVarFp.toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransferCreditsServiceTransferCreditsPayload} transferCreditsServiceTransferCreditsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferCredits(transferCreditsServiceTransferCreditsPayload: TransferCreditsServiceTransferCreditsPayload, options?: any): AxiosPromise<TransferCredits200Response> {
            return localVarFp.transferCredits(transferCreditsServiceTransferCreditsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditPayslipServiceUpdateLessonByAdminSVPL} editPayslipServiceUpdateLessonByAdminSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL: EditPayslipServiceUpdateLessonByAdminSVPL, options?: any): AxiosPromise<void> {
            return localVarFp.updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FamiliesApi - object-oriented interface
 * @export
 * @class FamiliesApi
 * @extends {BaseAPI}
 */
export class FamiliesApi extends BaseAPI {
    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public beginGocardlessRedirectFlow(familyId: number, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).beginGocardlessRedirectFlow(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeWeekDispoCustomerParams} changeWeekDispoCustomerParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public changeWeekDispoCustomer(changeWeekDispoCustomerParams: ChangeWeekDispoCustomerParams, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).changeWeekDispoCustomer(changeWeekDispoCustomerParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {string} redirectFlowId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public completeGocardlessRedirectFlow(familyId: number, redirectFlowId: string, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).completeGocardlessRedirectFlow(familyId, redirectFlowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPayslipServiceCreateLessonByAdminSVPL} editPayslipServiceCreateLessonByAdminSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL: EditPayslipServiceCreateLessonByAdminSVPL, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).createLessonByAdmin(editPayslipServiceCreateLessonByAdminSVPL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPayslipServiceDeleteLessonByAdminSVPL} editPayslipServiceDeleteLessonByAdminSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL: EditPayslipServiceDeleteLessonByAdminSVPL, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).deleteLessonByAdmin(editPayslipServiceDeleteLessonByAdminSVPL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeletePlannedCallParams} deletePlannedCallParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public deletePlannedCall(deletePlannedCallParams: DeletePlannedCallParams, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).deletePlannedCall(deletePlannedCallParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPayslipServiceEditPayslipPayload} editPayslipServiceEditPayslipPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public editPayslip(editPayslipServiceEditPayslipPayload: EditPayslipServiceEditPayslipPayload, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).editPayslip(editPayslipServiceEditPayslipPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public getPhoneCalls(familyId: number, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).getPhoneCalls(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertPlannedCallParams} insertPlannedCallParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public insertPlannedCall(insertPlannedCallParams: InsertPlannedCallParams, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).insertPlannedCall(insertPlannedCallParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyCreditsServiceModifyCreditsPayload} modifyCreditsServiceModifyCreditsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public modifyCredits(modifyCreditsServiceModifyCreditsPayload: ModifyCreditsServiceModifyCreditsPayload, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).modifyCredits(modifyCreditsServiceModifyCreditsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyPhoneCallParams} modifyPhoneCallParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public modifyPhoneCall(modifyPhoneCallParams: ModifyPhoneCallParams, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).modifyPhoneCall(modifyPhoneCallParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchPlannedCallParams} patchPlannedCallParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public patchPlannedCall(patchPlannedCallParams: PatchPlannedCallParams, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).patchPlannedCall(patchPlannedCallParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload} swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload: SwapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).swapCreditInvoiceAICIMode(swapCreditInvoiceAICIModeServiceSwapCreditInvoiceAICIModePayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public syncPlannedCallFromGoogleCalendar(options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).syncPlannedCallFromGoogleCalendar(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransferCreditsServiceToggleTransferDiscountPayload} transferCreditsServiceToggleTransferDiscountPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload: TransferCreditsServiceToggleTransferDiscountPayload, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).toggleTransferInvoiceDiscount(transferCreditsServiceToggleTransferDiscountPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransferCreditsServiceTransferCreditsPayload} transferCreditsServiceTransferCreditsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public transferCredits(transferCreditsServiceTransferCreditsPayload: TransferCreditsServiceTransferCreditsPayload, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).transferCredits(transferCreditsServiceTransferCreditsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditPayslipServiceUpdateLessonByAdminSVPL} editPayslipServiceUpdateLessonByAdminSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamiliesApi
     */
    public updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL: EditPayslipServiceUpdateLessonByAdminSVPL, options?: AxiosRequestConfig) {
        return FamiliesApiFp(this.configuration).updateLessonByAdmin(editPayslipServiceUpdateLessonByAdminSVPL, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GooglePeopleApi - axios parameter creator
 * @export
 */
export const GooglePeopleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/google/people/get-auth-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GooglePeopleReturnCodeParams} googlePeopleReturnCodeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnCode: async (googlePeopleReturnCodeParams: GooglePeopleReturnCodeParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googlePeopleReturnCodeParams' is not null or undefined
            assertParamExists('returnCode', 'googlePeopleReturnCodeParams', googlePeopleReturnCodeParams)
            const localVarPath = `/google/people/return-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googlePeopleReturnCodeParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync: async (account: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account' is not null or undefined
            assertParamExists('sync', 'account', account)
            const localVarPath = `/google/people/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GooglePeopleApi - functional programming interface
 * @export
 */
export const GooglePeopleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GooglePeopleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GooglePeopleReturnCodeParams} googlePeopleReturnCodeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async returnCode(googlePeopleReturnCodeParams: GooglePeopleReturnCodeParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.returnCode(googlePeopleReturnCodeParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} account 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sync(account: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sync200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sync(account, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GooglePeopleApi - factory interface
 * @export
 */
export const GooglePeopleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GooglePeopleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUrl(options?: any): AxiosPromise<string> {
            return localVarFp.getAuthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GooglePeopleReturnCodeParams} googlePeopleReturnCodeParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        returnCode(googlePeopleReturnCodeParams: GooglePeopleReturnCodeParams, options?: any): AxiosPromise<void> {
            return localVarFp.returnCode(googlePeopleReturnCodeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} account 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sync(account: string, options?: any): AxiosPromise<Sync200Response> {
            return localVarFp.sync(account, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GooglePeopleApi - object-oriented interface
 * @export
 * @class GooglePeopleApi
 * @extends {BaseAPI}
 */
export class GooglePeopleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GooglePeopleApi
     */
    public getAuthUrl(options?: AxiosRequestConfig) {
        return GooglePeopleApiFp(this.configuration).getAuthUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GooglePeopleReturnCodeParams} googlePeopleReturnCodeParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GooglePeopleApi
     */
    public returnCode(googlePeopleReturnCodeParams: GooglePeopleReturnCodeParams, options?: AxiosRequestConfig) {
        return GooglePeopleApiFp(this.configuration).returnCode(googlePeopleReturnCodeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} account 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GooglePeopleApi
     */
    public sync(account: string, options?: AxiosRequestConfig) {
        return GooglePeopleApiFp(this.configuration).sync(account, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GoogleRatingsApi - axios parameter creator
 * @export
 */
export const GoogleRatingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviews: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/google-ratings/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/google-ratings/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoogleRatingsApi - functional programming interface
 * @export
 */
export const GoogleRatingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GoogleRatingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviews(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleRatingsServiceReviewsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviews(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleRatingsServiceSummaryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GoogleRatingsApi - factory interface
 * @export
 */
export const GoogleRatingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GoogleRatingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviews(options?: any): AxiosPromise<GoogleRatingsServiceReviewsResult> {
            return localVarFp.reviews(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary(options?: any): AxiosPromise<GoogleRatingsServiceSummaryResult> {
            return localVarFp.summary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GoogleRatingsApi - object-oriented interface
 * @export
 * @class GoogleRatingsApi
 * @extends {BaseAPI}
 */
export class GoogleRatingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleRatingsApi
     */
    public reviews(options?: AxiosRequestConfig) {
        return GoogleRatingsApiFp(this.configuration).reviews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoogleRatingsApi
     */
    public summary(options?: AxiosRequestConfig) {
        return GoogleRatingsApiFp(this.configuration).summary(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HolidaysApi - axios parameter creator
 * @export
 */
export const HolidaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoliday: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHoliday', 'id', id)
            const localVarPath = `/holidays/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidays: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/holidays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HolidayServiceSetHolidayPayload} holidayServiceSetHolidayPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHoliday: async (holidayServiceSetHolidayPayload: HolidayServiceSetHolidayPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holidayServiceSetHolidayPayload' is not null or undefined
            assertParamExists('setHoliday', 'holidayServiceSetHolidayPayload', holidayServiceSetHolidayPayload)
            const localVarPath = `/holidays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(holidayServiceSetHolidayPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HolidaysApi - functional programming interface
 * @export
 */
export const HolidaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HolidaysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoliday(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoliday(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHolidays(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolidayServiceGetHolidaysOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHolidays(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HolidayServiceSetHolidayPayload} holidayServiceSetHolidayPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setHoliday(holidayServiceSetHolidayPayload: HolidayServiceSetHolidayPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolidayServiceSetHolidayOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setHoliday(holidayServiceSetHolidayPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HolidaysApi - factory interface
 * @export
 */
export const HolidaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HolidaysApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoliday(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteHoliday(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidays(name?: string, options?: any): AxiosPromise<HolidayServiceGetHolidaysOutput> {
            return localVarFp.getHolidays(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HolidayServiceSetHolidayPayload} holidayServiceSetHolidayPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setHoliday(holidayServiceSetHolidayPayload: HolidayServiceSetHolidayPayload, options?: any): AxiosPromise<HolidayServiceSetHolidayOutput> {
            return localVarFp.setHoliday(holidayServiceSetHolidayPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HolidaysApi - object-oriented interface
 * @export
 * @class HolidaysApi
 * @extends {BaseAPI}
 */
export class HolidaysApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidaysApi
     */
    public deleteHoliday(id: number, options?: AxiosRequestConfig) {
        return HolidaysApiFp(this.configuration).deleteHoliday(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidaysApi
     */
    public getHolidays(name?: string, options?: AxiosRequestConfig) {
        return HolidaysApiFp(this.configuration).getHolidays(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HolidayServiceSetHolidayPayload} holidayServiceSetHolidayPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidaysApi
     */
    public setHoliday(holidayServiceSetHolidayPayload: HolidayServiceSetHolidayPayload, options?: AxiosRequestConfig) {
        return HolidaysApiFp(this.configuration).setHoliday(holidayServiceSetHolidayPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailboxApi - axios parameter creator
 * @export
 */
export const MailboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {string} partID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachement: async (uid: number, account: string, folder: string, partID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getAttachement', 'uid', uid)
            // verify required parameter 'account' is not null or undefined
            assertParamExists('getAttachement', 'account', account)
            // verify required parameter 'folder' is not null or undefined
            assertParamExists('getAttachement', 'folder', folder)
            // verify required parameter 'partID' is not null or undefined
            assertParamExists('getAttachement', 'partID', partID)
            const localVarPath = `/mailbox/attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (folder !== undefined) {
                localVarQueryParameter['folder'] = folder;
            }

            if (partID !== undefined) {
                localVarQueryParameter['partID'] = partID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMessage: async (uid: number, account: string, folder: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('getFullMessage', 'uid', uid)
            // verify required parameter 'account' is not null or undefined
            assertParamExists('getFullMessage', 'account', account)
            // verify required parameter 'folder' is not null or undefined
            assertParamExists('getFullMessage', 'folder', folder)
            const localVarPath = `/mailbox/full-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (folder !== undefined) {
                localVarQueryParameter['folder'] = folder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewList: async (getPreviewListRequest: GetPreviewListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPreviewListRequest' is not null or undefined
            assertParamExists('getPreviewList', 'getPreviewListRequest', getPreviewListRequest)
            const localVarPath = `/mailbox/preview-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPreviewListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailboxApi - functional programming interface
 * @export
 */
export const MailboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {string} partID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachement(uid: number, account: string, folder: string, partID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachement(uid, account, folder, partID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullMessage(uid: number, account: string, folder: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FullMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullMessage(uid, account, folder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewList(getPreviewListRequest: GetPreviewListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PreparedListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewList(getPreviewListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailboxApi - factory interface
 * @export
 */
export const MailboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailboxApiFp(configuration)
    return {
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {string} partID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachement(uid: number, account: string, folder: string, partID: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAttachement(uid, account, folder, partID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} uid 
         * @param {string} account 
         * @param {string} folder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullMessage(uid: number, account: string, folder: string, options?: any): AxiosPromise<FullMessage> {
            return localVarFp.getFullMessage(uid, account, folder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPreviewListRequest} getPreviewListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewList(getPreviewListRequest: GetPreviewListRequest, options?: any): AxiosPromise<Array<PreparedListItem>> {
            return localVarFp.getPreviewList(getPreviewListRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailboxApi - object-oriented interface
 * @export
 * @class MailboxApi
 * @extends {BaseAPI}
 */
export class MailboxApi extends BaseAPI {
    /**
     * 
     * @param {number} uid 
     * @param {string} account 
     * @param {string} folder 
     * @param {string} partID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailboxApi
     */
    public getAttachement(uid: number, account: string, folder: string, partID: string, options?: AxiosRequestConfig) {
        return MailboxApiFp(this.configuration).getAttachement(uid, account, folder, partID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} uid 
     * @param {string} account 
     * @param {string} folder 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailboxApi
     */
    public getFullMessage(uid: number, account: string, folder: string, options?: AxiosRequestConfig) {
        return MailboxApiFp(this.configuration).getFullMessage(uid, account, folder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPreviewListRequest} getPreviewListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailboxApi
     */
    public getPreviewList(getPreviewListRequest: GetPreviewListRequest, options?: AxiosRequestConfig) {
        return MailboxApiFp(this.configuration).getPreviewList(getPreviewListRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteTokenNotificationRequest} deleteTokenNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenNotification: async (deleteTokenNotificationRequest: DeleteTokenNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTokenNotificationRequest' is not null or undefined
            assertParamExists('deleteTokenNotification', 'deleteTokenNotificationRequest', deleteTokenNotificationRequest)
            const localVarPath = `/notifications/delete-notification-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTokenNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertTokenNotificationParams} insertTokenNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTokenNotification: async (insertTokenNotificationParams: InsertTokenNotificationParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertTokenNotificationParams' is not null or undefined
            assertParamExists('insertTokenNotification', 'insertTokenNotificationParams', insertTokenNotificationParams)
            const localVarPath = `/notifications/add-notification-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertTokenNotificationParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteTokenNotificationRequest} deleteTokenNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTokenNotification(deleteTokenNotificationRequest: DeleteTokenNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTokenNotification(deleteTokenNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InsertTokenNotificationParams} insertTokenNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTokenNotification(insertTokenNotificationParams: InsertTokenNotificationParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTokenNotification(insertTokenNotificationParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteTokenNotificationRequest} deleteTokenNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenNotification(deleteTokenNotificationRequest: DeleteTokenNotificationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTokenNotification(deleteTokenNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertTokenNotificationParams} insertTokenNotificationParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTokenNotification(insertTokenNotificationParams: InsertTokenNotificationParams, options?: any): AxiosPromise<void> {
            return localVarFp.insertTokenNotification(insertTokenNotificationParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {DeleteTokenNotificationRequest} deleteTokenNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public deleteTokenNotification(deleteTokenNotificationRequest: DeleteTokenNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).deleteTokenNotification(deleteTokenNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertTokenNotificationParams} insertTokenNotificationParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public insertTokenNotification(insertTokenNotificationParams: InsertTokenNotificationParams, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).insertTokenNotification(insertTokenNotificationParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTeacherPaymentPayload} createTeacherPaymentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherPayment: async (createTeacherPaymentPayload: CreateTeacherPaymentPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTeacherPaymentPayload' is not null or undefined
            assertParamExists('createTeacherPayment', 'createTeacherPaymentPayload', createTeacherPaymentPayload)
            const localVarPath = `/payments/create-teacher-payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeacherPaymentPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FireTeacherPaymentsPayload} fireTeacherPaymentsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fireTeacherPayments: async (fireTeacherPaymentsPayload: FireTeacherPaymentsPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fireTeacherPaymentsPayload' is not null or undefined
            assertParamExists('fireTeacherPayments', 'fireTeacherPaymentsPayload', fireTeacherPaymentsPayload)
            const localVarPath = `/payments/fire-teacher-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fireTeacherPaymentsPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} teacherPaymentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTeacherPaymentsXML: async (teacherPaymentIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherPaymentIds' is not null or undefined
            assertParamExists('generateTeacherPaymentsXML', 'teacherPaymentIds', teacherPaymentIds)
            const localVarPath = `/payments/teacher-payments-xml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherPaymentIds) {
                localVarQueryParameter['teacherPaymentIds'] = teacherPaymentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pontoPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPontoPayment: async (pontoPaymentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pontoPaymentId' is not null or undefined
            assertParamExists('syncPontoPayment', 'pontoPaymentId', pontoPaymentId)
            const localVarPath = `/payments/sync-ponto-payment/{pontoPaymentId}`
                .replace(`{${"pontoPaymentId"}}`, encodeURIComponent(String(pontoPaymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTeacherPaymentPayload} createTeacherPaymentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeacherPayment(createTeacherPaymentPayload: CreateTeacherPaymentPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTeacherPayment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeacherPayment(createTeacherPaymentPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FireTeacherPaymentsPayload} fireTeacherPaymentsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fireTeacherPayments(fireTeacherPaymentsPayload: FireTeacherPaymentsPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FireTeacherPaymentsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fireTeacherPayments(fireTeacherPaymentsPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} teacherPaymentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTeacherPaymentsXML(teacherPaymentIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateTeacherPaymentsXML200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTeacherPaymentsXML(teacherPaymentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pontoPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPontoPayment(pontoPaymentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncPontoPayment200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPontoPayment(pontoPaymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTeacherPaymentPayload} createTeacherPaymentPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeacherPayment(createTeacherPaymentPayload: CreateTeacherPaymentPayload, options?: any): AxiosPromise<CreateTeacherPayment200Response> {
            return localVarFp.createTeacherPayment(createTeacherPaymentPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FireTeacherPaymentsPayload} fireTeacherPaymentsPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fireTeacherPayments(fireTeacherPaymentsPayload: FireTeacherPaymentsPayload, options?: any): AxiosPromise<FireTeacherPaymentsResult> {
            return localVarFp.fireTeacherPayments(fireTeacherPaymentsPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} teacherPaymentIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTeacherPaymentsXML(teacherPaymentIds: Array<number>, options?: any): AxiosPromise<GenerateTeacherPaymentsXML200Response> {
            return localVarFp.generateTeacherPaymentsXML(teacherPaymentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pontoPaymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPontoPayment(pontoPaymentId: number, options?: any): AxiosPromise<SyncPontoPayment200Response> {
            return localVarFp.syncPontoPayment(pontoPaymentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {CreateTeacherPaymentPayload} createTeacherPaymentPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public createTeacherPayment(createTeacherPaymentPayload: CreateTeacherPaymentPayload, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).createTeacherPayment(createTeacherPaymentPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FireTeacherPaymentsPayload} fireTeacherPaymentsPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public fireTeacherPayments(fireTeacherPaymentsPayload: FireTeacherPaymentsPayload, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).fireTeacherPayments(fireTeacherPaymentsPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} teacherPaymentIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public generateTeacherPaymentsXML(teacherPaymentIds: Array<number>, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).generateTeacherPaymentsXML(teacherPaymentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} pontoPaymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public syncPontoPayment(pontoPaymentId: number, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).syncPontoPayment(pontoPaymentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdfsApi - axios parameter creator
 * @export
 */
export const PdfsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestationFiscaleLink: async (attestationFiscaleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attestationFiscaleId' is not null or undefined
            assertParamExists('getAttestationFiscaleLink', 'attestationFiscaleId', attestationFiscaleId)
            const localVarPath = `/pdfs/attestation-fiscale/{attestationFiscaleId}/unique-link`
                .replace(`{${"attestationFiscaleId"}}`, encodeURIComponent(String(attestationFiscaleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestationFiscalePdf: async (attestationFiscaleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attestationFiscaleId' is not null or undefined
            assertParamExists('getAttestationFiscalePdf', 'attestationFiscaleId', attestationFiscaleId)
            const localVarPath = `/pdfs/attestation-fiscale/{attestationFiscaleId}`
                .replace(`{${"attestationFiscaleId"}}`, encodeURIComponent(String(attestationFiscaleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCgsPdf: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('getCgsPdf', 'familyId', familyId)
            const localVarPath = `/pdfs/cgs/{familyId}`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} creditInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditInvoicePdf: async (creditInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditInvoiceId' is not null or undefined
            assertParamExists('getCreditInvoicePdf', 'creditInvoiceId', creditInvoiceId)
            const localVarPath = `/pdfs/credit-invoice/{creditInvoiceId}`
                .replace(`{${"creditInvoiceId"}}`, encodeURIComponent(String(creditInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevisCrPdf: async (devisCrId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'devisCrId' is not null or undefined
            assertParamExists('getDevisCrPdf', 'devisCrId', devisCrId)
            const localVarPath = `/pdfs/devis-cr/{devisCrId}`
                .replace(`{${"devisCrId"}}`, encodeURIComponent(String(devisCrId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevisData: async (devisCrId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'devisCrId' is not null or undefined
            assertParamExists('getDevisData', 'devisCrId', devisCrId)
            const localVarPath = `/pdfs/devis-cr/{devisCrId}/data`
                .replace(`{${"devisCrId"}}`, encodeURIComponent(String(devisCrId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoices: async (legalInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInvoiceId' is not null or undefined
            assertParamExists('getLegalInvoices', 'legalInvoiceId', legalInvoiceId)
            const localVarPath = `/pdfs/legal-invoices/{legalInvoiceId}`
                .replace(`{${"legalInvoiceId"}}`, encodeURIComponent(String(legalInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesDetails: async (legalInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInvoiceId' is not null or undefined
            assertParamExists('getLegalInvoicesDetails', 'legalInvoiceId', legalInvoiceId)
            const localVarPath = `/pdfs/legal-invoices-details/{legalInvoiceId}`
                .replace(`{${"legalInvoiceId"}}`, encodeURIComponent(String(legalInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatClientPdf: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('getMandatClientPdf', 'familyId', familyId)
            const localVarPath = `/pdfs/mandat-client/{familyId}`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatSEPAPdf: async (familyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'familyId' is not null or undefined
            assertParamExists('getMandatSEPAPdf', 'familyId', familyId)
            const localVarPath = `/pdfs/mandat-sepa/{familyId}`
                .replace(`{${"familyId"}}`, encodeURIComponent(String(familyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} payslipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayslipPdf: async (payslipId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payslipId' is not null or undefined
            assertParamExists('getPayslipPdf', 'payslipId', payslipId)
            const localVarPath = `/pdfs/payslip/{payslipId}`
                .replace(`{${"payslipId"}}`, encodeURIComponent(String(payslipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} releveClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveClientPdf: async (releveClientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'releveClientId' is not null or undefined
            assertParamExists('getReleveClientPdf', 'releveClientId', releveClientId)
            const localVarPath = `/pdfs/releve-client/{releveClientId}`
                .replace(`{${"releveClientId"}}`, encodeURIComponent(String(releveClientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardDevis: async (standardInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardInvoiceId' is not null or undefined
            assertParamExists('getStandardDevis', 'standardInvoiceId', standardInvoiceId)
            const localVarPath = `/pdfs/standard-devis/{standardInvoiceId}`
                .replace(`{${"standardInvoiceId"}}`, encodeURIComponent(String(standardInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardInvoice: async (standardInvoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'standardInvoiceId' is not null or undefined
            assertParamExists('getStandardInvoice', 'standardInvoiceId', standardInvoiceId)
            const localVarPath = `/pdfs/standard-invoices/{standardInvoiceId}`
                .replace(`{${"standardInvoiceId"}}`, encodeURIComponent(String(standardInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfsApi - functional programming interface
 * @export
 */
export const PdfsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttestationFiscaleLink(attestationFiscaleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttestationFiscaleLink(attestationFiscaleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttestationFiscalePdf(attestationFiscaleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttestationFiscalePdf(attestationFiscaleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCgsPdf(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCgsPdf(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} creditInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreditInvoicePdf(creditInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreditInvoicePdf(creditInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevisCrPdf(devisCrId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevisCrPdf(devisCrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevisData(devisCrId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevisData(devisCrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalInvoices(legalInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalInvoices(legalInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalInvoicesDetails(legalInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalInvoicesDetails(legalInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatClientPdf(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatClientPdf(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMandatSEPAPdf(familyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMandatSEPAPdf(familyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} payslipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayslipPdf(payslipId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayslipPdf(payslipId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} releveClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReleveClientPdf(releveClientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReleveClientPdf(releveClientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandardDevis(standardInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStandardDevis(standardInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStandardInvoice(standardInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdfsApi - factory interface
 * @export
 */
export const PdfsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestationFiscaleLink(attestationFiscaleId: number, options?: any): AxiosPromise<string> {
            return localVarFp.getAttestationFiscaleLink(attestationFiscaleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} attestationFiscaleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttestationFiscalePdf(attestationFiscaleId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAttestationFiscalePdf(attestationFiscaleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCgsPdf(familyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getCgsPdf(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} creditInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditInvoicePdf(creditInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getCreditInvoicePdf(creditInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevisCrPdf(devisCrId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getDevisCrPdf(devisCrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} devisCrId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevisData(devisCrId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getDevisData(devisCrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoices(legalInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getLegalInvoices(legalInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} legalInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesDetails(legalInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getLegalInvoicesDetails(legalInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatClientPdf(familyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getMandatClientPdf(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} familyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMandatSEPAPdf(familyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getMandatSEPAPdf(familyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} payslipId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayslipPdf(payslipId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getPayslipPdf(payslipId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} releveClientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReleveClientPdf(releveClientId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getReleveClientPdf(releveClientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardDevis(standardInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getStandardDevis(standardInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} standardInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandardInvoice(standardInvoiceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.getStandardInvoice(standardInvoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfsApi - object-oriented interface
 * @export
 * @class PdfsApi
 * @extends {BaseAPI}
 */
export class PdfsApi extends BaseAPI {
    /**
     * 
     * @param {number} attestationFiscaleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getAttestationFiscaleLink(attestationFiscaleId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getAttestationFiscaleLink(attestationFiscaleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} attestationFiscaleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getAttestationFiscalePdf(attestationFiscaleId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getAttestationFiscalePdf(attestationFiscaleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getCgsPdf(familyId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getCgsPdf(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} creditInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getCreditInvoicePdf(creditInvoiceId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getCreditInvoicePdf(creditInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} devisCrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getDevisCrPdf(devisCrId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getDevisCrPdf(devisCrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} devisCrId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getDevisData(devisCrId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getDevisData(devisCrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} legalInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getLegalInvoices(legalInvoiceId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getLegalInvoices(legalInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} legalInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getLegalInvoicesDetails(legalInvoiceId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getLegalInvoicesDetails(legalInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getMandatClientPdf(familyId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getMandatClientPdf(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} familyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getMandatSEPAPdf(familyId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getMandatSEPAPdf(familyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} payslipId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getPayslipPdf(payslipId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getPayslipPdf(payslipId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} releveClientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getReleveClientPdf(releveClientId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getReleveClientPdf(releveClientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} standardInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getStandardDevis(standardInvoiceId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getStandardDevis(standardInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} standardInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfsApi
     */
    public getStandardInvoice(standardInvoiceId: number, options?: AxiosRequestConfig) {
        return PdfsApiFp(this.configuration).getStandardInvoice(standardInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PennylaneApi - axios parameter creator
 * @export
 */
export const PennylaneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchangeCode', 'code', code)
            const localVarPath = `/pennylane/auth/exchange-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/auth/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkBankTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/link/bank-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/list/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournals: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/list/journals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterStartWith] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLedgerAccounts: async (filterStartWith?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/list/ledger-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterStartWith !== undefined) {
                localVarQueryParameter['filterStartWith'] = filterStartWith;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncBankTransactions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/sync/bank-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initial setup : - creates if needed the basic ledger accounts required for Dopple to work with Pennylane - creates the Gocardless and CRCESU suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLedgerAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pennylane/sync/ledger-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleBankTransaction: async (body: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('syncSingleBankTransaction', 'body', body)
            const localVarPath = `/pennylane/sync/single-bank-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PennylaneApi - functional programming interface
 * @export
 */
export const PennylaneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PennylaneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PennylaneAuthServiceOAuthData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkBankTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkBankTransactions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkBankTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PennylaneSyncServicePennylaneCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listJournals(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PennylaneSyncServicePennylaneJournal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listJournals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterStartWith] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLedgerAccounts(filterStartWith?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PennylaneSyncServicePennylaneLedgerAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLedgerAccounts(filterStartWith, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncBankTransactions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PennylaneSyncServiceSyncBankTransactionsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncBankTransactions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initial setup : - creates if needed the basic ledger accounts required for Dopple to work with Pennylane - creates the Gocardless and CRCESU suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncLedgerAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncLedgerAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSingleBankTransaction(body: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSingleBankTransaction(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PennylaneApi - factory interface
 * @export
 */
export const PennylaneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PennylaneApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeCode(code: string, options?: any): AxiosPromise<PennylaneAuthServiceOAuthData> {
            return localVarFp.exchangeCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthUrl(options?: any): AxiosPromise<string> {
            return localVarFp.getAuthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkBankTransactions(options?: any): AxiosPromise<LinkBankTransactions200Response> {
            return localVarFp.linkBankTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCategories(options?: any): AxiosPromise<Array<PennylaneSyncServicePennylaneCategory>> {
            return localVarFp.listCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listJournals(options?: any): AxiosPromise<Array<PennylaneSyncServicePennylaneJournal>> {
            return localVarFp.listJournals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterStartWith] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLedgerAccounts(filterStartWith?: string, options?: any): AxiosPromise<Array<PennylaneSyncServicePennylaneLedgerAccount>> {
            return localVarFp.listLedgerAccounts(filterStartWith, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncBankTransactions(options?: any): AxiosPromise<PennylaneSyncServiceSyncBankTransactionsResult> {
            return localVarFp.syncBankTransactions(options).then((request) => request(axios, basePath));
        },
        /**
         * Initial setup : - creates if needed the basic ledger accounts required for Dopple to work with Pennylane - creates the Gocardless and CRCESU suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLedgerAccounts(options?: any): AxiosPromise<void> {
            return localVarFp.syncLedgerAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSingleBankTransaction(body: number, options?: any): AxiosPromise<void> {
            return localVarFp.syncSingleBankTransaction(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PennylaneApi - object-oriented interface
 * @export
 * @class PennylaneApi
 * @extends {BaseAPI}
 */
export class PennylaneApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public exchangeCode(code: string, options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).exchangeCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public getAuthUrl(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).getAuthUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public linkBankTransactions(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).linkBankTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public listCategories(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).listCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public listJournals(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).listJournals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterStartWith] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public listLedgerAccounts(filterStartWith?: string, options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).listLedgerAccounts(filterStartWith, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public syncBankTransactions(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).syncBankTransactions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initial setup : - creates if needed the basic ledger accounts required for Dopple to work with Pennylane - creates the Gocardless and CRCESU suppliers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public syncLedgerAccounts(options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).syncLedgerAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PennylaneApi
     */
    public syncSingleBankTransaction(body: number, options?: AxiosRequestConfig) {
        return PennylaneApiFp(this.configuration).syncSingleBankTransaction(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PhoneCallsApi - axios parameter creator
 * @export
 */
export const PhoneCallsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetStatsRequest} getStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (getStatsRequest: GetStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getStatsRequest' is not null or undefined
            assertParamExists('getStats', 'getStatsRequest', getStatsRequest)
            const localVarPath = `/phone-calls/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhoneCallsApi - functional programming interface
 * @export
 */
export const PhoneCallsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhoneCallsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetStatsRequest} getStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(getStatsRequest: GetStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStats200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(getStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhoneCallsApi - factory interface
 * @export
 */
export const PhoneCallsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhoneCallsApiFp(configuration)
    return {
        /**
         * 
         * @param {GetStatsRequest} getStatsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(getStatsRequest: GetStatsRequest, options?: any): AxiosPromise<GetStats200Response> {
            return localVarFp.getStats(getStatsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhoneCallsApi - object-oriented interface
 * @export
 * @class PhoneCallsApi
 * @extends {BaseAPI}
 */
export class PhoneCallsApi extends BaseAPI {
    /**
     * 
     * @param {GetStatsRequest} getStatsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhoneCallsApi
     */
    public getStats(getStatsRequest: GetStatsRequest, options?: AxiosRequestConfig) {
        return PhoneCallsApiFp(this.configuration).getStats(getStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropositionsApi - axios parameter creator
 * @export
 */
export const PropositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Pour un match pas encore actif Lorsque l\'on sait que le client ne veut plus / ou que le prof ne convient plus  - Abandon du match - Abandon des créneaux - Abandon des tprop  Interdit si match pas en DRAFT Interdit si cprop en cours. Doit être préalablement annulée  Si le client a validé des crédits mais que le match n\'est pas encore actif, il faudra les transférer.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortMatch: async (matchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('abortMatch', 'matchId', matchId)
            const localVarPath = `/propositions/match/{matchId}/abort`
                .replace(`{${"matchId"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Abandon d\'une tprop en cours. Si la tprop est déjà acceptée (= le prof est attribué.) Il faut plutôt abandonner le match. C\'est si le client ne veut plus, ou qu\'on change d\'avis sur le prof, mais qu\'on a déjà envoyé au prof
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortTeacherProposition: async (propositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propositionId' is not null or undefined
            assertParamExists('abortTeacherProposition', 'propositionId', propositionId)
            const localVarPath = `/propositions/teacher-propositions/{propositionId}/abort`
                .replace(`{${"propositionId"}}`, encodeURIComponent(String(propositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PropositionsCtrlAddFirstLessonByAdminBody} propositionsCtrlAddFirstLessonByAdminBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFirstLessonByAdmin: async (propositionsCtrlAddFirstLessonByAdminBody: PropositionsCtrlAddFirstLessonByAdminBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propositionsCtrlAddFirstLessonByAdminBody' is not null or undefined
            assertParamExists('addFirstLessonByAdmin', 'propositionsCtrlAddFirstLessonByAdminBody', propositionsCtrlAddFirstLessonByAdminBody)
            const localVarPath = `/propositions/first-lesson/create-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propositionsCtrlAddFirstLessonByAdminBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirstLessonByAdmin: async (lessonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('deleteFirstLessonByAdmin', 'lessonId', lessonId)
            const localVarPath = `/propositions/first-lesson/{lessonId}/delete-by-admin`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suppression d\'un tprop pas envoyée
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherProposition: async (propositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propositionId' is not null or undefined
            assertParamExists('deleteTeacherProposition', 'propositionId', propositionId)
            const localVarPath = `/propositions/teacher-propositions/{propositionId}/delete`
                .replace(`{${"propositionId"}}`, encodeURIComponent(String(propositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissExpiredProposition: async (propositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propositionId' is not null or undefined
            assertParamExists('dismissExpiredProposition', 'propositionId', propositionId)
            const localVarPath = `/propositions/teacher-propositions/{propositionId}/dismiss-expired`
                .replace(`{${"propositionId"}}`, encodeURIComponent(String(propositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceFinishMatch: async (matchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('forceFinishMatch', 'matchId', matchId)
            const localVarPath = `/propositions/match/{matchId}/force-finish`
                .replace(`{${"matchId"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Réactivation d\'un match FINISHED et création monthly match survey si nécessaire.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMatch: async (matchId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('reactivateMatch', 'matchId', matchId)
            const localVarPath = `/propositions/match/{matchId}/reactivate`
                .replace(`{${"matchId"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTeacherProposition: async (propositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propositionId' is not null or undefined
            assertParamExists('sendTeacherProposition', 'propositionId', propositionId)
            const localVarPath = `/propositions/teacher-propositions/{propositionId}/send`
                .replace(`{${"propositionId"}}`, encodeURIComponent(String(propositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropositionsApi - functional programming interface
 * @export
 */
export const PropositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Pour un match pas encore actif Lorsque l\'on sait que le client ne veut plus / ou que le prof ne convient plus  - Abandon du match - Abandon des créneaux - Abandon des tprop  Interdit si match pas en DRAFT Interdit si cprop en cours. Doit être préalablement annulée  Si le client a validé des crédits mais que le match n\'est pas encore actif, il faudra les transférer.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortMatch(matchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortMatch(matchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Abandon d\'une tprop en cours. Si la tprop est déjà acceptée (= le prof est attribué.) Il faut plutôt abandonner le match. C\'est si le client ne veut plus, ou qu\'on change d\'avis sur le prof, mais qu\'on a déjà envoyé au prof
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortTeacherProposition(propositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortTeacherProposition(propositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PropositionsCtrlAddFirstLessonByAdminBody} propositionsCtrlAddFirstLessonByAdminBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody: PropositionsCtrlAddFirstLessonByAdminBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFirstLessonByAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFirstLessonByAdmin(lessonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFirstLessonByAdmin(lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Suppression d\'un tprop pas envoyée
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeacherProposition(propositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeacherProposition(propositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismissExpiredProposition(propositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismissExpiredProposition(propositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceFinishMatch(matchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceFinishMatch(matchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Réactivation d\'un match FINISHED et création monthly match survey si nécessaire.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivateMatch(matchId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactivateMatch(matchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTeacherProposition(propositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendTeacherProposition200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTeacherProposition(propositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropositionsApi - factory interface
 * @export
 */
export const PropositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropositionsApiFp(configuration)
    return {
        /**
         * Pour un match pas encore actif Lorsque l\'on sait que le client ne veut plus / ou que le prof ne convient plus  - Abandon du match - Abandon des créneaux - Abandon des tprop  Interdit si match pas en DRAFT Interdit si cprop en cours. Doit être préalablement annulée  Si le client a validé des crédits mais que le match n\'est pas encore actif, il faudra les transférer.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortMatch(matchId: number, options?: any): AxiosPromise<void> {
            return localVarFp.abortMatch(matchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Abandon d\'une tprop en cours. Si la tprop est déjà acceptée (= le prof est attribué.) Il faut plutôt abandonner le match. C\'est si le client ne veut plus, ou qu\'on change d\'avis sur le prof, mais qu\'on a déjà envoyé au prof
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortTeacherProposition(propositionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.abortTeacherProposition(propositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PropositionsCtrlAddFirstLessonByAdminBody} propositionsCtrlAddFirstLessonByAdminBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody: PropositionsCtrlAddFirstLessonByAdminBody, options?: any): AxiosPromise<AddFirstLessonByAdmin200Response> {
            return localVarFp.addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFirstLessonByAdmin(lessonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFirstLessonByAdmin(lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * Suppression d\'un tprop pas envoyée
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherProposition(propositionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeacherProposition(propositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissExpiredProposition(propositionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.dismissExpiredProposition(propositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceFinishMatch(matchId: number, options?: any): AxiosPromise<void> {
            return localVarFp.forceFinishMatch(matchId, options).then((request) => request(axios, basePath));
        },
        /**
         * Réactivation d\'un match FINISHED et création monthly match survey si nécessaire.
         * @param {number} matchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateMatch(matchId: number, options?: any): AxiosPromise<void> {
            return localVarFp.reactivateMatch(matchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} propositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTeacherProposition(propositionId: number, options?: any): AxiosPromise<SendTeacherProposition200Response> {
            return localVarFp.sendTeacherProposition(propositionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropositionsApi - object-oriented interface
 * @export
 * @class PropositionsApi
 * @extends {BaseAPI}
 */
export class PropositionsApi extends BaseAPI {
    /**
     * Pour un match pas encore actif Lorsque l\'on sait que le client ne veut plus / ou que le prof ne convient plus  - Abandon du match - Abandon des créneaux - Abandon des tprop  Interdit si match pas en DRAFT Interdit si cprop en cours. Doit être préalablement annulée  Si le client a validé des crédits mais que le match n\'est pas encore actif, il faudra les transférer.
     * @param {number} matchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public abortMatch(matchId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).abortMatch(matchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Abandon d\'une tprop en cours. Si la tprop est déjà acceptée (= le prof est attribué.) Il faut plutôt abandonner le match. C\'est si le client ne veut plus, ou qu\'on change d\'avis sur le prof, mais qu\'on a déjà envoyé au prof
     * @param {number} propositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public abortTeacherProposition(propositionId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).abortTeacherProposition(propositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PropositionsCtrlAddFirstLessonByAdminBody} propositionsCtrlAddFirstLessonByAdminBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody: PropositionsCtrlAddFirstLessonByAdminBody, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).addFirstLessonByAdmin(propositionsCtrlAddFirstLessonByAdminBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public deleteFirstLessonByAdmin(lessonId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).deleteFirstLessonByAdmin(lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suppression d\'un tprop pas envoyée
     * @param {number} propositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public deleteTeacherProposition(propositionId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).deleteTeacherProposition(propositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public dismissExpiredProposition(propositionId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).dismissExpiredProposition(propositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} matchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public forceFinishMatch(matchId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).forceFinishMatch(matchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Réactivation d\'un match FINISHED et création monthly match survey si nécessaire.
     * @param {number} matchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public reactivateMatch(matchId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).reactivateMatch(matchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} propositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropositionsApi
     */
    public sendTeacherProposition(propositionId: number, options?: AxiosRequestConfig) {
        return PropositionsApiFp(this.configuration).sendTeacherProposition(propositionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RingoverApi - axios parameter creator
 * @export
 */
export const RingoverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RingoverServiceHandleContactEventPayload} ringoverServiceHandleContactEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactEventWebhook: async (ringoverServiceHandleContactEventPayload: RingoverServiceHandleContactEventPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ringoverServiceHandleContactEventPayload' is not null or undefined
            assertParamExists('contactEventWebhook', 'ringoverServiceHandleContactEventPayload', ringoverServiceHandleContactEventPayload)
            const localVarPath = `/ringover/contact-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ringoverServiceHandleContactEventPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RingoverServiceHandleContactSearchEventPayload} ringoverServiceHandleContactSearchEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSearchEventWebhook: async (ringoverServiceHandleContactSearchEventPayload: RingoverServiceHandleContactSearchEventPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ringoverServiceHandleContactSearchEventPayload' is not null or undefined
            assertParamExists('contactSearchEventWebhook', 'ringoverServiceHandleContactSearchEventPayload', ringoverServiceHandleContactSearchEventPayload)
            const localVarPath = `/ringover/contact-search-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ringoverServiceHandleContactSearchEventPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ringover/sync-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPhoneCalls: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ringover/sync-phone-calls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RingoverApi - functional programming interface
 * @export
 */
export const RingoverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RingoverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RingoverServiceHandleContactEventPayload} ringoverServiceHandleContactEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactEventWebhook(ringoverServiceHandleContactEventPayload: RingoverServiceHandleContactEventPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RingoverServiceHandleContactEventOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactEventWebhook(ringoverServiceHandleContactEventPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RingoverServiceHandleContactSearchEventPayload} ringoverServiceHandleContactSearchEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload: RingoverServiceHandleContactSearchEventPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RingoverServiceHandleContactSearchEventOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncContacts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPhoneCalls(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RingoverServiceSyncPhoneCallsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPhoneCalls(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RingoverApi - factory interface
 * @export
 */
export const RingoverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RingoverApiFp(configuration)
    return {
        /**
         * 
         * @param {RingoverServiceHandleContactEventPayload} ringoverServiceHandleContactEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactEventWebhook(ringoverServiceHandleContactEventPayload: RingoverServiceHandleContactEventPayload, options?: any): AxiosPromise<RingoverServiceHandleContactEventOutput> {
            return localVarFp.contactEventWebhook(ringoverServiceHandleContactEventPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RingoverServiceHandleContactSearchEventPayload} ringoverServiceHandleContactSearchEventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload: RingoverServiceHandleContactSearchEventPayload, options?: any): AxiosPromise<RingoverServiceHandleContactSearchEventOutput> {
            return localVarFp.contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncContacts(options?: any): AxiosPromise<SyncContacts200Response> {
            return localVarFp.syncContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPhoneCalls(options?: any): AxiosPromise<RingoverServiceSyncPhoneCallsOutput> {
            return localVarFp.syncPhoneCalls(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RingoverApi - object-oriented interface
 * @export
 * @class RingoverApi
 * @extends {BaseAPI}
 */
export class RingoverApi extends BaseAPI {
    /**
     * 
     * @param {RingoverServiceHandleContactEventPayload} ringoverServiceHandleContactEventPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RingoverApi
     */
    public contactEventWebhook(ringoverServiceHandleContactEventPayload: RingoverServiceHandleContactEventPayload, options?: AxiosRequestConfig) {
        return RingoverApiFp(this.configuration).contactEventWebhook(ringoverServiceHandleContactEventPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RingoverServiceHandleContactSearchEventPayload} ringoverServiceHandleContactSearchEventPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RingoverApi
     */
    public contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload: RingoverServiceHandleContactSearchEventPayload, options?: AxiosRequestConfig) {
        return RingoverApiFp(this.configuration).contactSearchEventWebhook(ringoverServiceHandleContactSearchEventPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RingoverApi
     */
    public syncContacts(options?: AxiosRequestConfig) {
        return RingoverApiFp(this.configuration).syncContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RingoverApi
     */
    public syncPhoneCalls(options?: AxiosRequestConfig) {
        return RingoverApiFp(this.configuration).syncPhoneCalls(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlackApi - axios parameter creator
 * @export
 */
export const SlackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SlackCtrlNotifyErrorBody} slackCtrlNotifyErrorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyError: async (slackCtrlNotifyErrorBody: SlackCtrlNotifyErrorBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slackCtrlNotifyErrorBody' is not null or undefined
            assertParamExists('notifyError', 'slackCtrlNotifyErrorBody', slackCtrlNotifyErrorBody)
            const localVarPath = `/slack/notify-error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackCtrlNotifyErrorBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlackApi - functional programming interface
 * @export
 */
export const SlackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SlackCtrlNotifyErrorBody} slackCtrlNotifyErrorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifyError(slackCtrlNotifyErrorBody: SlackCtrlNotifyErrorBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifyError(slackCtrlNotifyErrorBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlackApi - factory interface
 * @export
 */
export const SlackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlackApiFp(configuration)
    return {
        /**
         * 
         * @param {SlackCtrlNotifyErrorBody} slackCtrlNotifyErrorBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyError(slackCtrlNotifyErrorBody: SlackCtrlNotifyErrorBody, options?: any): AxiosPromise<void> {
            return localVarFp.notifyError(slackCtrlNotifyErrorBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlackApi - object-oriented interface
 * @export
 * @class SlackApi
 * @extends {BaseAPI}
 */
export class SlackApi extends BaseAPI {
    /**
     * 
     * @param {SlackCtrlNotifyErrorBody} slackCtrlNotifyErrorBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public notifyError(slackCtrlNotifyErrorBody: SlackCtrlNotifyErrorBody, options?: AxiosRequestConfig) {
        return SlackApiFp(this.configuration).notifyError(slackCtrlNotifyErrorBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * sales per month
         * @param {number} exercise 
         * @param {number} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormulasStatsTotals: async (exercise: number, city?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercise' is not null or undefined
            assertParamExists('getFormulasStatsTotals', 'exercise', exercise)
            const localVarPath = `/stats/formulas-stats-totals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exercise !== undefined) {
                localVarQueryParameter['exercise'] = exercise;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stats de recrutement
         * @param {number} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHiringStats: async (city: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'city' is not null or undefined
            assertParamExists('getHiringStats', 'city', city)
            const localVarPath = `/stats/hiring-stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Accueil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecruitmentRecap: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/recruitment-recap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Places moyennes demandées par prof
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWishedStudentsNbMean: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/wished-students-nb-mean`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * sales per month
         * @param {number} exercise 
         * @param {number} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFormulasStatsTotals(exercise: number, city?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFormulasStatsTotals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFormulasStatsTotals(exercise, city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stats de recrutement
         * @param {number} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHiringStats(city: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HiringStatsRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHiringStats(city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Accueil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecruitmentRecap(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecruitmentRecap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecruitmentRecap(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Places moyennes demandées par prof
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWishedStudentsNbMean(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWishedStudentsNbMean(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * sales per month
         * @param {number} exercise 
         * @param {number} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFormulasStatsTotals(exercise: number, city?: number, options?: any): AxiosPromise<GetFormulasStatsTotals200Response> {
            return localVarFp.getFormulasStatsTotals(exercise, city, options).then((request) => request(axios, basePath));
        },
        /**
         * Stats de recrutement
         * @param {number} city 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHiringStats(city: number, options?: any): AxiosPromise<Array<HiringStatsRow>> {
            return localVarFp.getHiringStats(city, options).then((request) => request(axios, basePath));
        },
        /**
         * Accueil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecruitmentRecap(options?: any): AxiosPromise<RecruitmentRecap> {
            return localVarFp.getRecruitmentRecap(options).then((request) => request(axios, basePath));
        },
        /**
         * Places moyennes demandées par prof
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWishedStudentsNbMean(options?: any): AxiosPromise<number> {
            return localVarFp.getWishedStudentsNbMean(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * sales per month
     * @param {number} exercise 
     * @param {number} [city] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getFormulasStatsTotals(exercise: number, city?: number, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getFormulasStatsTotals(exercise, city, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stats de recrutement
     * @param {number} city 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getHiringStats(city: number, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getHiringStats(city, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accueil
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getRecruitmentRecap(options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getRecruitmentRecap(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Places moyennes demandées par prof
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getWishedStudentsNbMean(options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getWishedStudentsNbMean(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeachersApi - axios parameter creator
 * @export
 */
export const TeachersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeWeekDispoTeacherParams} changeWeekDispoTeacherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWeekDispoTeacher: async (changeWeekDispoTeacherParams: ChangeWeekDispoTeacherParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeWeekDispoTeacherParams' is not null or undefined
            assertParamExists('changeWeekDispoTeacher', 'changeWeekDispoTeacherParams', changeWeekDispoTeacherParams)
            const localVarPath = `/teachers/change-week-dispo-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeWeekDispoTeacherParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteTeacherResearchParams} deleteTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherResearch: async (deleteTeacherResearchParams: DeleteTeacherResearchParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTeacherResearchParams' is not null or undefined
            assertParamExists('deleteTeacherResearch', 'deleteTeacherResearchParams', deleteTeacherResearchParams)
            const localVarPath = `/teachers/delete-teacher-research`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTeacherResearchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AttestationPoleEmploisServiceGenerateParams} attestationPoleEmploisServiceGenerateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAttestationPoleEmploi: async (attestationPoleEmploisServiceGenerateParams: AttestationPoleEmploisServiceGenerateParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attestationPoleEmploisServiceGenerateParams' is not null or undefined
            assertParamExists('generateAttestationPoleEmploi', 'attestationPoleEmploisServiceGenerateParams', attestationPoleEmploisServiceGenerateParams)
            const localVarPath = `/teachers/generate-attestation-pole-emploi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attestationPoleEmploisServiceGenerateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertTeacherResearchParams} insertTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTeacherResearch: async (insertTeacherResearchParams: InsertTeacherResearchParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertTeacherResearchParams' is not null or undefined
            assertParamExists('insertTeacherResearch', 'insertTeacherResearchParams', insertTeacherResearchParams)
            const localVarPath = `/teachers/add-teacher-research`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertTeacherResearchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModifyTeacherResearchParams} modifyTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeacherResearch: async (modifyTeacherResearchParams: ModifyTeacherResearchParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modifyTeacherResearchParams' is not null or undefined
            assertParamExists('modifyTeacherResearch', 'modifyTeacherResearchParams', modifyTeacherResearchParams)
            const localVarPath = `/teachers/modify-teacher-research`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyTeacherResearchParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeachersApi - functional programming interface
 * @export
 */
export const TeachersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeachersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeWeekDispoTeacherParams} changeWeekDispoTeacherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeWeekDispoTeacher(changeWeekDispoTeacherParams: ChangeWeekDispoTeacherParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeWeekDispoTeacher(changeWeekDispoTeacherParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteTeacherResearchParams} deleteTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTeacherResearch(deleteTeacherResearchParams: DeleteTeacherResearchParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTeacherResearch(deleteTeacherResearchParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AttestationPoleEmploisServiceGenerateParams} attestationPoleEmploisServiceGenerateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams: AttestationPoleEmploisServiceGenerateParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InsertTeacherResearchParams} insertTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertTeacherResearch(insertTeacherResearchParams: InsertTeacherResearchParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertTeacherResearch(insertTeacherResearchParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ModifyTeacherResearchParams} modifyTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyTeacherResearch(modifyTeacherResearchParams: ModifyTeacherResearchParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyTeacherResearch(modifyTeacherResearchParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeachersApi - factory interface
 * @export
 */
export const TeachersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeachersApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeWeekDispoTeacherParams} changeWeekDispoTeacherParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeWeekDispoTeacher(changeWeekDispoTeacherParams: ChangeWeekDispoTeacherParams, options?: any): AxiosPromise<void> {
            return localVarFp.changeWeekDispoTeacher(changeWeekDispoTeacherParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteTeacherResearchParams} deleteTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTeacherResearch(deleteTeacherResearchParams: DeleteTeacherResearchParams, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTeacherResearch(deleteTeacherResearchParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AttestationPoleEmploisServiceGenerateParams} attestationPoleEmploisServiceGenerateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams: AttestationPoleEmploisServiceGenerateParams, options?: any): AxiosPromise<void> {
            return localVarFp.generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertTeacherResearchParams} insertTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertTeacherResearch(insertTeacherResearchParams: InsertTeacherResearchParams, options?: any): AxiosPromise<number> {
            return localVarFp.insertTeacherResearch(insertTeacherResearchParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ModifyTeacherResearchParams} modifyTeacherResearchParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTeacherResearch(modifyTeacherResearchParams: ModifyTeacherResearchParams, options?: any): AxiosPromise<void> {
            return localVarFp.modifyTeacherResearch(modifyTeacherResearchParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeachersApi - object-oriented interface
 * @export
 * @class TeachersApi
 * @extends {BaseAPI}
 */
export class TeachersApi extends BaseAPI {
    /**
     * 
     * @param {ChangeWeekDispoTeacherParams} changeWeekDispoTeacherParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public changeWeekDispoTeacher(changeWeekDispoTeacherParams: ChangeWeekDispoTeacherParams, options?: AxiosRequestConfig) {
        return TeachersApiFp(this.configuration).changeWeekDispoTeacher(changeWeekDispoTeacherParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteTeacherResearchParams} deleteTeacherResearchParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public deleteTeacherResearch(deleteTeacherResearchParams: DeleteTeacherResearchParams, options?: AxiosRequestConfig) {
        return TeachersApiFp(this.configuration).deleteTeacherResearch(deleteTeacherResearchParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AttestationPoleEmploisServiceGenerateParams} attestationPoleEmploisServiceGenerateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams: AttestationPoleEmploisServiceGenerateParams, options?: AxiosRequestConfig) {
        return TeachersApiFp(this.configuration).generateAttestationPoleEmploi(attestationPoleEmploisServiceGenerateParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertTeacherResearchParams} insertTeacherResearchParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public insertTeacherResearch(insertTeacherResearchParams: InsertTeacherResearchParams, options?: AxiosRequestConfig) {
        return TeachersApiFp(this.configuration).insertTeacherResearch(insertTeacherResearchParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ModifyTeacherResearchParams} modifyTeacherResearchParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersApi
     */
    public modifyTeacherResearch(modifyTeacherResearchParams: ModifyTeacherResearchParams, options?: AxiosRequestConfig) {
        return TeachersApiFp(this.configuration).modifyTeacherResearch(modifyTeacherResearchParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeachersAppApi - axios parameter creator
 * @export
 */
export const TeachersAppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortLessonProposition: async (lessonId: number, body: PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('abortLessonProposition', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('abortLessonProposition', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/abort-proposition`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptLessonProposition: async (lessonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('acceptLessonProposition', 'lessonId', lessonId)
            const localVarPath = `/teachers-app/lessons/{lessonId}/accept-proposition`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptMatchAndLessonProposition: async (lessonId: number, body: PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('acceptMatchAndLessonProposition', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('acceptMatchAndLessonProposition', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/accept-match-and-lesson-proposition`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookedLesson: async (lessonId: number, body: PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('cancelBookedLesson', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cancelBookedLesson', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/cancel-booked`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlCounterproposeLessonPropositionBody} teachersAppCtrlCounterproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counterproposeLessonProposition: async (lessonId: number, teachersAppCtrlCounterproposeLessonPropositionBody: TeachersAppCtrlCounterproposeLessonPropositionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('counterproposeLessonProposition', 'lessonId', lessonId)
            // verify required parameter 'teachersAppCtrlCounterproposeLessonPropositionBody' is not null or undefined
            assertParamExists('counterproposeLessonProposition', 'teachersAppCtrlCounterproposeLessonPropositionBody', teachersAppCtrlCounterproposeLessonPropositionBody)
            const localVarPath = `/teachers-app/lessons/{lessonId}/counterpropose`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teachersAppCtrlCounterproposeLessonPropositionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorEditSubmittingLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmittingLesson: async (lessonId: number, body: LessonSubmissionMutatorEditSubmittingLessonPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('editSubmittingLesson', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('editSubmittingLesson', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/edit-submitting`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeachersAppCtrlSlackNotificationBody} teachersAppCtrlSlackNotificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifySlack: async (teachersAppCtrlSlackNotificationBody: TeachersAppCtrlSlackNotificationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teachersAppCtrlSlackNotificationBody' is not null or undefined
            assertParamExists('notifySlack', 'teachersAppCtrlSlackNotificationBody', teachersAppCtrlSlackNotificationBody)
            const localVarPath = `/teachers-app/slack-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teachersAppCtrlSlackNotificationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TeacherLessonPropositionServiceCreateSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeLesson: async (body: TeacherLessonPropositionServiceCreateSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('proposeLesson', 'body', body)
            const localVarPath = `/teachers-app/lessons/propose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseLessonProposition: async (lessonId: number, body: PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('refuseLessonProposition', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('refuseLessonProposition', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/refuse-proposition`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} matchPropositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseMatchProposition: async (matchPropositionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchPropositionId' is not null or undefined
            assertParamExists('refuseMatchProposition', 'matchPropositionId', matchPropositionId)
            const localVarPath = `/teachers-app/match-propositions/{matchPropositionId}/refuse-match-proposition`
                .replace(`{${"matchPropositionId"}}`, encodeURIComponent(String(matchPropositionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlReproposeLessonPropositionBody} teachersAppCtrlReproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reproposeLessonProposition: async (lessonId: number, teachersAppCtrlReproposeLessonPropositionBody: TeachersAppCtrlReproposeLessonPropositionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('reproposeLessonProposition', 'lessonId', lessonId)
            // verify required parameter 'teachersAppCtrlReproposeLessonPropositionBody' is not null or undefined
            assertParamExists('reproposeLessonProposition', 'teachersAppCtrlReproposeLessonPropositionBody', teachersAppCtrlReproposeLessonPropositionBody)
            const localVarPath = `/teachers-app/lessons/{lessonId}/repropose`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teachersAppCtrlReproposeLessonPropositionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Errors : UnknownUserError
         * @param {TeacherLoggingServiceResendPasswordSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPassword: async (body: TeacherLoggingServiceResendPasswordSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resendPassword', 'body', body)
            const localVarPath = `/teachers-app/resend-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {MonthlyMatchSurveyConfigBlock} monthlyMatchSurveyConfigBlock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOneAnswer: async (surveyId: number, monthlyMatchSurveyConfigBlock: MonthlyMatchSurveyConfigBlock, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'surveyId' is not null or undefined
            assertParamExists('saveOneAnswer', 'surveyId', surveyId)
            // verify required parameter 'monthlyMatchSurveyConfigBlock' is not null or undefined
            assertParamExists('saveOneAnswer', 'monthlyMatchSurveyConfigBlock', monthlyMatchSurveyConfigBlock)
            const localVarPath = `/teachers-app/monthly-match-surveys/{surveyId}/save-one-ansewer`
                .replace(`{${"surveyId"}}`, encodeURIComponent(String(surveyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthlyMatchSurveyConfigBlock, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Echange jwt token against teacher credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {TeacherLoggingServiceSigninSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin: async (body: TeacherLoggingServiceSigninSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('signin', 'body', body)
            const localVarPath = `/teachers-app/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorSubmitBookedLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookedLesson: async (lessonId: number, body: LessonSubmissionMutatorSubmitBookedLessonPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('submitBookedLesson', 'lessonId', lessonId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('submitBookedLesson', 'body', body)
            const localVarPath = `/teachers-app/lessons/{lessonId}/submit-booked`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LessonSubmissionMutatorSubmitFastLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitFastLesson: async (body: LessonSubmissionMutatorSubmitFastLessonPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('submitFastLesson', 'body', body)
            const localVarPath = `/teachers-app/lessons/submit-fast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubmitLesson: async (lessonId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('unsubmitLesson', 'lessonId', lessonId)
            const localVarPath = `/teachers-app/lessons/{lessonId}/unsubmit`
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeachersAppApi - functional programming interface
 * @export
 */
export const TeachersAppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeachersAppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async abortLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.abortLessonProposition(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptLessonProposition(lessonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptLessonProposition200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptLessonProposition(lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptMatchAndLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptMatchAndLessonProposition200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptMatchAndLessonProposition(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelBookedLesson(lessonId: number, body: PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelBookedLesson(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlCounterproposeLessonPropositionBody} teachersAppCtrlCounterproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async counterproposeLessonProposition(lessonId: number, teachersAppCtrlCounterproposeLessonPropositionBody: TeachersAppCtrlCounterproposeLessonPropositionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.counterproposeLessonProposition(lessonId, teachersAppCtrlCounterproposeLessonPropositionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorEditSubmittingLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editSubmittingLesson(lessonId: number, body: LessonSubmissionMutatorEditSubmittingLessonPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editSubmittingLesson(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TeachersAppCtrlSlackNotificationBody} teachersAppCtrlSlackNotificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notifySlack(teachersAppCtrlSlackNotificationBody: TeachersAppCtrlSlackNotificationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notifySlack(teachersAppCtrlSlackNotificationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TeacherLessonPropositionServiceCreateSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeLesson(body: TeacherLessonPropositionServiceCreateSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeLesson(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuseLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefuseLessonProposition200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refuseLessonProposition(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} matchPropositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refuseMatchProposition(matchPropositionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refuseMatchProposition(matchPropositionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlReproposeLessonPropositionBody} teachersAppCtrlReproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reproposeLessonProposition(lessonId: number, teachersAppCtrlReproposeLessonPropositionBody: TeachersAppCtrlReproposeLessonPropositionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reproposeLessonProposition(lessonId, teachersAppCtrlReproposeLessonPropositionBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Errors : UnknownUserError
         * @param {TeacherLoggingServiceResendPasswordSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendPassword(body: TeacherLoggingServiceResendPasswordSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendPassword(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {MonthlyMatchSurveyConfigBlock} monthlyMatchSurveyConfigBlock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOneAnswer(surveyId: number, monthlyMatchSurveyConfigBlock: MonthlyMatchSurveyConfigBlock, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOneAnswer(surveyId, monthlyMatchSurveyConfigBlock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Echange jwt token against teacher credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {TeacherLoggingServiceSigninSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signin(body: TeacherLoggingServiceSigninSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signin(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorSubmitBookedLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitBookedLesson(lessonId: number, body: LessonSubmissionMutatorSubmitBookedLessonPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitBookedLesson(lessonId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LessonSubmissionMutatorSubmitFastLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitFastLesson(body: LessonSubmissionMutatorSubmitFastLessonPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitFastLesson200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitFastLesson(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubmitLesson(lessonId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubmitLesson(lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeachersAppApi - factory interface
 * @export
 */
export const TeachersAppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeachersAppApiFp(configuration)
    return {
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        abortLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId, options?: any): AxiosPromise<void> {
            return localVarFp.abortLessonProposition(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptLessonProposition(lessonId: number, options?: any): AxiosPromise<AcceptLessonProposition200Response> {
            return localVarFp.acceptLessonProposition(lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptMatchAndLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId, options?: any): AxiosPromise<AcceptMatchAndLessonProposition200Response> {
            return localVarFp.acceptMatchAndLessonProposition(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelBookedLesson(lessonId: number, body: PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId, options?: any): AxiosPromise<void> {
            return localVarFp.cancelBookedLesson(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlCounterproposeLessonPropositionBody} teachersAppCtrlCounterproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counterproposeLessonProposition(lessonId: number, teachersAppCtrlCounterproposeLessonPropositionBody: TeachersAppCtrlCounterproposeLessonPropositionBody, options?: any): AxiosPromise<void> {
            return localVarFp.counterproposeLessonProposition(lessonId, teachersAppCtrlCounterproposeLessonPropositionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorEditSubmittingLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSubmittingLesson(lessonId: number, body: LessonSubmissionMutatorEditSubmittingLessonPayload, options?: any): AxiosPromise<void> {
            return localVarFp.editSubmittingLesson(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeachersAppCtrlSlackNotificationBody} teachersAppCtrlSlackNotificationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifySlack(teachersAppCtrlSlackNotificationBody: TeachersAppCtrlSlackNotificationBody, options?: any): AxiosPromise<void> {
            return localVarFp.notifySlack(teachersAppCtrlSlackNotificationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeacherLessonPropositionServiceCreateSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeLesson(body: TeacherLessonPropositionServiceCreateSVPL, options?: any): AxiosPromise<void> {
            return localVarFp.proposeLesson(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId, options?: any): AxiosPromise<RefuseLessonProposition200Response> {
            return localVarFp.refuseLessonProposition(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} matchPropositionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refuseMatchProposition(matchPropositionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.refuseMatchProposition(matchPropositionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {TeachersAppCtrlReproposeLessonPropositionBody} teachersAppCtrlReproposeLessonPropositionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reproposeLessonProposition(lessonId: number, teachersAppCtrlReproposeLessonPropositionBody: TeachersAppCtrlReproposeLessonPropositionBody, options?: any): AxiosPromise<void> {
            return localVarFp.reproposeLessonProposition(lessonId, teachersAppCtrlReproposeLessonPropositionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Errors : UnknownUserError
         * @param {TeacherLoggingServiceResendPasswordSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPassword(body: TeacherLoggingServiceResendPasswordSVPL, options?: any): AxiosPromise<void> {
            return localVarFp.resendPassword(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} surveyId 
         * @param {MonthlyMatchSurveyConfigBlock} monthlyMatchSurveyConfigBlock 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOneAnswer(surveyId: number, monthlyMatchSurveyConfigBlock: MonthlyMatchSurveyConfigBlock, options?: any): AxiosPromise<PickDryMonthlyMatchSurveyExcludeKeyofDryMonthlyMatchSurveyMatch> {
            return localVarFp.saveOneAnswer(surveyId, monthlyMatchSurveyConfigBlock, options).then((request) => request(axios, basePath));
        },
        /**
         * Echange jwt token against teacher credentials  Errors : UnknownUserError, InvalidPasswordError
         * @param {TeacherLoggingServiceSigninSVPL} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(body: TeacherLoggingServiceSigninSVPL, options?: any): AxiosPromise<string> {
            return localVarFp.signin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {LessonSubmissionMutatorSubmitBookedLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitBookedLesson(lessonId: number, body: LessonSubmissionMutatorSubmitBookedLessonPayload, options?: any): AxiosPromise<void> {
            return localVarFp.submitBookedLesson(lessonId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LessonSubmissionMutatorSubmitFastLessonPayload} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitFastLesson(body: LessonSubmissionMutatorSubmitFastLessonPayload, options?: any): AxiosPromise<SubmitFastLesson200Response> {
            return localVarFp.submitFastLesson(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubmitLesson(lessonId: number, options?: any): AxiosPromise<void> {
            return localVarFp.unsubmitLesson(lessonId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeachersAppApi - object-oriented interface
 * @export
 * @class TeachersAppApi
 * @extends {BaseAPI}
 */
export class TeachersAppApi extends BaseAPI {
    /**
     * 
     * @param {number} lessonId 
     * @param {PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public abortLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAbortSVPLExcludeKeyofTeacherLessonPropositionServiceAbortSVPLLessonId, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).abortLessonProposition(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public acceptLessonProposition(lessonId: number, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).acceptLessonProposition(lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public acceptMatchAndLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLExcludeKeyofTeacherLessonPropositionServiceAcceptMatchAndLessonSVPLLessonId, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).acceptMatchAndLessonProposition(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public cancelBookedLesson(lessonId: number, body: PickTeacherLessonPropositionServiceCancelBookedSVPLExcludeKeyofTeacherLessonPropositionServiceCancelBookedSVPLLessonId, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).cancelBookedLesson(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {TeachersAppCtrlCounterproposeLessonPropositionBody} teachersAppCtrlCounterproposeLessonPropositionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public counterproposeLessonProposition(lessonId: number, teachersAppCtrlCounterproposeLessonPropositionBody: TeachersAppCtrlCounterproposeLessonPropositionBody, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).counterproposeLessonProposition(lessonId, teachersAppCtrlCounterproposeLessonPropositionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {LessonSubmissionMutatorEditSubmittingLessonPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public editSubmittingLesson(lessonId: number, body: LessonSubmissionMutatorEditSubmittingLessonPayload, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).editSubmittingLesson(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeachersAppCtrlSlackNotificationBody} teachersAppCtrlSlackNotificationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public notifySlack(teachersAppCtrlSlackNotificationBody: TeachersAppCtrlSlackNotificationBody, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).notifySlack(teachersAppCtrlSlackNotificationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeacherLessonPropositionServiceCreateSVPL} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public proposeLesson(body: TeacherLessonPropositionServiceCreateSVPL, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).proposeLesson(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public refuseLessonProposition(lessonId: number, body: PickTeacherLessonPropositionServiceRefuseSVPLExcludeKeyofTeacherLessonPropositionServiceRefuseSVPLLessonId, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).refuseLessonProposition(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} matchPropositionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public refuseMatchProposition(matchPropositionId: number, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).refuseMatchProposition(matchPropositionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {TeachersAppCtrlReproposeLessonPropositionBody} teachersAppCtrlReproposeLessonPropositionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public reproposeLessonProposition(lessonId: number, teachersAppCtrlReproposeLessonPropositionBody: TeachersAppCtrlReproposeLessonPropositionBody, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).reproposeLessonProposition(lessonId, teachersAppCtrlReproposeLessonPropositionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Errors : UnknownUserError
     * @param {TeacherLoggingServiceResendPasswordSVPL} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public resendPassword(body: TeacherLoggingServiceResendPasswordSVPL, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).resendPassword(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} surveyId 
     * @param {MonthlyMatchSurveyConfigBlock} monthlyMatchSurveyConfigBlock 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public saveOneAnswer(surveyId: number, monthlyMatchSurveyConfigBlock: MonthlyMatchSurveyConfigBlock, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).saveOneAnswer(surveyId, monthlyMatchSurveyConfigBlock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Echange jwt token against teacher credentials  Errors : UnknownUserError, InvalidPasswordError
     * @param {TeacherLoggingServiceSigninSVPL} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public signin(body: TeacherLoggingServiceSigninSVPL, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).signin(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {LessonSubmissionMutatorSubmitBookedLessonPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public submitBookedLesson(lessonId: number, body: LessonSubmissionMutatorSubmitBookedLessonPayload, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).submitBookedLesson(lessonId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LessonSubmissionMutatorSubmitFastLessonPayload} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public submitFastLesson(body: LessonSubmissionMutatorSubmitFastLessonPayload, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).submitFastLesson(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachersAppApi
     */
    public unsubmitLesson(lessonId: number, options?: AxiosRequestConfig) {
        return TeachersAppApiFp(this.configuration).unsubmitLesson(lessonId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeachingsLevelsApi - axios parameter creator
 * @export
 */
export const TeachingsLevelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConciseLevels: async (activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachings-levels/concise-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachings: async (activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachings-levels/teachings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingsWithConciseLevels: async (activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teachings-levels/teachings-with-concise-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeachingsLevelsApi - functional programming interface
 * @export
 */
export const TeachingsLevelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeachingsLevelsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LevelsServiceConciseLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConciseLevels(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachings(activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TeachingsServiceTeachingDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachings(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachingsWithConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachingsWithConciseLevels(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeachingsLevelsApi - factory interface
 * @export
 */
export const TeachingsLevelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeachingsLevelsApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConciseLevels(activeOnly?: boolean, options?: any): AxiosPromise<Array<LevelsServiceConciseLevelDTO>> {
            return localVarFp.getConciseLevels(activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachings(activeOnly?: boolean, options?: any): AxiosPromise<Array<TeachingsServiceTeachingDTO>> {
            return localVarFp.getTeachings(activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingsWithConciseLevels(activeOnly?: boolean, options?: any): AxiosPromise<TeachingsWithConciseLevelsServiceGetTeachingsWithConciseLevelsOutput> {
            return localVarFp.getTeachingsWithConciseLevels(activeOnly, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeachingsLevelsApi - object-oriented interface
 * @export
 * @class TeachingsLevelsApi
 * @extends {BaseAPI}
 */
export class TeachingsLevelsApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [activeOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachingsLevelsApi
     */
    public getConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig) {
        return TeachingsLevelsApiFp(this.configuration).getConciseLevels(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [activeOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachingsLevelsApi
     */
    public getTeachings(activeOnly?: boolean, options?: AxiosRequestConfig) {
        return TeachingsLevelsApiFp(this.configuration).getTeachings(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Liste des matières/niveaux pour le flow client
     * @param {boolean} [activeOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeachingsLevelsApi
     */
    public getTeachingsWithConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig) {
        return TeachingsLevelsApiFp(this.configuration).getTeachingsWithConciseLevels(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TreatmentsApi - axios parameter creator
 * @export
 */
export const TreatmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computePayslipsMonth: async (computePayslipsMonthRequest: ComputePayslipsMonthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'computePayslipsMonthRequest' is not null or undefined
            assertParamExists('computePayslipsMonth', 'computePayslipsMonthRequest', computePayslipsMonthRequest)
            const localVarPath = `/treatments/payslips/treat-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computePayslipsMonthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLegalInvoicesMonth: async (computePayslipsMonthRequest: ComputePayslipsMonthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'computePayslipsMonthRequest' is not null or undefined
            assertParamExists('generateLegalInvoicesMonth', 'computePayslipsMonthRequest', computePayslipsMonthRequest)
            const localVarPath = `/treatments/legal-invoices/treat-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(computePayslipsMonthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelevesServiceGenerateRelevesPayload} relevesServiceGenerateRelevesPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReleves: async (relevesServiceGenerateRelevesPayload: RelevesServiceGenerateRelevesPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relevesServiceGenerateRelevesPayload' is not null or undefined
            assertParamExists('generateReleves', 'relevesServiceGenerateRelevesPayload', relevesServiceGenerateRelevesPayload)
            const localVarPath = `/treatments/generate-releves`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relevesServiceGenerateRelevesPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesFirstToTreatMonth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/treatments/legal-invoices/first-to-treat-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetLegalInvoicesMonthStatusRequest} getLegalInvoicesMonthStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesMonthStatus: async (getLegalInvoicesMonthStatusRequest: GetLegalInvoicesMonthStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getLegalInvoicesMonthStatusRequest' is not null or undefined
            assertParamExists('getLegalInvoicesMonthStatus', 'getLegalInvoicesMonthStatusRequest', getLegalInvoicesMonthStatusRequest)
            const localVarPath = `/treatments/legal-invoices/get-month-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getLegalInvoicesMonthStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayslipsInfos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/treatments/payslips/get-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShouldGenerateList: async (month: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getShouldGenerateList', 'month', month)
            const localVarPath = `/treatments/should-generate-releves/{month}`
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelevesServiceSendReleveSVPL} relevesServiceSendReleveSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReleve: async (relevesServiceSendReleveSVPL: RelevesServiceSendReleveSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relevesServiceSendReleveSVPL' is not null or undefined
            assertParamExists('sendReleve', 'relevesServiceSendReleveSVPL', relevesServiceSendReleveSVPL)
            const localVarPath = `/treatments/send-releve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relevesServiceSendReleveSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelevesServiceSendReleveBatchSVPL} relevesServiceSendReleveBatchSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReleveBatch: async (relevesServiceSendReleveBatchSVPL: RelevesServiceSendReleveBatchSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relevesServiceSendReleveBatchSVPL' is not null or undefined
            assertParamExists('sendReleveBatch', 'relevesServiceSendReleveBatchSVPL', relevesServiceSendReleveBatchSVPL)
            const localVarPath = `/treatments/send-releve-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relevesServiceSendReleveBatchSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TreatmentsApi - functional programming interface
 * @export
 */
export const TreatmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TreatmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async computePayslipsMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputePayslipsMonth200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.computePayslipsMonth(computePayslipsMonthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateLegalInvoicesMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateLegalInvoicesMonth200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateLegalInvoicesMonth(computePayslipsMonthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelevesServiceGenerateRelevesPayload} relevesServiceGenerateRelevesPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateReleves(relevesServiceGenerateRelevesPayload: RelevesServiceGenerateRelevesPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateReleves(relevesServiceGenerateRelevesPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalInvoicesFirstToTreatMonth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalInvoicesFirstToTreatMonth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetLegalInvoicesMonthStatusRequest} getLegalInvoicesMonthStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest: GetLegalInvoicesMonthStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalInvoicesTreamentServiceGetMonthStatusOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayslipsInfos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPayslipsInfos200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayslipsInfos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShouldGenerateList(month: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetShouldGenerateList200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShouldGenerateList(month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelevesServiceSendReleveSVPL} relevesServiceSendReleveSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendReleve(relevesServiceSendReleveSVPL: RelevesServiceSendReleveSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelevesServiceSendReleveResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendReleve(relevesServiceSendReleveSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelevesServiceSendReleveBatchSVPL} relevesServiceSendReleveBatchSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendReleveBatch(relevesServiceSendReleveBatchSVPL: RelevesServiceSendReleveBatchSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelevesServiceSendReleveBatchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendReleveBatch(relevesServiceSendReleveBatchSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TreatmentsApi - factory interface
 * @export
 */
export const TreatmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TreatmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        computePayslipsMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: any): AxiosPromise<ComputePayslipsMonth200Response> {
            return localVarFp.computePayslipsMonth(computePayslipsMonthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateLegalInvoicesMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: any): AxiosPromise<GenerateLegalInvoicesMonth200Response> {
            return localVarFp.generateLegalInvoicesMonth(computePayslipsMonthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelevesServiceGenerateRelevesPayload} relevesServiceGenerateRelevesPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReleves(relevesServiceGenerateRelevesPayload: RelevesServiceGenerateRelevesPayload, options?: any): AxiosPromise<void> {
            return localVarFp.generateReleves(relevesServiceGenerateRelevesPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesFirstToTreatMonth(options?: any): AxiosPromise<string> {
            return localVarFp.getLegalInvoicesFirstToTreatMonth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetLegalInvoicesMonthStatusRequest} getLegalInvoicesMonthStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest: GetLegalInvoicesMonthStatusRequest, options?: any): AxiosPromise<LegalInvoicesTreamentServiceGetMonthStatusOutput> {
            return localVarFp.getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayslipsInfos(options?: any): AxiosPromise<Array<GetPayslipsInfos200ResponseInner>> {
            return localVarFp.getPayslipsInfos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShouldGenerateList(month: string, options?: any): AxiosPromise<Array<GetShouldGenerateList200ResponseInner>> {
            return localVarFp.getShouldGenerateList(month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelevesServiceSendReleveSVPL} relevesServiceSendReleveSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReleve(relevesServiceSendReleveSVPL: RelevesServiceSendReleveSVPL, options?: any): AxiosPromise<RelevesServiceSendReleveResult> {
            return localVarFp.sendReleve(relevesServiceSendReleveSVPL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelevesServiceSendReleveBatchSVPL} relevesServiceSendReleveBatchSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReleveBatch(relevesServiceSendReleveBatchSVPL: RelevesServiceSendReleveBatchSVPL, options?: any): AxiosPromise<RelevesServiceSendReleveBatchResult> {
            return localVarFp.sendReleveBatch(relevesServiceSendReleveBatchSVPL, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TreatmentsApi - object-oriented interface
 * @export
 * @class TreatmentsApi
 * @extends {BaseAPI}
 */
export class TreatmentsApi extends BaseAPI {
    /**
     * 
     * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public computePayslipsMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).computePayslipsMonth(computePayslipsMonthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ComputePayslipsMonthRequest} computePayslipsMonthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public generateLegalInvoicesMonth(computePayslipsMonthRequest: ComputePayslipsMonthRequest, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).generateLegalInvoicesMonth(computePayslipsMonthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelevesServiceGenerateRelevesPayload} relevesServiceGenerateRelevesPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public generateReleves(relevesServiceGenerateRelevesPayload: RelevesServiceGenerateRelevesPayload, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).generateReleves(relevesServiceGenerateRelevesPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public getLegalInvoicesFirstToTreatMonth(options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).getLegalInvoicesFirstToTreatMonth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetLegalInvoicesMonthStatusRequest} getLegalInvoicesMonthStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest: GetLegalInvoicesMonthStatusRequest, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).getLegalInvoicesMonthStatus(getLegalInvoicesMonthStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public getPayslipsInfos(options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).getPayslipsInfos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public getShouldGenerateList(month: string, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).getShouldGenerateList(month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelevesServiceSendReleveSVPL} relevesServiceSendReleveSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public sendReleve(relevesServiceSendReleveSVPL: RelevesServiceSendReleveSVPL, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).sendReleve(relevesServiceSendReleveSVPL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelevesServiceSendReleveBatchSVPL} relevesServiceSendReleveBatchSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TreatmentsApi
     */
    public sendReleveBatch(relevesServiceSendReleveBatchSVPL: RelevesServiceSendReleveBatchSVPL, options?: AxiosRequestConfig) {
        return TreatmentsApiFp(this.configuration).sendReleveBatch(relevesServiceSendReleveBatchSVPL, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TsoaErrorsApi - axios parameter creator
 * @export
 */
export const TsoaErrorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fake route to export errors to the swagger.json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tsoa-errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TsoaErrorsApi - functional programming interface
 * @export
 */
export const TsoaErrorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TsoaErrorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Fake route to export errors to the swagger.json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TsoaErrorsApi - factory interface
 * @export
 */
export const TsoaErrorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TsoaErrorsApiFp(configuration)
    return {
        /**
         * Fake route to export errors to the swagger.json file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errors(options?: any): AxiosPromise<void> {
            return localVarFp.errors(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TsoaErrorsApi - object-oriented interface
 * @export
 * @class TsoaErrorsApi
 * @extends {BaseAPI}
 */
export class TsoaErrorsApi extends BaseAPI {
    /**
     * Fake route to export errors to the swagger.json file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TsoaErrorsApi
     */
    public errors(options?: AxiosRequestConfig) {
        return TsoaErrorsApiFp(this.configuration).errors(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WixPartenairesApi - axios parameter creator
 * @export
 */
export const WixPartenairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WixPartenairesServiceInsertBusinessContactPayload} wixPartenairesServiceInsertBusinessContactPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBusinessContact: async (wixPartenairesServiceInsertBusinessContactPayload: WixPartenairesServiceInsertBusinessContactPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wixPartenairesServiceInsertBusinessContactPayload' is not null or undefined
            assertParamExists('insertBusinessContact', 'wixPartenairesServiceInsertBusinessContactPayload', wixPartenairesServiceInsertBusinessContactPayload)
            const localVarPath = `/wix-partenaires/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wixPartenairesServiceInsertBusinessContactPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WixPartenairesApi - functional programming interface
 * @export
 */
export const WixPartenairesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WixPartenairesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WixPartenairesServiceInsertBusinessContactPayload} wixPartenairesServiceInsertBusinessContactPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload: WixPartenairesServiceInsertBusinessContactPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WixPartenairesApi - factory interface
 * @export
 */
export const WixPartenairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WixPartenairesApiFp(configuration)
    return {
        /**
         * 
         * @param {WixPartenairesServiceInsertBusinessContactPayload} wixPartenairesServiceInsertBusinessContactPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload: WixPartenairesServiceInsertBusinessContactPayload, options?: any): AxiosPromise<void> {
            return localVarFp.insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WixPartenairesApi - object-oriented interface
 * @export
 * @class WixPartenairesApi
 * @extends {BaseAPI}
 */
export class WixPartenairesApi extends BaseAPI {
    /**
     * 
     * @param {WixPartenairesServiceInsertBusinessContactPayload} wixPartenairesServiceInsertBusinessContactPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixPartenairesApi
     */
    public insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload: WixPartenairesServiceInsertBusinessContactPayload, options?: AxiosRequestConfig) {
        return WixPartenairesApiFp(this.configuration).insertBusinessContact(wixPartenairesServiceInsertBusinessContactPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WixProspectsApi - axios parameter creator
 * @export
 */
export const WixProspectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainCities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-prospects/main-cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} formula 
         * @param {number} conciseLevelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceEstimation: async (formula: number, conciseLevelId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formula' is not null or undefined
            assertParamExists('getPriceEstimation', 'formula', formula)
            // verify required parameter 'conciseLevelId' is not null or undefined
            assertParamExists('getPriceEstimation', 'conciseLevelId', conciseLevelId)
            const localVarPath = `/wix-prospects/price-estimation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (formula !== undefined) {
                localVarQueryParameter['formula'] = formula;
            }

            if (conciseLevelId !== undefined) {
                localVarQueryParameter['conciseLevelId'] = conciseLevelId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingsWithConciseLevels: async (activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-prospects/teachings-with-concise-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProspectRegistrationServiceHandleCalendlyScheduledPayload} prospectRegistrationServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendlyScheduled: async (prospectRegistrationServiceHandleCalendlyScheduledPayload: ProspectRegistrationServiceHandleCalendlyScheduledPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectRegistrationServiceHandleCalendlyScheduledPayload' is not null or undefined
            assertParamExists('handleCalendlyScheduled', 'prospectRegistrationServiceHandleCalendlyScheduledPayload', prospectRegistrationServiceHandleCalendlyScheduledPayload)
            const localVarPath = `/wix-prospects/schedule-calendly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prospectRegistrationServiceHandleCalendlyScheduledPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProspectRegistrationServiceRegistrationData} prospectRegistrationServiceRegistrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProspect: async (prospectRegistrationServiceRegistrationData: ProspectRegistrationServiceRegistrationData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectRegistrationServiceRegistrationData' is not null or undefined
            assertParamExists('registerProspect', 'prospectRegistrationServiceRegistrationData', prospectRegistrationServiceRegistrationData)
            const localVarPath = `/wix-prospects/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prospectRegistrationServiceRegistrationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WixProspectsApi - functional programming interface
 * @export
 */
export const WixProspectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WixProspectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMainCities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorefrontProspectDataServiceMainCityDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMainCities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} formula 
         * @param {number} conciseLevelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceEstimation(formula: number, conciseLevelId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceEstimation(formula, conciseLevelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachingsWithConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorefrontProspectDataServiceTeachingsWithConciseLevels>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachingsWithConciseLevels(activeOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProspectRegistrationServiceHandleCalendlyScheduledPayload} prospectRegistrationServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload: ProspectRegistrationServiceHandleCalendlyScheduledPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectRegistrationServiceHandleCalendlyScheduledOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProspectRegistrationServiceRegistrationData} prospectRegistrationServiceRegistrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerProspect(prospectRegistrationServiceRegistrationData: ProspectRegistrationServiceRegistrationData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterProspect200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerProspect(prospectRegistrationServiceRegistrationData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WixProspectsApi - factory interface
 * @export
 */
export const WixProspectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WixProspectsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMainCities(options?: any): AxiosPromise<Array<StorefrontProspectDataServiceMainCityDTO>> {
            return localVarFp.getMainCities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} formula 
         * @param {number} conciseLevelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceEstimation(formula: number, conciseLevelId: number, options?: any): AxiosPromise<number> {
            return localVarFp.getPriceEstimation(formula, conciseLevelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Liste des matières/niveaux pour le flow client
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingsWithConciseLevels(activeOnly?: boolean, options?: any): AxiosPromise<StorefrontProspectDataServiceTeachingsWithConciseLevels> {
            return localVarFp.getTeachingsWithConciseLevels(activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProspectRegistrationServiceHandleCalendlyScheduledPayload} prospectRegistrationServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload: ProspectRegistrationServiceHandleCalendlyScheduledPayload, options?: any): AxiosPromise<ProspectRegistrationServiceHandleCalendlyScheduledOutput> {
            return localVarFp.handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProspectRegistrationServiceRegistrationData} prospectRegistrationServiceRegistrationData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProspect(prospectRegistrationServiceRegistrationData: ProspectRegistrationServiceRegistrationData, options?: any): AxiosPromise<RegisterProspect200Response> {
            return localVarFp.registerProspect(prospectRegistrationServiceRegistrationData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WixProspectsApi - object-oriented interface
 * @export
 * @class WixProspectsApi
 * @extends {BaseAPI}
 */
export class WixProspectsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixProspectsApi
     */
    public getMainCities(options?: AxiosRequestConfig) {
        return WixProspectsApiFp(this.configuration).getMainCities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} formula 
     * @param {number} conciseLevelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixProspectsApi
     */
    public getPriceEstimation(formula: number, conciseLevelId: number, options?: AxiosRequestConfig) {
        return WixProspectsApiFp(this.configuration).getPriceEstimation(formula, conciseLevelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Liste des matières/niveaux pour le flow client
     * @param {boolean} [activeOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixProspectsApi
     */
    public getTeachingsWithConciseLevels(activeOnly?: boolean, options?: AxiosRequestConfig) {
        return WixProspectsApiFp(this.configuration).getTeachingsWithConciseLevels(activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProspectRegistrationServiceHandleCalendlyScheduledPayload} prospectRegistrationServiceHandleCalendlyScheduledPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixProspectsApi
     */
    public handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload: ProspectRegistrationServiceHandleCalendlyScheduledPayload, options?: AxiosRequestConfig) {
        return WixProspectsApiFp(this.configuration).handleCalendlyScheduled(prospectRegistrationServiceHandleCalendlyScheduledPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProspectRegistrationServiceRegistrationData} prospectRegistrationServiceRegistrationData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixProspectsApi
     */
    public registerProspect(prospectRegistrationServiceRegistrationData: ProspectRegistrationServiceRegistrationData, options?: AxiosRequestConfig) {
        return WixProspectsApiFp(this.configuration).registerProspect(prospectRegistrationServiceRegistrationData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WixTeachersApi - axios parameter creator
 * @export
 */
export const WixTeachersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScolaritiesMain: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-teachers/get-scolarities-main`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mainCityId 
         * @param {string} [withVisio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions: async (mainCityId: string, withVisio?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mainCityId' is not null or undefined
            assertParamExists('getSessions', 'mainCityId', mainCityId)
            const localVarPath = `/wix-teachers/get-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mainCityId !== undefined) {
                localVarQueryParameter['mainCityId'] = mainCityId;
            }

            if (withVisio !== undefined) {
                localVarQueryParameter['withVisio'] = withVisio;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyLevels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-teachers/get-study-levels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacher: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-teachers/get-teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isActiveTeachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherMainCities: async (isActiveTeachers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wix-teachers/main-cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isActiveTeachers !== undefined) {
                localVarQueryParameter['isActiveTeachers'] = isActiveTeachers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CandidatureServiceHandleCalendlyScheduledPayload} candidatureServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendlyScheduled: async (candidatureServiceHandleCalendlyScheduledPayload: CandidatureServiceHandleCalendlyScheduledPayload, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidatureServiceHandleCalendlyScheduledPayload' is not null or undefined
            assertParamExists('handleCalendlyScheduled', 'candidatureServiceHandleCalendlyScheduledPayload', candidatureServiceHandleCalendlyScheduledPayload)
            const localVarPath = `/wix-teachers/schedule-calendly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidatureServiceHandleCalendlyScheduledPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WixTeachersPostulateParams} wixTeachersPostulateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulate: async (wixTeachersPostulateParams: WixTeachersPostulateParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wixTeachersPostulateParams' is not null or undefined
            assertParamExists('postulate', 'wixTeachersPostulateParams', wixTeachersPostulateParams)
            const localVarPath = `/wix-teachers/postulate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wixTeachersPostulateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WixTeachersPostulateCompleteParams} wixTeachersPostulateCompleteParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulateComplete: async (wixTeachersPostulateCompleteParams: WixTeachersPostulateCompleteParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wixTeachersPostulateCompleteParams' is not null or undefined
            assertParamExists('postulateComplete', 'wixTeachersPostulateCompleteParams', wixTeachersPostulateCompleteParams)
            const localVarPath = `/wix-teachers/postulate-complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wixTeachersPostulateCompleteParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WixTeachersPostulateFastParams} wixTeachersPostulateFastParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulateFast: async (wixTeachersPostulateFastParams: WixTeachersPostulateFastParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wixTeachersPostulateFastParams' is not null or undefined
            assertParamExists('postulateFast', 'wixTeachersPostulateFastParams', wixTeachersPostulateFastParams)
            const localVarPath = `/wix-teachers/postulate-fast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wixTeachersPostulateFastParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StorefrontTeacherLoggingServiceSigninSVPL} storefrontTeacherLoggingServiceSigninSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin: async (storefrontTeacherLoggingServiceSigninSVPL: StorefrontTeacherLoggingServiceSigninSVPL, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storefrontTeacherLoggingServiceSigninSVPL' is not null or undefined
            assertParamExists('signin', 'storefrontTeacherLoggingServiceSigninSVPL', storefrontTeacherLoggingServiceSigninSVPL)
            const localVarPath = `/wix-teachers/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storefrontTeacherLoggingServiceSigninSVPL, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WixTeachersApi - functional programming interface
 * @export
 */
export const WixTeachersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WixTeachersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScolaritiesMain(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidatureServiceScolarityMainDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScolaritiesMain(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mainCityId 
         * @param {string} [withVisio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessions(mainCityId: string, withVisio?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidatureServiceDoodleSessionDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessions(mainCityId, withVisio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyLevels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidatureServiceStudyLevelDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyLevels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacher(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidatureServiceTeacherDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacher(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isActiveTeachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeacherMainCities(isActiveTeachers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidatureServiceMainCityDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeacherMainCities(isActiveTeachers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CandidatureServiceHandleCalendlyScheduledPayload} candidatureServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload: CandidatureServiceHandleCalendlyScheduledPayload, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidatureServiceHandleCalendlyScheduledOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WixTeachersPostulateParams} wixTeachersPostulateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postulate(wixTeachersPostulateParams: WixTeachersPostulateParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postulate(wixTeachersPostulateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WixTeachersPostulateCompleteParams} wixTeachersPostulateCompleteParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postulateComplete(wixTeachersPostulateCompleteParams: WixTeachersPostulateCompleteParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postulateComplete(wixTeachersPostulateCompleteParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WixTeachersPostulateFastParams} wixTeachersPostulateFastParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postulateFast(wixTeachersPostulateFastParams: WixTeachersPostulateFastParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postulateFast(wixTeachersPostulateFastParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StorefrontTeacherLoggingServiceSigninSVPL} storefrontTeacherLoggingServiceSigninSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signin(storefrontTeacherLoggingServiceSigninSVPL: StorefrontTeacherLoggingServiceSigninSVPL, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signin(storefrontTeacherLoggingServiceSigninSVPL, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WixTeachersApi - factory interface
 * @export
 */
export const WixTeachersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WixTeachersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScolaritiesMain(options?: any): AxiosPromise<Array<CandidatureServiceScolarityMainDTO>> {
            return localVarFp.getScolaritiesMain(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mainCityId 
         * @param {string} [withVisio] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions(mainCityId: string, withVisio?: string, options?: any): AxiosPromise<Array<CandidatureServiceDoodleSessionDTO>> {
            return localVarFp.getSessions(mainCityId, withVisio, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyLevels(options?: any): AxiosPromise<Array<CandidatureServiceStudyLevelDTO>> {
            return localVarFp.getStudyLevels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacher(options?: any): AxiosPromise<CandidatureServiceTeacherDTO> {
            return localVarFp.getTeacher(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isActiveTeachers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeacherMainCities(isActiveTeachers?: boolean, options?: any): AxiosPromise<Array<CandidatureServiceMainCityDTO>> {
            return localVarFp.getTeacherMainCities(isActiveTeachers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CandidatureServiceHandleCalendlyScheduledPayload} candidatureServiceHandleCalendlyScheduledPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload: CandidatureServiceHandleCalendlyScheduledPayload, options?: any): AxiosPromise<CandidatureServiceHandleCalendlyScheduledOutput> {
            return localVarFp.handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WixTeachersPostulateParams} wixTeachersPostulateParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulate(wixTeachersPostulateParams: WixTeachersPostulateParams, options?: any): AxiosPromise<void> {
            return localVarFp.postulate(wixTeachersPostulateParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WixTeachersPostulateCompleteParams} wixTeachersPostulateCompleteParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulateComplete(wixTeachersPostulateCompleteParams: WixTeachersPostulateCompleteParams, options?: any): AxiosPromise<void> {
            return localVarFp.postulateComplete(wixTeachersPostulateCompleteParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WixTeachersPostulateFastParams} wixTeachersPostulateFastParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postulateFast(wixTeachersPostulateFastParams: WixTeachersPostulateFastParams, options?: any): AxiosPromise<void> {
            return localVarFp.postulateFast(wixTeachersPostulateFastParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StorefrontTeacherLoggingServiceSigninSVPL} storefrontTeacherLoggingServiceSigninSVPL 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(storefrontTeacherLoggingServiceSigninSVPL: StorefrontTeacherLoggingServiceSigninSVPL, options?: any): AxiosPromise<string> {
            return localVarFp.signin(storefrontTeacherLoggingServiceSigninSVPL, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WixTeachersApi - object-oriented interface
 * @export
 * @class WixTeachersApi
 * @extends {BaseAPI}
 */
export class WixTeachersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public getScolaritiesMain(options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).getScolaritiesMain(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mainCityId 
     * @param {string} [withVisio] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public getSessions(mainCityId: string, withVisio?: string, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).getSessions(mainCityId, withVisio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public getStudyLevels(options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).getStudyLevels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public getTeacher(options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).getTeacher(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isActiveTeachers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public getTeacherMainCities(isActiveTeachers?: boolean, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).getTeacherMainCities(isActiveTeachers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CandidatureServiceHandleCalendlyScheduledPayload} candidatureServiceHandleCalendlyScheduledPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload: CandidatureServiceHandleCalendlyScheduledPayload, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).handleCalendlyScheduled(candidatureServiceHandleCalendlyScheduledPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WixTeachersPostulateParams} wixTeachersPostulateParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public postulate(wixTeachersPostulateParams: WixTeachersPostulateParams, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).postulate(wixTeachersPostulateParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WixTeachersPostulateCompleteParams} wixTeachersPostulateCompleteParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public postulateComplete(wixTeachersPostulateCompleteParams: WixTeachersPostulateCompleteParams, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).postulateComplete(wixTeachersPostulateCompleteParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WixTeachersPostulateFastParams} wixTeachersPostulateFastParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public postulateFast(wixTeachersPostulateFastParams: WixTeachersPostulateFastParams, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).postulateFast(wixTeachersPostulateFastParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorefrontTeacherLoggingServiceSigninSVPL} storefrontTeacherLoggingServiceSigninSVPL 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WixTeachersApi
     */
    public signin(storefrontTeacherLoggingServiceSigninSVPL: StorefrontTeacherLoggingServiceSigninSVPL, options?: AxiosRequestConfig) {
        return WixTeachersApiFp(this.configuration).signin(storefrontTeacherLoggingServiceSigninSVPL, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YousignWebhooksApi - axios parameter creator
 * @export
 */
export const YousignWebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCharte: async (handleCharteRequest: HandleCharteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'handleCharteRequest' is not null or undefined
            assertParamExists('handleCharte', 'handleCharteRequest', handleCharteRequest)
            const localVarPath = `/yousign-webhooks/charte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(handleCharteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCustomerProposition: async (handleCharteRequest: HandleCharteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'handleCharteRequest' is not null or undefined
            assertParamExists('handleCustomerProposition', 'handleCharteRequest', handleCharteRequest)
            const localVarPath = `/yousign-webhooks/customer-proposition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(handleCharteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YousignWebhooksApi - functional programming interface
 * @export
 */
export const YousignWebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YousignWebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCharte(handleCharteRequest: HandleCharteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCharte(handleCharteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleCustomerProposition(handleCharteRequest: HandleCharteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleCustomerProposition(handleCharteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YousignWebhooksApi - factory interface
 * @export
 */
export const YousignWebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YousignWebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCharte(handleCharteRequest: HandleCharteRequest, options?: any): AxiosPromise<any> {
            return localVarFp.handleCharte(handleCharteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {HandleCharteRequest} handleCharteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleCustomerProposition(handleCharteRequest: HandleCharteRequest, options?: any): AxiosPromise<any> {
            return localVarFp.handleCustomerProposition(handleCharteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * YousignWebhooksApi - object-oriented interface
 * @export
 * @class YousignWebhooksApi
 * @extends {BaseAPI}
 */
export class YousignWebhooksApi extends BaseAPI {
    /**
     * 
     * @param {HandleCharteRequest} handleCharteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YousignWebhooksApi
     */
    public handleCharte(handleCharteRequest: HandleCharteRequest, options?: AxiosRequestConfig) {
        return YousignWebhooksApiFp(this.configuration).handleCharte(handleCharteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {HandleCharteRequest} handleCharteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YousignWebhooksApi
     */
    public handleCustomerProposition(handleCharteRequest: HandleCharteRequest, options?: AxiosRequestConfig) {
        return YousignWebhooksApiFp(this.configuration).handleCustomerProposition(handleCharteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}




/**
 * MainApi - object-oriented interface
 * @export
 * @class MainApi
 * @extends {BaseAPI}
 */
export class MainApi extends BaseAPI {

    asap:AsapApi
    agents:AgentsApi
    candidatures:CandidaturesApi
    yousignWebooks:YousignWebhooksApi
    googlePeople:GooglePeopleApi
    googleRatings:GoogleRatingsApi
    emails:EmailsApi
    stats:StatsApi
    teachers:TeachersApi
    phoneCalls:PhoneCallsApi
    families:FamiliesApi
    propositions:PropositionsApi
    mailbox:MailboxApi
    teachersApp:TeachersAppApi
    pdfs:PdfsApi
    payments:PaymentsApi
    aici: AICIApi
    notifications:NotificationsApi
    treatments:TreatmentsApi
    customerValidation: CustomerValidationApi
    wixTeachers:WixTeachersApi
    wixProspects:WixProspectsApi
    compta: ComptaApi
    affiliationCode: AffiliationCodeApi
    holidays: HolidaysApi
    wixPartenaires: WixPartenairesApi
    ringover:RingoverApi
    pennylane:PennylaneApi
    db:DbApi




    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {

        super(configuration,basePath,axios)
        const args = [configuration,basePath,axios] as const
        
        this.asap = new AsapApi(...args)
        this.candidatures = new CandidaturesApi(...args)
        this.yousignWebooks = new YousignWebhooksApi(...args)
        this.agents = new AgentsApi(...args)
        this.googlePeople = new GooglePeopleApi(...args)
        this.googleRatings = new GoogleRatingsApi(...args)
        this.emails = new EmailsApi(...args)
        this.stats = new StatsApi(...args)
        this.teachers = new TeachersApi(...args)
        this.phoneCalls = new PhoneCallsApi(...args)
        this.families = new FamiliesApi(...args)
        this.propositions = new PropositionsApi(...args)
        this.mailbox = new MailboxApi(...args)
        this.teachersApp = new TeachersAppApi(...args)
        this.pdfs = new PdfsApi(...args)
        this.payments = new PaymentsApi(...args)
        this.aici = new AICIApi(...args)
        this.notifications = new NotificationsApi(...args)
        this.treatments = new TreatmentsApi(...args)
        this.customerValidation = new CustomerValidationApi(...args)
        this.wixTeachers = new WixTeachersApi(...args)
        this.wixProspects = new WixProspectsApi(...args)
        this.compta = new ComptaApi(...args)
        this.affiliationCode = new AffiliationCodeApi(...args)
        this.holidays = new HolidaysApi(...args)
        this.wixPartenaires = new WixPartenairesApi(...args)
        this.ringover = new RingoverApi(...args)
        this.pennylane = new PennylaneApi(...args)
        this.db = new DbApi(...args)
    }
}