import { PrimaryKeyNames, PrimaryKeyType, RootEntity } from './lib';
export const InfoRejetEntries = {
    REGUL_MNT_FACT: "REGUL_MNT_FACT",
    REGUL_NB_QTE_FACT: "REGUL_NB_QTE_FACT",
    REGUL_PRESTA_FACT: "REGUL_PRESTA_FACT",
    REGUL_AUTRE: "REGUL_AUTRE",
    CONTEST_ERR_FACT: "CONTEST_ERR_FACT",
    CONTEST_FACT_REGLEE: "CONTEST_FACT_REGLEE",
    CONTEST_AUTRE: "CONTEST_AUTRE",
    ANNUL_DBL: "ANNUL_DBL",
    ANNUL_ERR_MNT: "ANNUL_ERR_MNT",
    ANNUL_ERR_TECH: "ANNUL_ERR_TECH",
    ANNUL_AUTRE: "ANNUL_AUTRE",
};
export const AppSettingKeys = {
    gocardlessPaymentsLastSync: null,
    ringoverContactsLastSync: null,
    ringoverPhoneCallsLastSync: null,
    comptaLockedOn: null,
    pennylaneOAuthData: null,
    pennylaneSyncTransactionsStartFrom: null,
    pennylaneInitSyncAt: null,
    pennylaneLastLinkAt: null,
    pennylaneLastSyncAt: null,
    pennylaneCategory475Id: null,
    pennylaneCompanyId: null,
};
export const AsapDebitOpTypeEntries = {
    asap: "asap",
    payslip: "payslip",
    "manual-accounting": "manual-accounting",
};
export const AsapFamilyDeclarationStatusEntries = {
    DRAFT: "DRAFT",
    DECLARED: "DECLARED",
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
};
export const AsapGlobalDeclarationStatusEntries = {
    DRAFT: "DRAFT",
    LOCKED: "LOCKED",
    DECLARED: "DECLARED",
    CONFIRMED: "CONFIRMED",
    ABANDONNED: "ABANDONNED",
};
export const AsapUrssafDeclarationStatusEntries = {
    DRAFT: "DRAFT",
    DECLARED: "DECLARED",
    CONFIRMED: "CONFIRMED",
    PAID: "PAID",
    ABANDONNED: "ABANDONNED",
};
export const AttestationFiscaleTypeEntries = {
    AICI: "AICI",
    CIDiff: "CIDiff",
    Mixed: "Mixed",
    Unknown: "Unknown",
};
export const BankCheckStatusEnum = {
    RECEIVED: "RECEIVED",
    DEPOSED: "DEPOSED",
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
};
export const BankCheckDepositStatusEnum = {
    DEPOSED: "DEPOSED",
    RECONCILLED: "RECONCILLED",
};
export const BankTransactionTreatmentKindEntries = {
    MANUAL: "MANUAL",
    EXTERNAL: "EXTERNAL",
    TEACHER_PAYMENT: "TEACHER_PAYMENT",
    CUSTOMER_TRANSFER_CASHIN: "CUSTOMER_TRANSFER_CASHIN",
    CHECK_DEPOSIT: "CHECK_DEPOSIT",
    CESU_DEPOSIT: "CESU_DEPOSIT",
    GOCARDLESS_PAYOUT: "GOCARDLESS_PAYOUT",
    CUSTOMER_TRANSFER_PAYBACK: "CUSTOMER_TRANSFER_PAYBACK",
    TEACHER_TRANSFER_PAYBACK: "TEACHER_TRANSFER_PAYBACK",
    ASAP_PAYMENT: "ASAP_PAYMENT",
    AICI_PAYMENT: "AICI_PAYMENT",
    AICI_PAYBACK: "AICI_PAYBACK",
};
export const BankTransactionTresoKindEntries = {
    CUSTOMER_PAYMENT: "CUSTOMER_PAYMENT",
    TEACHER_PAYMENT: "TEACHER_PAYMENT",
    CUSTOMER_PAYBACK: "CUSTOMER_PAYBACK",
    TEACHER_PAYBACK: "TEACHER_PAYBACK",
    TEACHER_COTISATIONS: "TEACHER_COTISATIONS",
    AICI: "AICI",
    SPECIAL: "SPECIAL",
    IGNORE: "IGNORE",
};
const RegistrationTypeEnum = {
    coll: "coll",
    comp: "comp",
};
export const CandidatureStatusEntries = {
    CANDIDATURE_ASK: "CANDIDATURE_ASK",
    CANDIDATURE_REFUSED: "CANDIDATURE_REFUSED",
    CANDIDATURE_PAUSED: "CANDIDATURE_PAUSED",
    WAITING_TEACHER_INFO: "WAITING_TEACHER_INFO",
    WAITING_SESSION_SCHEDULING: "WAITING_SESSION_SCHEDULING",
    WAITING_SESSION_RESULT: "WAITING_SESSION_RESULT",
    SESSION_REFUSED: "SESSION_REFUSED",
    WAITING_QUALIF_AND_SIGN: "WAITING_QUALIF_AND_SIGN",
    WAITING_SIGN_QUALIF_OK: "WAITING_SIGN_QUALIF_OK",
    WAITING_QUALIF_SIGN_OK: "WAITING_QUALIF_SIGN_OK",
    CANDIDATURE_FINISHED: "CANDIDATURE_FINISHED",
};
export const CesuCheckDepositStatusEnum = {
    DEPOSED: "DEPOSED",
    RECONCILLED: "RECONCILLED",
};
export const CesuCheckStatusEnum = {
    RECEIVED: "RECEIVED",
    DEPOSED: "DEPOSED",
    CONFIRMED: "CONFIRMED",
};
export const CreditStatusEntries = {
    DRAFT: "DRAFT",
    AVAILABLE: "AVAILABLE",
    USED: "USED",
    CANCELLED: "CANCELLED",
    ABORTED: "ABORTED",
};
export const CreditInvoiceStatusEntries = {
    DRAFT: "DRAFT",
    READY: "READY",
    PENDING: "PENDING",
    REFUSED: "REFUSED",
    SIGNED: "SIGNED",
    INVOICE: "INVOICE",
    ABORTED: "ABORTED",
};
export const CreationBlockStatusEntries = {
    DRAFT: "DRAFT",
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    ABORTED: "ABORTED",
};
export const CustomerPropositionStatusEntries = {
    DRAFT: "DRAFT",
    AWAITING: "AWAITING",
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
};
export const DebitOpTypeEntries = {
    "credit-invoice": "credit-invoice",
    payslip: "payslip",
    "customer-check": "customer-check",
    "customer-check-rejected": "customer-check-rejected",
    "bank-transfer": "bank-transfer",
    "gocardless-prelevement": "gocardless-prelevement",
    "gocardless-refund": "gocardless-refund",
    "manual-accounting": "manual-accounting",
    "standard-invoice": "standard-invoice",
    "teacher-payment": "teacher-payment",
    "customer-transfer-cashin": "customer-transfer-cashin",
    "customer-transfer-payback": "customer-transfer-payback",
    "teacher-transfer-payback": "teacher-transfer-payback",
    "teacher-check-payback": "teacher-check-payback",
    "cesu-check-lot": "cesu-check-lot",
    "cesu-check-lot-rejection": "cesu-check-lot-rejection",
};
export const DocStatus = {
    TO_SIGN: "TO_SIGN",
    PENDING: "PENDING",
    SIGNED: "SIGNED",
    FORCED: "FORCED",
    REFUSED: "REFUSED",
    TO_SEND: "TO_SEND",
    SENT: "SENT",
    ABORTED: "ABORTED",
    NONE: "NONE",
};
export const DocTypes = {
    UNIQUE: "UNIQUE",
    CHARTE: "CHARTE",
    FACTURE_CREDITS: "FACTURE_CREDITS",
    DEVIS_CREDITS: "DEVIS_CREDITS",
    FACTURE_STANDARD: "FACTURE_STANDARD",
    DEVIS_STANDARD: "FACTURE_STANDARD",
    CGS: "CGS",
    MANDAT_CLIENT: "MANDAT_CLIENT",
    MANDAT_PROF: "MANDAT_PROF",
    PROPOSITION_PROF: "PROPOSITION_PROF",
    MANDAT_SEPA: "MANDAT_SEPA",
};
const FirebaseProjectStatusEntries = {
    "teacher-app": "teacher-app",
    "client-app": "client-app",
};
export const FamilyCommercialStepEnum = {
    LEAD: "LEAD",
    PROSPECT: "PROSPECT",
    CLIENT: "CLIENT",
};
export const FamilyTrashCategoryEnum = {
    ALIVE: "ALIVE",
    MAYBE_TRASH: "MAYBE_TRASH",
    REFUSED_TRASH: "REFUSED_TRASH",
    SUPERSENIOR: "SUPERSENIOR",
};
export const AsapExonerationEnum = {
    AGE70: "AGE70",
    HANDICAP: "HANDICAP",
    COMPENSATION_TIERS: "COMPENSATION_TIERS",
    MAJORATION_TIERS: "MAJORATION_TIERS",
    APA: "APA",
};
export const GocardlessPaymentStatusEnum = {
    DRAFT: "DRAFT",
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    FAILED: "FAILED",
    SUCCESS: "SUCCESS",
    CHARGED_BACK: "CHARGED_BACK",
};
export const GocardlessPaymentExtStatusEnum = {
    pending_customer_approval: "pending_customer_approval",
    pending_submission: "pending_submission",
    submitted: "submitted",
    confirmed: "confirmed",
    paid_out: "paid_out",
    cancelled: "cancelled",
    customer_approval_denied: "customer_approval_denied",
    failed: "failed",
    charged_back: "charged_back",
};
export const GocardlessPayoutStatusEnum = {
    PENDING: "PENDING",
    BOUNCED: "BOUNCED",
    PAID: "PAID",
    RECONCILLED: "RECONCILLED",
};
export const LedgerAccountRolesEnum = {
    clients: "clients",
    vente: "vente",
    produits_exceptionnels: "produits_exceptionnels",
    tva_achats: "tva_achats",
    tva_ventes: "tva_ventes",
    asap: "asap",
    profs: "profs",
    cheques_a_encaisser: "cheques_a_encaisser",
    cesu_a_encaisser: "cesu_a_encaisser",
    cesu_attente_versement: "cesu_attente_versement",
    gocardless_preleve: "gocardless_preleve",
    gocardless_attente_versement: "gocardless_attente_versement",
    attente: "attente",
    fournisseur_crcesu: "fournisseur_crcesu",
    fournisseur_gocardless: "fournisseur_gocardless",
    affectations: "affectations",
    external: "external",
};
export const LessonStatusEntries = {
    DRAFT: "DRAFT",
    ABORTED: "ABORTED",
    BOOKED: "BOOKED",
    CANCELLED: "CANCELLED",
    SUBMITTED: "SUBMITTED",
    PAYSLIPPED: "PAYSLIPPED",
    FORGOTTEN: "FORGOTTEN",
};
export const LessonProposedByEnum = { teacher: "teacher", learner: "learner", admin: "admin" };
export const LessonAbortReasonEnum = {
    refused_by_teacher: "refused_by_teacher",
    refused_by_learner: "refused_by_learner",
    aborted_by_teacher: "aborted_by_teacher",
    aborted_by_learner: "aborted_by_learner",
    aborted_by_admin: "aborted_by_admin",
    counterproposed_by_teacher: "counterproposed_by_teacher",
    counterproposed_by_learner: "counterproposed_by_learner",
    reproposed_by_teacher: "reproposed_by_teacher",
    reproposed_by_learner: "reproposed_by_learner",
    reproposed_by_admin: "reproposed_by_admin",
    too_late_teacher: "too_late_teacher",
    too_late_learner: "too_late_learner",
    too_late_both: "too_late_both",
};
export const MatchStatusEntries = {
    DRAFT: "DRAFT",
    ON_HOLD: "ON_HOLD",
    ABANDONNED: "ABANDONNED",
    ACTIVE: "ACTIVE",
    PAUSED: "PAUSED",
    FINISHED: "FINISHED",
};
const MonthlyMatchSurveyStatusEntries = {
    DRAFT: "DRAFT",
    SUBMITTED: "SUBMITTED",
};
export const PhoneCallReponseTypeEntries = {
    FAILED: "FAILED",
    NODISPO: "NODISPO",
    RESPONDEDBAD: "RESPONDEDBAD",
    RESPONDEDWELL: "RESPONDEDWELL",
};
export const PhoneCallOperatorEnum = {
    ringover: "ringover",
    keyyo: "keyyo",
};
export const RingoverLastStateEnum = {
    CANCELLED: "CANCELLED",
    ANSWERED: "ANSWERED",
    MISSED: "MISSED",
    FAILED: "FAILED",
    QUEUE_TIMEOUT: "QUEUE_TIMEOUT",
    BLIND_TRANSFERED: "BLIND_TRANSFERED",
    VOICEMAIL: "VOICEMAIL",
    PERMANENT_TRANSFERED: "PERMANENT_TRANSFERED",
    NOANSWER_TRANSFERED: "NOANSWER_TRANSFERED",
    FAX_RECEIVED: "FAX_RECEIVED",
    FAX_FAILED: "FAX_FAILED",
    FAX_OUT_SENT: "FAX_OUT_SENT",
    INCORRECT_PINCODE: "INCORRECT_PINCODE",
    FAX_OUT_NOT_SENT: "FAX_OUT_NOT_SENT",
    ANNOUNCE: "ANNOUNCE",
};
export const PlannedCallStatusEntries = {
    PLANNED: "PLANNED",
    CANCELLED: "CANCELLED",
    DONE: "DONE",
};
const ProgramStatusEntries = {
    DRAFT: "DRAFT",
    ACTIVE: "ACTIVE",
    PAUSED: "PAUSED",
    FINISHED: "FINISHED",
};
export const StandardInvoiceStatusEntries = {
    DRAFT: "DRAFT",
    READY: "READY",
    PENDING: "PENDING",
    REFUSED: "REFUSED",
    INVOICE: "INVOICE",
    ABORTED: "ABORTED",
};
const TeacherStatusEntries = {
    DRAFT: "DRAFT",
    ACTIVE: "ACTIVE",
    PAUSED: "PAUSED",
    FINISHED: "FINISHED",
};
export const TeacherPropositionStatusEntries = {
    DRAFT: "DRAFT",
    READY: "READY",
    SENDING: "SENDING",
    PENDING: "PENDING",
    REFUSED: "REFUSED",
    APPROVED: "APPROVED",
    EXPIRED: "EXPIRED",
    ABORTED: "ABORTED",
};
const TeacherResearchStatusEntries = {
    FINISH: "FINISH",
    DELAYED: "DELAYED",
    URGENT: "URGENT",
};
export const YsProcStatus = {
    DRAFT: "DRAFT",
    PENDING: "PENDING",
    SIGNED: "SIGNED",
    REFUSED: "REFUSED",
    FORCED: "FORCED",
    ABORTED: "ABORTED",
};
export class Coordinates {
    lat;
    lng;
}
export class RichComment {
    message;
    writtenAt;
    status;
    snoozeUntil;
    static defaultRaw;
}
export class RootBaseEntity extends RootEntity {
    static defaultInclude;
    static defaultExcude;
}
export class AppSetting extends RootBaseEntity {
    static __path = 'app-settings';
}
export class BaseEntity_TimestampsOnly extends RootBaseEntity {
}
export class BaseEntity extends BaseEntity_TimestampsOnly {
}
export class TeacherAbility extends BaseEntity {
    static __path = 'teacher-abilities';
}
export class MatchFormulas extends BaseEntity {
    payslipsHistory;
    lockedMonths;
    soldeReleveHistory;
    lessonsHistory;
    allMonths;
}
export class Match extends MatchFormulas {
    shouldFillPlanning;
    baseRem;
    monthInfos;
    static __path = 'matches';
}
export class FamilyFormulas extends BaseEntity {
    monthsHistory;
    lastLessonAt;
    firstLessonAt;
    allMonths;
    declaredTrimesters;
    undeclaredTrimesters;
}
export class Family extends FamilyFormulas {
    monthInfos;
    static __path = 'families';
}
export class BankTransaction extends BaseEntity {
    static __path = 'bank-transactions';
}
export class BankCheck extends BaseEntity {
    static __path = 'bank-checks';
}
export class AttestationFiscale extends BaseEntity {
    static __path = 'attestation-fiscales';
}
export class AsapTeacherBlock extends BaseEntity {
    static __path = 'asap-teacher-blocks';
}
export class AsapGlobalDeclaration extends BaseEntity {
    static __path = 'asap-global-declarations';
}
export class AsapDebit extends BaseEntity {
    $Lettrages;
    lettredAmount;
    static __path = 'asap-debits';
}
export class Agent extends BaseEntity {
    static __path = 'agents';
}
export class AcquisitionChannel extends BaseEntity {
    static __path = 'acquisition-channels';
}
export class BankCheckDeposit extends BaseEntity {
    static __path = 'bank-check-deposits';
}
export class BankAccount extends BaseEntity {
    static __path = 'bank-accounts';
}
export class AsapUrssafDeclaration extends BaseEntity {
    static __path = 'asap-urssaf-declarations';
}
export class AsapLettrage extends BaseEntity {
    static __path = 'asap-lettrages';
}
export class AsapFamilyDeclaration extends BaseEntity {
    static __path = 'asap-family-declarations';
}
export class Address extends BaseEntity {
    fullStreet;
    static __path = 'addresses';
}
export class AICIPaymentRequest extends BaseEntity {
    statusLabel;
    statusDescription;
    isTransitionnal;
    isFailed;
    isSuccess;
    static __path = 'aici-payment-requests';
}
export class BusinessContact extends BaseEntity {
    static __path = 'business-contacts';
}
export class Candidature extends BaseEntity {
    static __path = 'candidatures';
}
export class CesuCheckDeposit extends BaseEntity {
    static __path = 'cesu-check-deposits';
}
export class CesuCheckLot extends BaseEntity {
    static __path = 'cesu-check-lots';
}
export class ConciseLevel extends BaseEntity {
    static __path = 'concise-levels';
}
export class Credit extends BaseEntity {
    static __path = 'credits';
}
export class CreditInvoice extends BaseEntity {
    static __path = 'credit-invoices';
}
export class CreditInvoiceBlock extends BaseEntity {
}
export class CreationBlock extends CreditInvoiceBlock {
    static __path = 'creation-blocks';
}
export class ModificationBlock extends CreditInvoiceBlock {
    static __path = 'modification-blocks';
}
export class TransferBlock extends CreditInvoiceBlock {
    static __path = 'transfer-blocks';
}
export class CustomerProposition extends BaseEntity {
    static __path = 'customer-propositions';
}
export class Debit extends BaseEntity {
    amountC;
    amountD;
    $Lettrages;
    static __path = 'debits';
}
export class Doc extends BaseEntity {
    static __path = 'docs';
}
export class DoodleSession extends BaseEntity {
    static __path = 'doodle-sessions';
}
export class EmailTemplate extends BaseEntity {
    static __path = 'email-templates';
}
export class FCMToken extends BaseEntity {
    static __path = 'fcm-tokens';
}
export class FamilyMonth extends BaseEntity {
    static __path = 'family-months';
}
export class GocardlessPayment extends BaseEntity {
    static __path = 'gocardless-payments';
}
export class GocardlessPayout extends BaseEntity {
    static __path = 'gocardless-payouts';
}
export class HiringGoal extends BaseEntity {
    static __path = 'hiring-goals';
}
export class Holiday extends BaseEntity {
    static __path = 'holidays';
}
export class LearnerTrait extends BaseEntity {
    static __path = 'learner-traits';
}
export class LedgerAccount extends BaseEntity {
    static __path = 'ledger-accounts';
}
export class LegalInvoice extends BaseEntity {
    static __path = 'legal-invoices';
}
export class Lesson extends BaseEntity {
    static __path = 'lessons';
}
export class Level extends BaseEntity {
    static __path = 'levels';
}
export class LevelGroup extends BaseEntity {
    static __path = 'level-groups';
}
export class MainCity extends BaseEntity {
    static __path = 'main-cities';
}
export class Member extends BaseEntity {
    familyPhoneNumbers;
    static __path = 'members';
}
export class Mobility extends BaseEntity {
    static __path = 'mobilities';
}
export class MonthlyMatchSurvey extends BaseEntity {
    isComplete;
    isFinishing;
    incidentsCount;
    incidentsList;
    static __path = 'monthly-match-surveys';
}
export class Payslip extends BaseEntity {
    reportedDurationMin;
    static __path = 'payslips';
}
export class PayslipGroup extends BaseEntity {
    static __path = 'payslip-groups';
}
export class Person extends BaseEntity {
}
export class PhoneCall extends BaseEntity {
    static __path = 'phone-calls';
}
export class PhoneNumber extends BaseEntity {
    formatedNumber;
    static __path = 'phone-numbers';
}
export class PlannedCall extends BaseEntity {
    status;
    static __path = 'planned-calls';
}
export class PontoPayment extends BaseEntity {
    static __path = 'ponto-payments';
}
export class Program extends BaseEntity {
    static __path = 'programs';
}
export class Releve extends BaseEntity {
    static __path = 'releves';
}
export class SocialContributionRates extends BaseEntity {
    static __path = 'social-contribution-rates';
}
export class StandardInvoice extends BaseEntity {
    static __path = 'standard-invoices';
}
export class StudyLevel extends BaseEntity {
    static __path = 'study-levels';
}
export class TeacherFormulas extends BaseEntity {
}
export class Teacher extends TeacherFormulas {
    static __path = 'teachers';
}
export class TeacherPayment extends BaseEntity {
    static __path = 'teacher-payments';
}
export class TeacherProposition extends BaseEntity {
    baseRem;
    static __path = 'teacher-propositions';
}
export class TeacherResearch extends BaseEntity {
    static __path = 'teacher-researches';
}
export class TeacherScolarityComplement extends BaseEntity {
    static __path = 'teacher-scolarity-complements';
}
export class TeacherScolarityMain extends BaseEntity {
    static __path = 'teacher-scolarity-mains';
}
export class Teaching extends BaseEntity {
    static __path = 'teachings';
}
export class TeachingGroup extends BaseEntity {
    static __path = 'teaching-groups';
}
export class WeekDispo extends BaseEntity {
    static __path = 'teacher-week-dispos';
}
export class YearDispo extends BaseEntity {
    static __path = 'year-dispos';
}
export class YsProc extends BaseEntity {
    static __path = 'yousign-procs';
}
export class DBMonth extends BaseEntity_TimestampsOnly {
    legalInvoicesExpectedLockedOn;
    static __path = 'db-months';
}
export class Lettrage extends BaseEntity_TimestampsOnly {
    static __path = 'lettrages';
}
export class SlackMessage extends BaseEntity_TimestampsOnly {
    static __path = 'slack-messages';
}
export class TeachingLevel extends BaseEntity_TimestampsOnly {
    static __path = 'teaching-levels';
}
export class Urssaf extends BaseEntity_TimestampsOnly {
    static __path = 'urssafs';
}
const _Ignored_ = null;
